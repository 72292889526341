import { myAxios } from "./helper";
import { getToken } from "./user-service";

export const addCreditCard = (creditCard, token) => {
    return myAxios.post("/credit-card/", creditCard, { headers: { authorization: token } }).then((response) => response.data);
};
export const getCreditCards = (token, userId) => {
    return myAxios.get('/credit-card/' + userId, { headers: { authorization: token } }).then((response) => response.data);
};
export const updateCreditCard = (creditCard, token,) => {
    return myAxios.put("/credit-card/", creditCard, { headers: { authorization: token } }).then((response) => response.data);
};
export const getSingleCreditCard = (token, id) => {
    return myAxios.get('/credit-card/credit-card/' + id, { headers: { authorization: token } }).then((response) => response.data);
};

export const deleteCreditCard = (creditCardId) => {
    let token = "Bearer " + getToken();
    return myAxios.delete(`/credit-card/credit-card/${creditCardId}`, { headers: { authorization: token } }).then((response) => response.data);
}
