import {
  faDownload,
  faMinus,
  faPlus,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Option, Select, Textarea } from "@mui/joy";
import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
} from "reactstrap";
import SideBar from "../../components/sidebar/Sidebar";
import UserBase from "../../components/user/UserBase";
import "../../css/myestare.css";
import Deletebutton from "./Deletebutton";
import UpdateButton from "./UpdateButton";

import { toast } from "react-toastify";
import { formatAmount } from "../../components/Common";
import "../../css/formPOPup.css";
import {
  deleteOtherAsset,
  getOtherAsset,
  otherAsset,
} from "../../services/OtherAssetService";
import {
  deleteSingleProperty,
  downloadDocument1,
  fetchNetWorth,
  getBeneficiary,
  getOwnerByUserId,
  getSecondaryUser,
  getToken,
  getUser,
} from "../../services/user-service";
import { ConfirmationPopup } from "./ConfirmationPopup";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";

const initialOtherAssetData = {
  otherAsset: {
    owner: "",
    otherAssetListName: "",
    assetCaption: "",
    assetValue: "",
    assetName: "",
    notes: "",
  },
  sharedDetails: [
    {
      distributedType: "",
      distributedValue: "",
      distributedAmount: "",
      beneficiaryId: "",
    },
  ],
};

function OtherAssets() {
  const [otherAssetList, setOtherAssetList] = useState([initialOtherAssetData]);
  const [files, setFiles] = useState([]);

  const [modal, setModal] = useState(false);
  const [otherAssetListNameValue, setOtherAssetListNameValue] = useState("");
  const handleChangeAddOtherAssetListName = (event) => {
    const value = event.target.value;
    setOtherAssetListNameValue(value);
  };
  const handleChangeConfirm = () => {
    if (otherAssetListNameValue !== "") {
      setOtherAssetList((prevOtherAssetList) => {
        const updatedOtherAssetList = [...prevOtherAssetList];

        updatedOtherAssetList[0].otherAsset.otherAssetListName =
          otherAssetListNameValue;
        return updatedOtherAssetList;
      });
      setForm1(!form1);
    }
    toggleOtherAssetList();
  };
  const toggleOtherAssetList = () => {
    setModal(false);
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);
  const handleChange = (event, newValue, field, index, type = "otherAsset") => {
    if (field === "owner") {
      const ownerString = Array.isArray(newValue)
        ? newValue.join(", ")
        : newValue;

      setOtherAssetList((prevOtherAssetList) =>
        prevOtherAssetList.map((item, i) =>
          i === index
            ? {
                ...item,
                [type]: {
                  ...item[type],
                  owner: ownerString,
                },
              }
            : item
        )
      );

      setOwnerName((prevOwnerNames) => {
        const updatedOwnerNames = [...prevOwnerNames];
        updatedOwnerNames[index] = newValue;
        return updatedOwnerNames;
      });
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  const handleChanges = (e, field, index, type = "otherAsset") => {
    const { value } = e.target;

    setOtherAssetList((prevOtherAssetList) =>
      prevOtherAssetList.map((otherAsset, i) => {
        // If the field is "otherAssetListName" and the length is greater than 0
        if (field === "otherAssetListName" && prevOtherAssetList.length > 0) {
          return {
            ...otherAsset,
            [type]: {
              ...otherAsset[type],
              otherAssetListName: value, // Set otherAssetListName to the new value for all items
            },
          };
        }

        // Default behavior for other fields
        return i === index
          ? {
              ...otherAsset,
              [type]: {
                ...otherAsset[type],
                [field]: value,
              },
            }
          : otherAsset;
      })
    );
  };

  // Handle image
  const handleImageChange = (event, indexValue) => {
    const index = indexValue;
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        // Update the files state for the current index
        setFiles((prevFiles) => ({
          ...prevFiles,
          [index]: selectedFilesArray, // Store files for the current index
        }));
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    } else {
      // If no files selected, clear the files for the current index
      setFiles((prevFiles) => ({
        ...prevFiles,
        [index]: [], // Clear files for the current index
      }));
    }
  };

  const resetForm = () => {
    setFiles([]);
    setOwnerName([]);
    setOtherAssetList([initialOtherAssetData]);
    setOtherAssetListNameValue("");
  };

  // Set the form
  const otherAssestForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();

    if (otherAssetList[0].otherAssetListName === "") {
      // console.log("Error log");
      toast.error("Please fill all required feilds.");
      return;
    }
    // create form data to send a file and remaining class data
    const formData = new FormData();

    formData.append("data", JSON.stringify(otherAssetList));

    Object.keys(files).forEach((index) => {
      files[index].forEach((file) => {
        formData.append(`files${index}`, file);
      });
    });

    console.log("data: ", otherAssetList);

    otherAsset(formData, token)
      .then((resp) => {
        AddCard();
        toast.success("Data Added !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        fetchNetWorth(primaryUserDetails.commonId);
        toggle(null, null, "close");
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [currentIdType, setCurrentIdType] = useState("");
  const removeOtherAsset = (id, idType) => {
    setShowConfirmationDelete(true);
    setCurrentId(id);
    setCurrentIdType(idType);
  };

  const handleConfirmationDeleteClose = () => {
    setShowConfirmationDelete(false);
  };

  const handleRemove = (params) => {
    setShowConfirmationDelete(false);
    const { Id, idType } = params;
    if (idType === "otherAssetId") {
      deleteOtherAsset(Id)
        .then((res) => {
          toast.success("Deleted successfully...", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          AddCard();
          setShow1(false);
        })
        .catch((error) => {
          // console.log("Note Deleted " + error);s
        });
    } else {
      deleteSingleProperty(Id)
        .then((res) => {
          setBeneficiaryVisible(!beneficiaryVisible);
          setShow1(false);
          AddCard();
          toast.success("Deleted successfully...", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        })
        .catch((error) => {
          // console.log("Note Deleted " + error);
        });
    }
  };

  const handleDownload = (id, fileName) => {
    let myarry = fileName.split(".");

    downloadDocument1(id)
      .then((response) => {
        console.log("files in downlaod", response);
        const downloadUrl = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `${myarry[0]}.${myarry[1]}`;
        link.click();
        URL.revokeObjectURL(downloadUrl);
      })
      .catch((error) => {});
  };

  const [show, setShow] = useState(true);

  // show notes popup
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedNote, setSelectedNote] = useState("");

  const handleOpenPopup = (note) => {
    setSelectedNote(note);
    setPopupVisible(true);
  };

  // for multiple download
  const [popupVisibleDownlaod, setPopupVisibleDownlaod] = useState(false);
  const [selectedDownlaod, setSelectDownload] = useState("");

  const handleShowDownlaod = (showDetail) => {
    setPopupVisibleDownlaod(true);
    setSelectDownload(showDetail);
  };

  let [form1, setForm1] = useState(false);

  const [existingOtherAssetListName, setExistingOtherAssetListName] =
    useState(null);
  const toggle = (event, value, closePopup) => {
    setExistingOtherAssetListName(value);
    resetForm();
    if (closePopup) {
      setForm1(false);
      setOtherAssetListNameValue("");
      return;
    }
    if (value) {
      setForm1(true);
    } else {
      if (otherAssetListNameValue !== "") {
        setForm1(false);
      } else {
        setModal(true);
      }
    }
  };

  useEffect(() => {
    if (existingOtherAssetListName) {
      setOtherAssetList((prevOtherAssetList) =>
        prevOtherAssetList.map((item) => ({
          ...item,
          otherAsset: {
            ...item.otherAssetListName,
            otherAssetListName: existingOtherAssetListName, // Set the new jewelryListName
          },
        }))
      );
    }
  }, [existingOtherAssetListName]);

  // cards
  let [card, setCard] = useState([]); // card = [ {} , {} , {}] - include the form data going to use it for card
  let [showDetail, setShowDetail] = useState([]); // this is to display the card details
  let [show1, setShow1] = useState(false);

  // card creating
  const AddCard = () => {
    let userId = getUser().commonId;
    let token = "Bearer " + getToken(); // Added 'Bearer'
    getOtherAsset(token, userId)
      .then((res) => {
        setCard(res);
      })
      .catch((error) => {
        setCard([]);
        console.error(error); // Changed to console.error for better visibility of errors
      });
  };

  // showing the details of cards like popup
  const Showdetails = (obj) => {
    // const arrayFromObject = Object.keys(obj).map(key => obj[key]);
    setShowDetail(obj);
    setShow1(true);
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);

  const getBenificiarydata = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    AddCard();
    getBenificiarydata();
  }, []);

  // for add field pop
  let [showAdditionField, SetshowAdditionField] = useState(false);
  // for assests show
  let [showAssets, SetshowAssets] = useState(false);

  const [isTextFieldClicked, setIsTextFieldClicked] = useState(false);

  //
  const [benevisible, setbeneVisible] = useState(false);
  const [distributionType, setDistributionType] = useState("");
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [beneficiaryVisible, setBeneficiaryVisible] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");
  const [currentBeneficiaryIndex, setCurrentBeneficiaryIndex] = useState(null);

  const handleShowBeneficiary = (event, indexValue) => {
    setCurrentBeneficiaryIndex(indexValue);
    setShow1(false);
    setbeneVisible(true);
  };

  const handleReset = () => {
    setbeneVisible(false);
    setDistributionType("");
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
  };

  const handleDistributionTypeChange = (event) => {
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributionType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", value: "" },
      });
    }
  };

  const handleBeneficiaryClose = (beneficiary) => {
    const updatedBeneficiaries = selectedBeneficiaries.filter(
      (b) => b !== beneficiary
    );
    setSelectedBeneficiaries(updatedBeneficiaries);
    const updatedDetails = { ...beneficiaryDetails };
    delete updatedDetails[beneficiary];
    setBeneficiaryDetails(updatedDetails);
  };

  const getBenificiaryName = (id) => {
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }
    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found";
    }
  };

  const handleFieldChange = (beneficiary, field, value) => {
    const estimatedValue = parseFloat(
      otherAssetList[currentBeneficiaryIndex]?.otherAsset?.assetValue
    );
    if (!estimatedValue || estimatedValue === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }

    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }

    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.value);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });

    if (totalPercentage > 100 || totalValue > estimatedValue) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributionType,
    balance,
    beneficiaryDetail
  ) => {
    if (distributionType === "percentage") {
      console.log("percentage: ", beneficiaryDetail?.percentage);
      return (
        (parseFloat(balance) * parseFloat(beneficiaryDetail?.percentage || 0)) /
        100
      ).toFixed(2);
    } else if (distributionType === "dollar") {
      console.log("dollar: ", beneficiaryDetail?.value);
      return parseFloat(beneficiaryDetail?.value || 0).toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    const otherAssetIndex = currentBeneficiaryIndex;
    console.log("index: ", otherAssetIndex);
    console.log(
      "index balance: ",
      otherAssetList[otherAssetIndex]?.otherAsset?.assetValue
    );

    if (otherAssetIndex === null || !otherAssetList[otherAssetIndex]) {
      console.error("Invalid otherAsset index");
      return;
    }

    let i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.value);

      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          otherAssetList[otherAssetIndex]?.otherAsset?.assetValue,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i,
          otherAssetIndex
        );
        i += 1;
      }

      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          otherAssetList[otherAssetIndex]?.otherAsset?.assetValue,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i,
          otherAssetIndex
        );
        i += 1;
      }
    });
    handleReset();
  };

  const handleChanges2 = (t, v, a, b, i, otherAssetIndex) => {
    // Ensure otherAssetList and index are valid
    if (
      !Array.isArray(otherAssetList) ||
      otherAssetList[otherAssetIndex] === undefined
    ) {
      console.error("Invalid otherAssetList array or index");
      return;
    }

    // Check if the index i is within the bounds of the array
    if (i >= 0) {
      // Create a new object to update the specific element at index i
      const updatedElement = {
        distributedType: t,
        distributedValue: v,
        distributedAmount: a,
        beneficiaryId: b,
      };

      // Use the functional form of setState to ensure the current state is used
      setOtherAssetList((prevOtherAssetList) => {
        const updatedSharedDetails = [
          ...prevOtherAssetList[otherAssetIndex].sharedDetails,
        ];
        updatedSharedDetails[i] = updatedElement;

        const updatedOtherAssetList = [...prevOtherAssetList];
        updatedOtherAssetList[otherAssetIndex] = {
          ...updatedOtherAssetList[otherAssetIndex],
          sharedDetails: updatedSharedDetails,
        };

        return updatedOtherAssetList;
      });
    } else {
      console.error("Index out of bounds");
    }
  };

  const handleOpenBeneficiary = (showDetail) => {
    setSelectedBeneficiary(showDetail);
    setBeneficiaryVisible(true);
  };

  // const uniqueJewelryTypes = [
  //   ...new Set(
  //     card
  //       .map((entity) => entity.otherAsset.jewelryName)
  //       .filter((jewelryName) => jewelryName !== "Other")
  //   ),
  // ];
  // const uniqueOtherJewelryTypes = [
  //   ...new Set(
  //     card
  //       .map((entity) => entity.otherAsset.otherJewelryName)
  //       .filter(
  //         (otherJewelryName) =>
  //           otherJewelryName !== null && otherJewelryName !== ""
  //       )
  //   ),
  // ];

  // const base64ToBlob = (base64Data) => {
  //   const byteCharacters = atob(base64Data);
  //   const byteNumbers = new Array(byteCharacters.length);
  //   for (let i = 0; i < byteCharacters.length; i++) {
  //     byteNumbers[i] = byteCharacters.charCodeAt(i);
  //   }
  //   const byteArray = new Uint8Array(byteNumbers);
  //   return new Blob([byteArray], { type: "image/jpeg" }); // Adjust type if needed
  // };

  const addOtherAssetRow = () => {
    setOtherAssetList((prevOtherAssetList) => [
      ...prevOtherAssetList,
      {
        ...initialOtherAssetData,
        otherAsset: {
          ...initialOtherAssetData.otherAsset,
          otherAssetListName:
            prevOtherAssetList[0]?.otherAsset.otherAssetListName || "",
        },
      },
    ]);
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const removeOtherAssetRow = (index) => {
    setShowConfirmation(true);
    setCurrentIndex(index);
  };

  const handleConfirmation = () => {
    setShowConfirmation(false);
    const index = currentIndex;
    setOtherAssetList(otherAssetList.filter((_, i) => i !== index));

    setFiles((prevFiles) => {
      const updatedFiles = Object.keys(prevFiles).reduce((acc, key) => {
        const numericKey = parseInt(key, 10);
        if (numericKey !== index) {
          const newIndex = numericKey > index ? numericKey - 1 : numericKey;
          acc[newIndex] = prevFiles[key];
        }
        return acc;
      }, {});

      return updatedFiles;
    });

    // Update ownerName by shifting indices after removal
    setOwnerName((prevOwnerNames) => {
      const updatedOwnerNames = prevOwnerNames.filter((_, i) => i !== index);
      return updatedOwnerNames;
    });
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  // Group and consolidate data
  const consolidated = card.reduce((acc, item) => {
    const listName = item.otherAsset.otherAssetListName;

    if (!acc[listName]) {
      acc[listName] = {
        otherAssetListName: listName,
        data: [],
      };
    }

    acc[listName].data.push({
      otherAsset: item.otherAsset,
      documents: item.documents,
      sharedDetails: item.sharedDetails,
    });

    return acc;
  }, {});

  // Convert consolidated object to an array
  const uniqueOtherAssetListResult = Object.values(consolidated);

  return (
    <div className={`your-component ${show ? "fade-in-element" : ""}`}>
      <UserBase>
        <div className="mt-5"></div>
        <SideBar>
          {primaryUserDetails.accountType === "secondary" &&
          secondaryUserDetails.accountType === "primary" &&
          secondaryUserDetails.isSecondaryUserEditable === "false" ? (
            ""
          ) : (
            <div className="addme">
              <div className="addme_inner">
                <button onClick={() => toggle()}>Add New Assets</button>
              </div>
            </div>
          )}

          <div className="propCard">
            <div className="propCard-card">
              {uniqueOtherAssetListResult.map((item, index) => (
                <div
                  className="propCard-card-body"
                  style={{ padding: "20px 20px 10px 20px" }}
                >
                  <div className="propCard_card_status">
                    <p
                      className={`propCard_card_status_text ${
                        item.data.every(
                          (dataItem) => dataItem.sharedDetails.length > 0
                        )
                          ? "completed"
                          : "incomplete"
                      }`}
                    >
                      {item.data.every(
                        (dataItem) => dataItem.sharedDetails.length > 0
                      )
                        ? "completed"
                        : "incomplete"}
                    </p>
                  </div>

                  <h5 className="propCard-card-title">
                    {item.otherAssetListName}
                  </h5>
                  <div className="propCard-btn">
                    <p className="viewDetails">
                      <button
                        className="jewelry_add_more"
                        onClick={(event) =>
                          toggle(event, item.otherAssetListName)
                        }
                      >
                        Add More
                      </button>
                      <button
                        style={{ margin: "10px 0" }}
                        onClick={() => Showdetails(item.data)}
                      >
                        View Details
                      </button>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {form1 && (
            <div
              className="overlay1"
              style={{ transition: "500ms", height: "" }}
            >
              <div>
                <Form onSubmit={otherAssestForm} className="addform_body">
                  <container>
                    <div className="homepage jewelry_container">
                      <Card>
                        <CardHeader>
                          <div
                            style={{ width: "100%" }}
                            className="addform_heading"
                          >
                            <h3 className="addform_heading_h1">
                              {otherAssetListNameValue &&
                                otherAssetListNameValue}
                              {existingOtherAssetListName &&
                                existingOtherAssetListName}
                            </h3>
                            <div
                              className="addform_close"
                              onClick={(event) => toggle(event, "", "close")}
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody className="jewelry_card_body">
                          <div>
                            <table className="jewelry_table">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th className="otherAsset_th ">
                                    Select Owner*
                                  </th>
                                  <th className="otherAsset_th otherAsset_150px">
                                    Asset Caption
                                  </th>
                                  <th className="otherAsset_th otherAsset_150px">
                                    Asset Name*
                                  </th>
                                  <th className="otherAsset_th otherAsset_150px">
                                    Asset Value*
                                  </th>

                                  <th className="otherAsset_150px">Document</th>
                                  <th style={{ width: "190px" }}>
                                    Select Your Beneficiary*
                                  </th>
                                  <th className="otherAsset_th">Notes</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {otherAssetList.map((row, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      <Select
                                        className="jewelry_select"
                                        placeholder="Select Owner"
                                        value={ownerName[index] || []}
                                        multiple
                                        onChange={(event, newValue) =>
                                          handleChange(
                                            event,
                                            newValue,
                                            "owner",
                                            index,
                                            "otherAsset"
                                          )
                                        }
                                        required
                                      >
                                        {ownerNames.map((name) => (
                                          <Option key={name} value={name}>
                                            {name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </td>

                                    <td>
                                      <Input
                                        className="otherAsset_input otherAsset_150px"
                                        placeholder="Asset Caption"
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "assetCaption",
                                            index,
                                            "otherAsset"
                                          )
                                        }
                                        value={
                                          otherAssetList[index]?.otherAsset
                                            ?.assetCaption || ""
                                        }
                                      />
                                    </td>

                                    <td>
                                      <Input
                                        className="otherAsset_input otherAsset_150px"
                                        type="text"
                                        placeholder="Asset Name"
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "assetName",
                                            index,
                                            "otherAsset"
                                          )
                                        }
                                        value={
                                          otherAssetList[index]?.otherAsset
                                            ?.assetName || ""
                                        }
                                        required
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        className="otherAsset_input otherAsset_150px"
                                        id={`assetValue${index}`}
                                        placeholder="Asset Value"
                                        type="number"
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "assetValue",
                                            index,
                                            "otherAsset"
                                          )
                                        }
                                        value={
                                          otherAssetList[index]?.otherAsset
                                            ?.assetValue || ""
                                        }
                                        startDecorator={<div>$</div>}
                                        required
                                      />
                                    </td>

                                    <td>
                                      <div className="jewelry_choose_files otherAsset_150px">
                                        <div>
                                          <label
                                            className="otherAsset_choose_file_label"
                                            htmlFor={`files${index}`}
                                          >
                                            Choose Files
                                          </label>
                                        </div>
                                        {files[index] && (
                                          <div>
                                            <label className="jewelry_choose_file_file">
                                              {files[index]?.length === 1
                                                ? `${
                                                    files[index][0].name
                                                      .length > 15
                                                      ? files[
                                                          index
                                                        ][0].name.substring(
                                                          0,
                                                          5
                                                        ) +
                                                        "..." +
                                                        files[
                                                          index
                                                        ][0].name.slice(-7)
                                                      : files[index][0].name
                                                  }`
                                                : `${files[index]?.length} files`}
                                            </label>
                                          </div>
                                        )}

                                        <input
                                          style={{
                                            display: "none",
                                          }}
                                          id={`files${index}`}
                                          type="file"
                                          name="myfile"
                                          onChange={(e) =>
                                            handleImageChange(e, index)
                                          }
                                          accept=".pdf"
                                          multiple
                                        />
                                      </div>
                                    </td>

                                    <td style={{ width: "190px" }}>
                                      <div
                                        style={{ width: "190px" }}
                                        className="bene-select beneficiary_btn"
                                        onClick={(e) =>
                                          handleShowBeneficiary(e, index)
                                        }
                                      >
                                        Select Your Beneficiary
                                      </div>
                                    </td>
                                    <td>
                                      <Textarea
                                        style={{ fontSize: "14px" }}
                                        placeholder="Notes"
                                        id="notes"
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "notes",
                                            index,
                                            "otherAsset"
                                          )
                                        }
                                        value={
                                          otherAssetList[index]?.otherAsset
                                            ?.notes || ""
                                        }
                                      />
                                    </td>
                                    <td>
                                      <div className="jewelry_action">
                                        {otherAssetList.length > 1 && (
                                          <Button
                                            className="jewelry_remove_button"
                                            onClick={() =>
                                              removeOtherAssetRow(index)
                                            }
                                          >
                                            <FontAwesomeIcon icon={faMinus} />
                                          </Button>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div style={{ marginTop: "8px" }}>
                              <Button
                                className="jewelry_add_more_button"
                                type="button"
                                onClick={addOtherAssetRow}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </Button>
                            </div>
                          </div>
                        </CardBody>
                        <CardFooter>
                          <div className="text-center">
                            <Button
                              className="my-estate-clear-btn"
                              onClick={resetForm}
                              type="reset"
                              outline
                            >
                              Clear
                            </Button>
                            <Button
                              outline
                              type=""
                              className="my-estate-add-btn"
                            >
                              Add
                            </Button>
                          </div>
                        </CardFooter>
                      </Card>
                    </div>
                  </container>
                </Form>
              </div>
            </div>
          )}

          {modal && (
            <div>
              <Modal
                show={modal}
                toggle={toggleOtherAssetList}
                style={{ paddingTop: "50px" }}
              >
                <ModalBody style={{ color: "black" }}>
                  Please create your miscellaneous asset list here:
                  <div>
                    <Input
                      type="text"
                      value={otherAssetListNameValue}
                      placeholder="Enter your miscellaneous asset list name"
                      onChange={handleChangeAddOtherAssetListName}
                    />
                  </div>
                </ModalBody>
                <ModalFooter style={{ padding: "5px" }}>
                  <Button
                    className="confirm_delete_btn"
                    onClick={handleChangeConfirm}
                  >
                    Add
                  </Button>{" "}
                  <Button
                    className="confirm_cancel_btn"
                    onClick={toggleOtherAssetList}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          )}

          {showConfirmation && (
            <ConfirmationPopup
              handleConfirmation={handleConfirmation}
              handleConfirmationClose={handleConfirmationClose}
              anyText="Do you want to remove item from this list?"
            />
          )}

          {showConfirmationDelete && (
            <Deletebutton
              handleRemove={handleRemove}
              Id={currentId}
              idType={currentIdType}
              handleConfirmationClose={handleConfirmationDeleteClose}
            />
          )}

          {show1 && (
            <>
              <div className="popup">
                <div className="popup-content popup-content-download">
                  <div className="note_popup">
                    <div className="note_popup_heading">
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <h2>{`${showDetail[0]?.otherAsset?.otherAssetListName} Details`}</h2>
                      </div>
                      <div>
                        <button
                          className="note_popup_heading_close_btn"
                          onClick={() => {
                            setShow1(false);
                          }}
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </button>
                      </div>
                    </div>
                    <div>
                      {showDetail.map((details, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "5px",
                            padding: "10px",
                            border: "solid 1px lightgray",
                            borderRadius: "5px",
                            minWidth: "230px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* Left side */}
                            <div>
                              <p
                                style={{
                                  fontSize: "17px",
                                  color: "black",
                                  fontWeight: "500",
                                }}
                              >
                                {`Item - ${index + 1}`}
                                {"  "}
                                <span
                                  className={`propCard_card_status_text ${
                                    details.sharedDetails.length > 0
                                      ? "completed"
                                      : "incomplete"
                                  }`}
                                >
                                  {details.sharedDetails.length > 0
                                    ? "completed"
                                    : "incomplete"}
                                </span>
                              </p>
                            </div>
                            {/* Right side */}
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div className="me-2">
                                {details.documents &&
                                  details.documents.length > 0 && (
                                    <Tooltip title="click to see multiple download files">
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          handleShowDownlaod(details);
                                          setShow1(false);
                                        }}
                                      >
                                        <div className="myestate_download_button dwnbtn">
                                          <FontAwesomeIcon
                                            className="myestate_download_icon"
                                            icon={faDownload}
                                          />
                                          <span></span>
                                        </div>
                                      </div>
                                    </Tooltip>
                                  )}
                              </div>
                              {primaryUserDetails.accountType === "secondary" &&
                              secondaryUserDetails.accountType === "primary" &&
                              secondaryUserDetails.isSecondaryUserEditable ===
                                "false" ? (
                                ""
                              ) : (
                                <div className="me-2">
                                  <Tooltip title="Click Here To Edit">
                                    <div>
                                      <UpdateButton
                                        URL={"../my-estate/otherAsset/"}
                                        id={details.otherAsset.id}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              )}

                              {primaryUserDetails.accountType === "secondary" &&
                              secondaryUserDetails.accountType === "primary" &&
                              secondaryUserDetails.isSecondaryUserEditable ===
                                "false" ? (
                                ""
                              ) : (
                                <FontAwesomeIcon
                                  onClick={() =>
                                    removeOtherAsset(
                                      details.otherAsset.id,
                                      "otherAssetId"
                                    )
                                  }
                                  className="delete-button-comp"
                                  style={{ cursor: "pointer" }}
                                  icon={faTrash}
                                />
                              )}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",

                              flexWrap: "wrap", // Allow wrapping to next line if needed
                            }}
                            className="mt-2"
                          >
                            {/* Left section */}
                            <div style={{ flex: "1", minWidth: "250px" }}>
                              {details.otherAsset.owner && (
                                <p
                                  style={{ color: "black", fontWeight: "500" }}
                                >
                                  Owner :{" "}
                                  {details.otherAsset.owner && (
                                    <span>{details.otherAsset.owner}</span>
                                  )}
                                </p>
                              )}

                              {details.otherAsset.assetName && (
                                <p
                                  style={{ color: "black", fontWeight: "500" }}
                                >
                                  Asset Name :{" "}
                                  <span>
                                    {details.otherAsset.assetName && (
                                      <span>
                                        {details.otherAsset.assetName}
                                      </span>
                                    )}
                                  </span>
                                </p>
                              )}

                              <p style={{ color: "black", fontWeight: "500" }}>
                                Asset Value :{" "}
                                <span>
                                  {details.otherAsset.assetValue && (
                                    <span
                                      style={{
                                        color: "green",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {formatAmount(
                                        details.otherAsset.assetValue
                                      )}
                                    </span>
                                  )}
                                </span>
                              </p>
                            </div>

                            {/* Right section */}
                            <div style={{ flex: "0.5", minWidth: "250px" }}>
                              {details.otherAsset.assetCaption && (
                                <p
                                  style={{ color: "black", fontWeight: "500" }}
                                >
                                  Asset Caption :{" "}
                                  <span>
                                    {details.otherAsset.assetCaption && (
                                      <span>
                                        {details.otherAsset.assetCaption}
                                      </span>
                                    )}
                                  </span>
                                </p>
                              )}

                              {details.sharedDetails[0] && (
                                <p
                                  style={{ color: "black", fontWeight: "500" }}
                                  onClick={() => {
                                    handleOpenBeneficiary(details);
                                    setShow1(false);
                                  }}
                                >
                                  Beneficiary Details{" "}
                                  <span>
                                    <span className="readmore">Click Here</span>
                                  </span>
                                </p>
                              )}

                              {details.otherAsset.notes && (
                                <Tooltip title="Click To see Note">
                                  <p
                                    style={{
                                      color: "black",
                                      fontWeight: "500",
                                    }}
                                    onClick={() => {
                                      handleOpenPopup(details.otherAsset.notes);
                                      setShow1(!show1);
                                    }}
                                  >
                                    Note:{" "}
                                    <span>
                                      {" "}
                                      {details && details.otherAsset.notes
                                        ? details.otherAsset.notes.slice(0, 5)
                                        : ""}
                                      ...
                                      <span className="readmore">
                                        Read More
                                      </span>
                                    </span>
                                  </p>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/*  download popup */}
          {popupVisibleDownlaod && (
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h2>Download Files</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisibleDownlaod(false);
                          setShow(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>

                  <div>
                    <div style={{ marginBottom: "20px" }}>
                      <Tooltip title={selectedDownlaod.name}>
                        <div
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            gap: "20px",
                          }}
                          onClick={() => {
                            handleDownload(selectedDownlaod.name, 0);
                          }}
                        >
                          Downlaod - 1
                          <div className="myestate_download_button dwnbtn">
                            <FontAwesomeIcon
                              className="myestate_download_icon"
                              icon={faDownload}
                            />
                            <span>{selectedDownlaod.name}</span>
                          </div>
                        </div>
                      </Tooltip>
                    </div>

                    {selectedDownlaod.name1 && (
                      <div style={{ marginBottom: "20px" }}>
                        <Tooltip title={selectedDownlaod.name1}>
                          <div
                            //  value = {showDetail.user.id}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              gap: "20px",
                            }}
                            onClick={() => {
                              handleDownload(selectedDownlaod.name1, 1);
                            }}
                          >
                            Downlaod - 2
                            <div className="myestate_download_button dwnbtn">
                              <FontAwesomeIcon
                                className="myestate_download_icon"
                                icon={faDownload}
                              />
                              <span>{selectedDownlaod.name1}</span>
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    )}

                    {selectedDownlaod.name2 && (
                      <div style={{ marginBottom: "20px" }}>
                        <Tooltip title={selectedDownlaod.name2}>
                          <div
                            //  value = {showDetail.user.id}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              gap: "20px",
                            }}
                            onClick={() => {
                              handleDownload(selectedDownlaod.name2, 2);
                            }}
                          >
                            Download - 3
                            <div className="myestate_download_button dwnbtn">
                              <FontAwesomeIcon
                                className="myestate_download_icon"
                                icon={faDownload}
                              />
                              <span>{selectedDownlaod.name2}</span>
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    )}

                    {selectedDownlaod.name3 && (
                      <div style={{ marginBottom: "20px" }}>
                        <Tooltip title={selectedDownlaod.name3}>
                          <div
                            //  value = {showDetail.user.id}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              gap: "20px",
                            }}
                            onClick={() => {
                              handleDownload(selectedDownlaod.name3, 3);
                            }}
                          >
                            Download - 4
                            <div className="myestate_download_button dwnbtn">
                              <FontAwesomeIcon
                                className="myestate_download_icon"
                                icon={faDownload}
                              />
                              <span>{selectedDownlaod.name3}</span>
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    )}

                    {selectedDownlaod.name4 && (
                      <div style={{ marginBottom: "20px" }}>
                        <Tooltip title={selectedDownlaod.name4}>
                          <div
                            //  value = {showDetail.user.id}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              gap: "20px",
                            }}
                            onClick={() => {
                              handleDownload(selectedDownlaod.name4, 4);
                            }}
                          >
                            Downlaod - 5
                            <div className="myestate_download_button dwnbtn">
                              <FontAwesomeIcon
                                className="myestate_download_icon"
                                icon={faDownload}
                              />
                              <span>{selectedDownlaod.name4}</span>
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {popupVisible && (
            // Popup div
            <div className="popup">
              <div className="popup-content">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div>
                      <h2>Notes</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisible(false);
                          setShow1(!show1);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div>
                    <p>{selectedNote}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {showAdditionField && (
            // Display the popup when a row is selected
            <div className="popup">
              <div className="popup-content">
                <div className="note_popup_heading">
                  <div>
                    <h2>All Additional Field</h2>
                  </div>
                  <div>
                    <button
                      className="note_popup_heading_close_btn"
                      onClick={() => {
                        SetshowAdditionField(false);
                        setShow1(!show1);
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  </div>
                </div>
                <div className="popup-body">
                  {showAdditionField.addfield1 !== 0 &&
                  showAdditionField.addfield1 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      New Field 1 : {showAdditionField.addfield1}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {showAdditionField.addfield2 !== 0 &&
                  showAdditionField.addfield2 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      New Field 2 : {showAdditionField.addfield2}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {showAdditionField.addfield3 !== 0 &&
                  showAdditionField.addfield3 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      New Field 3 : {showAdditionField.addfield3}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {showAdditionField.addfield4 !== 0 &&
                  showAdditionField.addfield4 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      New Field 4 : {showAdditionField.addfield4}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {showAdditionField.addfield5 !== 0 &&
                  showAdditionField.addfield5 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      New Field 5 : {showAdditionField.addfield5}
                    </p>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
            </div>
          )}

          {showAssets && (
            // Display the popup when a row is selected
            <div className="popup">
              <div className="popup-content">
                <div className="note_popup_heading">
                  <div>
                    <h2>All Additional Assets</h2>
                  </div>
                  <div>
                    <button
                      className="note_popup_heading_close_btn"
                      onClick={() => {
                        SetshowAssets(false);
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  </div>
                </div>
                <div className="popup-body">
                  {showAssets.otherAsset.assetName !== 0 &&
                  showAssets.otherAsset.assetName !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      Other Field 1 : {showAssets.otherAsset.assetName}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {showAssets.otherAsset.otherAssets2 !== 0 &&
                  showAssets.otherAsset.otherAssets2 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      Other Field 2 : {showAssets.otherAsset.otherAssets2}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {showAssets.otherAsset.otherAssets3 !== 0 &&
                  showAssets.otherAsset.otherAssets3 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      Other Field 3 : {showAssets.otherAsset.otherAssets3}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {showAssets.otherAsset.otherAssets4 !== 0 &&
                  showAssets.otherAsset.otherAssets4 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      Other Field 4 : {showAssets.otherAsset.otherAssets4}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {showAssets.otherAsset.otherAssets5 !== 0 &&
                  showAssets.otherAsset.otherAssets5 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      Other Field 5 : {showAssets.otherAsset.otherAssets5}
                    </p>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
            </div>
          )}

          {/*  download popup */}
          {popupVisibleDownlaod && (
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h2>Download Files</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisibleDownlaod(false);
                          setShow1(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>

                  <div>
                    {selectedDownlaod.documents &&
                      selectedDownlaod.documents.length > 0 &&
                      selectedDownlaod.documents.map((file, index) => (
                        <div key={index} style={{ marginBottom: "20px" }}>
                          <Tooltip title={file.fileName}>
                            <div
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                gap: "20px",
                              }}
                              onClick={() => {
                                handleDownload(file.id, file.fileName);
                              }}
                            >
                              Download - {index + 1}
                              <div className="myestate_download_button dwnbtn">
                                <FontAwesomeIcon
                                  className="myestate_download_icon"
                                  icon={faDownload}
                                />
                                <span>{file.fileName}</span>
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {benevisible && (
            // beneShow &&
            <div className="popup">
              <div
                className="popup-content popup-content-download"
                style={{
                  minWidth: "350px",
                  width: "100%",
                  maxWidth: "700px",
                }}
              >
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div className="share_property_heading">
                      <h2>Share Property </h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={handleReset}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div className="share_property_est_value">
                    <p>
                      Estimated Value:{" "}
                      <code style={{ color: "green", fontWeight: "bold" }}>
                        $
                        {
                          otherAssetList[currentBeneficiaryIndex]?.otherAsset
                            ?.assetValue
                        }
                      </code>
                    </p>
                  </div>
                  <div className="BeneficiarySelect">
                    <div className="BeneficiarySelectContainer">
                      <div className="BeneficiarySelectRow">
                        <div className="share_property_Type">
                          <p className="share_property_Type_paragraph">
                            Choose Distribution Type:{" "}
                          </p>
                          <select
                            value={distributionType}
                            onChange={handleDistributionTypeChange}
                            className="share_property_Type_select"
                          >
                            <option value="">Select Type</option>
                            <option value="percentage">Percentage</option>
                            <option value="dollar">Dollar</option>
                          </select>
                        </div>
                        <div className="SelectContainer">
                          <select
                            className="Property-inputfiled"
                            onChange={handleBeneficiarySelection}
                            value=""
                            disabled={!distributionType}
                          >
                            <option value="" disabled hidden>
                              {distributionType
                                ? "Select Your Beneficiary Username"
                                : "Select Type First"}
                            </option>
                            {beneficiary.map((benif) => (
                              <option
                                key={benif.id}
                                value={benif.id}
                                disabled={selectedBeneficiaries.includes(
                                  benif.id
                                )}
                              >
                                {/* {benif.username} */}
                                {`${benif.firstName} ${benif.lastName}`}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="share_beneficiary_main_card">
                        {selectedBeneficiaries.map((beneficiary) => (
                          <div
                            key={beneficiary}
                            className="share_beneficiary_card"
                          >
                            <div>
                              <p className="share_beneficiary_card_para">
                                Beneficiary:{" "}
                                {getBenificiaryName({ beneficiary })}
                              </p>
                              {distributionType === "percentage" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Percentage"
                                  value={
                                    beneficiaryDetails[beneficiary]
                                      ?.percentage || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "percentage",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {distributionType === "dollar" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Dollar Value"
                                  value={
                                    beneficiaryDetails[beneficiary]?.value || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                />
                              )}

                              {distributionType && (
                                <p className="share_beneficiary_card_para">
                                  Distributed Value: $
                                  {distributionType === "percentage"
                                    ? calculateDistributedAmount(
                                        "percentage",
                                        otherAssetList[currentBeneficiaryIndex]
                                          ?.otherAsset?.assetValue,
                                        beneficiaryDetails[beneficiary]
                                      )
                                    : calculateDistributedAmount(
                                        "dollar",
                                        otherAssetList[currentBeneficiaryIndex]
                                          ?.otherAsset?.assetValue,
                                        beneficiaryDetails[beneficiary]
                                      )}
                                </p>
                              )}
                            </div>
                            <div
                              className="share_beneficiary_card_close"
                              onClick={() =>
                                handleBeneficiaryClose(beneficiary)
                              }
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {selectedBeneficiaries.length > 0 && (
                  <button onClick={handleSave}>Save</button>
                )}
              </div>
            </div>
          )}

          {beneficiaryVisible && (
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h2>share property</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setBeneficiaryVisible(false);
                          setShow1(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div>
                    {selectedBeneficiary.sharedDetails &&
                      selectedBeneficiary.sharedDetails.map(
                        (details, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              margin: "5px",
                              padding: "10px",
                              border: "solid 1px lightgray",
                              borderRadius: "5px",
                              minWidth: "230px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "17px",
                                  color: "black",
                                  fontWeight: "500",
                                }}
                              >
                                Share - {index + 1}
                              </p>
                              {primaryUserDetails.accountType === "secondary" &&
                              secondaryUserDetails.accountType === "primary" &&
                              secondaryUserDetails.isSecondaryUserEditable ===
                                "false" ? (
                                ""
                              ) : (
                                <FontAwesomeIcon
                                  onClick={() =>
                                    removeOtherAsset(
                                      details.id,
                                      "sharedDetailId"
                                    )
                                  }
                                  className="delete-button-comp"
                                  style={{ cursor: "pointer" }}
                                  icon={faTrash}
                                />
                              )}
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "20px",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "black",
                                  fontWeight: "400",
                                  marginLeft: "20px",
                                }}
                              >
                                Beneficiary Name:
                                <span style={{ marginLeft: "10px" }}>
                                  {getBenificiaryName(details.beneficiaryId)}
                                </span>
                              </p>
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "black",
                                  fontWeight: "400",
                                  marginLeft: "20px",
                                }}
                              >
                                Distributed Type:
                                <span style={{ marginLeft: "10px" }}>
                                  {details.distributedType}
                                </span>
                              </p>
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "black",
                                  fontWeight: "400",
                                  marginLeft: "20px",
                                }}
                              >
                                Distributed Value:{" "}
                                <span style={{ marginLeft: "10px" }}>
                                  {details &&
                                    details.distributedType === "dollar" &&
                                    "$"}
                                  {details.distributedValue}
                                  {details.distributedType === "percentage" &&
                                    "%"}
                                </span>
                              </p>
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "black",
                                  fontWeight: "400",
                                  marginLeft: "20px",
                                }}
                              >
                                Distributed Amount:
                                <span style={{ marginLeft: "10px" }}>
                                  ${details.distributedAmount}
                                </span>
                              </p>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </SideBar>
      </UserBase>
    </div>
  );
}

export default OtherAssets;
