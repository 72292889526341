import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTriangleExclamation,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "./../../pages/user-route/UserDashboard.css";
const AlertPopup = ({
  userAccountType,
  attorneyStringType,
  toggleBeneficiary,
  toggleAttorney,
  beneficiary,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    setShowPopup(true);
  }, []);

  const handleClose = () => {
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && (
        <div className="alertbox_overlay">
          <div className="alterbox_design">
            <div className="alertbox_main">
              <div className="alert_icon">
                <FontAwesomeIcon
                  className="alert_logo"
                  icon={faTriangleExclamation}
                  style={{ color: "#ffc800" }}
                />
              </div>
              <div className="alert_box_content">
                <h2 className="alert_box_heading">
                  Attention{" "}
                  <span className="alert_box_status">Profile Incomplete</span>
                </h2>
                <p className="alert_box_main_contant">
                  Please complete your profile by adding <br />
                  {userAccountType !== "secondary" && !beneficiary && (
                    <span
                      className="alert_button"
                      onClick={toggleBeneficiary}
                      role="button"
                      tabIndex="0"
                    >
                      Beneficiary
                    </span>
                  )}
                  {userAccountType !== "secondary" && attorneyStringType && (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={toggleAttorney}
                      className="alert_button"
                    >
                      , Invite Attorney
                    </span>
                  )}
                  .
                </p>
              </div>
              <div className="close_button" onClick={handleClose}>
                <FontAwesomeIcon className="alert_cancel_icon" icon={faXmark} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AlertPopup;
