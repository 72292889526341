import React from "react";
import "../components/Loading.css";

const Loading = ({ show }) => {
  return (
    show && (
      <>
        <div class="loading-overlay">
          <div class="spinner center">
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            {/* <h1 class="spinner-text">I-Chest..</h1> */}
          </div>
        </div>
      </>
    )
  );
};

export default Loading;
