import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControlLabel, Switch, Tooltip, Typography } from "@mui/material";
import {
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
  Textarea,
} from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
} from "reactstrap";
import SideBar from "../../../components/sidebar/Sidebar";
import UserBase from "../../../components/user/UserBase";
import "../../../css/formPOPup.css";
import {
  fetchNetWorth,
  getBeneficiary,
  getOwnerByUserId,
  getSecondaryUser,
  getToken,
  getTrustByUserId,
  getUser,
} from "../../../services/user-service";

import "../../../css/myestate_edit.css";
import {
  getSingleInsurance,
  updateInsurance,
} from "../../../services/InsuranceService";
import SharedBeneficiaryEdit from "../../../components/SharedBeneficiaryEdit";
function EditLifeInsurance() {
  const { id } = useParams();
  const navigate = useNavigate();
  // set Add data
  const [data, setData] = useState({
    insurance: {
      owner: "",
      insuranceCaption: "",
      insuranceAmount: "",
      insuranceProvider: "",
      details: "",
      trust: "",
      notes: "",
    },
    deathBenefitsModel: {
      deathBenefits: {
        insuranceAmount: "",
        trust: "",
      },
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  // for owner field
  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        insurance: {
          ...prevData.insurance,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });
        setSelectedImage(selectedFilesArray);
      } else {
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
  };

  const resetSharedDetails = [
    {
      distributedType: "",
      distributedValue: "",
      distributedAmount: "",
      beneficiaryId: "",
    },
  ];
  const [sharedType, setSharedType] = useState(null);

  const handleChanges = (e, field) => {
    const newValue = e.target.value;

    if (field === "sharedType") {
      setSharedType(newValue);

      setData((prevData) => {
        return {
          ...prevData,
          sharedDetails: resetSharedDetails,
        };
      });
      setData((prevData) => ({
        ...prevData,
        insurance: {
          ...prevData.insurance,
          trust: "",
        },
      }));
    } else if (field === "deathBenefitsSharedType") {
      setSharedType(newValue);

      setData((prevData) => {
        return {
          ...prevData,
          deathBenefitsModel: {
            ...prevData.deathBenefitsModel,
            sharedDetails: resetSharedDetails,
          },
        };
      });

      setData((prevData) => ({
        ...prevData,
        deathBenefitsModel: {
          ...prevData.deathBenefitsModel,
          deathBenefits: {
            ...prevData.deathBenefitsModel?.deathBenefits,
            trust: "", // Update death benefits insurance amount
          },
        },
      }));
    } else {
      setData((prevData) => {
        if (field === "generalInsuranceAmount") {
          return {
            ...prevData,
            insurance: {
              ...prevData.insurance,
              insuranceAmount: newValue, // Update general insurance amount
            },
          };
        } else if (field === "deathBenefitsInsuranceAmount") {
          return {
            ...prevData,
            deathBenefitsModel: {
              ...prevData.deathBenefitsModel,
              deathBenefits: {
                ...prevData.deathBenefitsModel?.deathBenefits,
                insuranceAmount: newValue, // Update death benefits insurance amount
              },
            },
          };
        } else if (field === "deathBenefitsTrust") {
          return {
            ...prevData,
            deathBenefitsModel: {
              ...prevData.deathBenefitsModel,
              deathBenefits: {
                ...prevData.deathBenefitsModel?.deathBenefits,
                trust: newValue, // Update death benefits insurance amount
              },
            },
          };
        } else {
          return {
            ...prevData,
            insurance: {
              ...prevData.insurance,
              [field]: newValue, // Update the field dynamically
            },
          };
        }
      });
    }
  };

  // post the form
  const lifeForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();
    const formData = new FormData();
    if (null != selectedImage) {
      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
      }
    }
    formData.append("data", JSON.stringify(data));
    console.log("data: ", data);
    return;
    updateInsurance(formData, token)
      .then((resp) => {
        toast.success("Updated Successfully!!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        fetchNetWorth(primaryUserDetails.commonId);
        navigate("/user/my-estate/insurances");
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;
    console.log("user Id=" + userId);
    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => console.log(err));
  };

  const [sharedDetailsNormal, setSharedDetailsNormal] = useState(null);
  const [sharedDetailsDeathBenefits, setSharedDetailsDeathBenefits] =
    useState(null);
  const [responseData, setResponseData] = useState(null);
  const [responseAssetData, setResponseAssetData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = "Bearer " + getToken();
        const res = await getSingleInsurance(token, id);
        setData({
          ...data,
          insurance: res.insurance,
          documents: res.documents,
          sharedDetails: res.sharedDetails,
          deathBenefitsModel: res.deathBenefitsModel,
        });
        setResponseData(res);
        setResponseAssetData(res?.insurance);
        const ownerNameArray = res.insurance.owner.split(", ");
        setOwnerName(ownerNameArray);

        setIsChecked(res.insurance.insuranceAmount);
        setSharedDetailsNormal(res.sharedDetails);
        setSharedDetailsDeathBenefits(res.deathBenefitsModel.sharedDetails);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    getBenificiarydata();
    getTrustsData();
  }, []);

  const [benevisible, setbeneVisible] = useState(false);
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);

  const [isDisabledNormal, setIsDisableNormal] = useState(false);
  const [isDisabledDeathBenefits, setIsDisableDeathBenefits] = useState(false);
  const [typeBeneficiary, setTypeBeneficiary] = useState(null);
  const handleShowBeneficiary = (event, beneficiaryType) => {
    if (beneficiaryType === "death-benefits") {
      setTypeBeneficiary(beneficiaryType);
      setEstimatedTotalAmount(
        data.deathBenefitsModel?.deathBenefits?.insuranceAmount
      );

      if (
        data.deathBenefitsModel.deathBenefits.trust !== "" &&
        data.deathBenefitsModel.deathBenefits.trust != null
      ) {
        setSharedType("Trust");
      } else if (data.deathBenefitsModel.sharedDetails.length > 0) {
        setSharedType("Beneficiary");
      } else {
        setSharedType(null);
      }
    } else {
      setTypeBeneficiary(beneficiaryType);
      setEstimatedTotalAmount(data.insurance?.insuranceAmount);
      if (data.insurance.trust !== "" && data.insurance.trust != null) {
        setSharedType("Trust");
      } else if (data.sharedDetails.length > 0) {
        setSharedType("Beneficiary");
      } else {
        setSharedType(null);
      }
    }
    setbeneVisible(true);
  };

  const [beneVisibleEdit, setBeneVisibleEdit] = useState(null);
  const handleEditCloseBeneficiary = (params) => {
    const { name } = params;
    if (name !== undefined && typeBeneficiary === "normal") {
      setData((prevData) => ({
        ...prevData,
        sharedDetails: name,
      }));
      if (name !== undefined && name[0]?.distributedType !== "") {
        setIsDisableNormal(true);
      }
    } else if (name !== undefined && typeBeneficiary === "death-benefits") {
      setData((prevData) => ({
        ...prevData,
        deathBenefitsModel: {
          ...prevData.deathBenefitsModel,
          sharedDetails: name,
        },
      }));
      if (name !== undefined && name[0]?.distributedType !== "") {
        setIsDisableDeathBenefits(true);
      }
    }
    setbeneVisible(false);
  };

  const [trusts, setTrusts] = useState([]);
  const getTrustsData = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getTrustByUserId(token, userId)
      .then((res) => {
        setTrusts(res);
      })
      .catch((err) => {});
  };

  const handleTrustSave = () => {
    setbeneVisible(false);
    if (typeBeneficiary === "normal" && data?.insurance?.trust !== "") {
      setIsDisableNormal(true);
    }
    if (
      typeBeneficiary !== "normal" &&
      data?.deathBenefitsModel?.deathBenefits?.trust !== ""
    ) {
      setIsDisableDeathBenefits(true);
    }
  };

  return (
    <UserBase>
      <div className="mt-5"></div>
      <SideBar>
        <div className="overlay1-edit">
          <div
            className="propertyform"
            style={{ display: "flex", justifyContent: "left" }}
          >
            <Container className="edit_container">
              <Card color="" outline>
                <CardHeader>
                  <h3 className="form1-heading">Edit Life Insurance</h3>
                  <div
                    className="Close"
                    onClick={() => {
                      navigate("/user/my-estate/insurances");
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={lifeForm}>
                    <div>
                      <Tooltip title="Select Owner">
                        <div>
                          <FormLabel required>Select Owner</FormLabel>

                          <Select
                            placeholder="Select Owner"
                            value={ownerName}
                            multiple
                            onChange={(event, newValue) =>
                              handleChange(event, newValue, "owner")
                            }
                            sx={{
                              minWidth: "13rem",
                            }}
                            slotProps={{
                              listbox: {
                                sx: {
                                  width: "100%",
                                },
                              },
                            }}
                            required
                          >
                            {ownerNames.map((name) => (
                              <Option key={name} value={name}>
                                {name}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </Tooltip>
                    </div>

                    <div>
                      <Tooltip title="Enter Heading for Insurance">
                        <div className="mt-2">
                          <FormLabel>Insurance Heading</FormLabel>
                          <Input
                            className="input_mui_joy"
                            placeholder="Enter insurance heading"
                            onChange={(e) =>
                              handleChanges(e, "insuranceCaption")
                            }
                            value={data.insurance.insuranceCaption}
                          />
                        </div>
                      </Tooltip>
                    </div>

                    <div>
                      <Tooltip title="Enter Heading for Insurance">
                        <div className="mt-2">
                          <FormLabel required>Insurance Provider</FormLabel>
                          <Input
                            className="input_mui_joy"
                            placeholder="Enter insurance provider"
                            onChange={(e) =>
                              handleChanges(e, "insuranceProvider")
                            }
                            value={data.insurance.insuranceProvider}
                            required
                          />
                        </div>
                      </Tooltip>
                    </div>

                    <div>
                      <Tooltip title="Enter The Name Of Your Insurance ">
                        <div className="mt-2">
                          <FormLabel required>Details</FormLabel>
                          <Input
                            className="input_mui_joy"
                            placeholder="Enter details"
                            onChange={(e) => handleChanges(e, "details")}
                            value={data.insurance.details}
                            required
                          />
                        </div>
                      </Tooltip>
                    </div>

                    <h4 className="mt-2">Death Benefits</h4>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="address_input">
                        <div className="addform_input_half">
                          <div>
                            <Tooltip title="Enter your Insurance Amount">
                              <div style={{ marginTop: "3px" }}>
                                <FormLabel required>Insurance Amount</FormLabel>
                                <Input
                                  type="number"
                                  className="input_mui_joy"
                                  placeholder="Enter insurance amount"
                                  onChange={(e) =>
                                    handleChanges(
                                      e,
                                      "deathBenefitsInsuranceAmount"
                                    )
                                  }
                                  value={
                                    data.deathBenefitsModel?.deathBenefits
                                      ?.insuranceAmount
                                  }
                                  startDecorator={<div>$</div>}
                                  required
                                  disabled={isDisabledDeathBenefits}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="mt-2 addform_input_half">
                          <div
                            className={`bene-select mt-3 ${
                              isDisabledDeathBenefits ? "disabled" : ""
                            }`}
                            onClick={
                              isDisabledDeathBenefits
                                ? null
                                : (event) =>
                                    handleShowBeneficiary(
                                      event,
                                      "death-benefits"
                                    )
                            }
                            style={{
                              cursor: isDisabledDeathBenefits
                                ? "not-allowed"
                                : "pointer",
                              opacity: isDisabledDeathBenefits ? 0.6 : 1,
                            }}
                          >
                            Select Your Beneficiary
                          </div>
                        </div>
                      </div>
                      <FormHelperText error>
                        <Typography variant="caption" color="error">
                          Note: Please recheck "Insurance Amount" before
                          selecting your beneficiary.
                        </Typography>
                      </FormHelperText>
                    </div>

                    <div className="mt-2 switch_changes_main">
                      <div className="switch_changes_base">
                        <div>
                          Do you have current cash value of the Insurance?
                        </div>
                        <div>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={isChecked}
                                onChange={handleSwitchChange}
                                color="primary"
                                size="normal"
                              />
                            }
                            label={isChecked ? "Yes" : "No"}
                            labelPlacement="end"
                          />
                        </div>
                      </div>
                    </div>
                    {isChecked && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className="address_input">
                          <div className="addform_input_half">
                            <div>
                              <Tooltip title="Enter your Insurance Amount">
                                <div style={{ marginTop: "3px" }}>
                                  <FormLabel>Insurance Cash Amount</FormLabel>
                                  <Input
                                    type="number"
                                    className="input_mui_joy"
                                    placeholder="Enter insurance cash amount"
                                    onChange={(e) =>
                                      handleChanges(e, "insuranceAmount")
                                    }
                                    value={data.insurance.insuranceAmount}
                                    startDecorator={<div>$</div>}
                                    required
                                    disabled={isDisabledNormal}
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                          <div className="mt-2 addform_input_half">
                            <div
                              className={`bene-select mt-3 ${
                                isDisabledNormal ? "disabled" : ""
                              }`}
                              onClick={
                                isDisabledNormal
                                  ? null
                                  : (event) =>
                                      handleShowBeneficiary(event, "normal")
                              }
                              style={{
                                cursor: isDisabledNormal
                                  ? "not-allowed"
                                  : "pointer",
                                opacity: isDisabledNormal ? 0.6 : 1,
                              }}
                            >
                              Select Your Beneficiary
                            </div>
                          </div>
                        </div>
                        <FormHelperText error>
                          <Typography variant="caption" color="error">
                            Note: Please recheck "Insurance Cash Amount" before
                            selecting your beneficiary.
                          </Typography>
                        </FormHelperText>
                      </div>
                    )}

                    <div className="mt-2">
                      <Tooltip title="Add your insurance related file">
                        <div>
                          <label
                            style={{
                              display: "block",
                              marginBottom: "5px",
                            }}
                          >
                            Supporting Document<span></span>
                          </label>
                          <input
                            style={{
                              border: "solid 1px lightgray",
                              borderLeft: "none",
                              width: "100%",
                              borderRadius: "5px",
                            }}
                            type="file"
                            name="myfile"
                            id="exampleFile"
                            multiple
                            accept=".pdf"
                            onChange={(e) => handleImageChange(e)}
                          />
                        </div>
                      </Tooltip>
                    </div>

                    <div className="address_input">
                      <div style={{ width: "100%" }} className="mt-2">
                        <Tooltip title="Enter notes for your insurance">
                          <div>
                            <FormLabel>Notes/Point of contact</FormLabel>
                            <Textarea
                              sx={{ height: "182px", minHeight: "52px" }}
                              placeholder="Notes"
                              id="notes"
                              onChange={(e) => handleChanges(e, "notes")}
                              value={data.insurance.notes}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>

                    <Container
                      className="text-center"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button outline className="my-estate-add-btn">
                        Update
                      </Button>
                    </Container>
                  </Form>
                </CardBody>
              </Card>
            </Container>
          </div>
        </div>

        {benevisible && (
          <SharedBeneficiaryEdit
            responseData={responseData}
            responseAssetData={responseAssetData}
            handleTrustSave={handleTrustSave}
            assetData={
              typeBeneficiary === "normal"
                ? data?.insurance
                : data?.deathBenefitsModel
            }
            trusts={trusts}
            handleChanges={handleChanges}
            sharedType={sharedType}
            handleCloseBeneficiaryPopup={handleEditCloseBeneficiary}
            estimatedTotalAmount={estimatedTotalAmount}
            typeBeneficiary={typeBeneficiary}
            sharedDetails={
              typeBeneficiary === "normal"
                ? data.sharedDetails
                : data?.deathBenefitsModel?.sharedDetails
            }
          />
        )}
      </SideBar>
    </UserBase>
  );
}

export default EditLifeInsurance;
