import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import React, { useState, useRef, useEffect } from "react";
import "../../css/userprofile.css";
import {
  currentUser,
  getToken,
  getUser,
  setUser,
  updateUserProfile,
} from "../../services/user-service";
import {
  faCircleCheck,
  faPlus,
  faTrashCan,
  faCamera,
} from "@fortawesome/free-solid-svg-icons";
import { Form } from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { changeUsFormatNumber } from "../Common";
import dayjs from "dayjs";
import { FormControl, FormHelperText } from "@mui/joy";
import { Typography } from "@mui/material";
import axios from "axios";

function Userprofileedit() {
  const user = getUser();
  let role = user.role.toLowerCase();
  const navigate = useNavigate();
  const [data, setData] = useState({
    id: getUser().id,
    jobStatus: "",
    gender: "",
    meritalStatus: "",
    dob: "",
    phoneNo: "",
    currentAddress: "",
    permanentAddress: "",
    street: "",
    apartment: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    isSecondaryUserEditable: "",
  });

  useEffect(() => {
    let user = getUser();
    setData(user);
  }, []);

  const [selectedProvider, setSelectedProvider] = useState("");

  const handleProviderChange = (event) => {
    setSelectedProvider(event.target.value);
  };

  const [profilePicture, setProfilePicture] = useState(null);
  const defaultProfilePicture = "./../../.././img/avtar.jpg";
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const newProfilePicture = event.target.files[0];
    setProfilePicture(newProfilePicture);
  };

  const handleDelete = () => {
    setProfilePicture(null);
  };

  const validateZipCode = (zipCode) => {
    return /^\d{5}$/.test(zipCode);
  };

  const validatePhoneNumber = (phoneNo) => {
    if (phoneNo === "") {
      return true;
    }
    const re = /^(\+1\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/;
    return re.test(phoneNo);
  };

  const [errors, setErrors] = useState({
    dobValidate: true,
    phoneValidate: true,
    zipValidate: true,
    validZip: true,
  });

  const today = dayjs().format("YYYY-MM-DD");
  const handleChanges = (event, property) => {
    let value = event.target.value;
    const errorStates = { ...errors };

    if (property === "phoneNo") {
      const formattedValue = changeUsFormatNumber(value);
      setData((prevData) => ({
        ...prevData,
        [property]: formattedValue,
      }));
    } else if (property === "dob" && value > today) {
      setData((prevData) => ({
        ...prevData,
        [property]: value,
      }));
      errorStates.dobValidate = false;
    } else if (property === "zip") {
      if (/^\d{0,5}$/.test(value)) {
        // Validation to allow only up to 5 digits (or empty string)
        setData((prevData) => ({
          ...prevData,
          [property]: value,
        }));
      }
    } else {
      setData((prevData) => ({
        ...prevData,
        [property]: value,
      }));
    }

    // Additional validations
    if (property === "zip") {
      errorStates.zipValidate =
        value !== "" ? validateZipCode(value.slice(0, 5)) : true;
    } else if (property === "phoneNo") {
      errorStates.phoneValidate =
        value !== "" ? validatePhoneNumber(changeUsFormatNumber(value)) : true;
    } else if (property === "dob" && value < today) {
      errorStates.dobValidate = value !== "" ? value < today : true;
    }
    // Set errors state once
    setErrors(errorStates);
  };

  useEffect(() => {
    const fetchDataFromAPI = () => {
      if (data.zip && data.zip.length === 5) {
        const apiUrl = `https://api.zippopotam.us/us/${data.zip}`;
        axios
          .get(apiUrl)
          .then((res) => {
            if (res.data && res.data.places && res.data.places.length > 0) {
              const place = res.data.places[0];
              const city = place["place name"];
              const state = place["state abbreviation"];
              const country = res.data["country abbreviation"];

              setData((prevData) => ({
                ...prevData,
                city: city,
                state: state,
                country: country,
              }));
              setErrors((prevErrors) => ({
                ...prevErrors,
                validZip: true,
              }));
            }
          })
          .catch((error) => {
            setErrors((prevErrors) => ({
              ...prevErrors,
              validZip: false,
            }));
          });
      } else {
        setData((prevData) => ({
          ...prevData,
          city: "",
          state: "",
          country: "",
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          validZip: true,
        }));
      }
    };

    fetchDataFromAPI();
  }, [data?.zip]);

  // post the data
  const UserProfileEdit = (event) => {
    event.preventDefault();
    // Initialize an errors object with boolean values
    const newErrors = {
      phoneValidate: data.phoneNo
        ? validatePhoneNumber(changeUsFormatNumber(data.phoneNo))
        : true,
      dobValidate: data.dob ? data.dob < today : true,
      zipValidate: data.zip ? validateZipCode(data.zip) : true,
      validZip: data.zip ? errors.validZip : true,
    };
    setErrors(newErrors);
    // Check if there are any errors
    const hasErrors = Object.values(newErrors).includes(false);
    if (!hasErrors) {
      updateUserProfile(data, data.id)
        .then((resp) => {
          currentUser("Bearer " + getToken())
            .then((user) => {
              setUser(user);

              let role = user.role.toLowerCase();

              navigate("/" + role + "/profile");
            })
            .catch((error) => {});
        })
        .catch((error) => {
          toast.error("An error occurred. Please try again.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        });
    }
  };
  const [imageSrc, setImageSrc] = useState("");
  const base64ToImage = () => {
    const base64String = user.image;
    const trimmedBase64 = base64String ? base64String.trim() : "";
    if (trimmedBase64) {
      setImageSrc(`data:image/jpeg;base64,${trimmedBase64}`);
    }
  };
  useEffect(() => {
    base64ToImage();
  }, []);

  return (
    <>
      <div className="userprofile_mainpage">
        <div className="userprofile_basepage">
          {/* {JSON.stringify(data)} */}
          <Form onSubmit={UserProfileEdit}>
            <div className="userprofile_content_left">
              <div className="userprofilr_page_details">
                <div className="userprofile_image">
                  <img
                    className="userprofile_img"
                    src={imageSrc || "./../../.././img/avtar.jpg"}
                    alt="Profile_image"
                  />
                </div>
                <div className="userprofile_details">
                  <ul className="userprofile_about">
                    <li className="userprofile_name">
                      {user.firstName + " "}
                      {user.lastName}
                    </li>
                    <ul className="userprofile_about_deatils">
                      <li className="userprofile_list">Username:</li>
                      <li>{user.username}</li>
                    </ul>
                    <ul className="userprofile_about_deatils">
                      {" "}
                      <li className="userprofile_list">Email:</li>
                      <li> {user.email}</li>
                    </ul>
                  </ul>
                </div>
              </div>
              <div className="userprofile_edit">
                <button className="userprofile_edit_btn">
                  Save
                  <FontAwesomeIcon
                    className="userprofile_edit_icon"
                    icon={faCircleCheck}
                  />
                </button>
              </div>
            </div>

            <div className="userprofile_content_right">
              <div className="userprofile_page_details">
                <div className="userprofile_container">
                  <h3>Basic Information</h3>
                  {!(
                    user.accountType === "secondary" ||
                    user.accountType === "normal" ||
                    role === "attorney"
                  ) && (
                    <ul className="userprofile_page_main_list">
                      <li className="userprofile_page_list">
                        {" "}
                        Secondary User Editable :
                      </li>
                      <select
                        id="secondary-user-editable"
                        value={data.isSecondaryUserEditable}
                        style={{ width: "180px", height: "30px" }}
                        onChange={(e) =>
                          handleChanges(e, "isSecondaryUserEditable")
                        }
                      >
                        <option hidden value="">
                          choose here{" "}
                        </option>
                        <option value="true">Read & Write</option>
                        <option value="false">Read Only</option>
                      </select>
                    </ul>
                  )}
                  <ul className="userprofile_page_main_list">
                    <li className="userprofile_page_list">Date of birth:</li>
                    <div>
                      <input
                        type="date"
                        style={{ width: "180px", height: "30px" }}
                        onChange={(e) => handleChanges(e, "dob")}
                        value={data.dob}
                        max={new Date().toISOString().split("T")[0]}
                      />

                      {!errors.dobValidate && (
                        <FormHelperText error>
                          <Typography variant="caption" color="error">
                            {!errors.dobValidate
                              ? "You can not enter future date"
                              : ""}
                          </Typography>
                        </FormHelperText>
                      )}
                    </div>
                  </ul>
                  <ul className="userprofile_page_main_list">
                    <li className="userprofile_page_list"> Phone number: </li>
                    <div>
                      <input
                        type="text"
                        style={{ width: "180px" }}
                        onChange={(e) => handleChanges(e, "phoneNo")}
                        value={data.phoneNo}
                      />
                      {!errors.phoneValidate && (
                        <FormHelperText error>
                          <Typography variant="caption" color="error">
                            {!errors.phoneValidate
                              ? "Enter a valid phone number"
                              : ""}
                          </Typography>
                        </FormHelperText>
                      )}
                    </div>
                  </ul>
                  <ul className="userprofile_page_main_list">
                    <li className="userprofile_page_list"> gender :</li>
                    <select
                      id="gender"
                      value={data.gender}
                      style={{ width: "180px", height: "30px" }}
                      onChange={(e) => handleChanges(e, "gender")}
                    >
                      <option hidden value="">
                        choose here{" "}
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </ul>
                  <ul className="userprofile_page_main_list">
                    <li className="userprofile_page_list">Marital: </li>
                    <select
                      id="meritalStatus"
                      value={data.meritalStatus}
                      style={{ width: "180px", height: "30px" }}
                      onChange={(e) => handleChanges(e, "meritalStatus")}
                    >
                      <option hidden value="">
                        choose here{" "}
                      </option>
                      <option value="Single">Single</option>
                      <option value="Married">Married</option>
                      <option value="Widow">Widow</option>
                    </select>
                  </ul>
                  <ul className="userprofile_page_main_list">
                    <li className="userprofile_page_list">Job Status:</li>
                    <select
                      id="jobStatus"
                      value={data.jobStatus}
                      style={{ width: "180px", height: "30px" }}
                      onChange={(e) => handleChanges(e, "jobStatus")}
                    >
                      <option hidden value="">
                        choose here{" "}
                      </option>
                      <option value="Employed">Employed</option>
                      <option value="Unemployed">Unemployed</option>
                    </select>
                  </ul>
                </div>
              </div>
              <div className="userprofile_page_details ">
                <h3>Address Information </h3>
                <div
                  style={{ marginLeft: "0" }}
                  className="userprofile_address_list"
                >
                  <ul className="userprofile_page_main_list">
                    <li className="userprofile_page_list">Street:</li>
                    <input
                      type="text"
                      style={{ width: "180px", height: "30px" }}
                      onChange={(e) => handleChanges(e, "street")}
                      value={data.street}
                    />
                  </ul>
                  <ul className="userprofile_page_main_list">
                    <li className="userprofile_page_list">Apartment:</li>
                    <input
                      type="text"
                      style={{ width: "180px", height: "30px" }}
                      onChange={(e) => handleChanges(e, "apartment")}
                      value={data.apartment}
                    />
                  </ul>
                  <ul className="userprofile_page_main_list">
                    <li className="userprofile_page_list">Zip Code:</li>
                    <div>
                      <input
                        type="text"
                        style={{ width: "180px", height: "30px" }}
                        onChange={(e) => handleChanges(e, "zip")}
                        value={data.zip}
                      />
                      {(!errors.zipValidate || !errors.validZip) && (
                        <FormHelperText error>
                          <Typography variant="caption" color="error">
                            {!errors.zipValidate
                              ? "Please enter a valid 5-digit Zip Code"
                              : !errors.validZip
                              ? "This is not a valid Zip Code"
                              : ""}
                          </Typography>
                        </FormHelperText>
                      )}
                    </div>
                  </ul>
                  <ul className="userprofile_page_main_list">
                    <li className="userprofile_page_list">City:</li>
                    <input
                      type="text"
                      style={{ width: "180px", height: "30px" }}
                      value={data.city}
                      disabled
                      readOnly
                    />
                  </ul>
                  <ul className="userprofile_page_main_list">
                    <li className="userprofile_page_list">State:</li>
                    <input
                      type="text"
                      style={{ width: "180px", height: "30px" }}
                      value={data.state}
                      disabled
                      readOnly
                    />
                  </ul>
                  <ul className="userprofile_page_main_list">
                    <li className="userprofile_page_list">Country:</li>
                    <input
                      type="text"
                      style={{ width: "180px", height: "30px" }}
                      value={data.country}
                      disabled
                      readOnly
                    />
                  </ul>
                  {/* <ul className="userprofile_permanent_address">
                    <li className="userprofile_permanent_address_list">
                      permanent address :
                      <textarea
                        style={{ width: "100%" }}
                        rows={4}
                        cols={50}
                        id="permanentAddress"
                        onChange={(e) => handleChanges(e, "permanentAddress")}
                        value={data.permanentAddress}
                      />
                    </li>
                  </ul>
                  <ul className="userprofile_current_address">
                    <li className="userprofile_current_address_list">
                      current address :
                      <textarea
                        style={{ width: "100%" }}
                        rows={4}
                        cols={50}
                        id="currentAddress"
                        onChange={(e) => handleChanges(e, "currentAddress")}
                        value={data.currentAddress}
                      />
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>

            {/* <div className="userprofile_social_media">
              <div className="usaerprofile_social_media_contect">
                <a href="#">
                  <FontAwesomeIcon
                    className="userprofile_social_icon"
                    icon={faPlus}
                  />
                </a>
                <a href="#" style={{ color: "red" }}>
                  <FontAwesomeIcon
                    className="userprofile_social_icon"
                    icon={faYoutube}
                  />
                </a>
                <a href="#" style={{ color: "red" }}>
                  <FontAwesomeIcon
                    className="userprofile_social_icon"
                    icon={faInstagram}
                  />
                </a>
                <a href="#" style={{ color: "#0082ff" }}>
                  <FontAwesomeIcon
                    className="userprofile_social_icon"
                    icon={faFacebook}
                  />
                </a>
                <a href="#" style={{ color: "blue" }}>
                  <FontAwesomeIcon
                    className="userprofile_social_icon"
                    icon={faTwitter}
                  />
                </a>
              </div>
            </div> */}
          </Form>
        </div>
      </div>
    </>
  );
}
export default Userprofileedit;
