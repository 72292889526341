import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserBase from "../../../components/user/UserBase";
import SideBar from "../../../components/sidebar/Sidebar";
import { Button, Form } from "reactstrap";
import { Card, CardBody, CardFooter, CardHeader } from "react-bootstrap";
import { FormHelperText, Input, Option, Select, Textarea } from "@mui/joy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { getToken } from "../../../services/user-service";
import {
  getSingleCreditCard,
  updateCreditCard,
} from "../../../services/CreditCardService";
import { toast } from "react-toastify";
import {
  getSinglePersonalLoan,
  updatePersonalLoan,
} from "../../../services/PersonalLoanService";
import axios from "axios";
import { Typography } from "@mui/material";

function EditPersonalLoan() {
  const { id } = useParams();
  const [data, setData] = useState({
    personalLoan: {
      personalLoanListName: "",
      name: "",
      date: "",
      street: "",
      apartment: "",
      zipCode: "",
      city: "",
      state: "",
      country: "",
      estimatedLoan: "",
      notes: "",
    },
  });
  const navigate = useNavigate();

  const handleChanges = (e, field) => {
    const { value } = e.target;
    if (field === "zipCode") {
      if (/^\d{0,5}$/.test(value)) {
        setData((prevData) => ({
          ...prevData,
          personalLoan: {
            ...prevData.personalLoan,
            [field]: value,
          },
        }));
      }
    } else {
      setData((prevData) => ({
        ...prevData,
        personalLoan: {
          ...prevData.personalLoan,
          [field]: value,
        },
      }));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = "Bearer " + getToken();
        const res = await getSinglePersonalLoan(token, id);
        console.log("data: ", res);
        setData({
          ...data,
          personalLoan: res.personalLoan,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [errors, setErrors] = useState({
    validZip: true,
  });

  useEffect(() => {
    const fetchDataFromAPI = () => {
      if (data.personalLoan.zipCode.length === 5) {
        const apiUrl = `https://api.zippopotam.us/us/${data.personalLoan.zipCode}`;
        axios
          .get(apiUrl)
          .then((res) => {
            if (res.data && res.data.places && res.data.places.length > 0) {
              const place = res.data.places[0];
              const city = place["place name"];
              const state = place["state abbreviation"];
              const country = res.data["country abbreviation"];

              setData((prevData) => ({
                ...prevData,
                personalLoan: {
                  ...prevData.personalLoan,
                  city: city,
                  state: state,
                  country: country,
                },
              }));
              setErrors((prevErrors) => ({
                ...prevErrors,
                validZip: true,
              }));
            }
          })
          .catch((error) => {
            setErrors((prevErrors) => ({
              ...prevErrors,
              validZip: false,
            }));
          });
      } else {
        setData((prevData) => ({
          ...prevData,
          personalLoan: {
            ...prevData.personalLoan,
            city: "",
            state: "",
            country: "",
          },
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          validZip: false,
        }));
      }
    };

    fetchDataFromAPI();
  }, [data.personalLoan.zipCode]);

  const creditCardForm = (event) => {
    event.preventDefault();

    let token = "Bearer " + getToken();
    const formData = new FormData();

    formData.append("data", JSON.stringify(data));
    console.log("data: ", data);

    if (errors.validZip === false) {
      return;
    }

    updatePersonalLoan(formData, token)
      .then((resp) => {
        toast.success("Updated Successfully!!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        navigate("/user/liabilities/p-loan");
      })
      .catch((error) => {});
    return;
  };

  return (
    <UserBase>
      <div className="mt-5"></div>
      <SideBar>
        <div className="overlay1" style={{ transition: "500ms", height: "" }}>
          <div>
            <form onSubmit={creditCardForm} className="addform_body">
              <container className="credit_card_base">
                <div className="homepage jewelry_container">
                  <Card>
                    <CardHeader>
                      <div
                        style={{ width: "100%" }}
                        className="addform_heading"
                      >
                        <h3 className="addform_heading_h1">
                          {data.personalLoan.personalLoanListName}
                        </h3>
                        <div className="addform_close">
                          <FontAwesomeIcon
                            icon={faXmark}
                            onClick={() => {
                              navigate("/user/liabilities/p-loan");
                            }}
                          />
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="credit_card_body">
                      <div>
                        <table className="credit_card_table">
                          <thead>
                            <tr>
                              <th style={{ width: "140px" }}>Lender Name*</th>
                              <th style={{ width: "140px" }}>
                                Estimated Loan*
                              </th>
                              <th style={{ width: "140px" }}>Date borrowed*</th>
                              <th style={{ width: "120px" }}>Street</th>
                              <th style={{ width: "120px" }}>Apartment</th>
                              <th style={{ width: "100px" }}>Zip Code</th>
                              <th style={{ width: "100px" }}>City</th>
                              <th style={{ width: "100px" }}>State</th>
                              <th style={{ width: "120px" }}>Country</th>
                              <th>Notes</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Input
                                  style={{ width: "140px" }}
                                  placeholder="Lender Name"
                                  onChange={(e) => handleChanges(e, "name")}
                                  value={data?.personalLoan?.name || ""}
                                  required
                                />
                              </td>
                              <td>
                                <Input
                                  style={{ width: "140px" }}
                                  placeholder="Estimated Loan"
                                  type="number"
                                  onChange={(e) =>
                                    handleChanges(e, "estimatedLoan")
                                  }
                                  value={
                                    data?.personalLoan?.estimatedLoan || ""
                                  }
                                  startDecorator={<div>$</div>}
                                  required
                                />
                              </td>
                              <td>
                                <Input
                                  style={{ width: "140px" }}
                                  type="date"
                                  onChange={(e) => handleChanges(e, "date")}
                                  value={data?.personalLoan?.date || ""}
                                  required
                                />
                              </td>
                              <td>
                                <Input
                                  style={{ width: "120px" }}
                                  placeholder="Street"
                                  onChange={(e) => handleChanges(e, "street")}
                                  value={data?.personalLoan?.street || ""}
                                  required
                                />
                              </td>
                              <td>
                                <Input
                                  style={{ width: "120px" }}
                                  placeholder="Apartment"
                                  onChange={(e) =>
                                    handleChanges(e, "apartment")
                                  }
                                  value={data?.personalLoan?.apartment || ""}
                                  required
                                />
                              </td>
                              <td>
                                <Input
                                  style={{ width: "100px" }}
                                  placeholder="Zip Code"
                                  onChange={(e) => handleChanges(e, "zipCode")}
                                  value={data?.personalLoan?.zipCode || ""}
                                />
                                <FormHelperText error>
                                  <Typography variant="caption" color="error">
                                    {!errors.validZip ? "Invalid Zip Code" : ""}
                                  </Typography>
                                </FormHelperText>
                              </td>
                              <td>
                                <Input
                                  style={{ width: "100px" }}
                                  placeholder="City"
                                  onChange={(e) => handleChanges(e, "city")}
                                  value={data?.personalLoan?.city || ""}
                                  disabled
                                />
                              </td>
                              <td>
                                <Input
                                  style={{ width: "100px" }}
                                  placeholder="State"
                                  onChange={(e) => handleChanges(e, "state")}
                                  value={data?.personalLoan?.state || ""}
                                  disabled
                                />
                              </td>

                              <td>
                                <Input
                                  style={{ width: "120px" }}
                                  placeholder="Country"
                                  onChange={(e) => handleChanges(e, "country")}
                                  value={data?.personalLoan?.country || ""}
                                  disabled
                                />
                              </td>
                              <td>
                                <Textarea
                                  style={{
                                    fontSize: "14px",
                                    width: "220px",
                                  }}
                                  placeholder="Notes"
                                  id="notes"
                                  value={data?.personalLoan.notes}
                                  onChange={(e) => handleChanges(e, "notes")}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="text-center">
                        <Button
                          type="submit"
                          outline
                          className="my-estate-add-btn"
                        >
                          Update
                        </Button>
                      </div>
                    </CardFooter>
                  </Card>
                </div>
              </container>
            </form>
          </div>
        </div>
      </SideBar>
    </UserBase>
  );
}
export default EditPersonalLoan;
