import { faMinus, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormLabel, Input, Option, Select, Textarea } from "@mui/joy";
import { FormControlLabel, Switch, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form } from "reactstrap";
import SideBar from "../../../components/sidebar/Sidebar";
import UserBase from "../../../components/user/UserBase";
import "../../../css/myestate_edit.css";
import { getSingleBank, updateBank } from "../../../services/bank-service";
import {
  fetchNetWorth,
  getBeneficiary,
  getOwnerByUserId,
  getSecondaryUser,
  getToken,
  getTrustByUserId,
  getUser,
} from "../../../services/user-service";
import SharedBeneficiaryEdit from "../../../components/SharedBeneficiaryEdit";

function Editbanks() {
  // get id from the url
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    bank: {
      owner: "",
      bankName: "",
      safetyBox: "",
      safetyBoxNumber: "",
      notes: "",
      bankType: "",
      totalAmount: "",
    },
    accounts: [
      {
        accountType: "",
        accountNumber: "",
        balance: "",
      },
    ],
    documents: [
      {
        fileName: "",
      },
    ],
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  // for owner field
  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        bank: {
          ...prevData.bank,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  const [selectedImage, setSelectedImage] = useState([]);
  const [sharedType, setSharedType] = useState(null);
  const handleChangesBank = (e, field) => {
    const newValue = e.target.value;
    if (field === "sharedType") {
      setSharedType(newValue);
      setData((prevData) => {
        const resetSharedDetails = [
          {
            distributedType: "",
            distributedValue: "",
            distributedAmount: "",
            beneficiaryId: "",
          },
        ];

        return {
          ...prevData,
          sharedDetails: resetSharedDetails,
        };
      });
      setData((prevData) => ({
        ...prevData,
        bank: {
          ...prevData.bank,
          trust: "",
        },
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        bank: {
          ...prevData.bank,
          [field]: newValue,
        },
      }));
    }
    const balances = data.accounts.map((account) => account.balance || 0);
    const newTotalBalance = balances.reduce(
      (acc, curr) => acc + parseFloat(curr),
      0
    );
    setEstimatedTotalAmount(newTotalBalance);
  };
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [visibleColumnIndex, setVisibleColumnIndex] = useState(0);
  const [account, setAccount] = useState([]);

  const multipleAccount = [0, 1, 2, 3];
  const handleAddBank = () => {
    if (visibleColumnIndex < 3) {
      setAccount([...account, { label: visibleColumnIndex + 1 }]);
      setVisibleColumnIndex(visibleColumnIndex + 1);
    }
  };

  const handleRemoveBank = (indexToRemove) => {
    if (visibleColumnIndex > 0) {
      // Filter out the account with the index to remove
      const updatedAccounts = account.filter(
        (_, index) => index !== indexToRemove - 1
      );
      setAccount(updatedAccounts);

      // Adjust visibleColumnIndex if the removed index is within the current visible range
      if (indexToRemove - 1 < visibleColumnIndex) {
        setVisibleColumnIndex(visibleColumnIndex - 1);
      }
    }

    setData((prevData) => {
      const updatedAccounts = prevData.accounts.map((account, index) => {
        if (index === indexToRemove) {
          // Create a new object with empty values for the removed index
          return {
            accountType: "",
            accountNumber: "",
            balance: "",
          };
        }
        return account; // Return the original bank object for other indices
      });

      // Filter out accounts with empty strings for accountType, accountNumber, and balance
      const filteredAccounts = updatedAccounts.filter(
        (account) =>
          account.accountType !== "" ||
          account.accountNumber !== "" ||
          account.balance !== ""
      );

      // Update the state with the new array of bank accounts
      const newData = {
        ...prevData,
        accounts: filteredAccounts,
      };

      // Perform further operations directly after updating the state
      // Filter out accounts with empty strings for accountType, accountNumber, and balance
      const furtherFilteredAccounts = newData.accounts.filter(
        (account) =>
          account.accountType !== "" ||
          account.accountNumber !== "" ||
          account.balance !== ""
      );

      // Update the state with the further filtered accounts
      setData({
        ...newData,
        accounts: furtherFilteredAccounts,
      });

      return newData;
    });

    setSelectedBankTypes((prevSelectedBankTypes) => {
      const newSelectedBankTypes = [...prevSelectedBankTypes];
      newSelectedBankTypes.splice(indexToRemove, 1);
      return newSelectedBankTypes;
    });
  };

  const [selectedBankTypes, setSelectedBankTypes] = useState(
    Array(multipleAccount.length).fill("")
  );

  // Handle image
  const handleImageChange = (event, maxFilesAllowed) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];
    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);
      if (selectedFilesArray.length > maxFilesAllowed) {
        // Notify the user if they exceed the file limit
        toast.error(`You can only select up to ${maxFilesAllowed} files.`, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        event.target.value = ""; // Clear the file input
      } else {
        const invalidFiles = selectedFilesArray.filter((file) => {
          const fileNameParts = file.name.split(".");
          const fileExtension =
            fileNameParts[fileNameParts.length - 1].toLowerCase();
          return !allowedExtensions.includes(fileExtension);
        });
        if (invalidFiles.length === 0) {
          const formData = new FormData();
          selectedFilesArray.forEach((file, index) => {
            formData.append(`file${index + 1}`, file);
          });
          setSelectedImage(selectedFilesArray);
        } else {
          const invalidExtensions = invalidFiles
            .map((file) => file.name.split(".").pop())
            .join(", ");
          toast.error(
            `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
            {
              position: toast.POSITION.BOTTOM_CENTER,
            }
          );
          event.target.value = "";
        }
      }
    }
  };
  // const [bankName, setBankName] = React.useState("");
  const bankHandleChange = (event, newValue) => {
    const selectedBank = newValue;
    if (selectedBank === "other") {
      setOtherPropertyTypes(true);
      setData({
        ...data,
        bank: {
          ...data.bank,
          bankType: "other",
          bankName: "",
        },
      });
    } else {
      setOtherPropertyTypes(false);
      setData({
        ...data,
        bank: {
          ...data.bank,
          bankType: "",
          bankName: selectedBank,
        },
      });
    }
  };
  // post the form
  const BankForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();
    const formData = new FormData();
    if (null != selectedImage) {
      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
        console.log("this is file indexs", selectedImage[i]);
      }
    }
    formData.append("data", JSON.stringify(data));
    console.log("formData : ", JSON.stringify(data));
    updateBank(formData, token)
      .then((resp) => {
        toast.success("Updated Successfully!!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        fetchNetWorth(primaryUserDetails.commonId);
        navigate("/user/my-estate/banks");
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred. Please try again.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  function stringToBoolean(str) {
    return str.toLowerCase() === "true";
  }

  const [trusts, setTrusts] = useState([]);
  const getTrustsData = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getTrustByUserId(token, userId)
      .then((res) => {
        setTrusts(res);
      })
      .catch((err) => {});
  };

  let findAccountLength = null;
  let [findAccLength, setFindAccLength] = useState(0);
  const [responseData, setResponseData] = useState(null);
  const [responseAssetData, setResponseAssetData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = "Bearer " + getToken();
        const res = await getSingleBank(token, id);
        console.log("this is  bank response ", res);

        setData({
          ...data,
          bank: res.bank,
          accounts: res.accounts,
          documents: res.documents,
          sharedDetails: res.sharedDetails,
        });
        setResponseData(res);
        setResponseAssetData(res?.bank);
        const ownerNameArray = res.bank.owner.split(", ");
        setOwnerName(ownerNameArray);
        setChecked(stringToBoolean(res.bank.safetyBox));
        findAccountLength = [...res.accounts];
        setFindAccLength(findAccountLength.length);
        setVisibleColumnIndex(findAccountLength.length - 1);

        setEstimatedTotalAmount(res.bank.totalAmount);

        if (res.bank.trust !== "" && res.bank.trust != null) {
          setSharedType("Trust");
        } else if (res.sharedDetails.length > 0) {
          setSharedType("Beneficiary");
        } else {
          setSharedType(null);
        }

        const checkSelectedBankTypes = [...res.accounts];
        setSelectedBankTypes((prevSelectedBankTypes) => {
          const newSelectedBankTypes = [...prevSelectedBankTypes];
          // Check each account type and update the selected bank types accordingly
          checkSelectedBankTypes.forEach((account, index) => {
            if (account.accountType === "Checking Account") {
              newSelectedBankTypes[index] = "Checking Account";
            } else if (account.accountType === "Savings Account") {
              newSelectedBankTypes[index] = "Savings Account";
            } else if (account.accountType === "Investment Account") {
              newSelectedBankTypes[index] = "Investment Account";
            } else if (account.accountType === "C.D Account") {
              newSelectedBankTypes[index] = "C.D Account";
            }
          });

          return newSelectedBankTypes;
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    getBenificiarydata();
    getTrustsData();
  }, []);

  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;
    console.log("user Id=" + userId);
    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => console.log(err));
  };

  const [benevisible, setbeneVisible] = useState(false);

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.bank.totalAmount);
  };
  const handleEditCloseBeneficiary = (params) => {
    const { name } = params;
    if (name !== undefined) {
      setData((prevData) => ({
        ...prevData,
        sharedDetails: name,
      }));
    }
    setbeneVisible(false);
  };

  const handleTrustSave = () => {
    setbeneVisible(false);
  };

  let [otherPropertyTypes, setOtherPropertyTypes] = useState(false);
  const [isChecked, setChecked] = useState(false); // Convert saftyBox value to boolean

  const handleSwitchChange = () => {
    if (isChecked === true) {
      setChecked(false);
      data.bank.safetyBoxNumber = "";
      data.bank.safetyBox = "false";
    } else {
      setChecked(true);
      data.bank.safetyBox = "true";
    }
  };

  const handleChanges1 = (e, newValue, field, { index }) => {
    if (field === "accountType") {
      if (newValue != null) {
        // Update selected bank types based on the index
        setSelectedBankTypes((prevSelectedBankTypes) => {
          const newSelectedBankTypes = [...prevSelectedBankTypes];
          newSelectedBankTypes[index] = newValue;
          return newSelectedBankTypes;
        });

        // Update the account type in the data state
        setData((prevData) => {
          const updatedAccounts = [...prevData.accounts];
          updatedAccounts[index] = {
            ...updatedAccounts[index],
            accountType: newValue,
          };

          return {
            ...prevData,
            accounts: updatedAccounts,
          };
        });
      }
    } else {
      // Handle changes for other fields
      const { value } = e.target;
      setData((prevData) => {
        const updatedAccounts = [...prevData.accounts];
        if (!updatedAccounts[index]) {
          updatedAccounts[index] = {};
        }
        updatedAccounts[index][field] = value;

        // Calculate total balance and update data.bank.totalAmount
        const balances = updatedAccounts.map((account) =>
          account.balance ? parseFloat(account.balance) : 0
        );
        const newTotalBalance = balances.reduce(
          (acc, curr) => acc + parseFloat(curr),
          0
        );

        return {
          ...prevData,
          accounts: updatedAccounts,
          bank: {
            ...prevData.bank,
            totalAmount: newTotalBalance,
          },
        };
      });
    }
  };

  return (
    <UserBase>
      <div className="mt-5"></div>
      <SideBar>
        <div className="overlay1" style={{ transition: "500ms", height: "" }}>
          <div className="addform_ichest">
            <div className="addform_main addform_main_bank">
              <div className="addform_heading">
                <h3 className="addform_heading_h1">Edit Bank Properties</h3>
                <div className="addform_close">
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={() => {
                      navigate("/user/my-estate/banks");
                    }}
                  />
                </div>
              </div>
              <div className="addform_body">
                <Form onSubmit={BankForm} className="addform_body">
                  <div className="addform_select_body">
                    <div className="addform_body_left addform_body_left_bank">
                      <div>
                        <Tooltip title="Select Owner" className="mt-2">
                          <div>
                            <FormLabel>Select Owner</FormLabel>
                            <Select
                              value={ownerName}
                              multiple
                              placeholder="Select Name"
                              onChange={(event, newValue) =>
                                handleChange(event, newValue, "owner")
                              }
                              sx={{
                                minWidth: "13rem",
                              }}
                            >
                              {ownerNames.map((name) => (
                                <Option key={name} value={name}>
                                  {name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </Tooltip>
                      </div>

                      <div className="mt-2">
                        <Tooltip title="Enter name of the bank">
                          <div>
                            <FormLabel>
                              {data.bank.bankType === "other"
                                ? "Bank Type"
                                : "Bank Name"}
                            </FormLabel>
                            <Select
                              id="banks"
                              placeholder="Select Bank Name"
                              onChange={(event, newValue) =>
                                bankHandleChange(event, newValue)
                              }
                              value={data.bank.bankName || ""}
                              disabled
                            >
                              <Option value={"JPMorgan Chase & Co"}>
                                <img
                                  src="/logos/J.P._Morgan_Chase_logo_PNG3.png"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;&nbsp;JPMorgan Chase & Co
                              </Option>
                              <Option value={"Bank of America"}>
                                <img
                                  src="/logos/Bank_of_America_logo_PNG4.png"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;&nbsp;Bank of America
                              </Option>
                              <Option value={"Wells Fargo & Co"}>
                                <img
                                  src="/logos/Wells_fargo_logo_PNG2.png"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;Wells Fargo & Co
                              </Option>
                              <Option value={"Citigroup Inc"}>
                                <img
                                  src="/logos/Citigroup_logo_PNG1.png"
                                  style={{
                                    width: "40px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;&nbsp;Citigroup Inc
                              </Option>
                              <Option value={"U.S. Bancorp"}>
                                <img
                                  src="/logos/US-Bank-Logo-PNG3.png"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;U.S. Bancorp
                              </Option>
                              <Option value={"PNC bank"}>
                                <img
                                  src="/logos/PNC_Bank_logo_PNG1.png"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;&nbsp;PNC bank
                              </Option>
                              <Option value={"TD Bank"}>
                                <img
                                  src="/logos/TD_Bank_logo_PNG1.png"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;TD Bank
                              </Option>
                              <Option value={"Capital One"}>
                                <img
                                  src="/logos/Capital_One_logo_PNG1.png"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;&nbsp;Capital One
                              </Option>
                              <Option value={"Fifth Third Bank"}>
                                <img
                                  src="/logos/Harris-Teeter-Logo-PNG_003-1.png"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;Fifth Third Bank
                              </Option>
                              <Option value={"Ally Financial Inc"}>
                                <img
                                  src="/logos/Ally_Financial_logo_PNG4.png"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;Ally Financial Inc
                              </Option>
                              <Option
                                value={"Huntington Bancshares Incorporated"}
                              >
                                <img
                                  src="/logos/huntington.webp"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;Huntington Bancshares Incorporated
                              </Option>
                              <Option value={"KeyCorp"}>
                                <img
                                  src="/logos/KeyBank_logo_PNG7.png"
                                  style={{
                                    width: "45px",
                                    aspectRatio: "16/9",
                                    objectFit: "contain",
                                  }}
                                />
                                &nbsp;&nbsp;KeyCorp
                              </Option>
                              <Option
                                value={"other"}
                                onClick={() => {
                                  setOtherPropertyTypes(!otherPropertyTypes);
                                }}
                              >
                                Other
                              </Option>
                            </Select>
                          </div>
                        </Tooltip>
                      </div>
                      {data.bank.bankType === "other" && (
                        <div className="mt-2">
                          <Tooltip title="Enter Your Other Bank Name ">
                            <div>
                              <FormLabel>Other Bank</FormLabel>
                              <Input
                                className="input_mui_joy"
                                placeholder="Enter Other Bank"
                                id="otherprop"
                                onChange={(e) =>
                                  handleChangesBank(e, "bankName")
                                }
                                value={data.bank.bankName}
                                aria-readonly
                                disabled
                              />
                            </div>
                          </Tooltip>
                        </div>
                      )}
                      <div
                        className="mt-3 form1-double"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          gap: "5px",
                        }}
                      >
                        <div
                          style={{
                            width: "49.5%",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div
                            className="txt"
                            style={{
                              alignSelf: "center",
                              marginRight: "10px",
                              flex: "1 1 100%",
                            }}
                          >
                            Safety Box
                          </div>
                          <div
                            className="switch"
                            style={{
                              flex: "1 1 100%",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isChecked}
                                  onChange={handleSwitchChange}
                                  color="primary"
                                  size="normal"
                                />
                              }
                              label={isChecked ? "Yes" : "No"}
                              labelPlacement="end"
                            />
                          </div>
                        </div>

                        {isChecked && (
                          <div className="addform_input_half">
                            <Tooltip title="Enter Your SafetyBox ID">
                              <div>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="Safety Box ID"
                                  id="safetyBoxNumber"
                                  onChange={(e) =>
                                    handleChangesBank(e, "safetyBoxNumber")
                                  }
                                  value={data.bank.safetyBoxNumber}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                      <div>
                        {multipleAccount.map((index) => (
                          <div
                            className="mt-2"
                            key={index}
                            style={{
                              flexDirection: "column",
                              display:
                                index <= visibleColumnIndex ? "flex" : "none",
                            }}
                          >
                            <div>
                              <Tooltip
                                title={`Add your bank details for bank ${
                                  index + 1
                                }`}
                              >
                                <div>
                                  <FormLabel id={`accountType${index + 1}`}>
                                    Bank Type {index >= 0 && <span>*</span>}
                                  </FormLabel>
                                  <Select
                                    placeholder="Select Your Account Type"
                                    id={`accountType${index + 1}`}
                                    value={
                                      data.accounts[index]?.accountType || ""
                                    }
                                    onChange={(e, newValue) =>
                                      handleChanges1(
                                        e,
                                        newValue,
                                        "accountType",
                                        {
                                          index,
                                        }
                                      )
                                    }
                                    // required={index === 0}
                                    required={visibleColumnIndex >= index}
                                  >
                                    <Option
                                      value="Checking Account"
                                      disabled={selectedBankTypes.includes(
                                        "Checking Account"
                                      )}
                                    >
                                      Checking Account
                                    </Option>
                                    <Option
                                      value="Savings Account"
                                      disabled={selectedBankTypes.includes(
                                        "Savings Account"
                                      )}
                                    >
                                      Savings Account
                                    </Option>
                                    <Option
                                      value="Investment Account"
                                      disabled={selectedBankTypes.includes(
                                        "Investment Account"
                                      )}
                                    >
                                      Investment Account
                                    </Option>
                                    <Option
                                      value="C.D Account"
                                      disabled={selectedBankTypes.includes(
                                        "C.D Account"
                                      )}
                                    >
                                      C.D Account
                                    </Option>
                                  </Select>
                                </div>
                              </Tooltip>
                            </div>

                            <div className="address_input">
                              <div className="mt-2 addform_input_half">
                                <Tooltip
                                  title={`Add your bank details for bank ${
                                    index + 1
                                  }`}
                                >
                                  <div>
                                    <FormLabel required>{`BankAccount ${
                                      index + 1
                                    }`}</FormLabel>
                                    <Input
                                      type="number"
                                      placeholder={`BankAccount ${index + 1}`}
                                      id={`BankAccount${index + 1}`}
                                      required={visibleColumnIndex >= index}
                                      onChange={(e) =>
                                        handleChanges1(
                                          e,
                                          null,
                                          "accountNumber",
                                          {
                                            index,
                                          }
                                        )
                                      }
                                      value={
                                        data.accounts[index]?.accountNumber ||
                                        ""
                                      }
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                              <div className="mt-2 addform_input_half">
                                <Tooltip
                                  title={`Add your bank details for bank ${
                                    index + 1
                                  }`}
                                >
                                  <div>
                                    <FormLabel required>{`Account Balance ${
                                      index + 1
                                    }`}</FormLabel>
                                    <Input
                                      type="number"
                                      placeholder={`Account Balance ${
                                        index + 1
                                      }`}
                                      id={`Account Balance ${index + 1}`}
                                      required={visibleColumnIndex >= index}
                                      onChange={(e) =>
                                        handleChanges1(e, null, "balance", {
                                          index,
                                        })
                                      }
                                      value={
                                        data.accounts[index]?.balance || ""
                                      }
                                      startDecorator={<div>$</div>}
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                            {index >= findAccLength && (
                              <Button
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                  backgroundColor: "#ff4a4a",
                                  border: "none",
                                }}
                                onClick={() => handleRemoveBank(index)}
                              >
                                <FontAwesomeIcon icon={faMinus} />
                              </Button>
                            )}
                          </div>
                        ))}
                        {visibleColumnIndex < 3 && (
                          <div
                            style={{
                              marginTop: "7px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              style={{
                                height: "30px",
                                width: "30px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                                backgroundColor: "#4aafff",
                                border: "none",
                              }}
                              onClick={handleAddBank}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </Button>
                          </div>
                        )}
                      </div>

                      <div>
                        <Tooltip title="Add your banks related file">
                          <div className="mt-3">
                            <FormLabel>Supporting Document</FormLabel>
                            <input
                              style={{
                                border: "solid 1px lightgray",
                                borderLeft: "none",
                                width: "100%",
                                borderRadius: "5px",
                              }}
                              type="file"
                              name="myfile"
                              id="exampleFile"
                              onChange={(e) => handleImageChange(e, 5)}
                              accept=".pdf"
                              multiple
                            />
                          </div>
                        </Tooltip>
                      </div>

                      <div
                        className="bene-select mt-3"
                        onClick={handleShowBeneficiary}
                        style={{ cursor: "pointer" }}
                      >
                        Select Your Beneficiary
                      </div>
                      <div className="address_input">
                        <div style={{ width: "100%" }} className="mt-2">
                          <Tooltip title="Enter notes for your real estate">
                            <div>
                              <FormLabel>Notes</FormLabel>
                              <Textarea
                                sx={{ height: "182px", minHeight: "52px" }}
                                placeholder="Notes"
                                id="notes"
                                onChange={(e) => handleChangesBank(e, "notes")}
                                value={data.bank.notes}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>

                    {/* <div className="addform_body_right_bank">
                      
                    </div> */}
                  </div>
                  <div
                    className="text-center"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button outline className="my-estate-add-btn">
                      Update
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>

        {benevisible && (
          <SharedBeneficiaryEdit
            responseData={responseData}
            responseAssetData={responseAssetData}
            handleTrustSave={handleTrustSave}
            assetData={data?.bank}
            trusts={trusts}
            handleChanges={handleChangesBank}
            sharedType={sharedType}
            handleCloseBeneficiaryPopup={handleEditCloseBeneficiary}
            estimatedTotalAmount={estimatedTotalAmount}
            typeBeneficiary={"normal"}
            sharedDetails={data?.sharedDetails}
          />
        )}
      </SideBar>
    </UserBase>
  );
}

export default Editbanks;
