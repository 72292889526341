import { Tooltip } from "@mui/material";
import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "./../../css/deletebtn.css";
import "../trustee-route/TrusteeDashboard.css";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faPlus, faTrash, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Code By Purnendu
//new ly modified Smruti ranjan
// This is Delete Button for remove any property
export function Deletebutton({
  handleRemove,
  Id,
  idType,
  roleText,
  anyText,
  userName,
  assetValue,
  handleConfirmationClose,
}) {
  const [modal, setModal] = useState(true);
  const [confirmation, setConfirmation] = useState("");

  const toggle = () => {
    setModal(!modal);
    setConfirmation("");
  };
  const handleConfirmationPopupClose = () => {
    toggle();
    handleConfirmationClose();
  };
  const handleConfirmationChange = (e) => {
    setConfirmation(e.target.value);
  };

  const isConfirmed = confirmation === "PERMANENTLY_DELETE";

  const handleDelete = () => {
    if (isConfirmed) {
      const params = {
        Id,
        idType,
        roleText,
        anyText,
        userName,
        assetValue,
      };
      toggle();
      handleRemove(params);
      setConfirmation("");
    }
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={handleConfirmationPopupClose} style={{ paddingTop: "50px" }}>
        <ModalHeader toggle={handleConfirmationPopupClose}>
          <span style={{ color: "red", fontWeight: "500" }}>Warning !!!</span>
        </ModalHeader>
        <ModalBody style={{ color: "black" }}>
          {/* Please enter{" "}
          <span className="delete_message">"PERMANENTLY_DELETE"</span> to
          confirm your deletion:  */}
          Please enter{" "}
          <span style={{ color: "red" }}>"PERMANENTLY_DELETE"</span> to confirm
          your deletion:
          <div className="delete_button_warning">
            <input
              type="text"
              value={confirmation}
              onChange={handleConfirmationChange}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="confirm_delete_btn"
            onClick={handleDelete}
            disabled={!isConfirmed}
          >
            Delete
          </Button>{" "}
          <Button
            className="confirm_cancel_btn"
            onClick={handleConfirmationPopupClose}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {/* <Modal isOpen={modal} toggle={toggle} style={{ paddingTop: "50px" }}>
        <ModalHeader style={{ borderBottom: "none" }}>
          <span style={{ color: "red", fontWeight: "500" }}>Warning !!!</span>
        </ModalHeader>
        <ModalBody
          style={{ color: "black", fontSize: "18px", padding: "0 16px" }}
        >
          {roleText === "removeInvitation"
            ? "Are you sure, do you want to remove invite?"
            : roleText === "acceptInvitation"
            ? "Are you sure, do you want to accept invite?"
            : roleText === "removeAttorney"
            ? "Are you sure, do you want to remove attorney?"
            : anyText === "addJewelryConfirmation"
            ? "Do you want to add more jewelry in this list?"
            : "Are you sure, do you want to delete this?"}
        </ModalBody>
        <ModalFooter style={{ borderTop: "none" }}>
          <Button className="confirm_delete_btn" onClick={handleDelete}>
            Yes
          </Button>{" "}
          {anyText === "addJewelryConfirmation" ? (
            <Button className="confirm_cancel_btn" onClick={toggle}>
              No
            </Button>
          ) : (
            <Button className="confirm_cancel_btn" onClick={toggle}>
              Cancel
            </Button>
          )}
        </ModalFooter>
      </Modal> */}
    </div>
  );
}

export default Deletebutton;

export function Del({ handleRemove, Id }) {
  const [modal, setModal] = useState(false);
  const [confirmation, setConfirmation] = useState("");
  const toggle = () => {
    setModal(!modal);
    setConfirmation("");
  };

  const handleConfirmationChange = (e) => {
    setConfirmation(e.target.value);
  };

  const isConfirmed = confirmation === "PERMANENTLY_DELETE";

  const handleDelete = () => {
    if (isConfirmed) {
      toggle();
      handleRemove(Id);
      setConfirmation("");
    }
  };

  return (
    <div>
      <Button onClick={toggle} className="book_card_delete_buttons">
        <Tooltip title="click here to delete your diary">
          <FontAwesomeIcon icon={faTrashCan} />
        </Tooltip>
      </Button>
      <Modal isOpen={modal} toggle={toggle} style={{ paddingTop: "50px" }}>
        <ModalHeader toggle={toggle}>
          <span style={{ color: "red", fontWeight: "500" }}>Warning !!!</span>
        </ModalHeader>
        <ModalBody style={{ color: "black" }}>
          Please enter{" "}
          <span className="delete_message">"PERMANENTLY_DELETE"</span> to
          confirm your deletion:
          <div className="delete_button_warning">
            <input
              type="text"
              value={confirmation}
              onChange={handleConfirmationChange}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="confirm_delete_btn"
            onClick={handleDelete}
            disabled={!isConfirmed}
          >
            Delete
          </Button>{" "}
          <Button className="confirm_cancel_btn" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
