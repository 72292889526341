import {
  faCircleInfo,
  faPencilAlt,
  faTrash,
  faUser,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SideBar from "../../components/sidebar/Sidebar";
import UserBase from "../../components/user/UserBase";
import "../../css/AttorneyForm.css";
import {
  deleteOwnerById,
  getOwnerByUserId,
  getToken,
  getUser
} from "../../services/user-service";
import CreateOwner from "../CreateOwner";
import Deletebutton from "../my-estate/Deletebutton";
import "./OwnerList.css";

const OwnerList = () => {
  const [editOwnerPopup, setEditOwnerPopup] = useState(false);
  const [ownerId, setOwnerId] = useState(null);

  const toggleBeneficiary = (id) => {
    setEditOwnerPopup(!editOwnerPopup);
    setOwnerId(id);
  };

  const handleBack = () => {
    setEditOwnerPopup(false);
    getAllOwner();
  };

  const [showUserDetails, setShowUserDetails] = useState(null);
  const [show, setShow] = useState(false);
  const toggleShow = (owner) => {
    setShowUserDetails(owner);
    setShow(!show);
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const removeOwner = (id) => {
    setShowConfirmation(true);
    setCurrentId(id);
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const handleRemove = (params) => {
    const token = "Bearer " + getToken();
    setShowConfirmation(false);
    const { Id } = params;
    deleteOwnerById(token, Id)
      .then(() => {
        toast.success("Deleted successfully...", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        getAllOwner();
      })
      .catch(() => {});
  };

  const [owner, setOwner] = useState([]);
  const getAllOwner = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getOwnerByUserId(token, userId)
      .then((res) => {
        setOwner(res);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getAllOwner();
  }, []);
  return (
    <>
      <div className={"your-component fade-in-element"}>
        <UserBase>
          <div style={{ marginTop: "4rem" }} className="otherElement">
            <SideBar>
              <div className="beneficiary_list_base">
                <div className="beneficiary_list_main">
                  <div className="beneficiary_heading">
                    <h4>
                      Owner List {"   "} ({owner.length})
                    </h4>
                  </div>
                  <div className="beneficiary_container">
                    <div className="beneficiary_details_cards">
                      <Paper
                        sx={{ padding: 1.5 }}
                        className="beneficiary_detail_card"
                        style={{
                          backgroundColor: "rgb(208,208,208)",
                          width: "fit-content",
                        }}
                      >
                        <p className="beneficiary_row">
                          <strong>Name</strong>
                        </p>
                        <p
                          style={{ minWidth: "190px" }}
                          className="beneficiary_row"
                        >
                          <strong>Email</strong>
                        </p>
                        <p className="beneficiary_row">
                          <strong>Status</strong>
                        </p>
                        <p className="beneficiary_row">
                          <strong>Date Of Birth</strong>
                        </p>
                        <p className="beneficiary_row">
                          <strong>Country Of Citizenship</strong>
                        </p>
                        <p
                          className="beneficiary_row"
                          style={{ width: "240px", textAlign: "center" }}
                        >
                          <strong>Action</strong>
                        </p>
                      </Paper>

                      {owner.map((detail, index) => (
                        <Paper
                          style={{ width: "fit-content" }}
                          key={index + 1}
                          sx={{ padding: 1.5 }}
                          className="beneficiary_detail_card"
                        >
                          <p className="beneficiary_row beneficiary_row_data">
                            <strong className="beneficiary_row_data_name">{`${detail.firstName} ${detail.lastName}`}</strong>

                            {`${detail.state ? `${detail.state}, ` : ""}${
                              detail.country ? detail.country : ""
                            }`}
                          </p>
                          <p
                            style={{ minWidth: "190px" }}
                            className="beneficiary_row beneficiary_row_data"
                          >
                            {detail.email}
                          </p>
                          <p className="beneficiary_row beneficiary_row_data">
                            {detail.ownerStatus === "Living" ? (
                              <span
                                style={{ fontWeight: "bold", color: "green" }}
                              >
                                {detail.ownerStatus}
                              </span>
                            ) : (
                              <span
                                style={{ fontWeight: "bold", color: "#d30000" }}
                              >
                                {detail.ownerStatus}
                              </span>
                            )}
                          </p>
                          <p className="beneficiary_row beneficiary_row_data">
                            {detail.dob}
                          </p>
                          <p className="beneficiary_row beneficiary_row_data">
                            {detail.countryOfCitizenship}
                          </p>
                          <div className="beneficiary_row_action_btn">
                            <button
                              onClick={() => {
                                removeOwner(detail.id);
                              }}
                              type="button"
                              className="beneficiary_row_Action_delete_edit"
                            >
                              <p>
                                <FontAwesomeIcon icon={faTrash} />
                              </p>
                            </button>
                            <button
                              // to="/user/dashboard"
                              onClick={() => {
                                toggleBeneficiary(detail.id);
                              }}
                              className="beneficiary_row_Action_delete_edit"
                            >
                              <p>
                                <FontAwesomeIcon icon={faPencilAlt} />
                              </p>
                            </button>
                            <button
                              className="beneficiary_row_Action"
                              onClick={() => {
                                toggleShow(detail);
                              }}
                            >
                              <p>
                                <FontAwesomeIcon icon={faCircleInfo} />
                              </p>
                              View Details
                            </button>
                          </div>
                        </Paper>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* confirmation for delete owner   */}
              {showConfirmation && (
                <Deletebutton
                  handleRemove={handleRemove}
                  Id={currentId}
                  handleConfirmationClose={handleConfirmationClose}
                />
              )}

              {/* show detail popup */}
              {show && Object.keys(showUserDetails).length > 0 && (
                <>
                  <div
                    className="card__data"
                    style={{ transition: "all 1s ease-out" }}
                  >
                    <div className="card__data-container">
                      <section className="section1">
                        <div>
                          <p className="row1-text">
                            <FontAwesomeIcon
                              icon={faUser}
                              style={{ color: "#025596", fontSize: "18px" }}
                            />
                            <span style={{}}>
                              {showUserDetails.firstName}{" "}
                              {showUserDetails.lastName}
                            </span>
                          </p>
                          <div className="row1-button">
                            <div>
                              <span
                                className="card__data-close"
                                onClick={() => {
                                  toggleShow();
                                }}
                              >
                                <FontAwesomeIcon icon={faXmark} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section className="section2">
                        <div>
                          <div className="col1">
                            {showUserDetails.ownerStatus && (
                              <p>
                                Owner Status:{" "}
                                <code
                                  style={{ color: "green", fontWeight: "bold" }}
                                >
                                  {showUserDetails.ownerStatus}
                                </code>
                              </p>
                            )}
                            {showUserDetails.firstName && (
                              <p>
                                Name:{" "}
                                <code>
                                  {showUserDetails.firstName}{" "}
                                  {showUserDetails.lastName}
                                </code>
                              </p>
                            )}
                            {showUserDetails.gender && (
                              <p>
                                Gender: <code>{showUserDetails.gender}</code>
                              </p>
                            )}
                            {showUserDetails.email && (
                              <p>
                                Email:{" "}
                                <code style={{ textTransform: "lowercase" }}>
                                  {showUserDetails.email}
                                </code>
                              </p>
                            )}
                            {showUserDetails.dob && (
                              <p>
                                Date of birth:{" "}
                                <code>{showUserDetails.dob}</code>
                              </p>
                            )}
                          </div>
                          <div className="col2">
                            {showUserDetails.phoneNo && (
                              <p>
                                Phone Number:{" "}
                                <code>{showUserDetails.phoneNo}</code>
                              </p>
                            )}
                            {showUserDetails.relationship && (
                              <p>
                                Relationship:{" "}
                                <code>{showUserDetails.relationship}</code>
                              </p>
                            )}
                            {showUserDetails.countryOfBirth && (
                              <p>
                                Country Of Birth:{" "}
                                <code>{showUserDetails.countryOfBirth}</code>
                              </p>
                            )}
                            {showUserDetails.countryOfCitizenship && (
                              <p>
                                Country Of Citizenship:{" "}
                                <code>
                                  {showUserDetails.countryOfCitizenship}
                                </code>
                              </p>
                            )}
                            {(showUserDetails.state ||
                              showUserDetails.country) && (
                              <p>
                                Address:{" "}
                                <code>
                                  {`${
                                    showUserDetails.street
                                      ? `${showUserDetails.street}, `
                                      : ""
                                  }${
                                    showUserDetails.apartment
                                      ? `${showUserDetails.apartment}, `
                                      : ""
                                  }${
                                    showUserDetails.city
                                      ? `${showUserDetails.city}, `
                                      : ""
                                  }${
                                    showUserDetails.state
                                      ? `${showUserDetails.state}, `
                                      : ""
                                  }${
                                    showUserDetails.country
                                      ? `${showUserDetails.country} `
                                      : ""
                                  }${
                                    showUserDetails.zipCode
                                      ? `${showUserDetails.zipCode}`
                                      : ""
                                  }`}
                                </code>
                              </p>
                            )}
                            {showUserDetails.legalGuardianInfo && (
                              <p>
                                Legal Guardian Information:{" "}
                                <code>{showUserDetails.legalGuardianInfo}</code>
                              </p>
                            )}
                            {showUserDetails.powerOfAttorneyDetails && (
                              <p>
                                Power Of Attorney Details:{" "}
                                <code>
                                  {showUserDetails.powerOfAttorneyDetails}
                                </code>
                              </p>
                            )}
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </>
              )}

              {editOwnerPopup && (
                <div className="beneficiary-popup">
                  <div className="beneficiary-content">
                    {editOwnerPopup && (
                      <CreateOwner onBack={handleBack} Id={ownerId} />
                    )}
                  </div>
                </div>
              )}
            </SideBar>
          </div>
        </UserBase>
      </div>
    </>
  );
};

export default OwnerList;
