import React, { useState } from "react";
import OtpInput from "react-otp-input";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
} from "reactstrap";
// Code By Purnendu
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../css/otppage.css";
import { sendOtp, signup, verifyOtp } from "../services/user-service";
import { EmailTemplatePostfix, Emailtemplate } from "./Emailtemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
export default function Otppage({
  userDetails,
  onFlagReceived,
  otpType,
}) {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [visible, setVisible] = useState(false);
  const [alertMeg, setAlertMeg] = useState();
  const usermail = userDetails.email;
  // const [email, setemail] = useState({
  //   to: "",
  //   subject: "",
  //   message: "",
  // });

  const handleVerify = (e) => {
    e.preventDefault();
    console.log("email: ", usermail);
    console.log("otp: ", otp);
    verifyOtp({ usermail, otp })
      .then((res) => {
        // navigate("/reset-password")
        if (res === true && otpType === "register") {
          signup(userDetails)
            .then((resp) => {
              toast.success("User register successfully");
              onFlagReceived(false);
              navigate("/");
              return;
            })
            .catch((error) => {
              // handle error
              console.log(error);
            });

        } else if (res === true && otpType === "forgetPassword") {
          // toast.success("User register successfully");
          onFlagReceived(true);

          // navigate("/")
        } else {
          setVisible(true);
          // toast.error("Please enter correct OTP");
          setAlertMeg("Please Enter the correct OTP");
        }
      })
      .catch((err) => {
        setVisible(true);
        setAlertMeg("Please Enter the correct OTP");
      });
  };

  const resendOtp = () => {
    sendOtp({
      to: usermail,
      subject: "Login OTP",
      message: Emailtemplate,
      postfixMessage: EmailTemplatePostfix,
    })
      .then((res) => {
        setOtp("");
        setVisible(true);
        setAlertMeg("OTP Sent");
      })
      .catch((err) => {
        return;
      });
  };
  return (
    <>
      <Container>
        <div className="otppage-mane">
          <Card className="otppage-card">
            <Alert
              className={
                alertMeg === "OTP Sent" ? "text-center-success" : "text-center"
              }
              color={alertMeg === "OTP Sent" ? "primary" : "danger"}
              isOpen={visible}
            >
              {alertMeg}
            </Alert>
            <div className="otpvalidate_page">
              <div className="login_cancel_icon">
                <FontAwesomeIcon
                  className="cancel_icon_login"
                  icon={faTimes}
                  onClick={() => window.history.back()}
                />
              </div>
              <div className="otp_heading">
                <span class="mainHeading">Enter OTP</span>
                <p class="otpSubheading">We have sent a verification <br /> code to your mobile number</p>
              </div>
            </div>

            <Form onSubmit={handleVerify}>
              <div className="otppage-otpdesign">
                <OtpInput
                  inputStyle={{
                    justifyContent: "center",
                    height: "40px",
                    width: "30px",
                    borderRadius: "7px",
                    border: "3px transparent",
                  }}
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
              <div className="otppage-button">
                <button class="verifyButton" type="submit">Verify</button>
                <p class="resendNote">Didn't receive the code? <button onClick={resendOtp} class="resendBtn">Resend Code</button></p>

              </div>
            </Form>
          </Card>
        </div>
      </Container>
    </>
  );
}
