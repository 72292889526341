import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "../css/BeneficiarySignup.css";
import {
  addUserBeneficiary,
  getBeneficiaryById,
  updateUserBeneficiary,
} from "../services/user-service";
// import {getUser} from "../../services/user-service";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
} from "@mui/joy";
import { Typography } from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import {
  changeSocialSecurityFormat,
  changeUsFormatNumber,
  validate,
} from "../components/Common";
import { getToken } from "../services/user-service";

// set signup data
const BeneficiarySignup = (params) => {
  const { handleBack, Id } = params;
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    phoneNo: "",
    socialSecurityNo: "",
    countryOfBirth: "",
    countryOfCitizenship: "",
    street: "",
    apartment: "",
    city: "",
    zipCode: "",
    state: "",
    country: "",
    legalGuardianInfo: "",
    powerOfAttorneyDetails: "",
  });

  const [email, setemail] = useState({
    to: "",
    subject: "",
    message: "",
  });

  const validatePhoneNumber = (phoneNo) => {
    if (phoneNo === "") {
      return true;
    }
    const re = /^(\+1\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/;
    return re.test(phoneNo);
  };

  const validateEmail = (email) => {
    const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    return re.test(email);
  };

  // const validateAlphanumeric = (value) => {
  //   // Check if value is alphanumeric (letters and/or numbers) and not entirely numeric
  //   const isAlphanumeric = /^[a-zA-Z0-9]+$/.test(value);
  //   const isNumeric = /^\d+$/.test(value);

  //   return isAlphanumeric && !isNumeric;
  // };
  const validateAlphanumeric = (value) => {
    return !/^\d+$/.test(value);
  };

  const validateZipCode = (zipCode) => {
    return /^\d{5}$/.test(zipCode);
  };

  const validateSocialSecurityNo = (value) => {
    const re = /^\d{3}-\d{2}-\d{4}$/;
    return re.test(value);
  };

  const [errors, setErrors] = useState({
    firstName: true,
    // username: true,
    dob: true,
    // email: true,
    // phoneNo: true,
    // password: true,
    // country: true,
    // countryValidate: true,
    legalGuardianInfo: true,
    firstNameValidate: true,
    lastNameValidate: true,
    dobValidate: true,
    phoneValidate: true,
    emailValidate: true,
    // usernameValidate: true,
    // passwordValidate: true,
    zipValidate: true,
    validZip: true,
  });

  const today = dayjs().format("YYYY-MM-DD");

  const handleChanges = (event, property) => {
    let value = event.target.value;
    const errorStates = { ...errors }; // Clone the existing errors state

    // Handle phone number formatting
    if (property === "phoneNo") {
      const formattedValue = changeUsFormatNumber(value);
      setData((prevData) => ({
        ...prevData,
        [property]: formattedValue,
      }));
      // errorStates[property] = !!formattedValue;
    } else if (property === "socialSecurityNo") {
      const formattedValue = changeSocialSecurityFormat(value);
      setData((prevData) => ({
        ...prevData,
        [property]: formattedValue,
      }));
      // errorStates.dobValidate = false;
    } else if (property === "zipCode") {
      if (/^\d{0,5}$/.test(value)) {
        // Validation to allow only up to 5 digits (or empty string)
        setData((prevData) => ({
          ...prevData,
          [property]: value,
        }));
      }
    }
    // Handle date of birth validation
    else if (property === "dob" && value > today) {
      setData((prevData) => ({
        ...prevData,
        [property]: value,
      }));
      errorStates.dobValidate = false;
    } else {
      // Update field value
      setData((prevData) => ({
        ...prevData,
        [property]: value,
      }));
      errorStates[property] = !!value;
    }

    // Additional validations
    if (property === "email") {
      errorStates.emailValidate = value !== "" ? validateEmail(value) : true;
    } else if (property === "zipCode") {
      errorStates.zipValidate =
        value !== "" ? validateZipCode(value.slice(0, 5)) : true;
    } else if (property === "phoneNo") {
      errorStates.phoneValidate =
        value !== "" ? validatePhoneNumber(changeUsFormatNumber(value)) : true;
    } else if (property === "dob" && value < today) {
      errorStates.dobValidate = true;
    } else if (property === "firstName" || property === "lastName") {
      if (property === "firstName") {
        errorStates.firstNameValidate = validateAlphanumeric(value);
      } else if (property === "lastName") {
        if (value !== "") {
          errorStates.lastNameValidate = validateAlphanumeric(value);
        } else {
          errorStates.lastNameValidate = true;
        }
      }
    }
    // Set errors state once
    setErrors(errorStates);
  };

  useEffect(() => {
    const fetchDataFromAPI = () => {
      if (data.zipCode.length === 5) {
        const apiUrl = `https://api.zippopotam.us/us/${data.zipCode}`;
        axios
          .get(apiUrl)
          .then((res) => {
            if (res.data && res.data.places && res.data.places.length > 0) {
              const place = res.data.places[0];
              const city = place["place name"];
              const state = place["state abbreviation"];
              const country = res.data["country abbreviation"];

              setData((prevData) => ({
                ...prevData,
                city: city,
                state: state,
                country: country,
              }));
              setErrors((prevErrors) => ({
                ...prevErrors,
                validZip: true,
              }));
            }
          })
          .catch((error) => {
            setErrors((prevErrors) => ({
              ...prevErrors,
              validZip: false,
            }));
          });
      } else {
        setData((prevData) => ({
          ...prevData,
          city: "",
          state: "",
          country: "",
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          validZip: true,
        }));
      }
    };

    fetchDataFromAPI();
  }, [data.zipCode]);

  // Calculate the age based on the date of birth
  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birthdate hasn't occurred this year
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      return age - 1;
    }

    return age;
  };

  const age = data.dob ? calculateAge(data.dob) : null;

  // use state to set the selected images
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]; // Get the first (and only) selected file
    const allowedExtensions = ["pdf"];

    if (selectedFile) {
      const fileNameParts = selectedFile.name.split(".");
      const fileExtension =
        fileNameParts[fileNameParts.length - 1].toLowerCase();

      // Check if the file's extension is allowed
      if (allowedExtensions.includes(fileExtension)) {
        const formData = new FormData();
        formData.append("file", selectedFile);

        setSelectedFile(selectedFile); // Update state with the selected file
      } else {
        // Handle invalid file extension
        toast.error(
          `Invalid file extension: ${fileExtension}. Please select a valid PDF document.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  // reseting the form
  const resetData = () => {
    setData({
      firstName: "",
      lastName: "",
      dob: "",
      email: "",
      phoneNo: "",
      socialSecurityNo: "",
      countryOfBirth: "",
      countryOfCitizenship: "",
      street: "",
      apartment: "",
      city: "",
      zipCode: "",
      state: "",
      country: "",
      legalGuardianInfo: "",
      powerOfAttorneyDetails: "",
    });
  };

  const [emailDetail] = useState({
    to: "",
    subject: "",
    message: "",
  });

  const getBeneficiaryDataById = () => {
    const token = "Bearer " + getToken();
    if (Id !== undefined && Id !== null) {
      getBeneficiaryById(token, Id)
        .then((res) => {
          setData(res.usersBeneficiary);
        })
        .catch((err) => {
          toast.error("Some error occurred", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        });
    }
  };

  // submit the form
  const submitForm = (event) => {
    event.preventDefault();

    // Initialize an errors object with boolean values
    const newErrors = {
      firstName: !!data.firstName,
      // username: !!data.username,
      dob: !!data.dob,
      legalGuardianInfo:
        age !== null && age < 18 ? !!data.legalGuardianInfo : true,
      // email: !!data.email,
      // phoneNo: !!data.phoneNo,
      emailValidate: data.email ? validateEmail(data.email) : true,
      phoneValidate: data.phoneNo
        ? validatePhoneNumber(changeUsFormatNumber(data.phoneNo))
        : true,
      dobValidate: data.dob ? data.dob < today : true,
      firstNameValidate: validateAlphanumeric(data.firstName),
      lastNameValidate: data.lastName
        ? validateAlphanumeric(data.lastName)
        : true,
      zipValidate: data.zipCode ? validateZipCode(data.zipCode) : true,
      validZip: errors.validZip,
    };
    setErrors(newErrors);
    // Check if there are any errors
    const hasErrors = Object.values(newErrors).includes(false);

    if (!hasErrors) {
      let token = "Bearer " + getToken();
      //create form data to send a file and remaining class data
      const formData = new FormData();
      if (null != selectedFile) {
        formData.append(`file`, selectedFile);
      }

      if (Id !== undefined && Id !== null) {
        const updatedData = { ...data, id: Id };
        formData.append("data", JSON.stringify(updatedData));

        updateUserBeneficiary(formData, token)
          .then((res) => {
            toast.success("Beneficiary updated successfully", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            handleBack();
          })
          .catch((err) => {
            toast.error("Some error occurred", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          });
      } else {
        formData.append("data", JSON.stringify(data));
        addUserBeneficiary(formData, token)
          .then((res) => {
            toast.success("Beneficiary added successfully", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            resetData();
            handleBack();
          })
          .catch((err) => {
            toast.error("Some error occurred", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          });
      }
    }
  };

  useEffect(() => {
    getBeneficiaryDataById();
  }, []);

  return (
    <div className="attorney_container">
      <div className="beneficiary_registration_form">
        <div className="beneficiary_registration_form_heading">
          {Id ? (
            <h1 style={{ textAlign: "center", width: "100%" }}>
              Edit Beneficiary
            </h1>
          ) : (
            <h1 style={{ textAlign: "center", width: "100%" }}>
              Add Beneficiary
            </h1>
          )}

          <div className="beneficiary_registration_cancel_icon">
            <FontAwesomeIcon icon={faTimes} onClick={handleBack} />
          </div>
        </div>
        <form
          className="beneficiary_registration_form_main"
          onSubmit={submitForm}
        >
          <div className="beneficiary_account_form">
            <div className="beneficiary_form_left">
              <FormControl>
                <FormLabel required>First Name</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter first name"
                  value={data.firstName}
                  onChange={(e) => handleChanges(e, "firstName")}
                />
                {(!errors.firstName || !errors.firstNameValidate) && (
                  <FormHelperText error>
                    <Typography variant="caption" color="error">
                      {!errors.firstName
                        ? "First Name is required"
                        : !errors.firstNameValidate
                        ? "Enter valid first name"
                        : ""}
                    </Typography>
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter last name"
                  value={data.lastName}
                  onChange={(e) => handleChanges(e, "lastName")}
                />
                {!errors.lastNameValidate && (
                  <FormHelperText error>
                    <Typography variant="caption" color="error">
                      Enter valid last name
                    </Typography>
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl>
                <FormLabel>Social Security Number</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter Social Security Number"
                  value={data.socialSecurityNo}
                  onChange={(e) => handleChanges(e, "socialSecurityNo")}
                />
              </FormControl>

              <FormControl>
                <FormLabel required>Date Of Birth</FormLabel>
                <Input
                  type="date"
                  onChange={(e) => handleChanges(e, "dob")}
                  value={data.dob}
                  slotProps={{
                    input: {
                      max: today,
                    },
                  }}
                />
                {(!errors.dob || !errors.dobValidate) && (
                  <FormHelperText error>
                    <Typography variant="caption" color="error">
                      {!errors.dob
                        ? "Date of birth is required"
                        : !errors.dobValidate
                        ? "You can not enter future date"
                        : ""}
                    </Typography>
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  placeholder="Enter phone number"
                  value={data.phoneNo}
                  onChange={(e) => handleChanges(e, "phoneNo")}
                />
                {!errors.phoneValidate && (
                  <FormHelperText error>
                    <Typography variant="caption" color="error">
                      {!errors.phoneValidate
                        ? "Enter a valid phone number"
                        : ""}
                    </Typography>
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            <div className="beneficiary_form_right">
              <FormControl className="bene_input_form">
                <FormLabel>Email</FormLabel>
                <Input
                  placeholder="Enter email address"
                  value={data.email}
                  onChange={(e) => handleChanges(e, "email")}
                />
                {!errors.emailValidate && (
                  <FormHelperText error>
                    <Typography variant="caption" color="error">
                      {!errors.emailValidate ? "Enter a valid email" : ""}
                    </Typography>
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl className="bene_input_form">
                <FormLabel>Country Of Citizenship</FormLabel>
                <Input
                  placeholder="Enter country of citizenship"
                  value={data.countryOfCitizenship}
                  onChange={(e) => handleChanges(e, "countryOfCitizenship")}
                />
              </FormControl>

              <FormControl className="bene_input_form">
                <FormLabel>Country Of Birth</FormLabel>
                <Input
                  placeholder="Enter country of birth"
                  value={data.countryOfBirth}
                  onChange={(e) => handleChanges(e, "countryOfBirth")}
                />
              </FormControl>
              <FormControl className="bene_input_form">
                <FormLabel>Street</FormLabel>
                <Input
                  placeholder="Enter street"
                  value={data.street}
                  onChange={(e) => handleChanges(e, "street")}
                />
              </FormControl>
              <FormControl className="bene_input_form">
                <FormLabel>Apartment</FormLabel>
                <Input
                  placeholder="Enter street"
                  value={data.apartment}
                  onChange={(e) => handleChanges(e, "apartment")}
                />
              </FormControl>

              <div className="bene_address">
                <div className="bene_address_half">
                  <FormControl className="bene_input_form">
                    <FormLabel>Zip Code</FormLabel>
                    <Input
                      placeholder="Enter zip code"
                      value={data.zipCode}
                      onChange={(e) => handleChanges(e, "zipCode")}
                    />
                    {(!errors.zipValidate || !errors.validZip) && (
                      <FormHelperText error>
                        <Typography variant="caption" color="error">
                          {!errors.zipValidate
                            ? "Please enter a valid 5-digit Zip Code"
                            : !errors.validZip
                            ? "This is not a valid Zip Code"
                            : ""}
                        </Typography>
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className="bene_address_half">
                  <FormControl className="bene_input_form">
                    <FormLabel>City</FormLabel>
                    <Input
                      placeholder="Enter city"
                      value={data.city}
                      disabled
                      readOnly
                    />
                  </FormControl>
                </div>
              </div>
              <div className="bene_address">
                <div className="bene_address_half">
                  <FormControl className="bene_input_form">
                    <FormLabel>State</FormLabel>
                    <Input
                      placeholder="Enter state"
                      value={data.state}
                      disabled
                      readOnly
                    />
                  </FormControl>
                </div>
                <div className="bene_address_half">
                  <FormControl className="bene_input_form">
                    <FormLabel>Country</FormLabel>
                    <Input
                      placeholder="Enter country"
                      value={data.country}
                      disabled
                      readOnly
                    />
                  </FormControl>
                </div>
              </div>

              <FormControl>
                <FormLabel htmlFor="exampleFile">
                  Identification Document
                </FormLabel>
                <input
                  style={{
                    border: "solid 1px lightgray",
                    borderLeft: "none",
                    width: "100%",
                    borderRadius: "2px",
                  }}
                  type="file"
                  name="myfile"
                  id="exampleFile"
                  onChange={handleFileChange}
                  accept=".pdf"
                />
              </FormControl>

              {/* {(!errors.country || !errors.countryValidate) && (
                  <FormHelperText error>
                    <Typography variant="caption" color="error">
                      {!errors.country
                        ? "Country of birth is required"
                        : !errors.countryValidate
                        ? "Enter valid country of birth"
                        : ""}
                    </Typography>
                  </FormHelperText>
                )} */}

              {age !== null && age < 18 && (
                <FormControl className="bene_input_form">
                  <FormLabel required>Legal Guardian Information</FormLabel>
                  <Textarea
                    placeholder="Legal Guardian Information"
                    value={data.legalGuardianInfo}
                    onChange={(e) => handleChanges(e, "legalGuardianInfo")}
                  />
                  {!errors.legalGuardianInfo && (
                    <FormHelperText error>
                      <Typography variant="caption" color="error">
                        {!errors.legalGuardianInfo
                          ? "Legal Guardian Information is required"
                          : ""}
                      </Typography>
                    </FormHelperText>
                  )}
                </FormControl>
              )}
              <FormControl className="bene_input_form">
                <FormLabel>Power Of Attorney Details</FormLabel>
                <Textarea
                  placeholder="Legal Guardian Information"
                  value={data.powerOfAttorneyDetails}
                  onChange={(e) => handleChanges(e, "powerOfAttorneyDetails")}
                />
              </FormControl>
            </div>
          </div>
          <div className="beneficiary_form_btn_main">
            <div className="beneficiary_registration_form_btn">
              {Id ? (
                ""
              ) : (
                <button onClick={resetData} type="button">
                  Clear
                </button>
              )}
            </div>
            <div className="beneficiary_registration_form_btn">
              {Id ? (
                <button type="submit">Update</button>
              ) : (
                <button type="submit">Create</button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BeneficiarySignup;
