import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, FormHelperText, FormLabel, Input } from "@mui/joy";
import { IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import "../../src/css/AttorneyForm.css";
import { changeUsFormatNumber } from "../components/Common";
import "../css/myestare.css";
import {
  checkEmail,
  checkUsername,
  secondaryUserSignUp,
  signup,
} from "../services/user-service";

// this function for both attorney registration and secondary user registration
export default function AttorneySignUp() {
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState(null);

  // Extract the token from the URL
  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      setToken(token);
      setData((prevData) => ({
        ...prevData,
        role: "USER",
        jointAccount: true,
        accountType: "secondary",
      }));
    }
  }, [searchParams]);

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    temporaryUsername: "",
    phoneNo: "",
    role: "ATTORNEY",
    jointAccount: false,
    accountType: "",
  });

  const resetData = () => {
    setData({
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      password: "",
      phoneNo: "",
    });
  };

  const goBack = () => {
    navigate("/");
  };

  const [errors, setErrors] = useState({
    firstName: true,
    lastName: true,
    username: true,
    email: true,
    phoneNo: true,
    firstNameValidate: true,
    lastNameValidate: true,
    emailValidate: true,
    phoneValidate: true,
    password: true,
    passwordValidate: true,
    usernameValidate: true,
  });

  const validateAlphanumeric = (value) => {
    return !/^\d+$/.test(value);
  };

  const validateEmail = (email) => {
    const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    return re.test(email);
  };

  const validatePhoneNumber = (phoneNo) => {
    const re = /^(\+1\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/;
    return re.test(phoneNo);
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;
    return re.test(password);
  };

  const validateUsername = (username) => {
    const re = /^[a-z][a-z0-9_]*$/;
    return re.test(username);
  };

  const checkEmailExists = (email) => {
    return new Promise((resolve, reject) => {
      if (email !== "" && validateEmail(email)) {
        checkEmail(email)
          .then((res) => {
            if (res === true) {
              resolve(res); // Resolve the promise with true
            } else {
              resolve(res); // Resolve the promise with false
            }
          })
          .catch((err) => {
            console.error(err);
            reject(err); // Reject the promise with the error
          });
      } else {
        resolve(false); // Resolve the promise with false if email is invalid
      }
    });
  };

  const checkUsernameExists = (username) => {
    return new Promise((resolve, reject) => {
      if (username !== "") {
        checkUsername(username)
          .then((res) => {
            if (res === true) {
              resolve(res); // Resolve the promise with true
            } else {
              resolve(res); // Resolve the promise with false
            }
          })
          .catch((err) => {
            console.error(err);
            reject(err); // Reject the promise with the error
          });
      } else {
        resolve(false); // Resolve the promise with false if email is invalid
      }
    });
  };

  const handleChanges = (event, property) => {
    const value = event.target.value;
    const errorStates = { ...errors }; // Clone the existing errors state

    if (property === "phoneNo") {
      const formattedValue = changeUsFormatNumber(value);
      setData((prevData) => ({
        ...prevData,
        [property]: formattedValue,
      }));
      errorStates[property] = !!formattedValue;
    } else {
      setData((prevData) => ({
        ...prevData,
        [property]: value,
      }));
      errorStates[property] = !!value;
    }

    // Additional validations
    if (property === "email") {
      errorStates.emailValidate = validateEmail(value);
    } else if (property === "phoneNo") {
      errorStates.phoneValidate = validatePhoneNumber(
        changeUsFormatNumber(value)
      );
    } else if (property === "username") {
      errorStates.usernameValidate = validateUsername(value);
    } else if (property === "password") {
      errorStates.passwordValidate = validatePassword(value);
    } else if (property === "firstName" || property === "lastName") {
      if (property === "firstName") {
        errorStates.firstNameValidate = validateAlphanumeric(value);
      } else if (property === "lastName") {
        if (value !== "") {
          errorStates.lastNameValidate = validateAlphanumeric(value);
        } else {
          errorStates.lastNameValidate = true;
        }
      }
    }
    // Set errors state once
    setErrors(errorStates);

    // setErrors((prevErrors) => {
    //   const newErrors = { ...prevErrors };

    //   if (property === "email") {
    //     newErrors.data.emailValid = validateEmail(value);
    //   }

    //   if (property === "phoneNo") {
    //     newErrors.data.phoneNumberValid = validatePhoneNumber(
    //       changeUsFormatNumber(value)
    //     );
    //   }

    //   if (property === "password") {
    //     newErrors.data.passwordValid = validatePassword(value);
    //   }

    //   if (property === "username") {
    //     newErrors.data.usernameValid = validateUsername(value);
    //   }

    //   newErrors.data[property] = !value;

    //   return newErrors;
    // });
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [validateExistsEmail, setValidateExistsEmail] = useState(false);
  const [validateExistsUsername, setValidateExistsUsername] = useState(false);

  const submitForm = async (event) => {
    event.preventDefault();
    // Initialize an errors object with boolean values
    const newErrors = {
      firstName: !!data.firstName,
      email: !!data.email,
      username: !!data.username,
      password: !!data.password,
      phoneNo: !!data.phoneNo,
      emailValidate: validateEmail(data.email),
      phoneValidate: validatePhoneNumber(changeUsFormatNumber(data.phoneNo)),
      firstNameValidate: validateAlphanumeric(data.firstName),
      lastNameValidate: data.lastName
        ? validateAlphanumeric(data.lastName)
        : true,
      passwordValidate: validatePassword(data.password),
      usernameValidate: validateUsername(data.username),
    };
    setErrors(newErrors);
    // Check if there are any errors
    const hasErrors = Object.values(newErrors).includes(false);

    const { username, email } = data;
    if (!hasErrors) {
      try {
        const isEmailExist = await checkEmailExists(email);
        if (isEmailExist) {
          setValidateExistsEmail(isEmailExist);
          return;
        }
      } catch (err) {
        console.error("Error: ", err);
      }

      try {
        const isUsernameExist = await checkUsernameExists(username);
        if (isUsernameExist) {
          setValidateExistsUsername(isUsernameExist);
          return;
        }
      } catch (err) {
        console.error("Error: ", err);
      }

      if (token) {
        const registrationModel = {
          token: token,
        };

        const formData = new FormData();
        formData.append("data", JSON.stringify(data));
        formData.append("request", JSON.stringify(registrationModel));

        console.log("Data: ", data);
        // return;
        secondaryUserSignUp(formData)
          .then((res) => {
            if (res) {
              toast.success(
                "Registration Successful, Login and fill your details.",
                {
                  position: toast.POSITION.BOTTOM_CENTER,
                }
              );
              goBack();
            } else {
              toast.error("An error occurred during registration.", {
                position: toast.POSITION.BOTTOM_CENTER,
              });
            }
          })
          .catch((err) => {
            if (err.response && err.response.status === 400) {
              const errorMessage = err.response.data;

              if (errorMessage === "error:invalid_token") {
                toast.error("Invalid token.", {
                  position: toast.POSITION.BOTTOM_CENTER,
                });
              } else if (errorMessage === "error:invitation_accepted") {
                toast.error("This invitation has already been accepted.", {
                  position: toast.POSITION.BOTTOM_CENTER,
                });
              } else {
                toast.error("Bad request. Please try again.", {
                  position: toast.POSITION.BOTTOM_CENTER,
                });
              }
            } else if (err.response && err.response.status === 500) {
              toast.error(
                "An unexpected error occurred. Please try again later.",
                {
                  position: toast.POSITION.BOTTOM_CENTER,
                }
              );
            } else {
              toast.error("Something went wrong.", {
                position: toast.POSITION.BOTTOM_CENTER,
              });
            }
          });
      } else {
        signup(data)
          .then((res) => {
            toast.success(
              "Registration Successful, Login and fill your details.",
              {
                position: toast.POSITION.BOTTOM_CENTER,
              }
            );
            goBack();
          })
          .catch((err) => {});
      }
    } else {
      return;
    }
  };

  const [validusername, setValidUsername] = useState(false);
  const [username, setUsername] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setUsername(e.target.value);
  };

  // for username confirmation
  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   getEmailByUsername(username)
  //     .then((res) => {
  //       if (!res) {
  //         toast.error("Username is not correct please correct your username");
  //         return;
  //       }
  //       setValidUsername(true);
  //       setData((prevData) => ({
  //         ...prevData,
  //         temporaryUsername: res.username,
  //       }));
  //     })
  //     .catch((err) => {
  //       toast.error("Server Error" + err);

  //       return;
  //     });
  // };

  return (
    // <>
    //   {validusername ? (
    //     <>
    //       <div className="attorney_container">
    //         <div className="attorney_registration_form">
    //           <div className="attorney_registration_form_heading">
    //             <h1 style={{ textAlign: "center", width: "100%" }}>
    //               Attorney Registration Form
    //             </h1>
    //             {/* <div className="attorney_cancel_icon">
    //             <FontAwesomeIcon icon={faTimes} />
    //           </div> */}
    //           </div>
    //           <form className="attorney_form_main" onSubmit={submitForm}>
    //             <div className="attorney_form">
    //               <div className="attorney_form_left">
    //                 <TextField
    //                   id="attorney-first-name"
    //                   label="First Name"
    //                   value={data.firstName}
    //                   onChange={(e) => handleChanges(e, "firstName")}
    //                   fullWidth
    //                   margin="normal"
    //                   required
    //                 />
    //                 <TextField
    //                   id="attorney-last-name"
    //                   label="Last Name"
    //                   value={data.lastName}
    //                   onChange={(e) => handleChanges(e, "lastName")}
    //                   fullWidth
    //                   margin="normal"
    //                   required
    //                 />
    //                 <TextField
    //                   id="attorney-email"
    //                   label="Email"
    //                   value={data.email}
    //                   onChange={(e) => handleChanges(e, "email")}
    //                   fullWidth
    //                   margin="normal"
    //                   required
    //                 />
    //                 {/* <TextField
    //                 id="attorney-password"
    //                 label="Password"
    //                 value={data.password}
    //                 onChange={(e) => handleChanges(e, "password")}
    //                 fullWidth
    //                 margin="normal"
    //                 type="password"
    //                 error={!!error}
    //                 helperText={error}
    //                 autoComplete="new-password"
    //                 required
    //               /> */}
    //                 <TextField
    //                   id="attorney-username"
    //                   label="Username"
    //                   value={data.username}
    //                   onChange={(e) => handleChanges(e, "username")}
    //                   fullWidth
    //                   margin="normal"
    //                   required
    //                 />
    //                 <TextField
    //                   id="attorney-password"
    //                   label="Password"
    //                   value={data.password}
    //                   onChange={(e) => handleChanges(e, "password")}
    //                   fullWidth
    //                   margin="normal"
    //                   type={showPassword ? "text" : "password"}
    //                   error={!!error}
    //                   helperText={error}
    //                   autoComplete="new-password"
    //                   required
    //                   InputProps={{
    //                     endAdornment: (
    //                       <InputAdornment position="end">
    //                         <IconButton
    //                           onClick={togglePasswordVisibility}
    //                           edge="end"
    //                           aria-label="toggle password visibility"
    //                           style={{ padding: 0 }}
    //                         >
    //                           <FontAwesomeIcon
    //                             icon={showPassword ? faEye : faEyeSlash}
    //                             style={{
    //                               fontSize: "16px",
    //                               marginRight: "4px",
    //                               color: "black",
    //                             }} // Adjust icon size and color as needed
    //                           />
    //                         </IconButton>
    //                       </InputAdornment>
    //                     ),
    //                   }}
    //                 />

    //                 <TextField
    //                   id="contact-number"
    //                   label="Contact Number*"
    //                   value={data.phoneNo}
    //                   onChange={(e) => handleChanges(e, "phoneNo")}
    //                   fullWidth
    //                   margin="normal"
    //                 />
    //                 <div className="attorney_form_btn_main">
    //                   <div className="attorney_form_btn">
    //                     <button type="button" onClick={resetData}>
    //                       Clear
    //                     </button>
    //                   </div>
    //                   <div className="attorney_form_btn">
    //                     <button type="submit">Register</button>
    //                   </div>
    //                 </div>
    //               </div>

    //               {/* <div className="attorney_form_right">
    //               <TextField
    //                 id="lay-firm-name"
    //                 label="Law Firm Name"
    //                 // value={data.name}
    //                 // onChange={(e) => handleChanges(e, "name")}
    //                 fullWidth
    //                 margin="normal"
    //               />
    //               <TextField
    //                 id="bar-association-license-number"
    //                 label="Bar Association License Number"
    //                 // value={data.name}
    //                 // onChange={(e) => handleChanges(e, "name")}
    //                 fullWidth
    //                 margin="normal"
    //               />
    //               <TextField
    //                 id="jurisdiction-of-practice"
    //                 label="Jurisdiction of Practice"
    //                 // value={data.name}
    //                 // onChange={(e) => handleChanges(e, "name")}
    //                 fullWidth
    //                 margin="normal"
    //               />
    //               <TextField
    //                 id="practice-area"
    //                 label="Practice Area"
    //                 // value={data.name}
    //                 // onChange={(e) => handleChanges(e, "name")}
    //                 fullWidth
    //                 margin="normal"
    //               />
    //               <TextField
    //                 id="year-of-experience"
    //                 label="Year of Experience"
    //                 // value={data.name}
    //                 // onChange={(e) => handleChanges(e, "name")}
    //                 fullWidth
    //                 margin="normal"
    //               />
    //               <TextField
    //                 id="education"
    //                 label="Education"
    //                 // value={data.name}
    //                 // onChange={(e) => handleChanges(e, "name")}
    //                 fullWidth
    //                 margin="normal"
    //               />
    //               <TextField
    //                 id="professional-affiliations"
    //                 label="Professional Affiliations"
    //                 // value={data.name}
    //                 // onChange={(e) => handleChanges(e, "name")}
    //                 fullWidth
    //                 margin="normal"
    //               />

    //               <TextField
    //                 id="website-url"
    //                 label="Website URL"
    //                 // value={data.name}
    //                 // onChange={(e) => handleChanges(e, "name")}
    //                 fullWidth
    //                 margin="normal"
    //               />

    //             </div> */}
    //             </div>
    //           </form>
    //         </div>
    //       </div>
    //     </>
    //   ) : (
    //     <>
    //       <div>
    //         <div>
    //           <Container className="restepassword_container">
    //             <div className="restepassword_maincard">
    //               <Card>
    //                 <CardHeader className="cardheading">
    //                   <h3 className="heading">Enter User Name</h3>
    //                 </CardHeader>
    //                 <CardBody className="resetpassword_cardbody">
    //                   <Form
    //                     onSubmit={(e) => {
    //                       handleSubmit(e);
    //                     }}
    //                   >
    //                     <FormGroup className="textfield">
    //                       <Label
    //                         className="resetpassword_heading"
    //                         for="username"
    //                       >
    //                         Username
    //                       </Label>
    //                       <Input
    //                         style={{ backgroundColor: "inherit" }}
    //                         className="inputfiled"
    //                         type="text"
    //                         placeholder="Enter your username"
    //                         id="username"
    //                         value={username}
    //                         onChange={(e) => handleChange(e)}
    //                       />
    //                     </FormGroup>
    //                     <Container
    //                       style={{
    //                         display: "flex",
    //                         justifyContent: "space-evenly",
    //                       }}
    //                       className="loginbuttonspace"
    //                     >
    //                       <Button
    //                         className="cler-button"
    //                         style={{ backgroundColor: "inherit" }}
    //                         onClick={goBack}
    //                         type="reset"
    //                       >
    //                         Cancel
    //                       </Button>

    //                       <Button
    //                         className="register-button"
    //                         style={{ backgroundColor: "inherit" }}
    //                         type="submit"
    //                       >
    //                         Submit
    //                       </Button>
    //                     </Container>
    //                   </Form>
    //                 </CardBody>
    //               </Card>
    //             </div>
    //           </Container>
    //         </div>
    //       </div>
    //     </>
    //   )}
    // </>

    <>
      <div className="attorney_container">
        <div className="attorney_registration_form">
          <div className="attorney_registration_form_heading">
            {token ? (
              <h1 style={{ textAlign: "center", width: "100%" }}>
                Secondary User Registration
              </h1>
            ) : (
              <h1 style={{ textAlign: "center", width: "100%" }}>
                Attorney Registration Form
              </h1>
            )}
          </div>
          <form className="attorney_form_main" onSubmit={submitForm}>
            <div className="attorney_form">
              <div className="attorney_form_left">
                {token === null && (
                  <p style={{ fontSize: "14px", color: "#d30000" }}>
                    Note: Please use same email where you received the
                    invitation!
                  </p>
                )}

                <FormControl>
                  <FormLabel required>First Name</FormLabel>
                  <Input
                    className="input_mui_joy"
                    placeholder="Enter first name"
                    id="attorney-first-name"
                    label="First Name"
                    value={data.firstName}
                    onChange={(e) => handleChanges(e, "firstName")}
                  />
                  {(!errors.firstName || !errors.firstNameValidate) && (
                    <FormHelperText error>
                      <Typography variant="caption" color="error">
                        {!errors.firstName
                          ? "First Name is required"
                          : !errors.firstNameValidate
                          ? "Enter valid first name"
                          : ""}
                      </Typography>
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    className="input_mui_joy"
                    placeholder="Enter last name"
                    id="attorney-first-name"
                    label="Last Name"
                    value={data.lastName}
                    onChange={(e) => handleChanges(e, "lastName")}
                  />
                  {!errors.lastNameValidate && (
                    <FormHelperText error>
                      <Typography variant="caption" color="error">
                        Enter valid last name
                      </Typography>
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl>
                  <FormLabel required>Email</FormLabel>
                  <Input
                    className="input_mui_joy"
                    placeholder="Enter your email"
                    id="attorney-email"
                    value={data.email}
                    onChange={(e) => handleChanges(e, "email")}
                  />
                  {(!errors.email ||
                    !errors.emailValidate ||
                    validateExistsEmail) && (
                    <FormHelperText>
                      <Typography variant="caption" color="error">
                        {!errors.email
                          ? "Email is required"
                          : !errors.emailValidate
                          ? "Enter a valid email"
                          : validateExistsEmail
                          ? "Email already exist"
                          : ""}
                      </Typography>
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl>
                  <FormLabel required>Username</FormLabel>
                  <Input
                    className="input_mui_joy"
                    placeholder="Enter first name"
                    id="attorney-username"
                    value={data.username}
                    onChange={(e) => handleChanges(e, "username")}
                  />
                  {(!errors.username ||
                    !errors.usernameValidate ||
                    validateExistsUsername) && (
                    <FormHelperText>
                      <Typography variant="caption" color="error">
                        {!errors.username
                          ? "Username is required"
                          : !errors.usernameValidate
                          ? "Username must start with a lowercase letter and can only contain lowercase letters, numbers, and underscores"
                          : validateExistsUsername
                          ? "Username already exist"
                          : ""}
                      </Typography>
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl>
                  <FormLabel required>Password</FormLabel>
                  <Input
                    id="attorney-password"
                    placeholder="Enter your password"
                    value={data.password}
                    onChange={(e) => handleChanges(e, "password")}
                    fullWidth
                    margin="normal"
                    type={showPassword ? "text" : "password"}
                    autoComplete="new-password"
                    endDecorator={
                      <IconButton
                        onClick={togglePasswordVisibility}
                        edge="end"
                        aria-label="toggle password visibility"
                        style={{ padding: 0 }}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                          style={{
                            fontSize: "16px",
                            marginRight: "4px",
                            color: "black",
                          }} // Adjust icon size and color as needed
                        />
                      </IconButton>
                    }
                  />
                  {(!errors.password || !errors.passwordValidate) && (
                    <FormHelperText>
                      <Typography variant="caption" color="error">
                        {!errors.password
                          ? "Password is required"
                          : !errors.passwordValidate
                          ? "Password must be at least 10 characters long and include one number, one special character, and one uppercase letter"
                          : ""}
                      </Typography>
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl>
                  <FormLabel required>Phone Number</FormLabel>
                  <Input
                    className="input_mui_joy"
                    placeholder="Enter phone number"
                    id="contact-number"
                    value={data.phoneNo}
                    onChange={(e) => handleChanges(e, "phoneNo")}
                  />
                  {(!errors.phoneNo || !errors.phoneValidate) && (
                    <FormHelperText>
                      <Typography variant="caption" color="error">
                        {!errors.phoneNo
                          ? "Phone Number is required"
                          : !errors.phoneValidate
                          ? "Enter a valid phone number"
                          : ""}
                      </Typography>
                    </FormHelperText>
                  )}
                </FormControl>

                <div className="attorney_form_btn_main">
                  <div className="attorney_form_btn">
                    <button type="submit">Register</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
