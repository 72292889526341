import { myAxios } from "./helper";
import { getToken } from "./user-service";

export const addPersonalLoan = (personalLoan, token) => {
    return myAxios.post("/personal-loan/", personalLoan, { headers: { authorization: token } }).then((response) => response.data);
};
export const getPersonalLoans = (token, userId) => {
    return myAxios.get('/personal-loan/' + userId, { headers: { authorization: token } }).then((response) => response.data);
};
export const updatePersonalLoan = (personalLoan, token,) => {
    return myAxios.put("/personal-loan/", personalLoan, { headers: { authorization: token } }).then((response) => response.data);
};
export const getSinglePersonalLoan = (token, id) => {
    return myAxios.get('/personal-loan/personal-loan/' + id, { headers: { authorization: token } }).then((response) => response.data);
};

export const deletePersonalLoan = (personalLoanId) => {
    let token = "Bearer " + getToken();
    return myAxios.delete(`/personal-loan/personal-loan/${personalLoanId}`, { headers: { authorization: token } }).then((response) => response.data);
}
