import {
    faPencilAlt,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SideBar from "../../components/sidebar/Sidebar";
import UserBase from "../../components/user/UserBase";
import "../../css/AttorneyForm.css";
import {
    deleteTrustById,
    getToken,
    getTrustByUserId,
    getUser,
} from "../../services/user-service";
import Deletebutton from "../my-estate/Deletebutton";
import CreateTrust from "./CreateTrust";

const TrustList = () => {
  const [editTrustPopup, setEditTrustPopup] = useState(false);
  const [trustId, setTrustId] = useState(null);

  const toggleTrust = (id) => {
    setEditTrustPopup(!editTrustPopup);
    setTrustId(id);
  };

  const handleBack = () => {
    setEditTrustPopup(false);
    getAllTrust();
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const removeTrust = (id) => {
    setShowConfirmation(true);
    setCurrentId(id);
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const handleRemove = (params) => {
    const token = "Bearer " + getToken();
    setShowConfirmation(false);
    const { Id } = params;
    deleteTrustById(token, Id)
      .then(() => {
        toast.success("Deleted successfully...", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        getAllTrust();
      })
      .catch(() => {});
  };

  const [trust, setTrust] = useState([]);
  const getAllTrust = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getTrustByUserId(token, userId)
      .then((res) => {
        setTrust(res);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getAllTrust();
  }, []);
  return (
    <>
      <div className={"your-component fade-in-element"}>
        <UserBase>
          <div style={{ marginTop: "4rem" }} className="otherElement">
            <SideBar>
              <div className="beneficiary_list_base">
                <div className="beneficiary_list_main">
                  <div className="beneficiary_heading">
                    <h4>
                      Trust List {"   "} ({trust.length})
                    </h4>
                  </div>
                  <div className="beneficiary_container">
                    <div className="beneficiary_details_cards">
                      <Paper
                        sx={{ padding: 1.5 }}
                        className="beneficiary_detail_card"
                        style={{
                          backgroundColor: "rgb(208,208,208)",
                          width: "fit-content",
                        }}
                      >
                        <p className="beneficiary_row">
                          <strong>Trust Name</strong>
                        </p>
                        <p
                          style={{ minWidth: "190px" }}
                          className="beneficiary_row"
                        >
                          <strong>Trust Type</strong>
                        </p>
                        <p className="beneficiary_row">
                          <strong>Creation Date</strong>
                        </p>
                        <p
                          style={{ minWidth: "190px" }}
                          className="beneficiary_row"
                        >
                          <strong>Identification Number</strong>
                        </p>
                        <p
                          className="beneficiary_row"
                          style={{ textAlign: "center" }}
                        >
                          <strong>Action</strong>
                        </p>
                      </Paper>

                      {trust.map((detail, index) => (
                        <Paper
                          style={{ width: "fit-content" }}
                          key={index + 1}
                          sx={{ padding: 1.5 }}
                          className="beneficiary_detail_card"
                        >
                          <p className="beneficiary_row beneficiary_row_data">
                            <strong className="beneficiary_row_data_name">{`${detail.trustName}`}</strong>
                          </p>
                          <p
                            style={{ minWidth: "190px" }}
                            className="beneficiary_row beneficiary_row_data"
                          >
                            {detail.trustType}
                          </p>
                          <p className="beneficiary_row beneficiary_row_data">
                            {detail.trustCreationDate}
                          </p>
                          <p
                            style={{ minWidth: "190px" }}
                            className="beneficiary_row beneficiary_row_data"
                          >
                            {detail.trustIdentificationNumber}
                          </p>
                          <div
                            className="beneficiary_row_action_btn"
                            style={{
                              width: "170px",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <button
                              onClick={() => {
                                removeTrust(detail.id);
                              }}
                              type="button"
                              className="beneficiary_row_Action_delete_edit"
                            >
                              <p>
                                <FontAwesomeIcon icon={faTrash} />
                              </p>
                            </button>
                            <button
                              onClick={() => {
                                toggleTrust(detail.id);
                              }}
                              className="beneficiary_row_Action_delete_edit"
                            >
                              <p>
                                <FontAwesomeIcon icon={faPencilAlt} />
                              </p>
                            </button>
                          </div>
                        </Paper>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* confirmation for delete owner   */}
              {showConfirmation && (
                <Deletebutton
                  handleRemove={handleRemove}
                  Id={currentId}
                  handleConfirmationClose={handleConfirmationClose}
                />
              )}

              {editTrustPopup && (
                <div className="beneficiary-popup">
                  <div className="beneficiary-content">
                    {editTrustPopup && (
                      <CreateTrust onBack={handleBack} Id={trustId} />
                    )}
                  </div>
                </div>
              )}
            </SideBar>
          </div>
        </UserBase>
      </div>
    </>
  );
};

export default TrustList;
