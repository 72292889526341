import React from "react";
import UserNavbar from "./UserNavbar";

const UserBase = ({ title = "Welcome to our website", children }) => {
  return (
    <div className="container-fluid p-0 m-0">
      <UserNavbar />
      {children}
      {/* <div style={{bottom:"82px",left:"0"}}>
        <Notification />
      </div> */}
    </div>
  );
};
export default UserBase;
