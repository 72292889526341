import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserBase from "../../../components/user/UserBase";
import SideBar from "../../../components/sidebar/Sidebar";
import { Button, Form } from "reactstrap";
import { Card, CardBody, CardFooter, CardHeader } from "react-bootstrap";
import { Input, Option, Select, Textarea } from "@mui/joy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { getToken } from "../../../services/user-service";
import {
  getSingleCreditCard,
  updateCreditCard,
} from "../../../services/CreditCardService";
import { toast } from "react-toastify";

function EditCreditCard() {
  const { id } = useParams();
  const [data, setData] = useState({
    creditCard: {
      creditCardListName: "",
      cardLoanType: "",
      cardNumberOrLoanNumber: "",
      institution: "",
      estimatedLoan: "",
      notes: "",
    },
  });
  const navigate = useNavigate();

  const handleChanges = (e, field) => {
    const { value } = e.target;
    setData((prevData) => ({
      ...prevData,
      creditCard: {
        ...prevData.creditCard,
        [field]: value,
      },
    }));
  };

  const handleChange = (e, newValue, field) => {
    const value = newValue;

    setData((prevData) => ({
      ...prevData,
      creditCard: {
        ...prevData.creditCard,
        [field]: value,
      },
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = "Bearer " + getToken();
        const res = await getSingleCreditCard(token, id);
        console.log("data: ", res);
        setData({
          ...data,
          creditCard: res.creditCard,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const creditCardForm = (event) => {
    event.preventDefault();

    let token = "Bearer " + getToken();
    const formData = new FormData();

    formData.append("data", JSON.stringify(data));
    console.log("data: ", data);

    updateCreditCard(formData, token)
      .then((resp) => {
        toast.success("Updated Successfully!!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        navigate("/user/liabilities/credit-card");
      })
      .catch((error) => {});
    return;
  };

  return (
    <UserBase>
      <div className="mt-5"></div>
      <SideBar>
        <div className="overlay1" style={{ transition: "500ms", height: "" }}>
          <div>
            <form onSubmit={creditCardForm} className="addform_body">
              <container className="credit_card_base">
                <div className="homepage credit_card_main">
                  <Card>
                    <CardHeader>
                      <div
                        style={{ width: "100%" }}
                        className="addform_heading"
                      >
                        <h3 className="addform_heading_h1">
                          {data.creditCard.creditCardListName}
                        </h3>
                        <div className="addform_close">
                          <FontAwesomeIcon
                            icon={faXmark}
                            onClick={() => {
                              navigate("/user/liabilities/credit-card");
                            }}
                          />
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="credit_card_body">
                      <div>
                        <table className="credit_card_table">
                          <thead>
                            <tr>
                              <th style={{ width: "192px" }}>
                                Credit Card/Bank Loan*
                              </th>
                              <th style={{ width: "240px" }}>
                                Card Number/Loan Number*
                              </th>
                              <th>Institution/Banks*</th>
                              <th>Estimated Loan*</th>
                              <th>Notes</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Select
                                  style={{ width: "192px" }}
                                  value={data?.creditCard.cardLoanType}
                                  onChange={(event, newValue) =>
                                    handleChange(
                                      event,
                                      newValue,
                                      "cardLoanType"
                                    )
                                  }
                                  required
                                >
                                  <Option value="">Select Any One</Option>
                                  <Option value="Credit Card">
                                    Credit Card
                                  </Option>
                                  <Option value="Bank Loan">Bank Loan</Option>
                                </Select>
                              </td>
                              <td>
                                <Input
                                  style={{ width: "240px" }}
                                  type="password"
                                  placeholder={
                                    data?.creditCard.cardLoanType ===
                                      "Credit Card" ||
                                    data?.creditCard.cardLoanType === ""
                                      ? "Card Number"
                                      : "Loan Number"
                                  }
                                  onChange={(e) =>
                                    handleChanges(e, "cardNumberOrLoanNumber")
                                  }
                                  value={
                                    data?.creditCard?.cardNumberOrLoanNumber ||
                                    ""
                                  }
                                  required
                                  autoComplete="new-password"
                                />
                              </td>
                              <td>
                                <Input
                                  placeholder="Institution"
                                  onChange={(e) =>
                                    handleChanges(e, "institution")
                                  }
                                  value={data?.creditCard?.institution || ""}
                                  required
                                />
                              </td>
                              <td>
                                <Input
                                  placeholder="Estimated Loan"
                                  type="number"
                                  onChange={(e) =>
                                    handleChanges(e, "estimatedLoan")
                                  }
                                  value={data?.creditCard?.estimatedLoan || ""}
                                  startDecorator={<div>$</div>}
                                  required
                                />
                              </td>
                              <td>
                                <Textarea
                                  style={{
                                    fontSize: "14px",
                                  }}
                                  placeholder="Notes"
                                  id="notes"
                                  value={data?.creditCard.notes}
                                  onChange={(e) => handleChanges(e, "notes")}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="text-center">
                        <Button
                          type="submit"
                          outline
                          className="my-estate-add-btn"
                        >
                          Update
                        </Button>
                      </div>
                    </CardFooter>
                  </Card>
                </div>
              </container>
            </form>
          </div>
        </div>
      </SideBar>
    </UserBase>
  );
}
export default EditCreditCard;
