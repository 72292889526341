import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    FormControl,
    FormLabel,
    Input,
    Option,
    Select
} from "@mui/joy";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { addUserTrust, getToken, getTrustById, updateUserTrust } from "../../services/user-service";

export default function CreateTrust(params) {
  const { onBack, Id } = params;
  const [data, setData] = useState({
    trustName: "",
    trustType: "",
    trustCreationDate: "",
    trustIdentificationNumber: "",
  });

  const reset = () => {
    setData({
      rustName: "",
      trustType: "",
      trustCreationDate: "",
      trustIdentificationNumber: "",
    });
  };

  const getTrustDataById = () => {
    const token = "Bearer " + getToken();
    if (Id !== undefined && Id !== null) {
      getTrustById(token, Id)
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          toast.error("Some error occurred", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        });
    }
  };

  const today = dayjs().format("YYYY-MM-DD");
  const handleChanges = (event, property) => {
    const value = event.target.value;
    setData((prevData) => ({
      ...prevData,
      [property]: value,
    }));
  };
  const handleSelectChanges = (event, value, property) => {
    if (property === "trustType") {
      setData((prevData) => ({
        ...prevData,
        [property]: value,
      }));
    }
  };

  const submitForm = (event) => {
    event.preventDefault();

    let token = "Bearer " + getToken();
    const formData = new FormData();
    if (Id !== undefined && Id !== null) {
      const updatedData = { ...data, id: Id };
      formData.append("data", JSON.stringify(updatedData));
      updateUserTrust(formData, token)
        .then((res) => {
          toast.success("Trust updated successfully", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          onBack();
        })
        .catch((err) => {
          toast.error("Some error occurred", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        });
    } else {
      formData.append("data", JSON.stringify(data));
      addUserTrust(formData, token)
        .then((resp) => {
          toast.success("Trust added successfully !!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          reset();
          onBack();
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    getTrustDataById();
  }, []);

  return (
    <>
      <div className="attorney_container">
        <div className="attorney_registration_form">
          <div className="attorney_registration_form_heading">
            {Id ? (
              <h1 style={{ textAlign: "center", width: "100%" }}>Edit Trust</h1>
            ) : (
              <h1 style={{ textAlign: "center", width: "100%" }}>Add Trust</h1>
            )}
            <div className="owner_creation_cancel_icon">
              <FontAwesomeIcon icon={faTimes} onClick={onBack} />
            </div>
          </div>
          <form className="attorney_form_main" onSubmit={submitForm}>
            <div className="attorney_form">
              <div className="attorney_form_left">
                <FormControl>
                  <FormLabel required>Trust Name</FormLabel>
                  <Input
                    className="input_mui_joy"
                    placeholder="Enter trust name"
                    id="attorney-first-name"
                    value={data.trustName}
                    onChange={(e) => handleChanges(e, "trustName")}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>First Type</FormLabel>

                  <Select
                    value={data.trustType}
                    onChange={(e, value) =>
                      handleSelectChanges(e, value, "trustType")
                    }
                  >
                    <Option value="">Select trust type</Option>
                    <Option value="Revocable">Revocable</Option>
                    <Option value="Irrevocable">Irrevocable</Option>
                    <Option value="Asset Protection">Asset Protection</Option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Trust Creation Date</FormLabel>
                  <Input
                    type="date"
                    className="input_mui_joy"
                    placeholder="Enter creation date"
                    id="attorney-first-name"
                    slotProps={{
                      input: {
                        max: today,
                      },
                    }}
                    value={data.trustCreationDate}
                    onChange={(e) => handleChanges(e, "trustCreationDate")}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Trust Identification Number</FormLabel>
                  <Input
                    className="input_mui_joy"
                    placeholder="Enter first name"
                    id="attorney-first-name"
                    value={data.trustIdentificationNumber}
                    onChange={(e) =>
                      handleChanges(e, "trustIdentificationNumber")
                    }
                  />
                </FormControl>

                <div
                  className="attorney_form_btn_main"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="attorney_form_btn">
                    {Id ? (
                      ""
                    ) : (
                      <button onClick={reset} type="button">
                        Clear
                      </button>
                    )}
                  </div>
                  <div className="attorney_form_btn">
                    {Id ? (
                      <button type="submit">Update</button>
                    ) : (
                      <button type="submit">Create</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
