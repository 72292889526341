import {
  faMinus,
  faPlus,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import { Input, Option, Select, Textarea } from "@mui/joy";
import { Tooltip } from "@mui/material";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { Button, Form } from "reactstrap";
import { formatAmount } from "../../components/Common";
import SideBar from "../../components/sidebar/Sidebar";
import UserBase from "../../components/user/UserBase";
import {
  addCreditCard,
  deleteCreditCard,
  getCreditCards,
} from "../../services/CreditCardService";
import {
  getSecondaryUser,
  getToken,
  getUser,
} from "../../services/user-service";
import { ConfirmationPopup } from "../my-estate/ConfirmationPopup";
import Deletebutton from "../my-estate/Deletebutton";
import UpdateButton from "../my-estate/UpdateButton";
import "./Liabilities.css";

const initialCreditCardData = {
  creditCard: {
    creditCardListName: "",
    cardLoanType: "",
    cardNumberOrLoanNumber: "",
    institution: "",
    estimatedLoan: "",
    notes: "",
  },
};

function CreditCards() {
  const [creditCardList, setCreditCardList] = useState([initialCreditCardData]);
  const [show, setShow] = useState(false);
  let [form1, setForm1] = useState(false);

  const [modal, setModal] = useState(false);
  const [creditCardListNameValue, setCreditCardListNameValue] = useState("");
  const handleChangeAddCreditCardListName = (event) => {
    const value = event.target.value;
    setCreditCardListNameValue(value);
  };
  const handleChangeConfirm = () => {
    if (creditCardListNameValue !== "") {
      setCreditCardList((prevList) => {
        const updatedCreditCard = [...prevList];

        updatedCreditCard[0].creditCard.creditCardListName =
          creditCardListNameValue;
        return updatedCreditCard;
      });
      setForm1(!form1);
    }
    toggleCreditCardList();
  };
  const toggleCreditCardList = () => {
    setModal(false);
  };

  const [existingCreditCardListName, setExistingCreditCardListName] =
    useState(null);
  const toggle = (event, value, closePopup) => {
    setExistingCreditCardListName(value);
    resetForm();
    if (closePopup) {
      setForm1(false);
      setCreditCardListNameValue("");
      return;
    }
    if (value) {
      setForm1(true);
    } else {
      if (creditCardListNameValue !== "") {
        setForm1(false);
      } else {
        setModal(true);
      }
    }
  };

  useEffect(() => {
    if (existingCreditCardListName) {
      setCreditCardList((prevList) =>
        prevList.map((item) => ({
          ...item,
          creditCard: {
            ...item.creditCard,
            creditCardListName: existingCreditCardListName, // Set the new jewelryListName
          },
        }))
      );
    }
  }, [existingCreditCardListName]);

  function getCardType(cardNumber) {
    const patterns = {
      visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      mastercard: /^5[1-5][0-9]{14}$/,
      amex: /^3[47][0-9]{13}$/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
      maestro: /^(5018|5020|5038|56(?:12|34|44|66|68))[0-9]{8,15}$/,
    };

    for (const [cardType, pattern] of Object.entries(patterns)) {
      if (pattern.test(cardNumber)) {
        return cardType;
      }
    }

    return "Unknown";
  }

  // Get primaryUser data
  const primaryUserDetails = getUser();
  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();

  const handleChanges = (e, field, index) => {
    const { value } = e.target;

    setCreditCardList((prevList) =>
      prevList.map((item, i) =>
        i === index
          ? {
              ...item,
              creditCard: {
                ...item.creditCard,
                [field]: value,
              },
            }
          : item
      )
    );
  };

  const handleChange = (e, newValue, field, index) => {
    const value = newValue;

    setCreditCardList((prevList) =>
      prevList.map((item, i) =>
        i === index
          ? {
              ...item,
              creditCard: {
                ...item.creditCard,
                [field]: value,
              },
            }
          : item
      )
    );
  };

  const resetForm = () => {
    setCreditCardList([initialCreditCardData]);
  };

  const creditCardForm = (event) => {
    event.preventDefault();

    let token = "Bearer " + getToken();
    const formData = new FormData();

    formData.append("data", JSON.stringify(creditCardList));
    console.log("data: ", creditCardList);

    addCreditCard(formData, token)
      .then((resp) => {
        AddCard();
        toast.success("Data Added !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle(null, null, "close");
      })
      .catch((error) => {});
  };

  // show notes popup
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedNote, setSelectedNote] = useState("");

  const handleOpenPopup = (note) => {
    setSelectedNote(note);
    setPopupVisible(true);
  };

  let [card, setCard] = useState([]);
  let [showDetail, setShowDetail] = useState([]);
  let [show1, setShow1] = useState(false);

  // card creating
  const AddCard = () => {
    let userId = getUser().commonId;
    let token = "Bearer " + getToken(); // Added 'Bearer'
    getCreditCards(token, userId)
      .then((res) => {
        setCard(res);
      })
      .catch((error) => {
        setCard([]);
      });
  };

  const ShowCreditCardDetails = (obj) => {
    setShowDetail(obj);
    setShow1(true);
  };

  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [currentIdType, setCurrentIdType] = useState("");
  const removeCreditCard = (id, idType) => {
    setShowConfirmationDelete(true);
    setCurrentId(id);
    setCurrentIdType(idType);
  };

  const handleConfirmationDeleteClose = () => {
    setShowConfirmationDelete(false);
  };

  // Code by Purnendu
  const handleRemove = (params) => {
    setShowConfirmationDelete(false);
    const { Id, idType } = params;
    if (idType === "creditCardId") {
      deleteCreditCard(Id)
        .then((res) => {
          toast.success("Deleted successfully...", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          AddCard();
          setShow1(false);
        })
        .catch((error) => {});
    }
  };

  const addCreditCardRow = () => {
    setCreditCardList((prevCreditCardList) => [
      ...prevCreditCardList,
      {
        ...initialCreditCardData,
        creditCard: {
          ...initialCreditCardData.creditCard,
          creditCardListName:
            prevCreditCardList[0]?.creditCard.creditCardListName || "",
        },
      },
    ]);
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const removeCreditCardRow = (index) => {
    setShowConfirmation(true);
    setCurrentIndex(index);
  };

  const handleConfirmation = () => {
    setShowConfirmation(false);
    const index = currentIndex;
    setCreditCardList(creditCardList.filter((_, i) => i !== index));
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const maskCardNumber = (cardNumber) => {
    return "•".repeat(cardNumber.length);
  };

  const maskNumberRemaining4digits = (cardNumber) => {
    if (cardNumber.length <= 4) {
      return maskCardNumber(cardNumber);
    } else {
      const visibleDigits = cardNumber.slice(-4);
      const maskedDigits = "•".repeat(cardNumber.length - 4);
      return maskedDigits + visibleDigits;
    }
  };

  useEffect(() => {
    AddCard();
    setShow(true);
  }, []);

  // Group and consolidate data
  const consolidated = card.reduce((acc, item) => {
    const listName = item.creditCard.creditCardListName;

    if (!acc[listName]) {
      acc[listName] = {
        creditCardListName: listName,
        data: [],
      };
    }

    acc[listName].data.push({
      creditCard: item.creditCard,
    });
    return acc;
  }, {});

  // Convert consolidated object to an array
  const uniqueCreditCardListResult = Object.values(consolidated);

  return (
    <div className={`your-component ${show ? "fade-in-element" : ""}`}>
      <UserBase>
        <div className="mt-5"></div>
        <SideBar>
          {primaryUserDetails.accountType === "secondary" &&
          secondaryUserDetails.accountType === "primary" &&
          secondaryUserDetails.isSecondaryUserEditable === "false" ? (
            ""
          ) : (
            <div className="addme">
              <div className="addme_inner">
                <button
                  style={{ padding: "7px 14px" }}
                  onClick={() => toggle()}
                >
                  Add Credit Cards/Loans
                </button>
              </div>
            </div>
          )}

          <div className="propCard">
            <div className="propCard-card">
              {uniqueCreditCardListResult.map((item, index) => (
                <div
                  className="propCard-card-body"
                  style={{ padding: "20px 20px 10px 20px" }}
                >
                  <div className="propCard_card_status"></div>
                  <h5 className="propCard-card-title">
                    {item?.creditCardListName}
                  </h5>
                  <div className="propCard-btn">
                    <p className="viewDetails">
                      <button
                        className="jewelry_add_more"
                        onClick={(event) =>
                          toggle(event, item.creditCardListName)
                        }
                      >
                        Add More
                      </button>
                      <button
                        style={{ margin: "10px 0" }}
                        onClick={() => ShowCreditCardDetails(item?.data)}
                      >
                        View Details
                      </button>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {form1 && (
            <div
              className="overlay1"
              style={{ transition: "500ms", height: "" }}
            >
              <div>
                <Form onSubmit={creditCardForm} className="addform_body">
                  <container className="credit_card_base">
                    <div className="homepage credit_card_main">
                      <Card>
                        <CardHeader>
                          <div
                            style={{ width: "100%" }}
                            className="addform_heading"
                          >
                            <h3 className="addform_heading_h1">
                              {creditCardListNameValue &&
                                creditCardListNameValue}
                              {existingCreditCardListName &&
                                existingCreditCardListName}
                            </h3>
                            <div
                              className="addform_close"
                              onClick={(event) => toggle(event, "", "close")}
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody className="credit_card_body">
                          <div>
                            <table className="credit_card_table">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th style={{ width: "192px" }}>
                                    Credit Card/Bank Loan*
                                  </th>
                                  <th style={{ width: "240px" }}>
                                    Card Number/Loan Number*
                                  </th>
                                  <th>Institution/Banks*</th>
                                  <th>Estimated Loan*</th>
                                  <th>Notes</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {creditCardList.map((row, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      <Select
                                        style={{ width: "192px" }}
                                        value={
                                          creditCardList[index]?.creditCard
                                            .cardLoanType
                                        }
                                        onChange={(event, newValue) =>
                                          handleChange(
                                            event,
                                            newValue,
                                            "cardLoanType",
                                            index
                                          )
                                        }
                                        required
                                      >
                                        <Option value="">Select Any One</Option>
                                        <Option value="Credit Card">
                                          Credit Card
                                        </Option>
                                        <Option value="Bank Loan">
                                          Bank Loan
                                        </Option>
                                      </Select>
                                    </td>
                                    <td>
                                      <Input
                                        style={{ width: "240px" }}
                                        name={`cardNumberOrLoanNumber-${index}`}
                                        id={`password-${index}`}
                                        type="password"
                                        placeholder={
                                          creditCardList[index]?.creditCard
                                            .cardLoanType === "Credit Card" ||
                                          creditCardList[index]?.creditCard
                                            .cardLoanType === ""
                                            ? "Card Number"
                                            : "Loan Number"
                                        }
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "cardNumberOrLoanNumber",
                                            index
                                          )
                                        }
                                        value={
                                          creditCardList[index]?.creditCard
                                            ?.cardNumberOrLoanNumber || ""
                                        }
                                        required
                                        autoComplete="new-password"
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        placeholder="Institution"
                                        onChange={(e) =>
                                          handleChanges(e, "institution", index)
                                        }
                                        value={
                                          creditCardList[index]?.creditCard
                                            ?.institution || ""
                                        }
                                        required
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        placeholder="Estimated Loan"
                                        type="number"
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "estimatedLoan",
                                            index
                                          )
                                        }
                                        value={
                                          creditCardList[index]?.creditCard
                                            ?.estimatedLoan || ""
                                        }
                                        startDecorator={<div>$</div>}
                                        required
                                      />
                                    </td>
                                    <td>
                                      <Textarea
                                        style={{
                                          fontSize: "14px",
                                        }}
                                        placeholder="Notes"
                                        id="notes"
                                        value={
                                          creditCardList[index].creditCard.notes
                                        }
                                        onChange={(e) =>
                                          handleChanges(e, "notes", index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <div className="jewelry_action">
                                        {creditCardList.length > 1 && (
                                          <Button
                                            className="credit_card_remove_button"
                                            onClick={() =>
                                              removeCreditCardRow(index)
                                            }
                                          >
                                            <FontAwesomeIcon icon={faMinus} />
                                          </Button>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div style={{ marginTop: "8px" }}>
                              <Button
                                className="credit_card_add_more_button"
                                type="button"
                                onClick={addCreditCardRow}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </Button>
                            </div>
                          </div>
                        </CardBody>
                        <CardFooter>
                          <div className="text-center">
                            <Button
                              className="my-estate-clear-btn"
                              type="reset"
                              onClick={resetForm}
                              outline
                            >
                              Clear
                            </Button>
                            <Button
                              outline
                              type=""
                              className="my-estate-add-btn"
                            >
                              Add
                            </Button>
                          </div>
                        </CardFooter>
                      </Card>
                    </div>
                  </container>
                </Form>
              </div>
            </div>
          )}

          {modal && (
            <div>
              <Modal
                show={modal}
                toggle={toggleCreditCardList}
                style={{ paddingTop: "50px" }}
              >
                <ModalBody style={{ color: "black" }}>
                  Please create your credit card/loan catalog here:
                  <div>
                    <Input
                      type="text"
                      value={creditCardListNameValue}
                      placeholder="Enter your credit card/loan catalog name"
                      onChange={handleChangeAddCreditCardListName}
                    />
                  </div>
                </ModalBody>
                <ModalFooter style={{ padding: "5px" }}>
                  <Button
                    className="confirm_delete_btn"
                    onClick={handleChangeConfirm}
                  >
                    Add
                  </Button>{" "}
                  <Button
                    className="confirm_cancel_btn"
                    onClick={toggleCreditCardList}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          )}

          {show1 && (
            <>
              <div className="popup">
                <div className="popup-content popup-content-download">
                  <div className="note_popup">
                    <div className="note_popup_heading">
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <h2>{`${showDetail[0]?.creditCard?.creditCardListName} Details`}</h2>
                      </div>
                      <div>
                        <button
                          className="note_popup_heading_close_btn"
                          onClick={() => {
                            setShow1(false);
                          }}
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </button>
                      </div>
                    </div>
                    <div>
                      {showDetail.map((details, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "5px",
                            padding: "10px",
                            border: "solid 1px lightgray",
                            borderRadius: "5px",
                            minWidth: "230px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* Left side */}

                            <div>
                              <p
                                style={{
                                  fontSize: "17px",
                                  color: "black",
                                  fontWeight: "500",
                                }}
                              >
                                {`Item - ${index + 1}`}
                                {"  "}
                              </p>
                            </div>
                            {/* Right side */}
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {primaryUserDetails.accountType === "secondary" &&
                              secondaryUserDetails.accountType === "primary" &&
                              secondaryUserDetails.isSecondaryUserEditable ===
                                "false" ? (
                                ""
                              ) : (
                                <div className="me-2">
                                  <Tooltip title="Click Here To Edit">
                                    <div>
                                      <UpdateButton
                                        URL={"../liabilities/credit-card/"}
                                        id={details.creditCard.id}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              )}

                              {primaryUserDetails.accountType === "secondary" &&
                              secondaryUserDetails.accountType === "primary" &&
                              secondaryUserDetails.isSecondaryUserEditable ===
                                "false" ? (
                                ""
                              ) : (
                                <FontAwesomeIcon
                                  onClick={() =>
                                    removeCreditCard(
                                      details.creditCard.id,
                                      "creditCardId"
                                    )
                                  }
                                  className="delete-button-comp"
                                  style={{ cursor: "pointer" }}
                                  icon={faTrash}
                                />
                              )}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",

                              flexWrap: "wrap",
                            }}
                            className="mt-2"
                          >
                            {/* Left section */}
                            <div style={{ flex: "1", minWidth: "250px" }}>
                              {details?.creditCard?.cardLoanType ===
                              "Credit Card" ? (
                                <p
                                  style={{ color: "black", fontWeight: "bold" }}
                                >
                                  Credit Card Number :{" "}
                                  {details?.creditCard
                                    ?.cardNumberOrLoanNumber && (
                                    <span style={{ fontWeight: "500" }}>
                                      {maskNumberRemaining4digits(
                                        details?.creditCard
                                          ?.cardNumberOrLoanNumber
                                      )}
                                    </span>
                                  )}
                                </p>
                              ) : (
                                <p
                                  style={{ color: "black", fontWeight: "bold" }}
                                >
                                  Bank Loan Number :{" "}
                                  {details?.creditCard
                                    ?.cardNumberOrLoanNumber && (
                                    <span style={{ fontWeight: "500" }}>
                                      {maskNumberRemaining4digits(
                                        details?.creditCard
                                          ?.cardNumberOrLoanNumber
                                      )}
                                    </span>
                                  )}
                                </p>
                              )}

                              {details?.creditCard?.institution && (
                                <p
                                  style={{ color: "black", fontWeight: "bold" }}
                                >
                                  Institution :{" "}
                                  <span style={{ fontWeight: "500" }}>
                                    {details?.creditCard?.institution && (
                                      <span>
                                        {details?.creditCard?.institution}
                                      </span>
                                    )}
                                  </span>
                                </p>
                              )}
                            </div>

                            {/* Right section */}
                            <div style={{ flex: "0.5", minWidth: "250px" }}>
                              {details?.creditCard?.estimatedLoan && (
                                <p
                                  style={{ color: "black", fontWeight: "bold" }}
                                >
                                  Estimated Loan :{" "}
                                  <span
                                    style={{
                                      color: "green",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {details?.creditCard?.estimatedLoan && (
                                      <span>
                                        {formatAmount(
                                          details?.creditCard?.estimatedLoan
                                        )}
                                      </span>
                                    )}
                                  </span>
                                </p>
                              )}

                              {details?.creditCard?.notes && (
                                <Tooltip title="Click To see Note">
                                  <p
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      handleOpenPopup(
                                        details?.creditCard?.notes
                                      );
                                      setShow1(!show1);
                                    }}
                                  >
                                    Note:{" "}
                                    <span style={{ fontWeight: "500" }}>
                                      {" "}
                                      {details && details?.creditCard?.notes
                                        ? details?.creditCard?.notes.slice(0, 5)
                                        : ""}
                                      ...
                                      <span className="readmore">
                                        Read More
                                      </span>
                                    </span>
                                  </p>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {showConfirmation && (
            <ConfirmationPopup
              handleConfirmation={handleConfirmation}
              handleConfirmationClose={handleConfirmationClose}
              anyText="Do you want to remove item from this list?"
            />
          )}

          {showConfirmationDelete && (
            <Deletebutton
              handleRemove={handleRemove}
              Id={currentId}
              idType={currentIdType}
              handleConfirmationClose={handleConfirmationDeleteClose}
            />
          )}

          {popupVisible && (
            <div className="popup">
              <div className="popup-content">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div>
                      <h2>Notes</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisible(false);
                          setShow1(!show1);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div>
                    <p>{selectedNote}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </SideBar>
      </UserBase>
    </div>
  );
}
export default CreditCards;
