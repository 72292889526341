import React, { useState } from "react";
import { toast } from "react-toastify";
// import {
//   Button,
//   Card,
//   CardBody,
//   CardHeader,
//   Container,
//   Form,
//   FormFeedback,
//   FormGroup,
//   Input,
//   Label,
// } from "reactstrap";
import { FormHelperText, FormLabel, Input, Option, Select } from "@mui/joy";
import "../css/BeneficiarySignup.css";
import {
  addAttorneyInvitation,
  getExistAttorneyInvitationByUsername,
  sendemail,
  updateAttorneyInvitation,
} from "../services/user-service";
// import {getUser} from "../../services/user-service";
import { getToken, getUser } from "../services/user-service";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardHeader, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import "./../css/inventoryReport.css";
import { EmailTemplateForInviteAttorney } from "./Emailtemplate";

// set signup data
const AttorneyDetail = ({ handleBack, pdfDataUri }) => {
  const userDetails = getUser();

  const [data, setData] = useState({
    agentName: "",
    agentEmail: "",
    agentType: "",
    otherAgentType: "",
    userName: userDetails.username,
    userEmail: userDetails.email,
    isInvitationAccepted: false,
  });

  const [emailDetail] = useState({
    to: "",
    subject: "",
    message: "",
  });

  // const [error, setError] = useState({
  //   errors: {},
  //   isError: false,
  // });

  const handleChanges = (event, property) => {
    const value = event.target.value;

    setData({ ...data, [property]: value });

    if (property === "agentEmail") {
      validateEmail(value);
    }
  };

  const handleSelectChanges = (e, newValue) => {
    const selectedAgentType = newValue || e.target.value;

    setData((prevData) => ({
      ...prevData,
      agentType: selectedAgentType,
      otherAgentType:
        selectedAgentType === "Other" ? prevData.otherAgentType : "",
    }));
  };

  const [errors, setErrors] = useState("");
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrors("Invalid email address");
    } else if (email !== email.toLowerCase()) {
      setErrors("Email should not contain capital letters");
    } else {
      setErrors("");
    }
  };

  // reseting the form
  // const resetData = () => {
  //   setData({
  //     name: "",
  //     email: "",
  //   });
  // };

  // submit the form
  const submitForm = (event) => {
    event.preventDefault();

    // Validate the email before proceeding
    if (errors) {
      toast.error("Invalid email address", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    // Prepare the email details
    emailDetail.to = data.agentEmail;
    emailDetail.message = EmailTemplateForInviteAttorney(
      data.agentName,
      userDetails.username
    );
    emailDetail.subject = "ATTORNEY INVITATION";

    // Get the token for authorization
    const token = "Bearer " + getToken();

    // Prepare form data
    const formData = new FormData();

    formData.append("data", JSON.stringify(data));

    // Prepare email data
    const emailFormData = new FormData();
    emailFormData.append("emailDetails", JSON.stringify(emailDetail));

    
    // Function to handle sending email
    const handleSendEmail = (emailFormData, agentName) => {
      sendemail(emailFormData)
        .then((resp) => {
          handleBack(); // Go back to the previous screen
          toast.success(`Email has been sent to ${agentName}`, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        })
        .catch((error) => {
          toast.error("Email has not been sent", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        });
    };

    // Check if an attorney invitation already exists
    getExistAttorneyInvitationByUsername(token, data.userName)
      .then((res) => {
        if (res !== false) {
          const id = res.id;

          // Update the state with the id
          setData((prevData) => ({
            ...prevData,
            id: id,
          }));

          // Prepare the updated form data with the ID
          const updatedFormData = new FormData();
          updatedFormData.append("data", JSON.stringify({ ...data, id: id }));

          // Update the attorney invitation
          updateAttorneyInvitation(token, updatedFormData)
            .then((res) => {
              handleSendEmail(emailFormData, data.agentName);
            })
            .catch((error) => {
              toast.error("Error updating attorney invitation", {
                position: toast.POSITION.BOTTOM_CENTER,
              });
            });
        } else {
          // Add new attorney invitation
          addAttorneyInvitation(token, formData)
            .then((res) => {
              handleSendEmail(emailFormData, data.agentName);
            })
            .catch((error) => {
              toast.error("Error adding attorney invitation", {
                position: toast.POSITION.BOTTOM_CENTER,
              });
            });
        }
      })
      .catch((error) => {
        toast.error("Error checking existing attorney invitation", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "25rem" }}>
        <Card>
          <CardContent>
            <div className="cross_button">
              <button className="cross_button_main" onClick={handleBack}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <CardHeader
              title={
                <Typography
                  variant="h5"
                  style={{ textAlign: "center", marginTop: "-10px" }}
                >
                  Attorney/Agent Details
                </Typography>
              }
            />
            <form onSubmit={submitForm}>
              <div>
                <div className="mt-2">
                  <FormLabel>Select Agent</FormLabel>
                  <Select
                    value={data.agentType}
                    required
                    onChange={(e, newValue) => handleSelectChanges(e, newValue)}
                  >
                    <Option value="">Select agent type</Option>
                    <Option value="Attorney">Attorney</Option>
                    <Option value="Executor">Executor</Option>
                    <Option value="Trustee">Trustee</Option>
                    <Option value="Advisor">Advisor</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </div>

                {data.agentType === "Other" && (
                  <div className="mt-2">
                    <div>
                      <FormLabel>Other Agent Type</FormLabel>
                      <Input
                        className="input_mui_joy"
                        placeholder="Enter Other Agent Type"
                        id="otherprop"
                        onChange={(e) => handleChanges(e, "otherAgentType")}
                        value={data.otherAgentType}
                      />
                    </div>
                  </div>
                )}

                <div className="mt-2">
                  <FormLabel>
                    {data.agentType === "Other" && data.otherAgentType
                      ? `Enter ${data.otherAgentType} Name`
                      : data.agentType
                      ? `Enter ${data.agentType} Name`
                      : "Enter Name"}
                  </FormLabel>
                  <Input
                    className="input_mui_joy"
                    placeholder={
                      data.agentType === "Other" && data.otherAgentType
                        ? `Enter ${data.otherAgentType} Name`
                        : data.agentType
                        ? `Enter ${data.agentType} Name`
                        : "Enter Name"
                    }
                    value={data.agentName}
                    onChange={(e) => handleChanges(e, "agentName")}
                    required
                  />
                </div>

                <div className="mt-2">
                  <FormLabel>
                    {data.agentType === "Other" && data.otherAgentType
                      ? `Enter ${data.otherAgentType} Email`
                      : data.agentType
                      ? `Enter ${data.agentType} Email`
                      : "Enter Email"}
                  </FormLabel>
                  <Input
                    className="input_mui_joy"
                    placeholder={
                      data.agentType === "Other" && data.otherAgentType
                        ? `Enter ${data.otherAgentType} Email`
                        : data.agentType
                        ? `Enter ${data.agentType} Email`
                        : "Enter Email"
                    }
                    value={data.agentEmail}
                    onChange={(e) => handleChanges(e, "agentEmail")}
                    error={!!errors}
                    required
                  />
                  {errors && (
                    <FormHelperText style={{ color: "#C41C1C" }}>
                      {errors}
                    </FormHelperText>
                  )}
                </div>
              </div>
              <div className="submit_button">
                <button type="submit">Send mail</button>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default AttorneyDetail;
