import {
  faDownload,
  faHandHoldingDollar,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Textarea } from "@mui/joy";
import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardHeader } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { formatAmount } from "../../components/Common";
import SideBar from "../../components/sidebar/Sidebar";
import UserBase from "../../components/user/UserBase";
import {
  addMortgageLoan,
  deleteMortgageLoan,
  getExistingMortgageLoans,
  getMortgageLoans,
} from "../../services/MortgageLoan";
import {
  downloadDocument1,
  getSecondaryUser,
  getToken,
  getUser,
} from "../../services/user-service";
import Deletebutton from "../my-estate/Deletebutton";
import UpdateButton from "../my-estate/UpdateButton";

function Mortgage() {
  const [data, setData] = useState({
    mortgageLoan: {
      estimatedLoan: "",
      financialInstitution: "",
      assetAgainstLoan: "",
      notes: "",
    },
  });

  const [files, setFiles] = useState([]);
  const [show, setShow] = useState(false);
  let [form1, setForm1] = useState(false);
  // Get primaryUser data
  const primaryUserDetails = getUser();
  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();

  const toggle = () => {
    setForm1(!form1);
  };

  const handleChanges = (e, field) => {
    const { value } = e.target;
    setData((prevData) => ({
      ...prevData,
      mortgageLoan: {
        ...prevData.mortgageLoan,
        [field]: value,
      },
    }));
  };

  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        // Update state with selected files
        setFiles(selectedFilesArray);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = "";
      }
    }
  };

  let [card, setCard] = useState([]);
  const AddCard = () => {
    let userId = getUser().commonId;
    let token = "Bearer " + getToken();
    getMortgageLoans(token, userId)
      .then((res) => {
        setCard(res);
      })
      .catch((error) => {
        setCard([]);
      });
  };

  const creditCardForm = (event) => {
    event.preventDefault();

    let token = "Bearer " + getToken();
    const formData = new FormData();

    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
    }

    formData.append("data", JSON.stringify(data));
    console.log("data: ", data);

    addMortgageLoan(formData, token)
      .then((resp) => {
        AddCard();
        toast.success("Data Added !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {});
  };

  const [popupVisibleDownload, setPopupVisibleDownload] = useState(false);
  const [selectedDownload, setSelectDownload] = useState("");

  const handleShowDownload = (showDetail) => {
    setPopupVisibleDownload(true);
    setSelectDownload(showDetail);
  };

  const handleDownload = (id, fileName) => {
    let myarry = fileName.split(".");

    downloadDocument1(id)
      .then((response) => {
        const downloadUrl = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `${myarry[0]}.${myarry[1]}`;
        link.click();
        URL.revokeObjectURL(downloadUrl);
      })
      .catch((error) => {});
  };

  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedNote, setSelectedNote] = useState("");

  const handleOpenPopup = (note) => {
    setSelectedNote(note);
    setPopupVisible(true);
  };

  let [showDetail, setShowDetail] = useState([]);

  const ShowDetails = (obj) => {
    setShowDetail(obj);
    setShow(true);
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [currentIdType, setCurrentIdType] = useState("");
  const removeMortgageLoan = (id, idType) => {
    setShowConfirmation(true);
    setCurrentId(id);
    setCurrentIdType(idType);
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  // code by purnendu
  const handleRemove = (params) => {
    setShowConfirmation(false);
    const { Id, idType } = params;
    if (idType === "mortgageLoanId") {
      deleteMortgageLoan(Id)
        .then((res) => {
          toast.success("Deleted successfully...", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          AddCard();
          setShow(false);
        })
        .catch((err) => {});
    }
  };

  const [existingMortgageCard, setExistingMortgageCard] = useState([]);
  const AddExistingMortgageCard = () => {
    let userId = getUser().commonId;
    let token = "Bearer " + getToken();
    getExistingMortgageLoans(token, userId)
      .then((res) => {
        setExistingMortgageCard(res);
      })
      .catch((error) => {
        setExistingMortgageCard([]);
      });
  };

  useEffect(() => {
    AddExistingMortgageCard();
    AddCard();
  }, []);
  return (
    <div className={`your-component ${show ? "fade-in-element" : ""}`}>
      <UserBase>
        <div className="mt-5"></div>
        <SideBar>
          {primaryUserDetails.accountType === "secondary" &&
          secondaryUserDetails.accountType === "primary" &&
          secondaryUserDetails.isSecondaryUserEditable === "false" ? (
            ""
          ) : (
            <div className="addme">
              <div className="addme_inner">
                <button onClick={() => toggle()}>Add Mortgage</button>
              </div>
            </div>
          )}

          {((existingMortgageCard && existingMortgageCard.length > 0) ||
            (card && card.length > 0)) && (
            <div className="propCard">
              <div className="propCard-card">
                {existingMortgageCard &&
                  existingMortgageCard.length > 0 &&
                  existingMortgageCard.map((entity) => (
                    <div className="propCard-card-body" key={entity.id}>
                      <h5 className="propCard-card-title">
                        {entity.assetType}
                      </h5>
                      <p className="propCard-card-text">
                        {" "}
                        {formatAmount(entity.estimatedLoan)}
                      </p>
                      <div className="propCard-btn">
                        <p className=" viewDetails">
                          <button onClick={() => ShowDetails(entity)}>
                            View Details
                          </button>
                        </p>
                      </div>
                    </div>
                  ))}
                {card &&
                  card.length > 0 &&
                  card.map((entity) => (
                    <div
                      className="propCard-card-body"
                      key={entity.mortgageLoan.id}
                    >
                      <h5 className="propCard-card-title">
                        {entity.mortgageLoan.financialInstitution}
                      </h5>
                      <p className="propCard-card-text">
                        {" "}
                        {formatAmount(entity.mortgageLoan.estimatedLoan)}
                      </p>
                      <div className="propCard-btn">
                        <p className=" viewDetails">
                          <button onClick={() => ShowDetails(entity)}>
                            View Details
                          </button>
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}

          {form1 && (
            <div
              className="overlay1"
              style={{ transition: "500ms", height: "" }}
            >
              <div>
                <form onSubmit={creditCardForm} className="addform_body">
                  <container className="credit_card_base">
                    <div className="homepage credit_card_main">
                      <Card>
                        <CardHeader>
                          <div
                            style={{ width: "100%" }}
                            className="addform_heading"
                          >
                            <h3 className="addform_heading_h1">
                              {"Add Mortgage/Loan"}
                            </h3>
                            <div className="addform_close">
                              <FontAwesomeIcon
                                icon={faXmark}
                                onClick={toggle}
                              />
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody className="credit_card_body">
                          <div>
                            <table className="credit_card_table">
                              <thead>
                                <tr>
                                  <th style={{ width: "192px" }}>
                                    Estimated Loan*
                                  </th>
                                  <th style={{ width: "240px" }}>
                                    Financial Institution*
                                  </th>
                                  <th style={{ width: "240px" }}>
                                    Asset Against Loan
                                  </th>
                                  <th style={{ width: "192px" }}>
                                    Supporting Document*
                                  </th>
                                  <th style={{ width: "240px" }}>Notes</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <Input
                                      placeholder="Estimated Loan"
                                      type="number"
                                      onChange={(e) =>
                                        handleChanges(e, "estimatedLoan")
                                      }
                                      value={
                                        data?.mortgageLoan?.estimatedLoan || ""
                                      }
                                      startDecorator={<div>$</div>}
                                      required
                                    />
                                  </td>
                                  <td>
                                    <Input
                                      placeholder="Financial Institution"
                                      onChange={(e) =>
                                        handleChanges(e, "financialInstitution")
                                      }
                                      value={
                                        data?.mortgageLoan
                                          ?.financialInstitution || ""
                                      }
                                      required
                                    />
                                  </td>
                                  <td>
                                    <Input
                                      placeholder="Asset Against Loan"
                                      onChange={(e) =>
                                        handleChanges(e, "assetAgainstLoan")
                                      }
                                      value={
                                        data?.mortgageLoan?.assetAgainstLoan ||
                                        ""
                                      }
                                      required
                                    />
                                  </td>
                                  <td>
                                    <input
                                      style={{
                                        border: "solid 1px lightgray",
                                        borderLeft: "none",
                                        width: "240px",
                                        borderRadius: "5px",
                                      }}
                                      type="file"
                                      name="myfile"
                                      id="exampleFile"
                                      onChange={handleImageChange}
                                      accept=".pdf"
                                      multiple
                                    />
                                  </td>
                                  <td>
                                    <Textarea
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      placeholder="Notes"
                                      id="notes"
                                      value={data?.mortgageLoan.notes}
                                      onChange={(e) =>
                                        handleChanges(e, "notes")
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                        <CardFooter>
                          <div className="text-center">
                            <Button
                              className="my-estate-clear-btn"
                              type="reset"
                              // onClick={resetForm}
                              outline
                            >
                              Clear
                            </Button>
                            <Button
                              outline
                              type=""
                              className="my-estate-add-btn"
                            >
                              Add
                            </Button>
                          </div>
                        </CardFooter>
                      </Card>
                    </div>
                  </container>
                </form>
              </div>
            </div>
          )}

          {show && Object.keys(showDetail).length > 0 && (
            <>
              <div
                className="card__data"
                style={{ transition: "all 1s ease-out" }}
              >
                <div className="card__data-container">
                  <section className="section1">
                    <div>
                      <p className="row1-text">
                        <FontAwesomeIcon
                          icon={faHandHoldingDollar}
                          style={{ color: "#025596", fontSize: "18px" }}
                        />
                        <span style={{}}>{"Mortgage/Loan"}</span>
                      </p>
                      <div className="row1-button">
                        <div>
                          {showDetail.documents &&
                            showDetail.documents.length > 0 && (
                              <Tooltip title="click to see multiple downlaod files">
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleShowDownload(showDetail);
                                    setShow(false);
                                  }}
                                >
                                  <div className="myestate_download_button dwnbtn">
                                    <FontAwesomeIcon
                                      className="myestate_download_icon"
                                      icon={faDownload}
                                    />
                                    <span></span>
                                  </div>
                                </div>
                              </Tooltip>
                            )}
                        </div>
                        {primaryUserDetails.accountType === "secondary" &&
                        secondaryUserDetails.accountType === "primary" &&
                        secondaryUserDetails.isSecondaryUserEditable ===
                          "false" ? (
                          ""
                        ) : (
                          <div>
                            <Tooltip title="Click Here To Edit">
                              {showDetail.assetId ? (
                                <></>
                              ) : (
                                <div>
                                  <UpdateButton
                                    URL={"../liabilities/mortgage/"}
                                    id={showDetail.mortgageLoan.id}
                                  />
                                </div>
                              )}
                            </Tooltip>
                          </div>
                        )}

                        {primaryUserDetails.accountType === "secondary" &&
                        secondaryUserDetails.accountType === "primary" &&
                        secondaryUserDetails.isSecondaryUserEditable ===
                          "false" ? (
                          ""
                        ) : (
                          <div
                            style={{ display: showDetail.assetId && "none" }}
                          >
                            <FontAwesomeIcon
                              onClick={() =>
                                removeMortgageLoan(
                                  showDetail?.mortgageLoan?.id,
                                  "mortgageLoanId"
                                )
                              }
                              className="delete-button-comp"
                              style={{ cursor: "pointer" }}
                              icon={faTrash}
                            />
                          </div>
                        )}
                        <div>
                          <span
                            className="card__data-close"
                            onClick={() => {
                              setShow(!show);
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="section2">
                    <div>
                      <div className="col1">
                        {showDetail.assetType && (
                          <p>
                            Asset Type : <code>{showDetail.assetType}</code>
                          </p>
                        )}
                        {showDetail.assetName && (
                          <p>
                            Asset Name : <code>{showDetail.assetName}</code>
                          </p>
                        )}
                        {showDetail.assetId && (
                          <p>
                            Details :{" "}
                            <Link
                              to={
                                showDetail.assetType === "Real Estate"
                                  ? "../my-estate/real-estate/"
                                  : "../my-estate/vehicles/"
                              }
                              style={{
                                marginLeft: "5px",
                                textDecoration: "none",
                                color: "black",
                                fontWeight: "400",
                              }}
                            >
                              click here
                            </Link>
                          </p>
                        )}

                        {showDetail?.mortgageLoan?.assetAgainstLoan && (
                          <p>
                            Asset Against Loan :{" "}
                            <code>
                              {showDetail?.mortgageLoan?.assetAgainstLoan}
                            </code>
                          </p>
                        )}
                        {showDetail?.mortgageLoan?.financialInstitution && (
                          <p>
                            Financial Institution/Bank :{" "}
                            <code>
                              {showDetail?.mortgageLoan?.financialInstitution}
                            </code>
                          </p>
                        )}
                      </div>
                      <div className="col2">
                        {showDetail.estimatedLoan && (
                          <p>
                            Estimated Loan :{" "}
                            <code>
                              {" "}
                              <span
                                style={{ color: "red", fontWeight: "bold" }}
                              >
                                {" "}
                                {formatAmount(showDetail.estimatedLoan)}
                              </span>
                            </code>
                          </p>
                        )}
                        {showDetail.estimatedValue && (
                          <p>
                            Estimated Value :{" "}
                            <code>
                              {" "}
                              <span
                                style={{ color: "green", fontWeight: "bold" }}
                              >
                                {" "}
                                {formatAmount(showDetail.estimatedValue)}
                              </span>
                            </code>
                          </p>
                        )}
                        {showDetail?.mortgageLoan?.estimatedLoan && (
                          <p>
                            Estimated Loan :{" "}
                            <code>
                              {" "}
                              <span
                                style={{ color: "green", fontWeight: "bold" }}
                              >
                                {" "}
                                {formatAmount(
                                  showDetail?.mortgageLoan?.estimatedLoan
                                )}
                              </span>
                            </code>
                          </p>
                        )}
                        {showDetail?.mortgageLoan?.notes && (
                          <Tooltip title="Click To see Note">
                            <p
                              onClick={() => {
                                handleOpenPopup(
                                  showDetail?.mortgageLoan?.notes
                                );
                                setShow(!show);
                              }}
                            >
                              Note:{" "}
                              <code>
                                {showDetail?.mortgageLoan?.notes
                                  ? showDetail.mortgageLoan.notes.slice(0, 10)
                                  : ""}
                                ...<span className="readmore">Read More</span>
                              </code>
                            </p>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </>
          )}

          {showConfirmation && (
            <Deletebutton
              handleRemove={handleRemove}
              Id={currentId}
              idType={currentIdType}
              handleConfirmationClose={handleConfirmationClose}
            />
          )}

          {popupVisible && (
            <div className="popup">
              <div className="popup-content">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div>
                      <h2>Note</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisible(false);
                          setShow(!show);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div>
                    <p>{selectedNote}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {popupVisibleDownload && (
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h2>Download Files</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisibleDownload(false);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>

                  <div>
                    {selectedDownload.documents &&
                      selectedDownload.documents.length > 0 &&
                      selectedDownload.documents.map((file, index) => (
                        <div key={index} style={{ marginBottom: "20px" }}>
                          <Tooltip title={file.fileName}>
                            <div
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                gap: "20px",
                              }}
                              onClick={() => {
                                handleDownload(file.id, file.fileName);
                              }}
                            >
                              Download - {index + 1}
                              <div className="myestate_download_button dwnbtn">
                                <FontAwesomeIcon
                                  className="myestate_download_icon"
                                  icon={faDownload}
                                />
                                <span>{file.fileName}</span>
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </SideBar>
      </UserBase>
    </div>
  );
}
export default Mortgage;
