import {
  faCircleInfo,
  faDownload,
  faPencilAlt,
  faTrash,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Paper, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SideBar from "../../components/sidebar/Sidebar";
import UserBase from "../../components/user/UserBase";
import {
  deleteBeneficiaryById,
  downloadDocument1,
  getBeneficiaryByUserId,
  getToken,
  getUser,
} from "../../services/user-service";
import "./BeneficiaryList.css";
import { toast } from "react-toastify";
import Deletebutton from "../my-estate/Deletebutton";
import BeneficiarySignup from "../BeneficiarySignup";

const BeneficiaryList = () => {
  const [editBeneficiaryPopup, setEditBeneficiaryPopup] = useState(false);
  const [beneficiaryId, setBeneficiaryId] = useState(null);

  const toggleBeneficiary = (id) => {
    setEditBeneficiaryPopup(!editBeneficiaryPopup);
    setBeneficiaryId(id);
  };

  const handleBack = () => {
    setEditBeneficiaryPopup(false);
    getAllBeneficiary();
  };

  const [showUserDetails, setShowUserDetails] = useState(null);
  const [show, setShow] = useState(false);
  const toggleShow = (beneficiary) => {
    setShowUserDetails(beneficiary);
    setShow(!show);
  };

  const handleDownload = (id, fileName) => {
    console.log("id: ", id);
    console.log("fileName: ", fileName);

    let file = fileName.split(".");
    downloadDocument1(id)
      .then((response) => {
        const downloadUrl = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `${file[0]}.${file[1]}`;
        link.click();
        URL.revokeObjectURL(downloadUrl);
      })
      .catch(() => {});
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const removeBeneficiary = (id) => {
    setShowConfirmation(true);
    setCurrentId(id);
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const handleRemove = (params) => {
    const token = "Bearer " + getToken();
    setShowConfirmation(false);
    const { Id } = params;
    deleteBeneficiaryById(token, Id)
      .then(() => {
        toast.success("Deleted successfully...", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        getAllBeneficiary();
      })
      .catch(() => {});
  };

  const [beneficiary, setBeneficiary] = useState([]);
  const getAllBeneficiary = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getBeneficiaryByUserId(token, userId)
      .then((res) => {
        setBeneficiary(res);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getAllBeneficiary();
  }, []);
  return (
    <>
      <div className={"your-component fade-in-element"}>
        <UserBase>
          <div style={{ marginTop: "4rem" }} className="otherElement">
            <SideBar>
              <div className="beneficiary_list_base">
                <div className="beneficiary_list_main">
                  <div className="beneficiary_heading">
                    <h4>
                      Beneficiary List {"   "} ({beneficiary.length})
                    </h4>
                  </div>
                  <div className="beneficiary_container">
                    <div className="beneficiary_details_cards">
                      <Paper
                        sx={{ padding: 1.5 }}
                        className="beneficiary_detail_card"
                        style={{
                          backgroundColor: "rgb(208,208,208)",
                          width: "fit-content",
                        }}
                      >
                        <p className="beneficiary_row">
                          <strong>Name</strong>
                        </p>
                        <p
                          style={{ minWidth: "190px" }}
                          className="beneficiary_row"
                        >
                          <strong>Email</strong>
                        </p>
                        <p className="beneficiary_row">
                          <strong>Phone</strong>
                        </p>
                        <p className="beneficiary_row">
                          <strong>Date Of Birth</strong>
                        </p>
                        <p className="beneficiary_row">
                          <strong>Country Of Citizenship</strong>
                        </p>
                        <p
                          className="beneficiary_row"
                          style={{ width: "240px", textAlign: "center" }}
                        >
                          <strong>Action</strong>
                        </p>
                      </Paper>

                      {beneficiary.map((detail, index) => (
                        <Paper
                          style={{ width: "fit-content" }}
                          key={index + 1}
                          sx={{ padding: 1.5 }}
                          className="beneficiary_detail_card"
                        >
                          <p className="beneficiary_row beneficiary_row_data">
                            <strong className="beneficiary_row_data_name">{`${detail.usersBeneficiary.firstName} ${detail.usersBeneficiary.lastName}`}</strong>

                            {`${
                              detail.usersBeneficiary.state
                                ? `${detail.usersBeneficiary.state}, `
                                : ""
                            }${
                              detail.usersBeneficiary.country
                                ? detail.usersBeneficiary.country
                                : ""
                            }`}
                          </p>
                          <p
                            style={{ minWidth: "190px" }}
                            className="beneficiary_row beneficiary_row_data"
                          >
                            {detail.usersBeneficiary.email}
                          </p>
                          <p className="beneficiary_row beneficiary_row_data">
                            {detail.usersBeneficiary.phoneNo}
                          </p>
                          <p className="beneficiary_row beneficiary_row_data">
                            {detail.usersBeneficiary.dob}
                          </p>
                          <p className="beneficiary_row beneficiary_row_data">
                            {detail.usersBeneficiary.countryOfCitizenship}
                          </p>
                          <div className="beneficiary_row_action_btn">
                            <button
                              onClick={() => {
                                removeBeneficiary(detail.usersBeneficiary.id);
                              }}
                              type="button"
                              className="beneficiary_row_Action_delete_edit"
                            >
                              <p>
                                <FontAwesomeIcon icon={faTrash} />
                              </p>
                            </button>
                            <button
                              // to="/user/dashboard"
                              onClick={() => {
                                toggleBeneficiary(detail.usersBeneficiary.id);
                              }}
                              className="beneficiary_row_Action_delete_edit"
                            >
                              <p>
                                <FontAwesomeIcon icon={faPencilAlt} />
                              </p>
                            </button>
                            <button
                              className="beneficiary_row_Action"
                              onClick={() => {
                                toggleShow(detail);
                              }}
                            >
                              <p>
                                <FontAwesomeIcon icon={faCircleInfo} />
                              </p>
                              View Details
                            </button>
                          </div>
                        </Paper>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* confirmation for delete beneficiary   */}
              {showConfirmation && (
                <Deletebutton
                  handleRemove={handleRemove}
                  Id={currentId}
                  handleConfirmationClose={handleConfirmationClose}
                />
              )}

              {/* show detail popup */}
              {show && Object.keys(showUserDetails).length > 0 && (
                <>
                  <div
                    className="card__data"
                    style={{ transition: "all 1s ease-out" }}
                  >
                    <div className="card__data-container">
                      <section className="section1">
                        <div>
                          <p className="row1-text">
                            <FontAwesomeIcon
                              icon={faUser}
                              style={{ color: "#025596", fontSize: "18px" }}
                            />
                            <span style={{}}>
                              {showUserDetails.usersBeneficiary.firstName}{" "}
                              {showUserDetails.usersBeneficiary.lastName}
                            </span>
                          </p>
                          <div className="row1-button">
                            <div>
                              {showUserDetails.document && (
                                <Tooltip title="click to see identification document">
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      handleDownload(
                                        showUserDetails.document.id,
                                        showUserDetails.document.fileName
                                      );
                                    }}
                                  >
                                    <div className="myestate_download_button dwnbtn">
                                      <FontAwesomeIcon
                                        className="myestate_download_icon"
                                        icon={faDownload}
                                      />
                                      <span></span>
                                    </div>
                                  </div>
                                </Tooltip>
                              )}
                            </div>
                            <div>
                              <span
                                className="card__data-close"
                                onClick={() => {
                                  toggleShow();
                                }}
                              >
                                <FontAwesomeIcon icon={faXmark} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section className="section2">
                        <div>
                          <div className="col1">
                            {showUserDetails.usersBeneficiary.firstName && (
                              <p>
                                Name:{" "}
                                <code>
                                  {showUserDetails.usersBeneficiary.firstName}{" "}
                                  {showUserDetails.usersBeneficiary.lastName}
                                </code>
                              </p>
                            )}
                            {showUserDetails.usersBeneficiary.email && (
                              <p>
                                Email:{" "}
                                <code style={{ textTransform: "lowercase" }}>
                                  {showUserDetails.usersBeneficiary.email}
                                </code>
                              </p>
                            )}
                            {showUserDetails.usersBeneficiary.dob && (
                              <p>
                                Date of birth:{" "}
                                <code>
                                  {showUserDetails.usersBeneficiary.dob}
                                </code>
                              </p>
                            )}
                            {showUserDetails.usersBeneficiary.phoneNo && (
                              <p>
                                Phone Number:{" "}
                                <code>
                                  {showUserDetails.usersBeneficiary.phoneNo}
                                </code>
                              </p>
                            )}
                            {showUserDetails.usersBeneficiary
                              .socialSecurityNo && (
                              <p>
                                Social Security No:{" "}
                                <code>
                                  {
                                    showUserDetails.usersBeneficiary
                                      .socialSecurityNo
                                  }
                                </code>
                              </p>
                            )}
                          </div>
                          <div className="col2">
                            {showUserDetails.usersBeneficiary
                              .countryOfBirth && (
                              <p>
                                Country Of Birth:{" "}
                                <code>
                                  {
                                    showUserDetails.usersBeneficiary
                                      .countryOfBirth
                                  }
                                </code>
                              </p>
                            )}
                            {showUserDetails.usersBeneficiary
                              .countryOfCitizenship && (
                              <p>
                                Country Of Citizenship:{" "}
                                <code>
                                  {
                                    showUserDetails.usersBeneficiary
                                      .countryOfCitizenship
                                  }
                                </code>
                              </p>
                            )}
                            {(showUserDetails.usersBeneficiary.state ||
                              showUserDetails.usersBeneficiary.country) && (
                              <p>
                                Address:{" "}
                                <code>
                                  {`${
                                    showUserDetails.usersBeneficiary.street
                                      ? `${showUserDetails.usersBeneficiary.street}, `
                                      : ""
                                  }${
                                    showUserDetails.usersBeneficiary.apartment
                                      ? `${showUserDetails.usersBeneficiary.apartment}, `
                                      : ""
                                  }${
                                    showUserDetails.usersBeneficiary.city
                                      ? `${showUserDetails.usersBeneficiary.city}, `
                                      : ""
                                  }${
                                    showUserDetails.usersBeneficiary.state
                                      ? `${showUserDetails.usersBeneficiary.state}, `
                                      : ""
                                  }${
                                    showUserDetails.usersBeneficiary.country
                                      ? `${showUserDetails.usersBeneficiary.country} `
                                      : ""
                                  }${
                                    showUserDetails.usersBeneficiary.zipCode
                                      ? `${showUserDetails.usersBeneficiary.zipCode}`
                                      : ""
                                  }`}
                                </code>
                              </p>
                            )}
                            {showUserDetails.usersBeneficiary
                              .legalGuardianInfo && (
                              <p>
                                Legal Guardian Information:{" "}
                                <code>
                                  {
                                    showUserDetails.usersBeneficiary
                                      .legalGuardianInfo
                                  }
                                </code>
                              </p>
                            )}
                            {showUserDetails.usersBeneficiary
                              .powerOfAttorneyDetails && (
                              <p>
                                Power Of Attorney Details:{" "}
                                <code>
                                  {
                                    showUserDetails.usersBeneficiary
                                      .powerOfAttorneyDetails
                                  }
                                </code>
                              </p>
                            )}
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </>
              )}

              {editBeneficiaryPopup && (
                <div className="beneficiary-popup">
                  <div className="beneficiary-content">
                    {editBeneficiaryPopup && (
                      <BeneficiarySignup
                        handleBack={handleBack}
                        Id={beneficiaryId}
                      />
                    )}
                  </div>
                </div>
              )}
            </SideBar>
          </div>
        </UserBase>
      </div>
    </>
  );
};

export default BeneficiaryList;
