import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Radio,
  RadioGroup,
  Select,
} from "@mui/joy";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "../../src/css/AttorneyForm.css";
import { changeUsFormatNumber } from "../components/Common";
import {
  addUserOwner,
  getOwnerById,
  getToken,
  updateUserOwner,
} from "../services/user-service";
import axios from "axios";
import { Typography } from "@mui/material";

export default function CreateOwner(params) {
  const { onBack, Id } = params;
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    dob: "",
    relationship: "",
    ownerStatus: "",
    phoneNo: "",
    countryOfBirth: "",
    countryOfCitizenship: "",
    street: "",
    apartment: "",
    city: "",
    zipCode: "",
    state: "",
    country: "",
  });

  const reset = () => {
    setData({
      firstName: "",
      lastName: "",
      email: "",
      gender: "",
      dob: "",
      relationship: "",
      ownerStatus: "",
      phoneNo: "",
      countryOfBirth: "",
      countryOfCitizenship: "",
      street: "",
      apartment: "",
      city: "",
      zipCode: "",
      state: "",
      country: "",
    });
  };

  const validatePhoneNumber = (phoneNo) => {
    if (phoneNo === "") {
      return true;
    }
    const re = /^(\+1\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/;
    return re.test(phoneNo);
  };

  const validateEmail = (email) => {
    const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    return re.test(email);
  };

  // const validateAlphanumeric = (value) => {
  //   // Check if value is alphanumeric (letters and/or numbers) and not entirely numeric
  //   const isAlphanumeric = /^[a-zA-Z0-9]+$/.test(value);
  //   const isNumeric = /^\d+$/.test(value);

  //   return isAlphanumeric && !isNumeric;
  // };
  const validateAlphanumeric = (value) => {
    return !/^\d+$/.test(value);
  };

  const validateZipCode = (zipCode) => {
    return /^\d{5}$/.test(zipCode);
  };

  const [errors, setErrors] = useState({
    ownerStatus: true,
    firstName: true,
    dob: true,
    gender: true,
    firstNameValidate: true,
    lastNameValidate: true,
    dobValidate: true,
    phoneValidate: true,
    emailValidate: true,
    zipValidate: true,
    validZip: true,
  });

  const getOwnerDataById = () => {
    const token = "Bearer " + getToken();
    if (Id !== undefined && Id !== null) {
      getOwnerById(token, Id)
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          toast.error("Some error occurred", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        });
    }
  };

  const today = dayjs().format("YYYY-MM-DD");
  const handleChanges = (event, property) => {
    const value = event.target.value;
    const errorStates = { ...errors }; // Clone the existing errors state

    if (property === "phoneNo") {
      const formattedValue = changeUsFormatNumber(value);
      setData((prevData) => ({
        ...prevData,
        [property]: formattedValue,
      }));
    } else if (property === "dob" && value > today) {
      setData((prevData) => ({
        ...prevData,
        [property]: value,
      }));
      errorStates.dobValidate = false;
    } else if (property === "zipCode") {
      if (/^\d{0,5}$/.test(value)) {
        // Validation to allow only up to 5 digits (or empty string)
        setData((prevData) => ({
          ...prevData,
          [property]: value,
        }));
      }
    } else {
      setData((prevData) => ({
        ...prevData,
        [property]: value,
      }));
      errorStates[property] = !!value;
    }

    // Additional validations
    if (property === "email") {
      errorStates.emailValidate = value !== "" ? validateEmail(value) : true;
    } else if (property === "zipCode") {
      errorStates.zipValidate =
        value !== "" ? validateZipCode(value.slice(0, 5)) : true;
    } else if (property === "phoneNo") {
      errorStates.phoneValidate =
        value !== "" ? validatePhoneNumber(changeUsFormatNumber(value)) : true;
    } else if (property === "dob" && value < today) {
      errorStates.dobValidate = true;
    } else if (property === "firstName" || property === "lastName") {
      if (property === "firstName") {
        errorStates.firstNameValidate = validateAlphanumeric(value);
      } else if (property === "lastName") {
        if (value !== "") {
          errorStates.lastNameValidate = validateAlphanumeric(value);
        } else {
          errorStates.lastNameValidate = true;
        }
      }
    }
    // Set errors state once
    setErrors(errorStates);
  };

  useEffect(() => {
    const fetchDataFromAPI = () => {
      if (data.zipCode.length === 5) {
        const apiUrl = `https://api.zippopotam.us/us/${data.zipCode}`;
        axios
          .get(apiUrl)
          .then((res) => {
            if (res.data && res.data.places && res.data.places.length > 0) {
              const place = res.data.places[0];
              const city = place["place name"];
              const state = place["state abbreviation"];
              const country = res.data["country abbreviation"];

              setData((prevData) => ({
                ...prevData,
                city: city,
                state: state,
                country: country,
              }));
              setErrors((prevErrors) => ({
                ...prevErrors,
                validZip: true,
              }));
            }
          })
          .catch((error) => {
            setErrors((prevErrors) => ({
              ...prevErrors,
              validZip: false,
            }));
          });
      } else {
        setData((prevData) => ({
          ...prevData,
          city: "",
          state: "",
          country: "",
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          validZip: true,
        }));
      }
    };

    fetchDataFromAPI();
  }, [data.zipCode]);

  const handleSelectChanges = (event, value, property) => {
    if (property === "gender") {
      setData((prevData) => ({
        ...prevData,
        [property]: value,
      }));
      errors.gender = !!value;
    }
  };

  const submitForm = (event) => {
    event.preventDefault();
    // Initialize an errors object with boolean values
    const newErrors = {
      ownerStatus: !!data.ownerStatus,
      firstName: !!data.firstName,
      gender: !!data.gender,
      dob: !!data.dob,
      emailValidate: data.email ? validateEmail(data.email) : true,
      phoneValidate: data.phoneNo
        ? validatePhoneNumber(changeUsFormatNumber(data.phoneNo))
        : true,
      dobValidate: data.dob ? data.dob < today : true,
      firstNameValidate: validateAlphanumeric(data.firstName),
      lastNameValidate: data.lastName
        ? validateAlphanumeric(data.lastName)
        : true,
      zipValidate: data.zipCode ? validateZipCode(data.zipCode) : true,
      validZip: errors.validZip,
    };
    setErrors(newErrors);
    // Check if there are any errors
    const hasErrors = Object.values(newErrors).includes(false);

    if (!hasErrors) {
      let token = "Bearer " + getToken();
      const formData = new FormData();
      if (Id !== undefined && Id !== null) {
        const updatedData = { ...data, id: Id };
        formData.append("data", JSON.stringify(updatedData));
        updateUserOwner(formData, token)
          .then((res) => {
            toast.success("Owner updated successfully", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            onBack();
          })
          .catch((err) => {
            toast.error("Some error occurred", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          });
      } else {
        formData.append("data", JSON.stringify(data));
        addUserOwner(formData, token)
          .then((resp) => {
            toast.success("Owner added successfully !!", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            reset();
            onBack();
          })
          .catch((error) => {});
      }
    }
  };

  useEffect(() => {
    getOwnerDataById();
  }, []);

  return (
    <>
      <div className="owner_container">
        <div className="owner_creation_form">
          <div className="owner_creation_form_heading">
            {Id ? (
              <h1 style={{ textAlign: "center", width: "100%" }}>Edit Owner</h1>
            ) : (
              <h1 style={{ textAlign: "center", width: "100%" }}>Add Owner</h1>
            )}
            <div className="owner_creation_cancel_icon">
              <FontAwesomeIcon icon={faTimes} onClick={onBack} />
            </div>
          </div>
          <form className="owner_creation_form_main" onSubmit={submitForm}>
            <div className="owner_form">
              <div className="owner_form_left">
                <FormControl>
                  <FormLabel required>Owner Status</FormLabel>
                  <RadioGroup
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                      margin: "8px 0",
                    }}
                    defaultValue="medium"
                    name="radio-buttons-group"
                    value={data.ownerStatus}
                    onChange={(e) => handleChanges(e, "ownerStatus")}
                  >
                    <Radio value="Living" label="Living" />
                    <Radio
                      sx={{ margin: 0 }}
                      value="Deceased"
                      label="Deceased"
                    />
                  </RadioGroup>
                  {!errors.ownerStatus && (
                    <FormHelperText error>
                      <Typography variant="caption" color="error">
                        {!errors.ownerStatus ? "Owner Status is required" : ""}
                      </Typography>
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel required>First Name</FormLabel>
                  <Input
                    placeholder="Enter first name"
                    value={data.firstName}
                    onChange={(e) => handleChanges(e, "firstName")}
                  />
                  {(!errors.firstName || !errors.firstNameValidate) && (
                    <FormHelperText error>
                      <Typography variant="caption" color="error">
                        {!errors.firstName
                          ? "First Name is required"
                          : !errors.firstNameValidate
                          ? "Enter valid first name"
                          : ""}
                      </Typography>
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    placeholder="Enter last name"
                    value={data.lastName}
                    onChange={(e) => handleChanges(e, "lastName")}
                  />
                  {!errors.lastNameValidate && (
                    <FormHelperText error>
                      <Typography variant="caption" color="error">
                        Enter valid last name
                      </Typography>
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl>
                  <FormLabel required>Date Of Birth</FormLabel>
                  <Input
                    type="date"
                    onChange={(e) => handleChanges(e, "dob")}
                    value={data.dob}
                    slotProps={{
                      input: {
                        max: today,
                      },
                    }}
                  />
                  {(!errors.dob || !errors.dobValidate) && (
                    <FormHelperText error>
                      <Typography variant="caption" color="error">
                        {!errors.dob
                          ? "Date of birth is required"
                          : !errors.dobValidate
                          ? "You can not enter future date"
                          : ""}
                      </Typography>
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel required>Gender</FormLabel>

                  <Select
                    value={data.gender}
                    onChange={(e, value) =>
                      handleSelectChanges(e, value, "gender")
                    }
                  >
                    <Option value="">Select Gender</Option>
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                  {!errors.gender && (
                    <FormHelperText error>
                      <Typography variant="caption" color="error">
                        {!errors.gender ? "Gender is required" : ""}
                      </Typography>
                    </FormHelperText>
                  )}
                </FormControl>
              </div>

              <div className="owner_form_right">
                <FormControl>
                  <FormLabel>Phone Number</FormLabel>
                  <Input
                    placeholder="Enter phone number"
                    value={data.phoneNo}
                    onChange={(e) => handleChanges(e, "phoneNo")}
                  />
                  {!errors.phoneValidate && (
                    <FormHelperText error>
                      <Typography variant="caption" color="error">
                        {!errors.phoneValidate
                          ? "Enter a valid phone number"
                          : ""}
                      </Typography>
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel>Relationship</FormLabel>
                  <Input
                    placeholder="Enter phone number"
                    value={data.relationship}
                    onChange={(e) => handleChanges(e, "relationship")}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    placeholder="Enter email address"
                    value={data.email}
                    onChange={(e) => handleChanges(e, "email")}
                  />
                  {!errors.emailValidate && (
                    <FormHelperText error>
                      <Typography variant="caption" color="error">
                        {!errors.emailValidate ? "Enter a valid email" : ""}
                      </Typography>
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel>Country Of Citizenship</FormLabel>
                  <Input
                    placeholder="Enter country of citizenship"
                    value={data.countryOfCitizenship}
                    onChange={(e) => handleChanges(e, "countryOfCitizenship")}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Country Of Birth</FormLabel>
                  <Input
                    placeholder="Enter country of birth"
                    value={data.countryOfBirth}
                    onChange={(e) => handleChanges(e, "countryOfBirth")}
                  />
                </FormControl>

                <FormControl className="bene_input_form">
                  <FormLabel>Street</FormLabel>
                  <Input
                    placeholder="Enter street"
                    value={data.street}
                    onChange={(e) => handleChanges(e, "street")}
                  />
                </FormControl>
                <FormControl className="bene_input_form">
                  <FormLabel>Apartment</FormLabel>
                  <Input
                    placeholder="Enter street"
                    value={data.apartment}
                    onChange={(e) => handleChanges(e, "apartment")}
                  />
                </FormControl>

                <div className="bene_address">
                  <div className="bene_address_half">
                    <FormControl className="bene_input_form">
                      <FormLabel>Zip Code</FormLabel>
                      <Input
                        placeholder="Enter zip code"
                        value={data.zipCode}
                        onChange={(e) => handleChanges(e, "zipCode")}
                      />
                      {(!errors.zipValidate || !errors.validZip) && (
                        <FormHelperText error>
                          <Typography variant="caption" color="error">
                            {!errors.zipValidate
                              ? "Please enter a valid 5-digit Zip Code"
                              : !errors.validZip
                              ? "This is not a valid Zip Code"
                              : ""}
                          </Typography>
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                  <div className="bene_address_half">
                    <FormControl className="bene_input_form">
                      <FormLabel>City</FormLabel>
                      <Input
                        placeholder="Enter city"
                        value={data.city}
                        disabled
                        readOnly
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="bene_address">
                  <div className="bene_address_half">
                    <FormControl className="bene_input_form">
                      <FormLabel>State</FormLabel>
                      <Input
                        placeholder="Enter state"
                        value={data.state}
                        disabled
                        readOnly
                      />
                    </FormControl>
                  </div>
                  <div className="bene_address_half">
                    <FormControl className="bene_input_form">
                      <FormLabel>Country</FormLabel>
                      <Input
                        placeholder="Enter country"
                        value={data.country}
                        disabled
                        readOnly
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <div className="owner_form_btn_main">
              <div className="owner_form_btn">
                {Id ? (
                  ""
                ) : (
                  <button onClick={reset} type="button">
                    Clear
                  </button>
                )}
              </div>
              <div className="owner_form_btn">
                {Id ? (
                  <button type="submit">Update</button>
                ) : (
                  <button type="submit">Create</button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
