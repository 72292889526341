import {
  faArrowTrendUp,
  faFileInvoiceDollar,
  faMoneyBillTrendUp,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";
import "../../css/why-I-chest.css";
import FooterPage from "./footer";

function Features() {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
  }, []);

  const line1 = "Life is about legacy,".split(" ");
  const line2 = "I-Chest.com helps preserve it !!!".split(" ");
  const first = "Life is about legacy, I-Chest.com helps preserve it !!!".split(
    " "
  );
  const [key, setKey] = useState(0);

  const LiabilitiesRef = useRef(null);
  const writingCenterSectionRef = useRef(null);
  const AssetsRef = useRef(null);
  const featuresRef = useRef(null);
  const IntegralRef = useRef(null);
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#Liabilities") {
      LiabilitiesRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (hash === "#writingCenterSection") {
      writingCenterSectionRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (hash === "#assets") {
      AssetsRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (hash === "#integral") {
      IntegralRef.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      featuresRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setKey((prevKey) => prevKey + 1);
    }, 3000);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <Navbar
        expand="lg"
        fixed="top"
        bg="dark"
        variant="dark"
        aria-label="Main navigation"
        className="navbar_base"
      >
        <Container>
          <Navbar.Brand href="#" className="navbar-brand logo-image">
            <img src="./logo192.webp" alt="alternative" />

            <div className="App_this">
              <div className="tab_largescreen_view">
                {first.map((el, i) => (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      duration: 1,
                      delay: i / 10,
                    }}
                    key={i + key * first.length}
                  >
                    {el}{" "}
                  </motion.span>
                ))}
              </div>
              <div className="tab_movile_view">
                {line1.map((el, i) => (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      duration: 1,
                      delay: i / 10,
                    }}
                    key={i + key * line1.length}
                  >
                    {el}{" "}
                  </motion.span>
                ))}
              </div>
              <div className="tab_movile_view">
                {line2.map((el, i) => (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      duration: 0.25,
                      delay: i / 10,
                    }}
                    key={i + key * line2.length}
                  >
                    {el}{" "}
                  </motion.span>
                ))}
              </div>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/">Home</Nav.Link>
              {/* <Nav.Link href="/services">Services</Nav.Link> */}
              <Nav.Link href="#features">Features</Nav.Link>
              {/* <Nav.Link href="/">Partners</Nav.Link> */}
              <Link className="partner" to="/home#aboutus">
                Partners
              </Link>

              <Nav.Link href="/WhyI-chest">Why I-chest</Nav.Link>
              <Nav.Link href="/contact-us">Contact Us</Nav.Link>
              {/* <button className="button">
                      <span className="button-content">Contact Us</span>
                    </button> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className={`your-component ${show ? "fade-in-element" : ""}`}>
        <div className="whyichest_base">
          <div className="fueatures_page" ref={featuresRef} id="#features">
            {" "}
            <div className="whyichest_base">
              <div className="whyichest_main">
                <div
                  className="whyichest_heading"
                  style={{ paddingTop: "60px" }}
                >
                  <h1 style={{ marginTop: "1rem" }}>Explore our Features</h1>
                </div>
                <div className="whyichest_paragraph">
                  <p>
                    I-Chest.com is a repository with a unique objective: to
                    resolve the challenges of asset management. The platform
                    will store comprehensive information on earned, unearned, or
                    inherited assets, accompanied by all relevant supporting
                    documents. It is designed to ensure that, in the case of an
                    emergency or unforeseen event, there is a clear plan for
                    asset distribution and management. Additionally, I-Chest.com
                    aims to extend its scope to include information about assets
                    held internationally, providing a complete picture of an
                    individual's or entity's wealth and property.
                  </p>
                </div>
                <br />

                <div className="whyichest_second_para">
                  <div className="whyichest_image">
                    <LazyLoadImage
                      className="whyichest_image_img"
                      src="/img/services.webp"
                      alt="secure_data_image "
                      effect="blur"
                    />
                  </div>
                  <div className="whyichest_content services_list">
                    <p>
                      <li>
                        Dynamic Assets, Liabilities, and Personal Information
                        Storage Platform.
                      </li>
                    </p>
                    <br />
                    <p>
                      <li>
                        Decentralize the information by sharing it with your
                        confidants and professionals.
                      </li>
                    </p>
                    <br />
                    <p>
                      <li>Plan of action in case of a mishap.</li>
                    </p>
                    <br />
                    <p>
                      <li>
                        Writing centre for diaries, autobiographies, books etc.
                      </li>
                    </p>
                    <br />
                    <p>
                      <li>Explore your net worth.</li>
                    </p>
                    <br />
                  </div>
                </div>
                <br />
                <div className="whyichest_last_para">
                  <p>
                    I-Chest.com is a unique repository platform designed to
                    address specific needs in asset inventory management. It
                    securely stores comprehensive information on earned,
                    unearned, and inherited assets, along with all relevant
                    supporting documents. The platform is built to ensure that,
                    in the event of an emergency or unforeseen circumstances,
                    there is a clear and actionable plan for asset distribution
                    and management. Additionally, I-Chest.com seeks to broaden
                    its scope by including information on internationally held
                    assets, offering a complete overview of an individual's or
                    entity's wealth and property.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="whyichest_main"
              ref={IntegralRef}
              id="integral"
              style={{ paddingTop: "60px" }}
            >
              <div className="whyichest_heading">
                <h1>Integral Features</h1>
              </div>
              <div className="whyichest_paragraph">
                <p>
                  Our platform offers robust Data Storage solutions, ensuring
                  secure and reliable access to your information. With
                  Customized Components, you can tailor the system to meet
                  specific needs. Inventory Drafting simplifies and organizes
                  your inventory processes, while Decentralization of
                  Information promotes seamless data sharing and collaboration
                  across teams. Our Writing Centre enhances content creation,
                  providing the tools you need for efficient and effective
                  communication.
                </p>
              </div>
              <br />

              <div className="features_section">
                <div className="features_section_main">
                  <div className="features_card">
                    <div className="features_card_header">
                      <div>
                        <p className="features_title">Data Storage</p>
                      </div>
                    </div>
                    <p className="features_description">
                      The platform is custom designed to securely store data on
                      various assets and liabilities, offering clear
                      differentiation bet ween titled and non-titled assets.
                      Leveraging the latest advanced cloud technology. <br />{" "}
                      <br /> I-Chest.com provides a robust tool to help users
                      prioritize and organize their financial information amid
                      the challenges of daily life.
                    </p>
                  </div>
                  <div className="features_card">
                    <div className="features_card_header">
                      <div>
                        <p className="features_title">Customized components</p>
                      </div>
                    </div>
                    <p className="features_description">
                      Customized components cater to various assets, including
                      Real Estate, Banks, Investments, Insurances, Vehicles,
                      Bitcoin, Passive Income, Business, Jewelry, Miscellaneous,
                      and International assets. <br />
                      <br /> These tailored solutions ensure that each asset
                      category is managed with precision and efficiency,
                      offering flexibility and control to meet the unique
                      demands of every asset type.
                    </p>
                  </div>
                  <div className="features_card">
                    <div className="features_card_header">
                      <div>
                        <p className="features_title">Inventory Drafting</p>
                      </div>
                    </div>
                    <p className="features_description">
                      I-Chest.com empowers users to comprehensively document and
                      manage their asset information, including ownership
                      details and beneficiaries. Users can draft a detailed
                      inventory that, once verified, is available in PDF format.{" "}
                      <br />
                      <br /> Attorneys, executors, and trustees can also
                      register on the platform to access the latest inventory
                      reports, ensuring all relevant parties have up-to-date
                      information.
                    </p>
                  </div>
                  <div className="features_card">
                    <div className="features_card_header">
                      <div>
                        <p className="features_title">
                          Decentralization of Information
                        </p>
                      </div>
                    </div>
                    <p className="features_description">
                      The platform allows users to share stored data with
                      trusted family members and professionals. Users can invite
                      attorneys, executors, and trustees to register, granting
                      them access to inventory details and supporting documents.
                      <br />
                      <br />
                      This approach decentralizes control and ensures
                      transparency.
                    </p>
                  </div>
                  <div className="features_card">
                    <div className="features_card_header">
                      <div>
                        <p className="features_title">Writing Centre</p>
                      </div>
                    </div>
                    <p className="features_description">
                      I-Chest.com also provides a dedicated writing space for
                      users to document autobiographies, plans, personal
                      diaries, and other important notes.
                      <br />
                      <br />
                      This feature offers a secure and private outlet within the
                      platform, allowing users to manage their personal
                      information in a safe and organized manner.
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="featurebackground"> */}
              <div
                className="key_Assets_main"
                ref={AssetsRef}
                id="assets"
                style={{ paddingTop: "60px" }}
              >
                <div className="Key_assets_base">
                  <div className="homepage_heding">
                    <h3>Assets</h3>
                  </div>
                  <div className="key_assets_left">
                    <div className="Assets_card">
                      <h4 className="Assetscard_Heading">Real Estate</h4>
                      <p className="Assetscard_paragraph">
                        "Add and manage all your real estate properties
                        conveniently in one place."
                      </p>
                    </div>
                    <div className="Assets_card">
                      <h4 className="Assetscard_Heading">
                        Miscellaneous Assets
                      </h4>
                      <p className="Assetscard_paragraph">
                        "Effortlessly add, organize, and manage all your
                        miscellaneous assets in one convenient platform."
                      </p>
                    </div>
                  </div>
                  <div className="key_assets_right">
                    <div className="Assets_card">
                      <h4 className="Assetscard_Heading">Bank</h4>
                      <p className="Assetscard_paragraph">
                        "Seamlessly track, organize, and manage your banking
                        assets all in one secure platform."
                      </p>
                    </div>
                    <div className="Assets_card">
                      <h4 className="Assetscard_Heading">Jewelry</h4>
                      <p className="Assetscard_paragraph">
                        "Effortlessly add multiple pieces, organize, and manage
                        your jewelry collection in one secure platform."
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <section
                ref={LiabilitiesRef}
                id="Liabilities"
                style={{ paddingTop: "60px" }}
              >
                <div className="key_Assets_main">
                  <div className="Key_assets_base">
                    <div className="homepage_heding">
                      <h3>Liabilities</h3>
                    </div>
                    <div className="key_assets_left">
                      <div className="Assets_card">
                        <h4 className="Assetscard_Heading">Bank loan</h4>
                        <p className="Assetscard_paragraph">
                          "Manage your bank loan efficiently here, keeping track
                          of payments, balances, and important details easily."
                        </p>
                      </div>
                    </div>
                    <div className="key_assets_right">
                      <div className="Assets_card">
                        <h4 className="Assetscard_Heading">Mortgages</h4>
                        <p className="Assetscard_paragraph">
                          "Manage your mortgages here, track payments, balances,
                          and details, all from one convenient platform."{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <div
                className="key_Assets_main"
                ref={writingCenterSectionRef}
                style={{ paddingTop: "60px" }}
                id="writingCenterSection"
              >
                <div className="Key_assets_base">
                  <div className="homepage_heding">
                    <h3>Writing Centre</h3>
                  </div>
                  <div className="key_assets_left">
                    <div className="Assets_card">
                      <h4 className="Assetscard_Heading">
                        Create Your Personalized Diary
                      </h4>
                      <p className="Assetscard_paragraph">
                        "Create your personal diary, effortlessly record
                        thoughts, and manage your entries all in one place."
                      </p>
                    </div>
                  </div>
                  <div className="key_assets_right">
                    <div className="Assets_card">
                      <h4 className="Assetscard_Heading">
                        Compose Your Own Book
                      </h4>
                      <p className="Assetscard_paragraph">
                        "Make your own book By Sharing details about yourself,
                        including your interests, experiences, and achievements,
                        all in one profile."
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-1">
                <div class="container">
                  <div class="row">
                    <div
                      class="col-xl-12 homepage_heding"
                      style={{ marginTop: "20px" }}
                    >
                      <h2>Key Objectives</h2>
                      <p class="p-heading"></p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-5">
                      <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                          <div class="accordion-icon">
                            <span class="fas fa-tv">
                              {" "}
                              <FontAwesomeIcon icon={faFileInvoiceDollar} />
                            </span>
                          </div>
                          <div class="accordion-header" id="headingOne">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Inventory Report Creation
                            </button>
                          </div>
                          <div
                            id="collapseOne"
                            class="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              Generate detailed reports of earned or inherited
                              assets, including supporting documents.
                            </div>
                          </div>
                        </div>

                        <div class="accordion-item">
                          <div class="accordion-icon blue">
                            <span class="fas fa-microphone">
                              {" "}
                              <FontAwesomeIcon icon={faUserTie} />
                            </span>
                          </div>
                          <div class="accordion-header" id="headingTwo">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Real-Time Sharing
                            </button>
                          </div>
                          <div
                            id="collapseTwo"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              Enable selective sharing of asset information with
                              designated parties in real time.
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <div class="accordion-icon purple">
                            <span class="fas fa-video">
                              <FontAwesomeIcon icon={faArrowTrendUp} />
                            </span>
                          </div>
                          <div class="accordion-header" id="headingThree">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Mishap Preparedness
                            </button>
                          </div>
                          <div
                            id="collapseThree"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              Ensure there is a plan in place for asset
                              management in the event of a mishap or accident.
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <div class="accordion-icon orange">
                            <span class="fas fa-tools">
                              <FontAwesomeIcon icon={faMoneyBillTrendUp} />
                            </span>
                          </div>
                          <div class="accordion-header" id="headingFour">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Global Asset Inclusion
                            </button>
                          </div>
                          <div
                            id="collapseFour"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              Include information about assets beyond local
                              borders, offering a roadmap for international
                              asset management.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="display_image">
                      <div class="image-container">
                        <img
                          class="img-fluid"
                          src="./logo192.webp"
                          alt="alternative"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        <FooterPage />
      </div>
    </>
  );
}
export default Features;
