import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export function ConfirmationPopup({
  handleConfirmation,
  anyText,
  handleConfirmationClose,
  Id,
  userName,
  roleText,
}) {
  const [modal, setModal] = useState(true);

  const toggle = () => {
    setModal(!modal);
  };
  const handleConfirmationPopupClose = () => {
    toggle();
    handleConfirmationClose();
  };
  const handleConfirmationPopup = () => {
    const params = {
      Id,
      roleText,
      userName,
    };
    toggle();
    handleConfirmation(params);
  };
  return (
    <>
      <div>
        <Modal isOpen={modal} toggle={toggle} style={{ paddingTop: "50px" }}>
          <ModalHeader style={{ borderBottom: "none" }}>
            <span style={{ color: "red", fontWeight: "500" }}>Warning !!!</span>
          </ModalHeader>
          <ModalBody
            style={{ color: "black", fontSize: "18px", padding: "0 16px" }}
          >
            {anyText}
          </ModalBody>
          <ModalFooter style={{ borderTop: "none" }}>
            <Button
              className="confirm_delete_btn"
              onClick={handleConfirmationPopup}
            >
              Yes
            </Button>{" "}
            <Button
              className="confirm_cancel_btn"
              onClick={handleConfirmationPopupClose}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}
