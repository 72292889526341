import {
  faDownload,
  faMinus,
  faPlus,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Option, Select, Textarea } from "@mui/joy";
import { Tooltip } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { Button, Form } from "reactstrap";
import { formatAmount } from "../../components/Common";
import SideBar from "../../components/sidebar/Sidebar";
import UserBase from "../../components/user/UserBase";
import "../../css/formPOPup.css";
import "../../css/myestare.css";
import {
  cryptoAssests,
  deleteCryptoAssest,
  getCryptoAssests,
} from "../../services/CryptoService";
import {
  deleteSingleProperty,
  downloadDocument1,
  fetchNetWorth,
  getBeneficiary,
  getOwnerByUserId,
  getSecondaryUser,
  getToken,
  getUser,
} from "../../services/user-service";
import { ConfirmationPopup } from "./ConfirmationPopup";
import Deletebutton from "./Deletebutton";
import UpdateButton from "./UpdateButton";

const initialCryptoData = {
  cryptoAssest: {
    cryptoListName: "",
    owner: "",
    heading: "",
    coin: "Bitcoin",
    storageType: "",
    exchangeOrWallet: "",
    otherExchangeOrWallet: "",
    quantity: "",
    notes: "",
    estValue: "",
  },
  sharedDetails: [
    {
      distributedType: "",
      distributedValue: "",
      distributedAmount: "",
      beneficiaryId: "",
    },
  ],
};

function Crypto() {
  const [cryptoAssetData, setCryptoAssetData] = useState([initialCryptoData]);
  const [files, setFiles] = useState([]);

  const [modal, setModal] = useState(false);
  const [cryptoListNameValue, setCryptoListNameValue] = useState("");
  const handleChangeAddCryptoListName = (event) => {
    const value = event.target.value;
    setCryptoListNameValue(value);
  };
  const handleChangeConfirm = () => {
    if (cryptoListNameValue !== "") {
      setCryptoAssetData((prevCryptoAssetData) => {
        const updatedCryptoAsset = [...prevCryptoAssetData];

        updatedCryptoAsset[0].cryptoAssest.cryptoListName = cryptoListNameValue;
        return updatedCryptoAsset;
      });
      setForm1(!form1);
    }
    toggleCryptoList();
  };
  const toggleCryptoList = () => {
    setModal(false);
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);
  const handleChange = (
    event,
    newValue,
    field,
    index,
    type = "cryptoAssest"
  ) => {
    if (
      field === "coin" ||
      field === "storageType" ||
      field === "exchangeOrWallet"
    ) {
      setCryptoAssetData((prevCryptoAssetData) =>
        prevCryptoAssetData.map((item, i) =>
          i === index
            ? {
                ...item,
                [type]: {
                  ...item[type],
                  [field]: newValue,
                  otherExchangeOrWallet:
                    field === "otherExchangeOrWallet" && newValue !== "Other"
                      ? ""
                      : "",
                  exchangeOrWallet: field === "storageType" ? "" : newValue,
                },
              }
            : item
        )
      );
    }

    if (field === "owner") {
      const ownerString = Array.isArray(newValue)
        ? newValue.join(", ")
        : newValue;

      setCryptoAssetData((prevCryptoAssetData) =>
        prevCryptoAssetData.map((item, i) =>
          i === index
            ? {
                ...item,
                [type]: {
                  ...item[type],
                  owner: ownerString,
                },
              }
            : item
        )
      );

      setOwnerName((prevOwnerNames) => {
        const updatedOwnerNames = [...prevOwnerNames];
        updatedOwnerNames[index] = newValue;
        return updatedOwnerNames;
      });
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  const handleChanges = (e, field, index, type = "cryptoAssest") => {
    const { value } = e.target;

    setCryptoAssetData((prevCryptoAssetData) =>
      prevCryptoAssetData.map((crypto, i) => {
        if (field === "cryptoListName" && prevCryptoAssetData.length > 0) {
          return {
            ...crypto,
            [type]: {
              ...crypto[type],
              cryptoListName: value,
            },
          };
        }

        if (field === "quantity" && i === index) {
          return {
            ...crypto,
            [type]: {
              ...crypto[type],
              quantity: value,
              estValue: value * bitcoinPrice,
            },
          };
        }
        return i === index
          ? {
              ...crypto,
              [type]: {
                ...crypto[type],
                [field]: value,
              },
            }
          : crypto;
      })
    );
  };

  // Handle image
  const handleImageChange = (event, indexValue) => {
    const index = indexValue;
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        // Update the files state for the current index
        setFiles((prevFiles) => ({
          ...prevFiles,
          [index]: selectedFilesArray, // Store files for the current index
        }));
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    } else {
      // If no files selected, clear the files for the current index
      setFiles((prevFiles) => ({
        ...prevFiles,
        [index]: [], // Clear files for the current index
      }));
    }
  };

  const resetForm = () => {
    setFiles([]);
    setOwnerName([]);
    setCryptoAssetData([initialCryptoData]);
    setCryptoListNameValue("");
  };

  // Set the form
  const cryptoForm = (event) => {
    event.preventDefault();

    let token = "Bearer " + getToken();
    //create form data to send a file and remaining class data
    const formData = new FormData();

    formData.append("data", JSON.stringify(cryptoAssetData));
    Object.keys(files).forEach((index) => {
      files[index].forEach((file) => {
        formData.append(`files${index}`, file);
      });
    });

    cryptoAssests(formData, token)
      .then((resp) => {
        AddCard();
        toast.success("Data Added !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        fetchNetWorth(primaryUserDetails.commonId);
        toggle(null, null, "close");
      })
      .catch((error) => {});
  };

  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [currentIdType, setCurrentIdType] = useState("");
  const removeCrypto = (id, idType) => {
    setShowConfirmationDelete(true);
    setCurrentId(id);
    setCurrentIdType(idType);
  };

  const handleConfirmationDeleteClose = () => {
    setShowConfirmationDelete(false);
  };

  // Code by Purnendu
  const handleRemove = (params) => {
    setShowConfirmationDelete(false);
    const { Id, idType } = params;
    if (idType === "cryptoId") {
      deleteCryptoAssest(Id)
        .then((res) => {
          toast.success("Deleted successfully...", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          fetchNetWorth(primaryUserDetails.commonId);
          AddCard();
          setShow1(false);
        })
        .catch((error) => {});
    } else {
      deleteSingleProperty(Id)
        .then((res) => {
          setBeneficiaryVisible(!beneficiaryVisible);
          setShow(false);
          AddCard();
          toast.success("Deleted successfully...", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          fetchNetWorth(primaryUserDetails.commonId);
        })
        .catch((error) => {});
    }
  };

  const handleDownload = (id, fileName) => {
    let myarry = fileName.split(".");
    downloadDocument1(id)
      .then((response) => {
        const downloadUrl = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `${myarry[0]}.${myarry[1]}`;
        link.click();
        URL.revokeObjectURL(downloadUrl);
      })
      .catch((error) => {});
  };

  const [bitcoinPrice, setBitcoinPrice] = useState(null);

  const getBitcoinPrice = () => {
    axios
      .get("https://api.coindesk.com/v1/bpi/currentprice/BTC.json")
      .then((res) => {
        const price = res.data.bpi.USD.rate_float;
        setBitcoinPrice(price);
      })
      .catch((error) => {
        console.error("Error fetching Bitcoin price:", error);
      });
  };

  // show note popup
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedNote, setSelectedNote] = useState("");
  const handleOpenPopup = (note) => {
    setSelectedNote(note);
    setPopupVisible(true);
  };

  // for multiple download
  const [popupVisibleDownlaod, setPopupVisibleDownlaod] = useState(false);
  const [selectedDownlaod, setSelectDownload] = useState("");

  const handleShowDownlaod = (showDetail) => {
    setPopupVisibleDownlaod(true);
    setSelectDownload(showDetail);
  };

  // page opening  animation
  const [show, setShow] = useState(false);

  //  form show button
  let [form1, setForm1] = useState(false);

  const [existingCryptoListName, setExistingCryptoListName] = useState(null);
  const toggle = (event, value, closePopup) => {
    setExistingCryptoListName(value);
    resetForm();
    if (closePopup) {
      setForm1(false);
      setCryptoListNameValue("");
      return;
    }
    if (value) {
      setForm1(true);
    } else {
      if (cryptoListNameValue !== "") {
        setForm1(false);
      } else {
        setModal(true);
      }
    }
  };

  useEffect(() => {
    if (existingCryptoListName) {
      setCryptoAssetData((prevCryptoAssetData) =>
        prevCryptoAssetData.map((item) => ({
          ...item,
          cryptoAssest: {
            ...item.cryptoAssest,
            cryptoListName: existingCryptoListName, // Set the new jewelryListName
          },
        }))
      );
    }
  }, [existingCryptoListName]);

  // cards
  let [card, setCard] = useState([]); // card = [ {} , {} , {}] - include the form data going to use it for card
  let [showDetail, setShowDetail] = useState([]); // this is to display the card details
  let [show1, setShow1] = useState(false);

  // card creating
  const AddCard = () => {
    let userId = getUser().commonId;
    let token = "Bearer " + getToken(); // Added 'Bearer'
    getCryptoAssests(token, userId)
      .then((res) => {
        setCard(res);
      })
      .catch((error) => {
        setCard([]);
      });
  };

  // showing the details of cards like popup
  const Showdetails = (obj) => {
    // const arrayFromObject = Object.keys(obj).map(key => obj[key]);
    setShowDetail(obj);
    setShow1(true);
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };

  // for add field pop
  let [showAdditionField, SetshowAdditionField] = useState(false);

  //
  //
  const [benevisible, setbeneVisible] = useState(false);
  const [distributionType, setDistributionType] = useState("");
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [beneficiaryVisible, setBeneficiaryVisible] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");
  const [currentBeneficiaryIndex, setCurrentBeneficiaryIndex] = useState(null);

  const handleShowBeneficiary = (event, indexValue) => {
    setCurrentBeneficiaryIndex(indexValue);
    setShow1(false);
    setbeneVisible(true);
  };

  const handleReset = () => {
    setbeneVisible(false);
    setDistributionType("");
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
  };

  const handleDistributionTypeChange = (event) => {
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributionType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", value: "" },
      });
    }
  };

  const handleBeneficiaryClose = (beneficiary) => {
    const updatedBeneficiaries = selectedBeneficiaries.filter(
      (b) => b !== beneficiary
    );
    setSelectedBeneficiaries(updatedBeneficiaries);
    const updatedDetails = { ...beneficiaryDetails };
    delete updatedDetails[beneficiary];
    setBeneficiaryDetails(updatedDetails);
  };

  const getBenificiaryName = (id) => {
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }
    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found";
    }
  };

  const handleFieldChange = (beneficiary, field, value) => {
    const estimatedValue = parseFloat(
      cryptoAssetData[currentBeneficiaryIndex]?.cryptoAssest?.estValue
    );

    if (!estimatedValue || estimatedValue === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }

    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }

    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.value);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });

    if (totalPercentage > 100 || totalValue > estimatedValue) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributionType,
    balance,
    beneficiaryDetail
  ) => {
    if (distributionType === "percentage") {
      return (
        (parseFloat(balance) * parseFloat(beneficiaryDetail?.percentage || 0)) /
        100
      ).toFixed(2);
    } else if (distributionType === "dollar") {
      return parseFloat(beneficiaryDetail?.value || 0).toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    const cryptoIndex = currentBeneficiaryIndex;
    console.log("index: ", cryptoIndex);

    if (cryptoIndex === null || !cryptoAssetData[cryptoIndex]) {
      console.error("Invalid jewelry index");
      return;
    }

    let i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.value);

      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          cryptoAssetData[cryptoIndex]?.cryptoAssest?.estValue,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i,
          cryptoIndex
        );
        i += 1;
      }

      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          cryptoAssetData[cryptoIndex]?.cryptoAssest?.estValue,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i,
          cryptoIndex
        );
        i += 1;
      }
    });
    handleReset();
  };

  const handleChanges2 = (t, v, a, b, i, cryptoIndex) => {
    // Ensure cryptoAssetData and index are valid
    if (
      !Array.isArray(cryptoAssetData) ||
      cryptoAssetData[cryptoIndex] === undefined
    ) {
      console.error("Invalid cryptoAssetData array or index");
      return;
    }

    // Check if the index i is within the bounds of the array
    if (i >= 0) {
      // Create a new object to update the specific element at index i
      const updatedElement = {
        distributedType: t,
        distributedValue: v,
        distributedAmount: a,
        beneficiaryId: b,
      };

      // Use the functional form of setState to ensure the current state is used
      setCryptoAssetData((prevCryptoAssetData) => {
        const updatedSharedDetails = [
          ...prevCryptoAssetData[cryptoIndex].sharedDetails,
        ];
        updatedSharedDetails[i] = updatedElement;

        const updatedJewelries = [...prevCryptoAssetData];
        updatedJewelries[cryptoIndex] = {
          ...updatedJewelries[cryptoIndex],
          sharedDetails: updatedSharedDetails,
        };

        return updatedJewelries;
      });
    } else {
      console.error("Index out of bounds");
    }
  };

  const handleOpenBeneficiary = (showDetail) => {
    setSelectedBeneficiary(showDetail);
    setBeneficiaryVisible(true);
  };

  useEffect(() => {
    AddCard();
    getBitcoinPrice();
    getBenificiarydata();
    setShow(true);
  }, []);

  const addCryptoRow = () => {
    setCryptoAssetData((prevCryptoAssetData) => [
      ...prevCryptoAssetData,
      {
        ...initialCryptoData,
        cryptoAssest: {
          ...initialCryptoData.cryptoAssest,
          cryptoListName:
            prevCryptoAssetData[0]?.cryptoAssest.cryptoListName || "",
        },
      },
    ]);
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const removeCryptoRow = (index) => {
    setShowConfirmation(true);
    setCurrentIndex(index);
  };

  const handleConfirmation = () => {
    setShowConfirmation(false);
    const index = currentIndex;
    setCryptoAssetData(cryptoAssetData.filter((_, i) => i !== index));

    setFiles((prevFiles) => {
      const updatedFiles = Object.keys(prevFiles).reduce((acc, key) => {
        const numericKey = parseInt(key, 10);
        if (numericKey !== index) {
          const newIndex = numericKey > index ? numericKey - 1 : numericKey;
          acc[newIndex] = prevFiles[key];
        }
        return acc;
      }, {});

      return updatedFiles;
    });

    // Update ownerName by shifting indices after removal
    setOwnerName((prevOwnerNames) => {
      const updatedOwnerNames = prevOwnerNames.filter((_, i) => i !== index);
      return updatedOwnerNames;
    });
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  // Group and consolidate data
  const consolidated = card.reduce((acc, item) => {
    const listName = item.cryptoAssest.cryptoListName;

    if (!acc[listName]) {
      acc[listName] = {
        cryptoListName: listName,
        data: [],
      };
    }

    acc[listName].data.push({
      cryptoAssest: item.cryptoAssest,
      documents: item.documents,
      sharedDetails: item.sharedDetails,
    });

    return acc;
  }, {});

  // Convert consolidated object to an array
  const uniqueCryptoListResult = Object.values(consolidated);

  return (
    <div className={`your-component ${show ? "fade-in-element" : ""}`}>
      <UserBase>
        <div className="mt-5"></div>
        <SideBar>
          {primaryUserDetails.accountType === "secondary" &&
          secondaryUserDetails.accountType === "primary" &&
          secondaryUserDetails.isSecondaryUserEditable === "false" ? (
            ""
          ) : (
            <div className="addme">
              <div className="addme_inner">
                <button onClick={() => toggle()}>Add New Bitcoin</button>
              </div>
            </div>
          )}

          <div className="propCard">
            <div className="propCard-card">
              {uniqueCryptoListResult.map((item, index) => (
                <div
                  className="propCard-card-body"
                  style={{ padding: "20px 20px 10px 20px" }}
                >
                  <div className="propCard_card_status">
                    <p
                      className={`propCard_card_status_text ${
                        item.data.every(
                          (dataItem) => dataItem.sharedDetails.length > 0
                        )
                          ? "completed"
                          : "incomplete"
                      }`}
                    >
                      {item.data.every(
                        (dataItem) => dataItem.sharedDetails.length > 0
                      )
                        ? "completed"
                        : "incomplete"}
                    </p>
                  </div>
                  <h5 className="propCard-card-title">{item.cryptoListName}</h5>
                  <div className="propCard-btn">
                    <p className="viewDetails">
                      <button
                        className="jewelry_add_more"
                        onClick={(event) => toggle(event, item.cryptoListName)}
                      >
                        Add More
                      </button>
                      <button
                        style={{ margin: "10px 0" }}
                        onClick={() => Showdetails(item.data)}
                      >
                        View Details
                      </button>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {form1 && (
            <div
              className="overlay1"
              style={{ transition: "500ms", height: "" }}
            >
              <div>
                <Form onSubmit={cryptoForm} className="addform_body">
                  <container>
                    <div className="homepage jewelry_container">
                      <Card>
                        <CardHeader>
                          <div
                            style={{ width: "100%" }}
                            className="addform_heading"
                          >
                            <h3 className="addform_heading_h1">
                              {cryptoListNameValue && cryptoListNameValue}
                              {existingCryptoListName && existingCryptoListName}
                            </h3>
                            <div
                              className="addform_close"
                              onClick={(event) => toggle(event, "", "close")}
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody className="jewelry_card_body">
                          <div>
                            <p className="crypto_note">
                              NOTE: DO NOT STORE YOUR SEED PHRASES ON ANY
                              ELECTRONIC PLATFORM, INCLUDING I
                              <span style={{ color: "green" }}>-</span>
                              CHEST.COM, GOOGLE DOC ETC.
                            </p>
                            <table className="jewelry_table">
                              <thead>
                                <tr>
                                
                                  <th className="crypto_132px">
                                    Select Owner*
                                  </th>
                                  <th className="crypto_115px">Heading</th>
                                  <th className="crypto_115px">Select Coin*</th>
                                  <th className="crypto_115px">
                                    Select Exchange/Wallet*
                                  </th>
                                  <th className="crypto_100px">Quantity*</th>
                                  <th className="crypto_100px">Value*</th>

                                  <th className="crypto_115px">Documents</th>
                                  <th>Beneficiary*</th>
                                  <th>Notes</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {cryptoAssetData.map((row, index) => (
                                  <tr key={index}>
                                    <td>
                                      <Select
                                        className="crypto_select crypto_132px"
                                        placeholder="Owner"
                                        value={ownerName[index] || []}
                                        multiple
                                        onChange={(event, newValue) =>
                                          handleChange(
                                            event,
                                            newValue,
                                            "owner",
                                            index
                                          )
                                        }
                                        required
                                      >
                                        {ownerNames.map((name) => (
                                          <Option key={name} value={name}>
                                            {name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </td>

                                    <td>
                                      <Input
                                        className="crypto_input crypto_115px"
                                        placeholder="Heading"
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "heading",
                                            index,
                                            "cryptoAssest"
                                          )
                                        }
                                        value={
                                          cryptoAssetData[index].cryptoAssest
                                            .heading
                                        }
                                      />
                                    </td>

                                    <td>
                                      <Select
                                        className="crypto_select crypto_115px"
                                        value={
                                          cryptoAssetData[index]?.cryptoAssest
                                            .coin
                                        }
                                        onChange={(event, newValue) =>
                                          handleChange(
                                            event,
                                            newValue,
                                            "coin",
                                            index
                                          )
                                        }
                                        required
                                      >
                                        <Option value="">Select coin</Option>
                                        <Option value="Bitcoin">Bitcoin</Option>
                                      </Select>
                                    </td>

                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          gap: "2px",
                                        }}
                                      >
                                        <Select
                                          className="crypto_select crypto_115px"
                                          value={
                                            cryptoAssetData[index].cryptoAssest
                                              .storageType
                                          }
                                          onChange={(event, newValue) =>
                                            handleChange(
                                              event,
                                              newValue,
                                              "storageType",
                                              index
                                            )
                                          }
                                          required
                                        >
                                          <Option value="">Select one</Option>
                                          <Option value="Exchange">
                                            Exchange
                                          </Option>
                                          <Option value="Wallet">Wallet</Option>
                                        </Select>

                                        {cryptoAssetData[index].cryptoAssest
                                          .storageType === "" ||
                                        cryptoAssetData[index].cryptoAssest
                                          .storageType === "Exchange" ? (
                                          <>
                                            <Select
                                              className="crypto_115px"
                                              value={
                                                cryptoAssetData[index]
                                                  .cryptoAssest.exchangeOrWallet
                                              }
                                              onChange={(event, newValue) =>
                                                handleChange(
                                                  event,
                                                  newValue,
                                                  "exchangeOrWallet",
                                                  index
                                                )
                                              }
                                              required
                                            >
                                              <Option value="">
                                                Select exchange
                                              </Option>
                                              <Option value="Coinbase">
                                                Coinbase
                                              </Option>
                                              <Option value="Binance.US">
                                                Binance.US
                                              </Option>
                                              <Option value="Kraken">
                                                Kraken
                                              </Option>
                                              <Option value="Gemini">
                                                Gemini
                                              </Option>
                                              <Option value="Bitfinex">
                                                Bitfinex
                                              </Option>
                                              <Option value="Bitstamp">
                                                Bitstamp
                                              </Option>
                                              <Option value="Huobi US">
                                                Huobi US
                                              </Option>
                                              <Option value="Crypto.com">
                                                Crypto.com
                                              </Option>
                                              <Option value="BitFlyer">
                                                BitFlyer
                                              </Option>
                                              <Option value="OKCoin">
                                                OKCoin
                                              </Option>
                                              <Option value="Other">
                                                Other
                                              </Option>
                                            </Select>
                                            {cryptoAssetData[index].cryptoAssest
                                              .exchangeOrWallet === "Other" && (
                                              <Input
                                                className="crypto_input crypto_115px"
                                                placeholder="Other Exchange"
                                                onChange={(e) =>
                                                  handleChanges(
                                                    e,
                                                    "otherExchangeOrWallet",
                                                    index,
                                                    "cryptoAssest"
                                                  )
                                                }
                                                value={
                                                  cryptoAssetData[index]
                                                    .cryptoAssest
                                                    .otherExchangeOrWallet
                                                }
                                                required
                                              />
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <Select
                                              className="crypto_115px"
                                              value={
                                                cryptoAssetData[index]
                                                  .cryptoAssest.exchangeOrWallet
                                              }
                                              onChange={(event, newValue) =>
                                                handleChange(
                                                  event,
                                                  newValue,
                                                  "exchangeOrWallet",
                                                  index
                                                )
                                              }
                                              required
                                            >
                                              <Option value="">
                                                Select wallet
                                              </Option>
                                              <Option value="Ledger Nano S">
                                                Ledger Nano S
                                              </Option>
                                              <Option value="Trezor Model T">
                                                Trezor Model T
                                              </Option>
                                              <Option value="Exodus">
                                                Exodus
                                              </Option>
                                              <Option value="Atomic Wallet">
                                                Atomic Wallet
                                              </Option>
                                              <Option value="Trust Wallet">
                                                Trust Wallet
                                              </Option>
                                              <Option value="MyEtherWallet">
                                                MyEtherWallet
                                              </Option>
                                              <Option value="Coinomi">
                                                Coinomi
                                              </Option>
                                              <Option value="MetaMask">
                                                MetaMask
                                              </Option>
                                              <Option value="Freewallet">
                                                Freewallet
                                              </Option>
                                              <Option value="KeepKey">
                                                KeepKey
                                              </Option>
                                              <Option value="Other">
                                                Other
                                              </Option>
                                            </Select>
                                            {cryptoAssetData[index].cryptoAssest
                                              .exchangeOrWallet === "Other" && (
                                              <Input
                                                className="crypto_input crypto_115px"
                                                placeholder="Other Wallet"
                                                onChange={(e) =>
                                                  handleChanges(
                                                    e,
                                                    "otherExchangeOrWallet",
                                                    index,
                                                    "cryptoAssest"
                                                  )
                                                }
                                                value={
                                                  cryptoAssetData[index]
                                                    .cryptoAssest
                                                    .otherExchangeOrWallet
                                                }
                                                required
                                              />
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </td>

                                    <td>
                                      <Input
                                        className="crypto_input crypto_100px"
                                        type="number"
                                        placeholder="Quantity"
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "quantity",
                                            index,
                                            "cryptoAssest"
                                          )
                                        }
                                        value={
                                          cryptoAssetData[index].cryptoAssest
                                            .quantity
                                        }
                                        required
                                      />
                                    </td>

                                    <td>
                                      <Input
                                        className="crypto_input crypto_100px"
                                        placeholder="Est Value"
                                        type="number"
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "estValue",
                                            index,
                                            "cryptoAssest"
                                          )
                                        }
                                        value={
                                          cryptoAssetData[index].cryptoAssest
                                            .estValue
                                        }
                                        startDecorator={<div>$</div>}
                                        disabled
                                        required
                                      />
                                    </td>

                                    <td>
                                      <div className="jewelry_choose_files crypto_115px">
                                        <div>
                                          <label
                                            className="otherAsset_choose_file_label"
                                            htmlFor={`files${index}`}
                                          >
                                            Choose Files
                                          </label>
                                        </div>
                                        {files[index] && (
                                          <div>
                                            <label className="jewelry_choose_file_file">
                                              {files[index]?.length === 1
                                                ? `${
                                                    files[index][0].name
                                                      .length > 15
                                                      ? files[
                                                          index
                                                        ][0].name.substring(
                                                          0,
                                                          5
                                                        ) +
                                                        "..." +
                                                        files[
                                                          index
                                                        ][0].name.slice(-7)
                                                      : files[index][0].name
                                                  }`
                                                : `${files[index]?.length} files`}
                                            </label>
                                          </div>
                                        )}
                                        <input
                                          style={{ display: "none" }}
                                          id={`files${index}`}
                                          type="file"
                                          name="myfile"
                                          onChange={(e) =>
                                            handleImageChange(e, index)
                                          }
                                          accept=".pdf"
                                          multiple
                                        />
                                      </div>
                                    </td>

                                    <td>
                                      <div
                                        className="bene-select beneficiary_btn"
                                        onClick={(e) =>
                                          handleShowBeneficiary(e, index)
                                        }
                                      >
                                        Beneficiary
                                      </div>
                                    </td>

                                    <td>
                                      <Textarea
                                        style={{ fontSize: "14px" }}
                                        placeholder="Notes"
                                        id="notes"
                                        value={
                                          cryptoAssetData[index].cryptoAssest
                                            .notes
                                        }
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "notes",
                                            index,
                                            "cryptoAssest"
                                          )
                                        }
                                      />
                                    </td>

                                    <td>
                                      <div className="jewelry_action">
                                        {cryptoAssetData.length > 1 && (
                                          <Button
                                            className="jewelry_remove_button"
                                            onClick={() =>
                                              removeCryptoRow(index)
                                            }
                                          >
                                            <FontAwesomeIcon icon={faMinus} />
                                          </Button>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div style={{ marginTop: "8px" }}>
                              <Button
                                className="jewelry_add_more_button"
                                type="button"
                                onClick={addCryptoRow}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </Button>
                            </div>
                            <div className="crypto_recommendations">
                              <div className="crypto_recommendation_base">
                                <input
                                  type="checkbox"
                                  className="crypto_check"
                                  required
                                />
                                <p>
                                  <span style={{ fontWeight: "bold" }}>
                                    Secure your Bitcoin
                                  </span>{" "}
                                  with a hardware wallet or paper wallet.
                                </p>
                              </div>
                              <div className="crypto_recommendation_base">
                                <input
                                  type="checkbox"
                                  className="crypto_check"
                                  required
                                />
                                <p>
                                  <span style={{ fontWeight: "bold" }}>
                                    Create a detailed plan
                                  </span>{" "}
                                  including a will or trust t hat outlines how
                                  your Bitcoin should be transferred.
                                </p>
                              </div>
                              <div className="crypto_recommendation_base">
                                <input
                                  type="checkbox"
                                  className="crypto_check"
                                  required
                                />
                                <p>
                                  <span style={{ fontWeight: "bold" }}>
                                    Provide clear instructions
                                  </span>{" "}
                                  on accessing Bitcoin and re your heir
                                  understands them.
                                </p>
                              </div>
                              <div className="crypto_recommendation_base">
                                <input
                                  type="checkbox"
                                  className="crypto_check"
                                  required
                                />
                                <p>
                                  <span style={{ fontWeight: "bold" }}>
                                    Consult professionals
                                  </span>{" "}
                                  for legal and tax advice
                                </p>
                              </div>
                              <div className="crypto_recommendation_base">
                                <p>
                                  <span style={{ fontWeight: "bold" }}>
                                    Third Party applications like UnChained.com
                                    are suitable for safe transfer to heirs
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                        <CardFooter>
                          <div className="text-center">
                            <Button
                              className="my-estate-clear-btn"
                              onClick={resetForm}
                              type="reset"
                              outline
                            >
                              Clear
                            </Button>
                            <Button
                              outline
                              type=""
                              className="my-estate-add-btn"
                            >
                              Add
                            </Button>
                          </div>
                        </CardFooter>
                      </Card>
                    </div>
                  </container>
                </Form>
              </div>
            </div>
          )}

          {modal && (
            <div>
              <Modal
                show={modal}
                toggle={toggleCryptoList}
                style={{ paddingTop: "50px" }}
              >
                <ModalBody style={{ color: "black" }}>
                  Please create your bitcoin catalog here:
                  <div>
                    <Input
                      type="text"
                      value={cryptoListNameValue}
                      placeholder="Enter your bitcoin catalog name"
                      onChange={handleChangeAddCryptoListName}
                    />
                  </div>
                </ModalBody>
                <ModalFooter style={{ padding: "5px" }}>
                  <Button
                    className="confirm_delete_btn"
                    onClick={handleChangeConfirm}
                  >
                    Add
                  </Button>{" "}
                  <Button
                    className="confirm_cancel_btn"
                    onClick={toggleCryptoList}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          )}

          {show1 && (
            <>
              <div className="popup">
                <div className="popup-content popup-content-download">
                  <div className="note_popup">
                    <div className="note_popup_heading">
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <h2>{`${showDetail[0]?.cryptoAssest?.cryptoListName} Details`}</h2>
                      </div>
                      <div>
                        <button
                          className="note_popup_heading_close_btn"
                          onClick={() => {
                            setShow1(false);
                          }}
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </button>
                      </div>
                    </div>
                    <div>
                      {showDetail.map((details, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "5px",
                            padding: "10px",
                            border: "solid 1px lightgray",
                            borderRadius: "5px",
                            minWidth: "230px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* Left side */}
                            <div>
                              <p
                                style={{
                                  fontSize: "17px",
                                  color: "black",
                                  fontWeight: "500",
                                }}
                              >
                                {`Item - ${index + 1}`}
                                {"  "}
                                <span
                                  className={`propCard_card_status_text ${
                                    details.sharedDetails.length > 0
                                      ? "completed"
                                      : "incomplete"
                                  }`}
                                >
                                  {details.sharedDetails.length > 0
                                    ? "completed"
                                    : "incomplete"}
                                </span>
                              </p>
                            </div>
                            {/* Right side */}
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div className="me-2">
                                {details.documents &&
                                  details.documents.length > 0 && (
                                    <Tooltip title="click to see multiple download files">
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          handleShowDownlaod(details);
                                          setShow1(false);
                                        }}
                                      >
                                        <div className="myestate_download_button dwnbtn">
                                          <FontAwesomeIcon
                                            className="myestate_download_icon"
                                            icon={faDownload}
                                          />
                                          <span></span>
                                        </div>
                                      </div>
                                    </Tooltip>
                                  )}
                              </div>
                              {primaryUserDetails.accountType === "secondary" &&
                              secondaryUserDetails.accountType === "primary" &&
                              secondaryUserDetails.isSecondaryUserEditable ===
                                "false" ? (
                                ""
                              ) : (
                                <div className="me-2">
                                  <Tooltip title="Click Here To Edit">
                                    <div>
                                      <UpdateButton
                                        URL={"../my-estate/crypto/"}
                                        id={details.cryptoAssest.id}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              )}

                              {primaryUserDetails.accountType === "secondary" &&
                              secondaryUserDetails.accountType === "primary" &&
                              secondaryUserDetails.isSecondaryUserEditable ===
                                "false" ? (
                                ""
                              ) : (
                                <FontAwesomeIcon
                                  onClick={() =>
                                    removeCrypto(
                                      details.cryptoAssest.id,
                                      "cryptoId"
                                    )
                                  }
                                  className="delete-button-comp"
                                  style={{ cursor: "pointer" }}
                                  icon={faTrash}
                                />
                              )}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",

                              flexWrap: "wrap",
                            }}
                            className="mt-2"
                          >
                            {/* Left section */}
                            <div style={{ flex: "1", minWidth: "250px" }}>
                              {details.cryptoAssest.owner && (
                                <p
                                  style={{ color: "black", fontWeight: "500" }}
                                >
                                  Owner :{" "}
                                  {details.cryptoAssest.owner && (
                                    <span>{details.cryptoAssest.owner}</span>
                                  )}
                                </p>
                              )}

                              {details.cryptoAssest.heading && (
                                <p
                                  style={{ color: "black", fontWeight: "500" }}
                                >
                                  Heading :{" "}
                                  <span>
                                    {details.cryptoAssest.heading && (
                                      <span>
                                        {details.cryptoAssest.heading}
                                      </span>
                                    )}
                                  </span>
                                </p>
                              )}

                              {details.cryptoAssest.coin && (
                                <p
                                  style={{ color: "black", fontWeight: "500" }}
                                >
                                  Coin :{" "}
                                  <span>
                                    {details.cryptoAssest.coin && (
                                      <span>{details.cryptoAssest.coin}</span>
                                    )}
                                  </span>
                                </p>
                              )}

                              {details.cryptoAssest.storageType ===
                              "Exchange" ? (
                                <p
                                  style={{
                                    color: "black",
                                    fontWeight: "500",
                                  }}
                                >
                                  Exchange :{" "}
                                  <span>
                                    {details.cryptoAssest.exchangeOrWallet ===
                                    "Other" ? (
                                      <span>
                                        {
                                          details.cryptoAssest
                                            .otherExchangeOrWallet
                                        }
                                      </span>
                                    ) : (
                                      <span>
                                        {details.cryptoAssest.exchangeOrWallet}
                                      </span>
                                    )}
                                  </span>
                                </p>
                              ) : (
                                <>
                                  <p
                                    style={{
                                      color: "black",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Wallet :{" "}
                                    <span>
                                      {details.cryptoAssest.exchangeOrWallet ===
                                      "Other" ? (
                                        <span>
                                          {
                                            details.cryptoAssest
                                              .otherExchangeOrWallet
                                          }
                                        </span>
                                      ) : (
                                        <span>
                                          {
                                            details.cryptoAssest
                                              .exchangeOrWallet
                                          }
                                        </span>
                                      )}
                                    </span>
                                  </p>
                                </>
                              )}
                            </div>

                            {/* Right section */}
                            <div style={{ flex: "0.5", minWidth: "250px" }}>
                              <p style={{ color: "black", fontWeight: "500" }}>
                                Quantity :{" "}
                                <span>
                                  {details.cryptoAssest.quantity && (
                                    <span>{details.cryptoAssest.quantity}</span>
                                  )}
                                </span>
                              </p>
                              <p style={{ color: "black", fontWeight: "500" }}>
                                Estimated Value :{" "}
                                <span>
                                  {details.cryptoAssest.estValue && (
                                    <span
                                      style={{
                                        color: "green",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {formatAmount(
                                        details.cryptoAssest.estValue
                                      )}
                                    </span>
                                  )}
                                </span>
                              </p>

                              {details.sharedDetails[0] && (
                                <p
                                  style={{ color: "black", fontWeight: "500" }}
                                  onClick={() => {
                                    handleOpenBeneficiary(details);
                                    setShow1(false);
                                  }}
                                >
                                  Beneficiary Details{" "}
                                  <span>
                                    <span className="readmore">Click Here</span>
                                  </span>
                                </p>
                              )}

                              {details.cryptoAssest.notes && (
                                <Tooltip title="Click To see Note">
                                  <p
                                    style={{
                                      color: "black",
                                      fontWeight: "500",
                                    }}
                                    onClick={() => {
                                      handleOpenPopup(
                                        details.cryptoAssest.notes
                                      );
                                      setShow1(!show1);
                                    }}
                                  >
                                    Note:{" "}
                                    <span>
                                      {" "}
                                      {details && details.cryptoAssest.notes
                                        ? details.cryptoAssest.notes.slice(0, 5)
                                        : ""}
                                      ...
                                      <span className="readmore">
                                        Read More
                                      </span>
                                    </span>
                                  </p>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {showConfirmation && (
            <ConfirmationPopup
              handleConfirmation={handleConfirmation}
              handleConfirmationClose={handleConfirmationClose}
              anyText="Do you want to remove item from this list?"
            />
          )}

          {showConfirmationDelete && (
            <Deletebutton
              handleRemove={handleRemove}
              Id={currentId}
              idType={currentIdType}
              handleConfirmationClose={handleConfirmationDeleteClose}
            />
          )}

          {popupVisible && (
            // Popup div
            <div className="popup">
              <div className="popup-content">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div>
                      <h2>Notes</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisible(false);
                          setShow1(!show1);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div>
                    <p>{selectedNote}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/*  download popup */}
          {popupVisibleDownlaod && (
            // Popup div

            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h2>Download Files</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisibleDownlaod(false);
                          setShow(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>

                  <div>
                    <div style={{ marginBottom: "20px" }}>
                      <Tooltip title={selectedDownlaod.name}>
                        <div
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            gap: "20px",
                          }}
                          onClick={() => {
                            handleDownload(selectedDownlaod.name, 0);
                          }}
                        >
                          Downlaod - 1
                          <div className="myestate_download_button dwnbtn">
                            <FontAwesomeIcon
                              className="myestate_download_icon"
                              icon={faDownload}
                            />
                            <span>{selectedDownlaod.name}</span>
                          </div>
                        </div>
                      </Tooltip>
                    </div>

                    {selectedDownlaod.name1 && (
                      <div style={{ marginBottom: "20px" }}>
                        <Tooltip title={selectedDownlaod.name1}>
                          <div
                            //  value = {showDetail.user.id}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              gap: "20px",
                            }}
                            onClick={() => {
                              handleDownload(selectedDownlaod.name1, 1);
                            }}
                          >
                            Downlaod - 2
                            <div className="myestate_download_button dwnbtn">
                              <FontAwesomeIcon
                                className="myestate_download_icon"
                                icon={faDownload}
                              />
                              <span>{selectedDownlaod.name1}</span>
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    )}

                    {selectedDownlaod.name2 && (
                      <div style={{ marginBottom: "20px" }}>
                        <Tooltip title={selectedDownlaod.name2}>
                          <div
                            //  value = {showDetail.user.id}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              gap: "20px",
                            }}
                            onClick={() => {
                              handleDownload(selectedDownlaod.name2, 2);
                            }}
                          >
                            Download - 3
                            <div className="myestate_download_button dwnbtn">
                              <FontAwesomeIcon
                                className="myestate_download_icon"
                                icon={faDownload}
                              />
                              <span>{selectedDownlaod.name2}</span>
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    )}

                    {selectedDownlaod.name3 && (
                      <div style={{ marginBottom: "20px" }}>
                        <Tooltip title={selectedDownlaod.name3}>
                          <div
                            //  value = {showDetail.user.id}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              gap: "20px",
                            }}
                            onClick={() => {
                              handleDownload(selectedDownlaod.name3, 3);
                            }}
                          >
                            Download - 4
                            <div className="myestate_download_button dwnbtn">
                              <FontAwesomeIcon
                                className="myestate_download_icon"
                                icon={faDownload}
                              />
                              <span>{selectedDownlaod.name3}</span>
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    )}

                    {selectedDownlaod.name4 && (
                      <div style={{ marginBottom: "20px" }}>
                        <Tooltip title={selectedDownlaod.name4}>
                          <div
                            //  value = {showDetail.user.id}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              gap: "20px",
                            }}
                            onClick={() => {
                              handleDownload(selectedDownlaod.name4, 4);
                            }}
                          >
                            Downlaod - 5
                            <div className="myestate_download_button dwnbtn">
                              <FontAwesomeIcon
                                className="myestate_download_icon"
                                icon={faDownload}
                              />
                              <span>{selectedDownlaod.name4}</span>
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {benevisible && (
            // beneShow &&
            <div className="popup">
              <div
                className="popup-content"
                style={{
                  minWidth: "350px",
                  width: "100%",
                  maxWidth: "700px",
                }}
              >
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div className="share_property_heading">
                      <h2>Share Property </h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={handleReset}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div className="share_property_est_value">
                    <p>
                      Estimated Value:{" "}
                      <code style={{ color: "green", fontWeight: "bold" }}>
                        {formatAmount(
                          cryptoAssetData[currentBeneficiaryIndex]?.cryptoAssest
                            ?.estValue
                        )}
                      </code>
                    </p>
                  </div>
                  <div className="BeneficiarySelect">
                    <div className="BeneficiarySelectContainer">
                      <div className="BeneficiarySelectRow">
                        <div className="share_property_Type">
                          <p className="share_property_Type_paragraph">
                            Choose Distribution Type:{" "}
                          </p>
                          <select
                            value={distributionType}
                            onChange={handleDistributionTypeChange}
                            className="share_property_Type_select"
                          >
                            <option value="">Select Type</option>
                            <option value="percentage">Percentage</option>
                            <option value="dollar">Dollar</option>
                          </select>
                        </div>
                        <div className="SelectContainer">
                          <select
                            className="Property-inputfiled"
                            onChange={handleBeneficiarySelection}
                            value=""
                            disabled={!distributionType}
                          >
                            <option value="" disabled hidden>
                              {distributionType
                                ? "Select Your Beneficiary Username"
                                : "Select Type First"}
                            </option>
                            {beneficiary.map((benif) => (
                              <option
                                key={benif.id}
                                value={benif.id}
                                disabled={selectedBeneficiaries.includes(
                                  benif.id
                                )}
                              >
                                {/* {benif.username} */}
                                {`${benif.firstName} ${benif.lastName}`}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="share_beneficiary_main_card">
                        {selectedBeneficiaries.map((beneficiary) => (
                          <div
                            key={beneficiary}
                            className="share_beneficiary_card"
                          >
                            <div>
                              <p className="share_beneficiary_card_para">
                                Beneficiary:{" "}
                                {getBenificiaryName({ beneficiary })}
                              </p>
                              {distributionType === "percentage" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Percentage"
                                  value={
                                    beneficiaryDetails[beneficiary]
                                      ?.percentage || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "percentage",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {distributionType === "dollar" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Dollar Value"
                                  value={
                                    beneficiaryDetails[beneficiary]?.value || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                />
                              )}

                              {distributionType && (
                                <p className="share_beneficiary_card_para">
                                  Distributed Value: $
                                  {distributionType === "percentage"
                                    ? calculateDistributedAmount(
                                        "percentage",
                                        cryptoAssetData[currentBeneficiaryIndex]
                                          ?.cryptoAssest?.estValue,
                                        beneficiaryDetails[beneficiary]
                                      )
                                    : calculateDistributedAmount(
                                        "dollar",
                                        cryptoAssetData[currentBeneficiaryIndex]
                                          ?.cryptoAssest?.estValue,
                                        beneficiaryDetails[beneficiary]
                                      )}
                                </p>
                              )}
                            </div>
                            <div
                              className="share_beneficiary_card_close"
                              onClick={() =>
                                handleBeneficiaryClose(beneficiary)
                              }
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {selectedBeneficiaries.length > 0 && (
                  <button onClick={handleSave}>Save</button>
                )}
              </div>
            </div>
          )}

          {/*  beneficiary details popup */}
          {beneficiaryVisible && (
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h2>share property</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setBeneficiaryVisible(false);
                          setShow1(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div>
                    {selectedBeneficiary.sharedDetails &&
                      selectedBeneficiary.sharedDetails.map(
                        (details, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              margin: "5px",
                              padding: "10px",
                              border: "solid 1px lightgray",
                              borderRadius: "5px",
                              minWidth: "230px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "17px",
                                  color: "black",
                                  fontWeight: "500",
                                }}
                              >
                                Share - {index + 1}
                              </p>
                              {primaryUserDetails.accountType === "secondary" &&
                              secondaryUserDetails.accountType === "primary" &&
                              secondaryUserDetails.isSecondaryUserEditable ===
                                "false" ? (
                                ""
                              ) : (
                                // <Deletebutton
                                //   handleRemove={handleRemove}
                                //   Id={details.id}
                                //   idType="sharedDetailId"
                                // />
                                <FontAwesomeIcon
                                  onClick={() =>
                                    removeCrypto(details.id, "sharedDetailId")
                                  }
                                  className="delete-button-comp"
                                  style={{ cursor: "pointer" }}
                                  icon={faTrash}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "20px",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "black",
                                  fontWeight: "400",
                                  marginLeft: "20px",
                                }}
                              >
                                Beneficiary Name:
                                <span style={{ marginLeft: "10px" }}>
                                  {getBenificiaryName(details.beneficiaryId)}
                                </span>
                              </p>
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "black",
                                  fontWeight: "400",
                                  marginLeft: "20px",
                                }}
                              >
                                Distributed Type:
                                <span style={{ marginLeft: "10px" }}>
                                  {details.distributedType}
                                </span>
                              </p>
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "black",
                                  fontWeight: "400",
                                  marginLeft: "20px",
                                }}
                              >
                                Distributed Value:{" "}
                                <span style={{ marginLeft: "10px" }}>
                                  {details &&
                                    details.distributedType === "dollar" &&
                                    "$"}
                                  {details.distributedValue}
                                  {details.distributedType === "percentage" &&
                                    "%"}
                                </span>
                              </p>
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "black",
                                  fontWeight: "400",
                                  marginLeft: "20px",
                                }}
                              >
                                Distributed Amount:
                                <span style={{ marginLeft: "10px" }}>
                                  ${details.distributedAmount}
                                </span>
                              </p>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/*  download popup */}
          {popupVisibleDownlaod && (
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h2>Download Files</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisibleDownlaod(false);
                          setShow1(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div>
                    {selectedDownlaod.documents &&
                      selectedDownlaod.documents.length > 0 &&
                      selectedDownlaod.documents.map((file, index) => (
                        <div key={index} style={{ marginBottom: "20px" }}>
                          <Tooltip title={file.fileName}>
                            <div
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                gap: "20px",
                              }}
                              onClick={() => {
                                handleDownload(file.id, file.fileName);
                              }}
                            >
                              Download - {index + 1}
                              <div className="myestate_download_button dwnbtn">
                                <FontAwesomeIcon
                                  className="myestate_download_icon"
                                  icon={faDownload}
                                />
                                <span>{file.fileName}</span>
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </SideBar>
      </UserBase>
    </div>
  );
}

export default Crypto;
