import "@fortawesome/fontawesome-svg-core/styles.css";

import { Typography } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useRef, useState } from "react";
import "../../src/components/styles.css";

import {
  faArrowTrendUp,
  faFileInvoiceDollar,
  faMoneyBillTrendUp,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Nav, Navbar } from "react-bootstrap";
import "./../components/user/whyichest.css";
import Login from "./Login";
import Signup from "./Signup";
import WelcomeIchest from "./my-estate/welcomeIchest";

import { motion } from "framer-motion";
import FooterPage from "./ExploreMore/footer";
import { Homepagenotification } from "../components/alerting-visitors/notification";
import { Link } from "react-router-dom";

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const [showLogin, setShowLogin] = React.useState(false);
  const [showSignin, setShowSignin] = React.useState(false);

  const partnersRef = useRef(null);
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#aboutus") {
      partnersRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const toggleLogin = () => {
    setShowLogin(!showLogin);
  };

  const handleBack = () => {
    setShowLogin(false);
    setShowSignin(false);
  };
  const toggleSignin = () => {
    togglePopup();
    setShowSignin(!showSignin);
  };
  // popup component
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };
  const [isPopupOpen, setPopupOpen] = useState(false);
  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const line1 = "Life is about legacy,".split(" ");
  const line2 = "I-Chest.com helps preserve it.".split(" ");
  const first = "Life is about legacy, I-Chest.com helps preserve it.".split(
    " "
  );
  const [key, setKey] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setKey((prevKey) => prevKey + 1);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const [modalData, setModalData] = useState(null);

  const profiles = {
    ranjay: {
      title: "R. Kumar",
      role: "President",
      details1:
        "Mr. Kumar is the President of i- chest.com, bringing with him a wealth of experience across multiple domains, including educational leadership, technology, and project management. As the visionary behind the concept of I-Chest.com, he has been instrumental in shaping the platform from its inception. Under his guidance, the platform is being meticulously developed to serve as a comprehensive and secure repository for asset management.",
      details2:
        "Mr. Kumar's entrepreneurial journey is marked by a series of successful ventures, demonstrating his ability to innovate and lead in diverse fields. His background in directing educational institutions, combined with his expertise in technology and project management, has equipped him with the unique skills needed to drive the development of I-Chest.com. With a proven track record of entrepreneurship, Mr. Kumar is dedicated to ensuring that I-Chest becomes a trusted and reliable resource for users seeking to manage their legacies.",
      image: "ranjay.jpeg",
    },
    janice: {
      title: "Janice C. Parker",
      role: "Chairperson Board of Directors",
      details1:
        "Mrs. Parker, in her role with I-Chest.com, collaborates closely with the President to support the marketing efforts of this emerging platform and its unique services, both within the United States and internationally. She is also the President, CEO, and owner of an internationally and culturally diverse associate degree-granting college in Chicago, Illinois.",
      details2:
        "Janice C. Parker Chairperson Board of Directors Mrs. Parker, in her role with I-Chest.com, collaborates closely with the President to support the marketing efforts of this emerging platform and its unique services, both within the United States and internationally. She is also the President, CEO, and owner of an internationally and culturally diverse associate degree-granting college in Chicago, Illinois.",
      image: "parker.png",
    },
    thomas: {
      title: "Thomas Planera",
      role: "Legal Counsellor and Partner",
      details1:
        "Mr. Thomas Planera brings over forty years of experience in legal services to his role as Legal Counsellor and Partner at i-chest.com. As a guiding force behind the platform, Mr. Planera’s expertise has been invaluable in shaping its legal framework and strategic direction.",
      details2:
        "Mr.Planera specializes in estate and asset protection planning, corporate and business transactions, including labor issues, and all aspects of commercial and residential real estate development. His firm is also dedicated to advising businesses on the advantages of certifying as a Minority Business Enterprise (MBE) or a Women’s Business Enterprise (WBE). In addition to his legal practice, Mr. Planera serves as the president of an educational institution, further demonstrating his leadership and commitment to community and business development.",
      image: "Thomas.png",
    },
    idea: {
      title: "IdeaGenSys",
      role: "Production & Technical Partner",
      details1:
        "Bring about a transformative change and continue striving to make people's lives easier. Embrace the process of development as we endeavour to create a positive impact on the world through cutting-edge IT solutions. Our mission is to provide exceptional services to individuals and institutions globally, and we are committed to fostering a corporate culture that prioritizes people and their needs.",
      details2:
        "We offer a comprehensive range of custom software solutions designed to meet your specific requirements across various domains, including Web, Mobility, AI, IoT, and Block chain development. Our team of highly skilled developers excels in rapid software development, ensuring that we deliver innovative and efficient solutions tailored to your needs. By integrating the latest technologies and maintaining a strong focus on client satisfaction, we aim to drive success and create lasting value for our partners and clients.",
      image: "ideanensys.png",
    },
  };

  const openModal = (profileKey) => {
    setModalData(profiles[profileKey]);
  };

  const closeModal = () => {
    setModalData(null);
  };
  return (
    <>
      <div className="homepage_design_main">
        {showPopup && <WelcomeIchest closePopup={closePopup} />}
        {!showPopup && (
          <div className="homepage_design_main">
            <Navbar
              expand="lg"
              fixed="top"
              bg="dark"
              variant="dark"
              aria-label="Main navigation"
              className="navbar_base"
            >
              <Container>
                <Navbar.Brand href="#" className="navbar-brand logo-image">
                  <img src="./logo192.webp" alt="alternative" />

                  <div className="App_this">
                    <div className="tab_largescreen_view">
                      {first.map((el, i) => (
                        <motion.span
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{
                            duration: 1,
                            delay: i / 10,
                          }}
                          key={i + key * first.length}
                        >
                          {el}{" "}
                        </motion.span>
                      ))}
                    </div>
                    <div className="tab_movile_view">
                      {line1.map((el, i) => (
                        <motion.span
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{
                            duration: 1,
                            delay: i / 10,
                          }}
                          key={i + key * line1.length}
                        >
                          {el}{" "}
                        </motion.span>
                      ))}
                    </div>
                    <div className="tab_movile_view">
                      {line2.map((el, i) => (
                        <motion.span
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{
                            duration: 0.25,
                            delay: i / 10,
                          }}
                          key={i + key * line2.length}
                        >
                          {el}{" "}
                        </motion.span>
                      ))}
                    </div>
                  </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav" />
                <Navbar.Collapse id="navbar-nav">
                  <Nav className="ms-auto">
                    <Nav.Link href="#header">Home</Nav.Link>
                    {/* <Nav.Link href="#services">Services</Nav.Link> */}
                    <Nav.Link href="#features">Features</Nav.Link>
                    <Nav.Link href="#aboutus">Partners</Nav.Link>
                    <Nav.Link href="#whyichest">Why I-chest</Nav.Link>
                    <Nav.Link href="/contact-us">Contact Us</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>

            {showLogin && (
              <div
                className="login-popup"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="1000"
              >
                <div className="login-content">
                  {showLogin && <Login onBack={handleBack} />}
                </div>
              </div>
            )}

            {/* user registeration form  */}
            {showSignin && (
              <div
                className="login-popup"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="1000"
              >
                <div className="login-content">
                  {showSignin && <Signup onBack={handleBack} />}
                </div>
              </div>
            )}
            <div
              style={{ marginTop: "60px", position: "fixed", zIndex: "999" }}
            >
              <Homepagenotification />
            </div>
            <div id="header" class="header">
              <div class="container">
                <div class="homepage_main">
                  <div class="homepage_details">
                    <div class="text-container home_page_content">
                      <h2>
                        'Life is about legacy, <br />I
                        <span style={{ color: "rgb(255 213 0)" }}>-</span>
                        Chest.com helps you preserve it.'
                        <span style={{ fontSize: "14px", color: "#e1ff00" }}>
                          &copy;
                        </span>
                      </h2>
                      <p>
                        “Welcome to I-Chest, a reliable platform for managing
                        assets, liabilities, and future planning, backed by
                        expert founders and copyright protection.”{" "}
                      </p>
                    </div>
                    <div className="contact_us">
                      <a href="#whyichest" class="btn-shine">
                        Why I<span style={{ color: "#1dff00" }}>-</span>Chest
                      </a>
                    </div>
                  </div>
                  <div class="homepage_image">
                    <div class="image-container">
                      <img
                        class="img-fluid"
                        src="/img/homepage_image.webp"
                        alt="alternative"
                      />
                      <div className="login_buttons">
                        <button
                          class="button btn-solid-lg"
                          onClick={toggleSignin}
                        >
                          <span class="button-content">Sign Up</span>
                        </button>

                        <button
                          class="button btn-solid-lg"
                          onClick={toggleLogin}
                        >
                          <span class="button-content">Sign In</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cards-1 ">
              <div class="basic-3">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="text-container">
                        <h2>
                          Welcome to I
                          <span style={{ color: "rgb(255 213 0)" }}>-</span>
                          Chest.com
                        </h2>
                        <p class="p-large">
                          We welcome you to a reliable, stable, and convenient
                          legacy management platform. I-Chest.com offers you the
                          ability to manage your asset inventories, liabilities,
                          and future planning, along with a personal writing
                          space for your heart and soul. We encourage you to
                          subscribe to the platform if you have a legacy to
                          leave for your family and friends. This unique and
                          one-of-a-kind endeavour is proudly backed by copyright
                          protection.
                        </p>
                        {/* <a class="btn-solid-lg" href="#contact">Get free quote</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container" id="services">
                <div class="row">
                  <div
                    class="col-lg-12 our_service"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "3rem",
                    }}
                  >
                    <div className="col-lg-12 homepage_heding">
                      <h2>Explore our Services</h2>
                    </div>

                    <div className="service_page">
                      <div className="service_details">
                        <p>
                          I-chest.com is a repository with an unique objective
                          to resolve. The platform will store comprehensive
                          information on any earned, unearned or inherited
                          assets, accompanied by all relevant supporting
                          documents.
                        </p>
                        <ul className="liststyledesign">
                          <li className="serviceList">
                            Dynamic Assets, Liabilities and Personal Information
                            Storage platform{" "}
                          </li>
                          <li className="serviceList">
                            Decentralize the information by sharing it with your
                            confidantes and professionals{" "}
                          </li>
                          <li className="serviceList">
                            Plan in action in case of mishap{" "}
                          </li>
                          <li className="serviceList">
                            Writing centre, for diaries, autobiographies, books
                            etc.{" "}
                          </li>
                          <li className="serviceList">
                            Explore your net worth{" "}
                          </li>
                        </ul>
                        <Link to="/features#features" className="explorebutton">
                          Explore more....
                        </Link>
                      </div>
                      <div className="service_image ">
                        <img
                          className="service_image_srvice"
                          src="./img/homepage_image/service_image.jpg"
                          alt="service-image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="secondndbckground">
                  <div class="row" id="features">
                    <div class="col-lg-12 homepage_heding">
                      <h2>Integral Features</h2>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="card">
                        <div class="card-body">
                          <h5 class="card-title1">Data Storage</h5>
                          <p>
                            Securely store and organize assets, liabilities, and
                            financial data using advanced cloud technology on
                            I-Chest.com.
                          </p>
                          <Link
                            to="/features#integral"
                            className="explorebutton"
                          >
                            Explore more....
                          </Link>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-body">
                          <h5 class="card-title1">Customized components </h5>
                          <p>
                            Customized components for Real Estate, Banks,
                            Investments, Insurance, Vehicles, Bit coin,
                            Business, Jewelry, and more.
                          </p>
                          <Link
                            to="/features#integral"
                            className="explorebutton"
                          >
                            Explore more....
                          </Link>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-body">
                          <h5 class="card-title1">Inventory Drafting</h5>
                          <p>
                            I-Chest.com enables users to document, manage, and
                            share verified asset inventories with relevant
                            parties.
                          </p>
                          <Link
                            to="/features#integral"
                            className="explorebutton"
                          >
                            Explore more....
                          </Link>
                        </div>
                      </div>

                      <div class="card">
                        <div class="card-body">
                          <h5 class="card-title1">
                            Decentralization of Information
                          </h5>
                          <p>
                            The platform enables users to share data with
                            family, attorneys, and trustees, ensuring
                            transparency and control.
                          </p>
                          <Link
                            to="/features#integral"
                            className="explorebutton"
                          >
                            Explore more....
                          </Link>
                        </div>
                      </div>

                      <div class="card">
                        <div class="card-body">
                          <h5 class="card-title1">Writing Centre</h5>
                          <p>
                            I-Chest.com provides a secure space for users to
                            document autobiographies, plans, diaries, and more.
                          </p>
                          <Link
                            to="/features#integral"
                            className="explorebutton"
                          >
                            Explore more....
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="cards-1" style={{ marginBottom: "-40px" }}>
              <div class="container">
                <div class="row">
                  <div class="col-lg-12 homepage_heding">
                    <h2>Modules</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title1">Assets</h5>
                        <p>
                          This module provides a detailed overview of the users
                          assets and holdings. These assets are integral to
                          calculating the users estimated net worth, which is
                          determined after accounting for any liabilities.{" "}
                        </p>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title1">Liabilities</h5>
                        <p>
                          This module outlines the users loans, debts, and any
                          other outstanding obligations. These liabilities are
                          subtracted from the users assets to calculate an
                          estimated net worth.{" "}
                        </p>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title1">Personal Space</h5>
                        <p>
                          The Personal Space module offers users a dedicated
                          area for personal use. It can be utilized for writing
                          books, autobiographies, or personal diaries, with the
                          option to share these writings if desired.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="featurebackground">
              <div className="key_Assets_main">
                <div className="Key_assets_base">
                  <div className="homepage_heding">
                    <h3>Assets</h3>
                  </div>
                  <div className="key_assets_left">
                    <div className="Assets_card">
                      <h4 className="Assetscard_Heading">Real Estate</h4>
                      <p className="Assetscard_paragraph">
                        "Add and manage all your real estate properties
                        conveniently in one place."
                      </p>
                      <Link to="/features#assets" className="explorebutton">
                        Explore More...
                      </Link>{" "}
                    </div>
                    <div className="Assets_card">
                      <h4 className="Assetscard_Heading">
                        Miscellaneous Assets
                      </h4>
                      <p className="Assetscard_paragraph">
                        "Effortlessly add, organize, and manage all your
                        miscellaneous assets in one convenient platform."
                      </p>
                      <Link to="/features#assets" className="explorebutton">
                        Explore More...
                      </Link>
                    </div>
                  </div>
                  <div className="key_assets_right">
                    <div className="Assets_card">
                      <h4 className="Assetscard_Heading">Bank</h4>
                      <p className="Assetscard_paragraph">
                        "Seamlessly track, organize, and manage your banking
                        assets all in one secure platform."
                      </p>
                      <Link to="/features#assets" className="explorebutton">
                        Explore More...
                      </Link>
                    </div>
                    <div className="Assets_card">
                      <h4 className="Assetscard_Heading">Jewelry</h4>
                      <p className="Assetscard_paragraph">
                        "Effortlessly add multiple pieces, organize, and manage
                        your jewelry collection in one secure platform."
                      </p>
                      <Link to="/features#assets" className="explorebutton">
                        Explore More...
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="key_Assets_main">
                <div className="Key_assets_base">
                  <div className="homepage_heding">
                    <h3>Liabilities</h3>
                  </div>
                  <div className="key_assets_left">
                    <div className="Assets_card">
                      <h4 className="Assetscard_Heading">Bank loan</h4>
                      <p className="Assetscard_paragraph">
                        "Manage your bank loan efficiently here, keeping track
                        of payments, balances, and important details easily."
                      </p>
                      <Link
                        to="/features#Liabilities"
                        className="explorebutton"
                      >
                        Explore More...
                      </Link>
                    </div>
                  </div>
                  <div className="key_assets_right">
                    <div className="Assets_card">
                      <h4 className="Assetscard_Heading">Mortgages</h4>
                      <p className="Assetscard_paragraph">
                        "Manage your mortgages here, track payments, balances,
                        and details, all from one convenient platform."{" "}
                      </p>
                      <Link
                        to="/features#Liabilities"
                        className="explorebutton"
                      >
                        Explore More...
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="key_Assets_main">
                <div className="Key_assets_base">
                  <div className="homepage_heding">
                    <h3>Writing Centre</h3>
                  </div>
                  <div className="key_assets_left">
                    <div className="Assets_card">
                      <h4 className="Assetscard_Heading">
                        Create Your Personalized Diary
                      </h4>
                      <p className="Assetscard_paragraph">
                        "Create your personal diary, effortlessly record
                        thoughts, and manage your entries all in one place."
                      </p>
                      <Link
                        to="/features#writingCenterSection"
                        className="explorebutton"
                      >
                        Explore more...
                      </Link>
                    </div>
                  </div>
                  <div className="key_assets_right">
                    <div className="Assets_card">
                      <h4 className="Assetscard_Heading">
                        Compose Your Own Book
                      </h4>
                      <p className="Assetscard_paragraph">
                        "Make your own book By Sharing details about yourself,
                        including your interests, experiences, and achievements,
                        all in one profile."
                      </p>
                      <Link
                        to="/features#writingCenterSection"
                        className="explorebutton"
                      >
                        Explore more...
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-1">
                <div class="container">
                  <div class="row">
                    <div
                      class="col-xl-12 homepage_heding"
                      style={{ marginTop: "20px" }}
                    >
                      <h2>Key Objectives</h2>
                      <p class="p-heading"></p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-5">
                      <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                          <div class="accordion-icon">
                            <span class="fas fa-tv">
                              {" "}
                              <FontAwesomeIcon icon={faFileInvoiceDollar} />
                            </span>
                          </div>
                          <div class="accordion-header" id="headingOne">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Inventory Report Creation
                            </button>
                          </div>
                          <div
                            id="collapseOne"
                            class="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              Generate detailed reports of earned or inherited
                              assets, including supporting documents.
                            </div>
                          </div>
                        </div>

                        <div class="accordion-item">
                          <div class="accordion-icon blue">
                            <span class="fas fa-microphone">
                              {" "}
                              <FontAwesomeIcon icon={faUserTie} />
                            </span>
                          </div>
                          <div class="accordion-header" id="headingTwo">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Real-Time Sharing
                            </button>
                          </div>
                          <div
                            id="collapseTwo"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              Enable selective sharing of asset information with
                              designated parties in real time.
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <div class="accordion-icon purple">
                            <span class="fas fa-video">
                              <FontAwesomeIcon icon={faArrowTrendUp} />
                            </span>
                          </div>
                          <div class="accordion-header" id="headingThree">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Mishap Preparedness
                            </button>
                          </div>
                          <div
                            id="collapseThree"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              Ensure there is a plan in place for asset
                              management in the event of a mishap or accident.
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <div class="accordion-icon orange">
                            <span class="fas fa-tools">
                              <FontAwesomeIcon icon={faMoneyBillTrendUp} />
                            </span>
                          </div>
                          <div class="accordion-header" id="headingFour">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Global Asset Inclusion
                            </button>
                          </div>
                          <div
                            id="collapseFour"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              Include information about assets beyond local
                              borders, offering a roadmap for international
                              asset management.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="display_image">
                      <div class="image-container">
                        <img
                          class="img-fluid"
                          src="./logo192.webp"
                          alt="alternative"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cards-2 bg-gray">
              <div
                class="container"
                ref={partnersRef}
                id="aboutus"
                style={{ paddingTop: "60px" }}
              >
                <div class="row">
                  <div className="homepage_heding">
                    <h2 className="People_heading">
                      People behind the platform
                    </h2>
                  </div>
                  <div class="patners_card">
                    {Object.keys(profiles).map((key) => (
                      <div
                        className="Profile_card"
                        key={key}
                        onClick={() => openModal(key)}
                      >
                        <div className="profile_image_card">
                          <img
                            className="patner_profile_image"
                            src={`./img/patners-image/${profiles[key].image}`}
                            alt=""
                            style={
                              profiles[key].image === "ideanensys.png"
                                ? { objectFit: "contain" }
                                : {}
                            }
                          />
                        </div>
                        <div className="Profile_card-details">
                          <p className="text-title">{profiles[key].title}</p>
                          <p className="text-body">{profiles[key].role}</p>
                        </div>
                        <button
                          className="Profile_card-button"
                          onClick={() => openModal(key)}
                        >
                          More About
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div class="cards-2 bg-gray" id="whyichest">
              <div class="container">
                <div class="row">
                  <div
                    class="col-lg-12 "
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "3rem",
                    }}
                  >
                    <div className="homepage_heding">
                      <h2>
                        Why I<span style={{ color: "rgb(255 213 0)" }}>-</span>
                        Chest.com?
                      </h2>
                    </div>
                    <p className="paragraph">
                      “I-Chest is a platform designed to serve as a
                      comprehensive repository for assets, primarily functioning
                      as an Inventory Report for a Trust or Will. The platform
                      aims to offer real - time sharing capabilities, providing
                      secure and discreet access to Trustees, Beneficiaries,
                      Executors, Owners, and Attorneys.”{" "}
                    </p>
                  </div>
                </div>
                <div
                  class="row"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <ul className="icest_main_card">
                    <li className="icest_card">
                      <strong>Live Transition Document:</strong> A constantly
                      updated plan that ensures seamless transitions—no dropped
                      batons when it matters most.
                    </li>
                    <li className="icest_card">
                      <strong>Vision of Executioners:</strong> Live documents
                      should be actively maintained under the supervision of
                      trusted individuals who will carry out your wishes
                      precisely.
                    </li>
                    <li className="icest_card">
                      <strong>Inheritance and Legacy:</strong> Are your loved
                      ones aware of your intentions? Make sure details like
                      inheritance, legacy, and beneficiaries are clearly
                      communicated to avoid confusion.
                    </li>
                    <li className="icest_card">
                      <strong>Confidant Oversight:</strong> Do you have a
                      trusted confidant, like a lawyer, who monitors your plans
                      to ensure they're correctly executed?
                    </li>
                    <li className="icest_card">
                      <strong>Personal Privacy:</strong> Is there a part of your
                      life or plan that remains personal, only to be shared when
                      you're ready? Maintain those boundaries until the time is
                      right.
                    </li>
                  </ul>
                  {/* <a href="/WhyI-chest" className="explorebutton">Explore more...</a> */}
                  <Link to="/WhyI-chest#integral" className="explorebutton">
                    Explore more....
                  </Link>
                </div>
              </div>
            </div>
            <FooterPage />
          </div>
        )}
      </div>

      {modalData && (
        <div className="modal" onClick={closeModal}>
          <div
            className="card_modal_content"
            onClick={(e) => e.stopPropagation()}
          >
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <div id="modal-details">
              <div className="card_design">
                <div className="card_image">
                  <img
                    className="card_profile_image"
                    src={`./img/patners-image/${modalData.image}`}
                    alt={modalData.title}
                  />
                </div>
                <div className="card_content">
                  <h2>{modalData.title}</h2>
                  <p>
                    <strong>Role:</strong> {modalData.role}
                  </p>
                </div>
              </div>
              <div className="details_card">
                <p>{modalData.details1}</p>
                {/* <br /> */}
                <p className="second_paragraph">{modalData.details2}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
