import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { getBeneficiary, getToken, getUser } from "../services/user-service";
import { Radio, RadioGroup } from "@mui/joy";

function SharedBeneficiaryEdit({
  responseData,
  responseAssetData,
  handleTrustSave,
  assetData,
  trusts,
  handleChanges,
  sharedType,
  handleCloseBeneficiaryPopup,
  estimatedTotalAmount,
  typeBeneficiary,
  sharedDetails,
}) {
  const [data, setData] = useState({
    sharedDetails: sharedDetails || [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const handleReset = () => {
    setDistributedType("");
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
  };

  const [benevisible, setbeneVisible] = useState(false);

  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});

  const [distributedType, setDistributedType] = useState("");

  const ben = (newType) => {
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributedType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleDistributionTypeChange = (event) => {
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", dollar: "" };
    });
    setDistributedType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection1 = (selectedBeneficiary) => {
    console.log("handleBeneficiarySelection working : ", selectedBeneficiary);
    if (selectedBeneficiary === null) {
      return null;
    }
    setSelectedBeneficiaries((prevSelectedBeneficiaries) => {
      // Check if the selectedBeneficiary is already included
      if (!prevSelectedBeneficiaries.includes(selectedBeneficiary)) {
        // If not included, update the selected beneficiaries
        const newSelectedBeneficiaries = [
          ...prevSelectedBeneficiaries,
          selectedBeneficiary,
        ];
        console.log(
          "Updated selected beneficiaries:",
          newSelectedBeneficiaries
        );
        return newSelectedBeneficiaries;
      }

      // If already included, return the previous state without any changes
      return prevSelectedBeneficiaries;
    });

    setBeneficiaryDetails((prevBeneficiaryDetails) => {
      const newBeneficiaryDetails = {
        ...prevBeneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      };
      return newBeneficiaryDetails;
    });
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      });
    }
  };

  const getBenificiaryName = (id) => {
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }

    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found";
    }
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getBenificiarydata();
  }, []);

  const handleFieldChange1 = (beneficiary, field, value) => {
    setBeneficiaryDetails((prevDetails) => {
      let updatedDetails = { ...prevDetails };

      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };

      return updatedDetails;
    });
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.dollar);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributedType,
    balance,
    beneficiaryDetail
  ) => {
    // Assuming beneficiaryDetail is an object with a property 'value'
    if (distributedType === "percentage") {
      const calculatedAmount =
        (parseFloat(balance) * parseFloat(beneficiaryDetail.percentage)) / 100;
      return calculatedAmount.toFixed(2);
    } else if (distributedType === "dollar") {
      const detailValue = parseFloat(beneficiaryDetail.dollar);
      return detailValue.toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.dollar);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
    handleCloseBeneficiaryPopup({ name: data.sharedDetails });
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails];
    const updatedElement = {
      ...updatedSharedDetails[i],
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };
    updatedSharedDetails[i] = updatedElement;

    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails,
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };

  useEffect(() => {
    const copiedSharedDetails =
      sharedDetails !== undefined &&
      sharedDetails[0]?.distributedType !== undefined
        ? [...sharedDetails]
        : [];

    if (
      copiedSharedDetails.length > 0 &&
      copiedSharedDetails[0]?.distributedType !== ""
    ) {
      ben(copiedSharedDetails[0]?.distributedType);
      for (var i = 0; i < copiedSharedDetails.length; i++) {
        handleBeneficiarySelection1(copiedSharedDetails[i].beneficiaryId);
        handleFieldChange1(
          copiedSharedDetails[i].beneficiaryId,
          copiedSharedDetails[i]?.distributedType,
          copiedSharedDetails[i].distributedValue
        );
      }
    }
  }, []);

  return (
    <>
      <div className="popup">
        <div
          className="popup-content"
          style={{
            minWidth: "350px",
            width: "100%",
            maxWidth: "700px",
          }}
        >
          <div className="note_popup">
            <div className="note_popup_heading">
              <div className="share_property_heading">
                <h2>Share Property </h2>
              </div>
              <div>
                <button
                  className="note_popup_heading_close_btn"
                  onClick={handleCloseBeneficiaryPopup}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <p>Do you want to share your asset to beneficiary or trust?</p>
              <RadioGroup
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                  margin: "8px 0",
                }}
                defaultValue="medium"
                name="radio-buttons-group"
                value={sharedType}
                onChange={(e) => {
                  typeBeneficiary === "normal"
                    ? handleChanges(e, "sharedType")
                    : handleChanges(e, "deathBenefitsSharedType");
                  handleReset(e);
                }}
              >
                <Radio
                  disabled={
                    (sharedType === "Beneficiary" || sharedType === "Trust") &&
                    ((responseAssetData?.trust &&
                      responseAssetData?.trust !== "") ||
                      (responseData?.sharedDetails[0]?.id !== "" &&
                        responseData?.sharedDetails[0]?.id !== undefined))
                  }
                  value="Beneficiary"
                  label="Beneficiary"
                />
                <Radio
                  disabled={
                    (sharedType === "Beneficiary" || sharedType === "Trust") &&
                    ((responseAssetData?.trust &&
                      responseAssetData?.trust !== "") ||
                      (responseData?.sharedDetails[0]?.id !== "" &&
                        responseData?.sharedDetails[0]?.id !== undefined))
                  }
                  sx={{ margin: 0 }}
                  value="Trust"
                  label="Trust"
                />
              </RadioGroup>
            </div>

            {sharedType ? (
              <div style={{ marginTop: "8px" }}>
                <div className="share_property_est_value">
                  <p>
                    Estimated Value:{" "}
                    <code style={{ color: "green", fontWeight: "bold" }}>
                      ${estimatedTotalAmount}
                    </code>
                  </p>
                </div>
                {sharedType === "Beneficiary" ? (
                  <div className="BeneficiarySelect">
                    <div className="BeneficiarySelectContainer">
                      <div className="BeneficiarySelectRow">
                        <div className="share_property_Type">
                          <p className="share_property_Type_paragraph">
                            Choose Distribution Type:{" "}
                          </p>
                          <p className="ms-2 text-black fw-normal"></p>
                          <select
                            value={distributedType}
                            onChange={(e) => handleDistributionTypeChange(e)}
                            class="share_property_Type_select"
                            disabled={
                              sharedDetails.length > 0 &&
                              sharedDetails[0]?.distributedType !== ""
                                ? true
                                : false
                            }
                          >
                            <option value="">Select Type</option>
                            <option value="percentage">Percentage</option>
                            <option value="dollar">Dollar</option>
                          </select>
                        </div>
                        <div className="SelectContainer">
                          <select
                            className="Property-inputfiled"
                            onChange={handleBeneficiarySelection}
                            value=""
                            disabled={!distributedType}
                          >
                            <option value="" disabled hidden>
                              {distributedType
                                ? "Select Your Beneficiary Username"
                                : "Select Type First"}
                            </option>
                            {beneficiary.map((benif) => (
                              <option
                                key={benif.id}
                                value={benif.id}
                                disabled={selectedBeneficiaries.includes(
                                  benif.id
                                )}
                              >
                                {`${benif.firstName} ${benif.lastName}`}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="share_beneficiary_main_card">
                        {selectedBeneficiaries.map((beneficiary) => (
                          <div key={beneficiary} class="share_beneficiary_card">
                            <div>
                              <p className="share_beneficiary_card_para">
                                Beneficiary:{" "}
                                {getBenificiaryName({ beneficiary })}
                              </p>
                              {distributedType === "percentage" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Percentage"
                                  value={
                                    beneficiaryDetails[beneficiary]
                                      ?.percentage || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "percentage",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {distributedType === "dollar" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Dollar Value"
                                  value={
                                    beneficiaryDetails[beneficiary]?.dollar ||
                                    ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "dollar",
                                      e.target.value
                                    )
                                  }
                                />
                              )}

                              {distributedType && (
                                <p className="share_beneficiary_card_para">
                                  {/* <p></p> */}
                                  Distributed Value: $
                                  {distributedType === "percentage"
                                    ? calculateDistributedAmount(
                                        "percentage",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )
                                    : calculateDistributedAmount(
                                        "dollar",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )}
                                </p>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "8px",
                      margin: "12px 0",
                    }}
                  >
                    <p>Select your trust where you want to share your asset:</p>
                    <select
                      value={assetData?.trust}
                      onChange={(e) => handleChanges(e, "trust")}
                      className="share_property_Type_select"
                    >
                      <option value={""}>{"Select your trust"}</option>
                      {trusts.map((trust) => (
                        <option key={trust.id} value={trust?.trustName}>
                          {trust?.trustName}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
          {(selectedBeneficiaries.length > 0 ||
            (sharedDetails.length > 0 &&
              sharedDetails[0].distributedType !== "")) && (
            <button
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </button>
          )}
          {assetData?.trust !== "" &&
            assetData?.trust !== null &&
            assetData?.trust !== undefined && (
              <button onClick={handleTrustSave}>Save</button>
            )}
          {typeBeneficiary === "death-benefits" &&
            assetData?.deathBenefits?.trust !== null &&
            assetData?.deathBenefits?.trust !== "" &&
            assetData?.deathBenefits?.trust !== undefined && (
              <button onClick={handleTrustSave}>Save</button>
            )}
        </div>
      </div>
    </>
  );
}

export default SharedBeneficiaryEdit;
