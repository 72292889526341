import {
  faBuildingColumns,
  faDownload,
  faEye,
  faEyeSlash,
  faMinus,
  faPlus,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControlLabel, Switch, Tooltip } from "@mui/material";
import { formatAmount } from "../../components/Common";

import { FormLabel, Input, Option, Select, Textarea } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Form } from "reactstrap";
import SideBar from "../../components/sidebar/Sidebar";
import UserBase from "../../components/user/UserBase";
import "../../css/formPOPup.css";
import "../../css/myestare.css";
import { addBank, deleteBank, getBank } from "../../services/bank-service";
import {
  deleteSingleProperty,
  downloadDocument1,
  fetchNetWorth,
  getBeneficiary,
  getOwnerByUserId,
  getSecondaryUser,
  getToken,
  getTrustByUserId,
  getUser,
} from "../../services/user-service";
import Deletebutton from "./Deletebutton";
import UpdateButton from "./UpdateButton";
import SharedBeneficiary from "../../components/SharedBeneficiary";

function Banks() {
  // const [bankName, setBankName] = React.useState("");
  const [selectedImage, setSelectedImage] = useState([]);

  const [category, setCategory] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [beneficiaryVisible, setBeneficiaryVisible] = useState(false);

  const [selectedNote, setSelectedNote] = useState("");
  const [popupVisibleDownlaod, setPopupVisibleDownlaod] = useState(false);
  const [selectedDownlaod, setSelectDownload] = useState("");
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});

  const [benevisible, setbeneVisible] = useState(false);
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [show, setShow] = useState(false);
  const [beneficiary, setBenificiary] = useState([]);
  const [distributionType, setDistributionType] = useState("");
  const [bankFieldClicked, setBankFieldClicked] = useState(false);
  const [account, setAccount] = useState([]);
  const [visibleColumnIndex, setVisibleColumnIndex] = useState(0);
  let [showAdditionField, SetshowAdditionField] = useState(false);
  let [card, setCard] = useState([]);
  let [showDetail, setShowDetail] = useState([]);
  let [show1, setShow1] = useState(false);
  let [otherPropertyTypes, setOtherPropertyTypes] = useState(false);
  let [form1, setForm1] = useState(false);
  // let userId = getUser().id;

  const [data, setData] = useState({
    bank: {
      owner: "",
      bankName: "",
      safetyBox: "",
      safetyBoxNumber: "",
      notes: "",
      bankType: "",
      totalAmount: "",
      trust: "",
    },
    accounts: [
      {
        accountType: "",
        accountNumber: "",
        balance: "",
      },
    ],
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  // for owner field
  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "owner") {
      // Convert ownerName array to a single string
      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        bank: {
          ...prevData.bank,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  const handleImageChange = (event, maxFilesAllowed) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      if (selectedFilesArray.length > maxFilesAllowed) {
        // Notify the user if they exceed the file limit
        toast.error(`You can only select up to ${maxFilesAllowed} files.`, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        event.target.value = ""; // Clear the file input
      } else {
        const invalidFiles = selectedFilesArray.filter((file) => {
          const fileNameParts = file.name.split(".");
          const fileExtension =
            fileNameParts[fileNameParts.length - 1].toLowerCase();
          return !allowedExtensions.includes(fileExtension);
        });

        if (invalidFiles.length === 0) {
          const formData = new FormData();
          selectedFilesArray.forEach((file, index) => {
            formData.append(`file${index + 1}`, file);
          });

          // Update your state or perform actions with the selected files
          setSelectedImage(selectedFilesArray);
        } else {
          const invalidExtensions = invalidFiles
            .map((file) => file.name.split(".").pop())
            .join(", ");
          // Notify the user about invalid file extensions
          toast.error(
            `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
            {
              position: toast.POSITION.BOTTOM_CENTER,
            }
          );

          // Clear the file input field
          event.target.value = "";
        }
      }
    }
  };
  const bankHandleChange = (event, newValue) => {
    const selectedBank = newValue;
    if (selectedBank === "other") {
      setOtherPropertyTypes(true);
      setData({
        ...data,
        bank: {
          ...data.bank,
          bankType: "other",
          bankName: "",
        },
      });
    } else {
      setOtherPropertyTypes(false);
      setData({
        ...data,
        bank: {
          ...data.bank,
          bankType: "",
          bankName: selectedBank,
        },
      });
    }
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [currentIdType, setCurrentIdType] = useState("");
  const removeBank = (id, idType) => {
    setShowConfirmation(true);
    setCurrentId(id);
    setCurrentIdType(idType);
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const BankForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();

    const formData = new FormData();
    for (let i = 0; i < selectedImage.length; i++) {
      formData.append(`files`, selectedImage[i]);
    }
    formData.append("data", JSON.stringify(data));
    console.log("data: ", data);

    addBank(formData, token)
      .then(() => {
        AddCard();
        toast.success("Data Added !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        fetchNetWorth(primaryUserDetails.commonId);
        toggle();
      })
      .catch(() => {});
  };

  const handleRemove = (params) => {
    setShowConfirmation(false);
    const { Id, idType } = params;
    if (idType === "bankId") {
      deleteBank(Id)
        .then(() => {
          toast.success("Deleted successfully...", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          fetchNetWorth(primaryUserDetails.commonId);

          AddCard();
          setShow1(false);
        })
        .catch(() => {});
    } else {
      deleteSingleProperty(Id)
        .then(() => {
          fetchNetWorth(primaryUserDetails.commonId);
          setBeneficiaryVisible(!beneficiaryVisible);
          setShow1(false);
          AddCard();
          toast.success("Deleted successfully...", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        })
        .catch(() => {});
    }
  };
  const handleDownload = (id, fileName) => {
    let myarry = fileName.split(".");
    downloadDocument1(id)
      .then((response) => {
        const downloadUrl = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `${myarry[0]}.${myarry[1]}`;
        link.click();
        URL.revokeObjectURL(downloadUrl);
      })
      .catch(() => {});
  };
  const handleOpenPopup = (note) => {
    setSelectedNote(note);
    setPopupVisible(true);
  };
  const handleOpenBeneficiary = (showDetail) => {
    setBeneficiaryVisible(true);
  };
  const handleShowDownlaod = (showDetail) => {
    setPopupVisibleDownlaod(true);
    setSelectDownload(showDetail);
  };
  const toggle = () => {
    resetForm();
    setForm1(!form1);
  };
  // card creating
  const AddCard = () => {
    let userId = getUser().commonId;
    let token = "Bearer " + getToken();

    getBank(token, userId)
      .then((res) => {
        setCard(res);
        setCategory(res);
      })
      .catch(() => {
        setCard([]);
      });
  };
  const Showdetails = (obj) => {
    setShowDetail(obj);
    setShow1(true);
  };
  const getBenificiarydata = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch(() => {});
  };
  const getBankName = (bankName) => {
    return category.some((item) => item.bank.bankName === bankName);
  };

  const getBenificiaryName = (id) => {
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }

    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found"; // Or handle the case where beneficiary with the given ID isn't found
    }
  };

  const bankLogo = [
    { "JPMorgan Chase & Co": "/logos/J.P._Morgan_Chase_logo_PNG3.png" },
    { "Bank of America": "/logos/Bank_of_America_logo_PNG4.png" },
    { "Wells Fargo & Co": "/logos/Wells_fargo_logo_PNG2.png" },
    { "Citigroup Inc": "/logos/Citigroup_logo_PNG1.png" },
    { "U.S. Bancorp": "/logos/US-Bank-Logo-PNG3.png" },
    { "PNC bank": "/logos/PNC_Bank_logo_PNG1.png" },
    { "TD Bank": "/logos/TD_Bank_logo_PNG1.png" },
    { "Capital One": "/logos/Capital_One_logo_PNG1.png" },
    { "Fifth Third Bank": "/logos/Harris-Teeter-Logo-PNG_003-1.png" },
    { "Ally Financial Inc": "/logos/Ally_Financial_logo_PNG4.png" },
    { "Huntington Bancshares Incorporated": "/logos/huntington.webp" },
    { KeyCorp: "/logos/KeyBank_logo_PNG7.png" },
  ];

  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
    data.bank.safetyBox = !isChecked;
    if (isChecked) {
      data.bank.safetyBoxNumber = "";
    }
  };

  const [sharedType, setSharedType] = useState(null);
  const handleChangesBank = (e, field) => {
    const newValue = e.target.value;
    if (field === "sharedType") {
      setSharedType(newValue);

      setData((prevData) => {
        const resetSharedDetails = [
          {
            distributedType: "",
            distributedValue: "",
            distributedAmount: "",
            beneficiaryId: "",
          },
        ];

        return {
          ...prevData,
          sharedDetails: resetSharedDetails,
        };
      });
      setData((prevData) => ({
        ...prevData,
        bank: {
          ...prevData.bank,
          trust: "",
        },
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        bank: {
          ...prevData.bank,
          [field]: newValue,
        },
      }));
    }
  };

  const [totalCalculatedAmount, setTotalCalculatedAmount] = useState(null);
  const handleChanges1 = (e, newValue, field, { index }) => {
    if (field === "accountType") {
      if (newValue != null) {
        // Update selected bank types based on the index
        setSelectedBankTypes((prevSelectedBankTypes) => {
          const newSelectedBankTypes = [...prevSelectedBankTypes];
          newSelectedBankTypes[index] = newValue;
          return newSelectedBankTypes;
        });

        // Update the account type in the data state
        setData((prevData) => {
          const updatedAccounts = [...prevData.accounts];
          updatedAccounts[index] = {
            ...updatedAccounts[index],
            accountType: newValue,
          };

          return {
            ...prevData,
            accounts: updatedAccounts,
          };
        });
      }
    } else {
      // Handle changes for other fields
      const { value } = e.target;
      setData((prevData) => {
        const updatedAccounts = [...prevData.accounts];
        if (!updatedAccounts[index]) {
          updatedAccounts[index] = {};
        }
        updatedAccounts[index][field] = value;

        // Calculate total balance and update data.bank.totalAmount
        const balances = updatedAccounts.map((account) =>
          account.balance ? parseFloat(account.balance) : 0
        );
        const newTotalBalance = balances.reduce(
          (acc, curr) => acc + parseFloat(curr),
          0
        );

        return {
          ...prevData,
          accounts: updatedAccounts,
          bank: {
            ...prevData.bank,
            totalAmount: newTotalBalance,
          },
        };
      });
    }
  };

  const maskAccountNumber = (accountNumber) => {
    if (!accountNumber) return ""; // Handle empty or null input

    // If account number length is less than or equal to 4, return it as-is
    if (accountNumber.length <= 4) {
      return accountNumber;
    }

    // Mask all but the last four digits
    const maskedPart = "*".repeat(accountNumber.length - 4);
    const lastFourDigits = accountNumber.slice(-4);
    return `${maskedPart}${lastFourDigits}`;
  };

  // multiple account
  const multipleAccount = [0, 1, 2, 3];
  const handleAddBank = () => {
    if (visibleColumnIndex < 3) {
      setAccount([...account, { label: visibleColumnIndex + 1 }]);
      setVisibleColumnIndex(visibleColumnIndex + 1);
    }
  };

  const handleRemoveBank = (indexToRemove) => {
    if (visibleColumnIndex > 0) {
      // Filter out the account with the index to remove
      const updatedAccounts = account.filter(
        (_, index) => index !== indexToRemove - 1
      );
      setAccount(updatedAccounts);

      // Adjust visibleColumnIndex if the removed index is within the current visible range
      if (indexToRemove - 1 < visibleColumnIndex) {
        setVisibleColumnIndex(visibleColumnIndex - 1);
      }
    }

    setData((prevData) => {
      const updatedAccounts = prevData.accounts.map((account, index) => {
        if (index === indexToRemove) {
          // Create a new object with empty values for the removed index
          return {
            accountType: "",
            accountNumber: "",
            balance: "",
          };
        }
        return account; // Return the original bank object for other indices
      });

      // Filter out accounts with empty strings for accountType, accountNumber, and balance
      const filteredAccounts = updatedAccounts.filter(
        (account) =>
          account.accountType !== "" ||
          account.accountNumber !== "" ||
          account.balance !== ""
      );

      // Update the state with the new array of bank accounts
      const newData = {
        ...prevData,
        accounts: filteredAccounts,
      };

      // Perform further operations directly after updating the state
      // Filter out accounts with empty strings for accountType, accountNumber, and balance
      const furtherFilteredAccounts = newData.accounts.filter(
        (account) =>
          account.accountType !== "" ||
          account.accountNumber !== "" ||
          account.balance !== ""
      );

      // Update the state with the further filtered accounts
      setData({
        ...newData,
        accounts: furtherFilteredAccounts,
      });

      return newData;
    });

    setSelectedBankTypes((prevSelectedBankTypes) => {
      const newSelectedBankTypes = [...prevSelectedBankTypes];
      newSelectedBankTypes.splice(indexToRemove, 1);
      return newSelectedBankTypes;
    });
  };

  const [selectedBankTypes, setSelectedBankTypes] = useState(
    Array(multipleAccount.length).fill("")
  );
  const handleReset = () => {
    setbeneVisible(false);
  };

  const [visibleAccountIndices, setVisibleAccountIndices] = useState([]);

  const toggleVisibility = (index) => {
    const currentIndex = visibleAccountIndices.indexOf(index);
    const newVisibleIndices = [...visibleAccountIndices];

    if (currentIndex === -1) {
      newVisibleIndices.push(index);
    } else {
      newVisibleIndices.splice(currentIndex, 1);
    }

    setVisibleAccountIndices(newVisibleIndices);
  };

  const renderAccountNumber = (accountNumber, index) => {
    if (visibleAccountIndices.includes(index)) {
      return accountNumber;
    } else {
      // Mask all but the last four digits
      const maskedDigits = accountNumber.slice(0, -4).replace(/\d/g, "*");
      const lastFourDigits = accountNumber.slice(-4);
      return maskedDigits + lastFourDigits;
    }
  };

  const [trusts, setTrusts] = useState([]);
  const getTrustsData = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getTrustByUserId(token, userId)
      .then((res) => {
        setTrusts(res);
      })
      .catch((err) => {});
  };

  const handleTrustSave = () => {
    setbeneVisible(false);
  };

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.bank.totalAmount);
  };
  const handleCloseBeneficiaryPopup = (params) => {
    const { name } = params;
    if (name !== undefined) {
      setData((prevData) => ({
        ...prevData,
        sharedDetails: name,
      }));
    }
    setbeneVisible(false);
  };

  const resetForm = () => {
    setData({
      bank: {
        owner: "",
        bankName: "",
        safetyBox: "",
        safetyBoxNumber: "",
        notes: "",
        bankType: "",
        totalAmount: "",
      },
      accounts: [
        {
          accountType: "",
          accountNumber: "",
          balance: "",
        },
      ],
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });
    setOwnerName([]);
    handleReset();
    setIsChecked(false);
    getBankName("");
    setBankFieldClicked(false);
    setVisibleColumnIndex(0);
    setEstimatedTotalAmount(0);
    setSelectedBankTypes("");
  };

  useEffect(() => {
    AddCard();
    getBenificiarydata();
    getTrustsData();
  }, []);

  return (
    <div className={`your-component ${show ? "fade-in-element" : ""}`}>
      <UserBase>
        <div className="mt-5"></div>
        <SideBar>
          {primaryUserDetails.accountType === "secondary" &&
          secondaryUserDetails.accountType === "primary" &&
          secondaryUserDetails.isSecondaryUserEditable === "false" ? (
            ""
          ) : (
            <div className="addme">
              <div className="addme_inner">
                <button onClick={() => toggle()}>Add New Bank</button>
              </div>
            </div>
          )}

          <div className="propCard">
            <div className="propCard-card">
              {card.map((entity) => (
                <div className="propCard-card-body" key={entity.bank.id}>
                  <div className="propCard_card_status">
                    <p
                      className={`propCard_card_status_text ${
                        entity.sharedDetails.length > 0
                          ? "completed"
                          : "incomplete"
                      }`}
                    >
                      {entity.sharedDetails.length > 0
                        ? "completed"
                        : "incomplete"}
                    </p>
                  </div>
                  <div className="propCard-card-title">
                    {entity.bank.bankType === "other"
                      ? entity.bank.bankName
                      : bankLogo.map((bank) => {
                          const [bankName, logoUrl] = Object.entries(bank)[0];
                          if (bankName === entity.bank.bankName) {
                            return (
                              <div className="card_bank_main" key={bankName}>
                                <h5 className="card_banks">
                                  {entity.bank.bankName}
                                </h5>
                                <div className="card-image">
                                  <img
                                    src={logoUrl}
                                    alt={`Logo for ${bankName}`}
                                  />
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}
                  </div>
                  {entity.accounts && (
                    <p className="propCard-card-text">
                      {maskAccountNumber(entity.accounts[0].accountNumber)}
                    </p>
                  )}
                  <div className="propCard-btn">
                    <p className="viewDetails">
                      <button onClick={() => Showdetails(entity)}>
                        View Details
                      </button>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {form1 && (
            <div
              className="overlay1"
              style={{ transition: "500ms", height: "" }}
            >
              <div className="addform_ichest">
                <div className="addform_main addform_main_bank">
                  <div className="addform_heading">
                    <h3 className="addform_heading_h1">Add Bank Properties</h3>
                    <div className="addform_close" onClick={toggle}>
                      <FontAwesomeIcon icon={faXmark} />
                    </div>
                  </div>
                  <div className="addform_body">
                    <Form onSubmit={BankForm} className="addform_body">
                      <div className="addform_select_body">
                        <div className="addform_body_left addform_body_left_bank">
                          <div>
                            <Tooltip title="Select Owner" className="mt-2">
                              <div required>
                                <FormLabel required>Select Owner</FormLabel>
                                <Select
                                  value={ownerName}
                                  multiple
                                  placeholder="Select Name"
                                  onChange={(event, newValue) =>
                                    handleChange(event, newValue, "owner")
                                  }
                                  sx={{
                                    minWidth: "13rem",
                                  }}
                                  required
                                >
                                  {ownerNames.map((name) => (
                                    <Option key={name} value={name}>
                                      {name}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </Tooltip>
                          </div>

                          <div className="mt-2">
                            <Tooltip title="Enter name of the bank">
                              <div>
                                <FormLabel required>Bank Name</FormLabel>
                                <Select
                                  id="banks"
                                  placeholder="Select Bank Name"
                                  onChange={(event, newValue) =>
                                    bankHandleChange(event, newValue)
                                  }
                                  value={data.bank.bankName || ""}
                                  required
                                >
                                  <Option
                                    value={"JPMorgan Chase & Co"}
                                    disabled={getBankName(
                                      "JPMorgan Chase & Co"
                                    )}
                                  >
                                    <img
                                      src="/logos/J.P._Morgan_Chase_logo_PNG3.png"
                                      alt="logo"
                                      style={{
                                        width: "45px",
                                        aspectRatio: "16/9",
                                        objectFit: "contain",
                                      }}
                                    />
                                    &nbsp;&nbsp;JPMorgan Chase & Co
                                  </Option>
                                  <Option
                                    value={"Bank of America"}
                                    disabled={getBankName("Bank of America")}
                                  >
                                    <img
                                      src="/logos/Bank_of_America_logo_PNG4.png"
                                      alt="logo"
                                      style={{
                                        width: "45px",
                                        aspectRatio: "16/9",
                                        objectFit: "contain",
                                      }}
                                    />
                                    &nbsp;&nbsp;Bank of America
                                  </Option>
                                  <Option
                                    value={"Wells Fargo & Co"}
                                    disabled={getBankName("Wells Fargo & Co")}
                                  >
                                    <img
                                      src="/logos/Wells_fargo_logo_PNG2.png"
                                      alt="logo"
                                      style={{
                                        width: "45px",
                                        aspectRatio: "16/9",
                                        objectFit: "contain",
                                      }}
                                    />
                                    &nbsp;Wells Fargo & Co
                                  </Option>
                                  <Option
                                    value={"Citigroup Inc"}
                                    disabled={getBankName("Citigroup Inc")}
                                  >
                                    <img
                                      src="/logos/Citigroup_logo_PNG1.png"
                                      alt="logo"
                                      style={{
                                        width: "40px",
                                        aspectRatio: "16/9",
                                        objectFit: "contain",
                                      }}
                                    />
                                    &nbsp;&nbsp;Citigroup Inc
                                  </Option>
                                  <Option
                                    value={"U.S. Bancorp"}
                                    disabled={getBankName("U.S. Bancorp")}
                                  >
                                    <img
                                      src="/logos/US-Bank-Logo-PNG3.png"
                                      alt="logo"
                                      style={{
                                        width: "45px",
                                        aspectRatio: "16/9",
                                        objectFit: "contain",
                                      }}
                                    />
                                    &nbsp;U.S. Bancorp
                                  </Option>
                                  <Option
                                    value={"PNC bank"}
                                    disabled={getBankName("PNC bank")}
                                  >
                                    <img
                                      src="/logos/PNC_Bank_logo_PNG1.png"
                                      alt="logo"
                                      style={{
                                        width: "45px",
                                        aspectRatio: "16/9",
                                        objectFit: "contain",
                                      }}
                                    />
                                    &nbsp;&nbsp;PNC bank
                                  </Option>
                                  <Option
                                    value={"TD Bank"}
                                    disabled={getBankName("TD Bank")}
                                  >
                                    <img
                                      src="/logos/TD_Bank_logo_PNG1.png"
                                      alt="logo"
                                      style={{
                                        width: "45px",
                                        aspectRatio: "16/9",
                                        objectFit: "contain",
                                      }}
                                    />
                                    &nbsp;TD Bank
                                  </Option>
                                  <Option
                                    value={"Capital One"}
                                    disabled={getBankName("Capital One")}
                                  >
                                    <img
                                      src="/logos/Capital_One_logo_PNG1.png"
                                      alt="logo"
                                      style={{
                                        width: "45px",
                                        aspectRatio: "16/9",
                                        objectFit: "contain",
                                      }}
                                    />
                                    &nbsp;&nbsp;Capital One
                                  </Option>
                                  <Option
                                    value={"Fifth Third Bank"}
                                    disabled={getBankName("Fifth Third Bank")}
                                  >
                                    <img
                                      src="/logos/Harris-Teeter-Logo-PNG_003-1.png"
                                      alt="logo"
                                      style={{
                                        width: "45px",
                                        aspectRatio: "16/9",
                                        objectFit: "contain",
                                      }}
                                    />
                                    &nbsp;Fifth Third Bank
                                  </Option>
                                  <Option
                                    value={"Ally Financial Inc"}
                                    disabled={getBankName("Ally Financial Inc")}
                                  >
                                    <img
                                      src="/logos/Ally_Financial_logo_PNG4.png"
                                      alt="logo"
                                      style={{
                                        width: "45px",
                                        aspectRatio: "16/9",
                                        objectFit: "contain",
                                      }}
                                    />
                                    &nbsp;Ally Financial Inc
                                  </Option>
                                  <Option
                                    value={"Huntington Bancshares Incorporated"}
                                    disabled={getBankName(
                                      "Huntington Bancshares Incorporated"
                                    )}
                                  >
                                    <img
                                      src="/logos/huntington.webp"
                                      alt="logo"
                                      style={{
                                        width: "45px",
                                        aspectRatio: "16/9",
                                        objectFit: "contain",
                                      }}
                                    />
                                    &nbsp;Huntington Bancshares Incorporated
                                  </Option>
                                  <Option
                                    value={"KeyCorp"}
                                    disabled={getBankName("KeyCorp")}
                                  >
                                    <img
                                      src="/logos/KeyBank_logo_PNG7.png"
                                      alt="logo"
                                      style={{
                                        width: "45px",
                                        aspectRatio: "16/9",
                                        objectFit: "contain",
                                      }}
                                    />
                                    &nbsp;&nbsp;KeyCorp
                                  </Option>
                                  <Option value={"other"}>Other</Option>
                                </Select>
                              </div>
                            </Tooltip>
                          </div>
                          {otherPropertyTypes && (
                            <div className="mt-2">
                              <Tooltip title="Enter Your Other Bank Name ">
                                <div>
                                  <FormLabel>Other Bank</FormLabel>
                                  <Input
                                    className="input_mui_joy"
                                    placeholder="Enter Other Bank"
                                    id="otherprop"
                                    onChange={(e) =>
                                      handleChangesBank(e, "bankName")
                                    }
                                    value={data.bank.bankName}
                                    aria-readonly
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          )}

                          <div
                            className="mt-3 form1-double"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              gap: "5px",
                            }}
                          >
                            <div
                              style={{
                                width: "49.5%",
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <div
                                className="txt"
                                style={{
                                  alignSelf: "center",
                                  marginRight: "10px",
                                  flex: "1 1 100%",
                                }}
                              >
                                Safety Box
                              </div>
                              <div
                                className="switch"
                                style={{
                                  flex: "1 1 100%",
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={isChecked}
                                      onChange={handleSwitchChange}
                                      color="primary"
                                      size="normal"
                                    />
                                  }
                                  label={isChecked ? "Yes" : "No"}
                                  labelPlacement="end"
                                />
                              </div>
                            </div>

                            {isChecked && (
                              <div className="addform_input_half">
                                <Tooltip title="Enter Your SafetyBox ID">
                                  <div>
                                    <Input
                                      className="input_mui_joy"
                                      placeholder="Safety Box ID"
                                      id="safetyBoxNumber"
                                      onChange={(e) =>
                                        handleChangesBank(e, "safetyBoxNumber")
                                      }
                                      value={data.bank.safetyBoxNumber}
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            )}
                          </div>
                          <div>
                            {multipleAccount.map((index) => (
                              <div
                                className="mt-2"
                                key={index}
                                style={{
                                  flexDirection: "column",
                                  display:
                                    index <= visibleColumnIndex
                                      ? "flex"
                                      : "none",
                                }}
                              >
                                <div>
                                  <Tooltip
                                    title={`Add your bank details for bank ${
                                      index + 1
                                    }`}
                                  >
                                    <div>
                                      <FormLabel id={`accountType${index + 1}`}>
                                        Bank Type {index >= 0 && <span>*</span>}
                                      </FormLabel>
                                      <Select
                                        id={`accountType${index + 1}`}
                                        placeholder="Select Your Account Type"
                                        onChange={(e, newValue) =>
                                          handleChanges1(
                                            e,
                                            newValue,
                                            "accountType",
                                            {
                                              index,
                                            }
                                          )
                                        }
                                        value={
                                          data.accounts[index]?.accountType ||
                                          ""
                                        }
                                        onClick={() =>
                                          setBankFieldClicked(true)
                                        }
                                        required={visibleColumnIndex >= index}
                                      >
                                        <Option
                                          value="Checking Account"
                                          disabled={selectedBankTypes.includes(
                                            "Checking Account"
                                          )}
                                        >
                                          Checking Account
                                        </Option>
                                        <Option
                                          value="Savings Account"
                                          disabled={selectedBankTypes.includes(
                                            "Savings Account"
                                          )}
                                        >
                                          Savings Account
                                        </Option>
                                        <Option
                                          value="Investment Account"
                                          disabled={selectedBankTypes.includes(
                                            "Investment Account"
                                          )}
                                        >
                                          Investment Account
                                        </Option>
                                        <Option
                                          value="C.D Account"
                                          disabled={selectedBankTypes.includes(
                                            "C.D Account"
                                          )}
                                        >
                                          C.D Account
                                        </Option>
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>

                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <Tooltip
                                      title={`Add your bank details for bank ${
                                        index + 1
                                      }`}
                                    >
                                      <div>
                                        <FormLabel required>{`Bank Account ${
                                          index + 1
                                        }`}</FormLabel>
                                        <Input
                                          type="number"
                                          placeholder={`Bank Account ${
                                            index + 1
                                          }`}
                                          id={`BankAccount${index + 1}`}
                                          required={visibleColumnIndex >= index}
                                          onChange={(e) =>
                                            handleChanges1(
                                              e,
                                              null,
                                              "accountNumber",
                                              {
                                                index,
                                              }
                                            )
                                          }
                                          value={
                                            data.accounts[index]
                                              ?.accountNumber || ""
                                          }
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                  <div className="mt-2 addform_input_half">
                                    <Tooltip
                                      title={`Add your bank details for bank ${
                                        index + 1
                                      }`}
                                    >
                                      <div>
                                        <FormLabel required>{`Account Balance ${
                                          index + 1
                                        }`}</FormLabel>
                                        <Input
                                          type="number"
                                          placeholder={`Account Balance ${
                                            index + 1
                                          }`}
                                          id={`Account Balance ${index + 1}`}
                                          required={visibleColumnIndex >= index}
                                          onChange={(e) =>
                                            handleChanges1(e, null, "balance", {
                                              index,
                                            })
                                          }
                                          value={
                                            data.accounts[index]?.balance || ""
                                          }
                                          startDecorator={<div>$</div>}
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                                {index !== 0 && (
                                  <Button
                                    style={{
                                      marginTop: "7px",
                                      height: "30px",
                                      width: "30px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "50%",
                                      backgroundColor: "#ff4a4a",
                                      border: "none",
                                    }}
                                    onClick={() => handleRemoveBank(index)}
                                  >
                                    <FontAwesomeIcon icon={faMinus} />
                                  </Button>
                                )}
                              </div>
                            ))}
                            {visibleColumnIndex < 3 && (
                              <div
                                style={{
                                  marginTop: "7px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Button
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "50%",
                                    backgroundColor: "#4aafff",
                                    border: "none",
                                  }}
                                  onClick={handleAddBank}
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </Button>
                              </div>
                            )}
                          </div>

                          <div>
                            <Tooltip title="Add your banks related file">
                              <div className="mt-3">
                                <FormLabel>Supporting Document</FormLabel>
                                <input
                                  style={{
                                    border: "solid 1px lightgray",
                                    borderLeft: "none",
                                    width: "100%",
                                    borderRadius: "5px",
                                  }}
                                  type="file"
                                  name="myfile"
                                  id="exampleFile"
                                  onChange={(e) => handleImageChange(e, 5)}
                                  accept=".pdf"
                                  multiple
                                />
                              </div>
                            </Tooltip>
                          </div>

                          <div
                            className="bene-select mt-3"
                            onClick={
                              data.bank?.totalAmount !== "" &&
                              parseFloat(data.bank?.totalAmount) > 0
                                ? handleShowBeneficiary
                                : null
                            }
                            style={{
                              cursor:
                                parseFloat(data.bank?.totalAmount) > 0
                                  ? "pointer"
                                  : "not-allowed",
                              opacity:
                                parseFloat(data.bank?.totalAmount) > 0
                                  ? 1
                                  : 0.6,
                            }}
                          >
                            Select Your Beneficiary
                          </div>

                          <div className="address_input">
                            <div style={{ width: "100%" }} className="mt-2">
                              <Tooltip title="Enter notes for your real estate">
                                <div>
                                  <FormLabel>Notes</FormLabel>
                                  <Textarea
                                    sx={{ height: "182px", minHeight: "52px" }}
                                    placeholder="Notes"
                                    id="notes"
                                    onChange={(e) =>
                                      handleChangesBank(e, "notes")
                                    }
                                    value={data.bank.notes}
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        {/* <div className="addform_body_right_bank"></div> */}
                      </div>
                      <div className="text-center">
                        <Button
                          className="my-estate-clear-btn"
                          type="button"
                          onClick={resetForm}
                          outline
                        >
                          Clear
                        </Button>
                        <Button
                          type="submit"
                          outline
                          className="my-estate-add-btn"
                        >
                          Add
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          )}

          {show1 && Object.keys(showDetail).length > 0 && (
            <>
              <div className="card__data">
                <div className="card__data-container">
                  <section className="section1">
                    <div>
                      <p className="row1-text">
                        <FontAwesomeIcon
                          icon={faBuildingColumns}
                          style={{ color: "#025596", fontSize: "18px" }}
                        />
                        <span>{showDetail.bank.bankName}</span>
                      </p>
                      <div className="row1-button">
                        <div>
                          {showDetail.documents &&
                            showDetail.documents.length > 0 && (
                              <Tooltip title="click to see multiple downlaod files">
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleShowDownlaod(showDetail);
                                    setShow1(false);
                                  }}
                                >
                                  <div className="myestate_download_button dwnbtn">
                                    <FontAwesomeIcon
                                      className="myestate_download_icon"
                                      icon={faDownload}
                                    />
                                    <span></span>
                                  </div>
                                </div>
                              </Tooltip>
                            )}
                        </div>

                        {primaryUserDetails.accountType === "secondary" &&
                        secondaryUserDetails.accountType === "primary" &&
                        secondaryUserDetails.isSecondaryUserEditable ===
                          "false" ? (
                          ""
                        ) : (
                          <div>
                            <Tooltip title="Click Here To Edit">
                              <div>
                                <UpdateButton
                                  URL={"../my-estate/banks/"}
                                  id={showDetail.bank.id}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        )}

                        {primaryUserDetails.accountType === "secondary" &&
                        secondaryUserDetails.accountType === "primary" &&
                        secondaryUserDetails.isSecondaryUserEditable ===
                          "false" ? (
                          ""
                        ) : (
                          <div>
                            <FontAwesomeIcon
                              onClick={() =>
                                removeBank(showDetail.bank.id, "bankId")
                              }
                              className="delete-button-comp"
                              style={{ cursor: "pointer" }}
                              icon={faTrash}
                            />
                          </div>
                        )}

                        <div>
                          <span
                            className="card__data-close"
                            onClick={() => {
                              setShow1(!show1);
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="section2">
                    <div>
                      <div className="col1">
                        {showDetail.bank.owner && (
                          <p>
                            Owner : <code>{showDetail.bank.owner}</code>
                          </p>
                        )}

                        {showDetail.bank.bankType !== "Other" &&
                        showDetail.bank.bankName ? (
                          <p>
                            Bank Name:
                            <code>
                              {/* <img
                                src={bankLogo[showDetail.bank.bankName]}
                                style={{
                                  width: "45px",
                                  aspectRatio: "16/9",
                                  objectFit: "contain",
                                }}
                                alt={`Logo for ${showDetail.bank.bankName}`}
                              /> */}
                              &nbsp;&nbsp;{showDetail.bank.bankName}
                            </code>
                          </p>
                        ) : (
                          <p>
                            Other Banks: <code>{showDetail.bank.bankName}</code>
                          </p>
                        )}
                        {
                          <Tooltip title={`Click To See Details`}>
                            <p
                              onClick={() => {
                                SetshowAdditionField(showDetail);
                                setShow1(false);
                              }}
                            >
                              Additional Accounts Details:&nbsp;
                              <code>
                                <span className="readmore">Click to see</span>
                              </code>
                            </p>
                          </Tooltip>
                        }
                        <p>
                          Estimated Total Bank Assets:{" "}
                          <code style={{ color: "green", fontWeight: "bold" }}>
                            {formatAmount(showDetail.bank.totalAmount)}
                          </code>
                        </p>
                      </div>
                      <div className="col2">
                        {showDetail.bank.safetyBox ? (
                          <p>
                            Safty Box:{" "}
                            <code>
                              {showDetail.bank.safetyBox === "true" ? (
                                "yes"
                              ) : (
                                <span style={{ color: "red" }}>No</span>
                              )}
                            </code>
                          </p>
                        ) : (
                          ""
                        )}
                        {showDetail.bank.safetyBoxNumber ? (
                          <p>
                            SaftyBox ID:{" "}
                            <code>
                              {showDetail.bank.safetyBoxNumber === "" ? (
                                ""
                              ) : (
                                <span>{showDetail.bank.safetyBoxNumber}</span>
                              )}
                            </code>
                          </p>
                        ) : (
                          ""
                        )}
                        {showDetail.sharedDetails[0] && (
                          <p
                            onClick={() => {
                              handleOpenBeneficiary(showDetail);
                              setShow1(false);
                            }}
                          >
                            Beneficiary Details{" "}
                            <code>
                              <span className="readmore">Click Here</span>
                            </code>
                          </p>
                        )}
                        {showDetail.bank.notes && (
                          <Tooltip title="Click To see Note">
                            <p
                              onClick={() => {
                                handleOpenPopup(showDetail.bank.notes);
                                setShow1(false);
                              }}
                            >
                              Note:{" "}
                              <code>
                                {showDetail && showDetail.bank.notes
                                  ? showDetail.bank.notes.slice(0, 5)
                                  : ""}
                                ...<span className="readmore">Read More</span>
                              </code>
                            </p>
                          </Tooltip>
                        )}

                        {/* <p
                          onClick={() => {
                            handleOpenNewBank();
                            setShow1(false);
                          }}
                        >
                          New Bank UI:{" "}
                          <code>
                            <span className="readmore">Click Here</span>
                          </code>
                        </p> */}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </>
          )}

          {showConfirmation && (
            <Deletebutton
              handleRemove={handleRemove}
              Id={currentId}
              idType={currentIdType}
              handleConfirmationClose={handleConfirmationClose}
            />
          )}

          {/*  download popup */}
          {popupVisibleDownlaod && (
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h2>Download Files</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisibleDownlaod(false);
                          setShow1(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div>
                    {selectedDownlaod.documents &&
                      selectedDownlaod.documents.length > 0 &&
                      selectedDownlaod.documents.map((file, index) => (
                        <div key={index} style={{ marginBottom: "20px" }}>
                          <Tooltip title={file.fileName}>
                            <div
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                gap: "20px",
                              }}
                              onClick={() => {
                                handleDownload(file.id, file.fileName);
                              }}
                            >
                              Download - {index + 1}
                              <div className="myestate_download_button dwnbtn">
                                <FontAwesomeIcon
                                  className="myestate_download_icon"
                                  icon={faDownload}
                                />
                                <span>{file.fileName}</span>
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {beneficiaryVisible && (
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h2>share property</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setBeneficiaryVisible(false);
                          setShow1(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div>
                    {showDetail.sharedDetails &&
                      showDetail.sharedDetails.map((details, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "5px",
                            padding: "10px",
                            border: "solid 1px lightgray",
                            borderRadius: "5px",
                            minWidth: "230px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "17px",
                                color: "black",
                                fontWeight: "500",
                              }}
                            >
                              Share - {index + 1}
                            </p>
                            {primaryUserDetails.accountType === "secondary" &&
                            secondaryUserDetails.accountType === "primary" &&
                            secondaryUserDetails.isSecondaryUserEditable ===
                              "false" ? (
                              ""
                            ) : (
                              <FontAwesomeIcon
                                onClick={() =>
                                  removeBank(details.id, "sharedDetailId")
                                }
                                className="delete-button-comp"
                                style={{ cursor: "pointer" }}
                                icon={faTrash}
                              />
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: "20px",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "15px",
                                color: "black",
                                fontWeight: "400",
                                marginLeft: "20px",
                              }}
                            >
                              Beneficiary Name:
                              <span style={{ marginLeft: "10px" }}>
                                {getBenificiaryName(details.beneficiaryId)}
                              </span>
                            </p>
                            <p
                              style={{
                                fontSize: "15px",
                                color: "black",
                                fontWeight: "400",
                                marginLeft: "20px",
                              }}
                            >
                              Distributed Type:
                              <span style={{ marginLeft: "10px" }}>
                                {details.distributedType}
                              </span>
                            </p>
                            <p
                              style={{
                                fontSize: "15px",
                                color: "black",
                                fontWeight: "400",
                                marginLeft: "20px",
                              }}
                            >
                              Distributed Value:{" "}
                              <span style={{ marginLeft: "10px" }}>
                                {details &&
                                  details.distributedType === "dollar" &&
                                  "$"}
                                {details.distributedValue}
                                {details.distributedType === "percentage" &&
                                  "%"}
                              </span>
                            </p>
                            <p
                              style={{
                                fontSize: "15px",
                                color: "black",
                                fontWeight: "400",
                                marginLeft: "20px",
                              }}
                            >
                              Distributed Amount:
                              <span style={{ marginLeft: "10px" }}>
                                ${details.distributedAmount}
                              </span>
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {popupVisible && (
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div>
                      <h2>Note</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisible(false);
                          setShow1(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div>
                    <p>{selectedNote}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {showAdditionField && (
            // Display the popup when a row is selected
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup_heading">
                  <div style={{ width: "100%" }}>
                    <h2 style={{ textAlign: "center" }}>Accounts Details</h2>
                  </div>
                  <div>
                    <button
                      className="note_popup_heading_close_btn"
                      onClick={() => {
                        SetshowAdditionField(false);
                        setShow1(true);
                        setVisibleAccountIndices([]);
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  </div>
                </div>
                {/* <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", }} > */}
                {showDetail.accounts &&
                  showDetail.accounts.map((account, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "5px",
                        padding: "10px",
                        border: "solid 1px lightgray",
                        borderRadius: "5px",
                        minWidth: "230px",
                      }}
                    >
                      <div>
                        <p
                          style={{
                            fontSize: "17px",
                            color: "black",
                            fontWeight: "500",
                          }}
                        >
                          Account - {index + 1}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "20px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "15px",
                            color: "black",
                            fontWeight: "400",
                            marginLeft: "20px",
                          }}
                        >
                          Account Number:
                          <span
                            style={{ marginLeft: "10px", fontWeight: "500" }}
                          >
                            {renderAccountNumber(account.accountNumber, index)}
                          </span>
                          <button
                            onClick={() => toggleVisibility(index)}
                            style={{
                              marginLeft: "10px",
                              border: "none",
                              background: "none",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={
                                visibleAccountIndices.includes(index)
                                  ? faEye
                                  : faEyeSlash
                              }
                            />
                          </button>
                        </p>
                        <p
                          style={{
                            fontSize: "15px",
                            color: "black",
                            fontWeight: "400",
                            marginLeft: "20px",
                          }}
                        >
                          Account Type:{" "}
                          <span
                            style={{ marginLeft: "10px", fontWeight: "500" }}
                          >
                            {account.accountType}
                          </span>
                        </p>
                        <p
                          style={{
                            fontSize: "15px",
                            color: "black",
                            fontWeight: "400",
                            marginLeft: "20px",
                          }}
                        >
                          Account Balance:
                          <span
                            style={{ marginLeft: "10px", fontWeight: "500" }}
                          >
                            {formatAmount(account.balance)}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}

                {/* </div> */}
              </div>
            </div>
          )}

          {benevisible && (
            <SharedBeneficiary
              handleTrustSave={handleTrustSave}
              assetData={data?.bank}
              trusts={trusts}
              handleChanges={handleChangesBank}
              sharedType={sharedType}
              handleCloseBeneficiaryPopup={handleCloseBeneficiaryPopup}
              estimatedTotalAmount={estimatedTotalAmount}
              typeBeneficiary={"normal"}
              sharedDetails={data?.sharedDetails}
            />
          )}
        </SideBar>
      </UserBase>
    </div>
  );
}

export default Banks;
