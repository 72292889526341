import {
  faChevronDown,
  faChevronUp,
  faCircleInfo,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LegacyDetails from "../components/legacydetails/legacydetails";
import SideBar from "../components/sidebar/Sidebar";
import UserBase from "../components/user/UserBase";
import "./../css/legacychart.css";
import {
  getBeneficiary,
  getCombinedData,
  getToken,
  getUser,
} from "../services/user-service";
import { formatAmount } from "../components/Common";

function Legacychary() {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [expandedAssets, setExpandedAssets] = useState({});
  const [viewDetail, setViewDetail] = useState(null);

  const transformData = (data) => {
    const status = (sharedDetails) =>
      sharedDetails?.length > 0 ? "Completed" : "Incomplete";

    // Helper function to check if data is non-empty
    const hasData = (dataArray) => dataArray && dataArray.length > 0;

    const transformSection = (
      name,
      dataArray,
      detailMappings,
      edit_url,
      url
    ) => ({
      name,
      details: hasData(dataArray)
        ? dataArray.map((item) => ({
            ...detailMappings(item),
            Status: status(item.sharedDetails),
            edit_url,
            url,
          }))
        : [], // Return an empty array if no data
    });

    const sections = [
      transformSection(
        "Real Estate",
        data[0]?.realEstateData,
        (item) => ({
          id: item.realEstate.id,
          detail1: item.realEstate.propertyType,
          detail2: item.realEstate.owner,
          detail3: formatAmount(item.realEstate.estPropertyVal),
          detail4: item?.sharedDetails?.length
            ? item.sharedDetails.map((detail) => detail.beneficiaryId)
            : null,
        }),
        "/user/my-estate/real-estate/",
        "real-estate"
      ),
      transformSection(
        "Bank",
        data[1]?.bankData,
        (item) => ({
          id: item.bank.id, // Ensure the id field is added correctly
          detail1: item.bank.bankName,
          detail2: item.bank.owner,
          detail3: formatAmount(item.bank.totalAmount),
          detail4: item?.sharedDetails?.length
            ? item.sharedDetails.map((detail) => detail.beneficiaryId)
            : null,
        }),
        "/user/my-estate/banks/",
        "banks"
      ),
      transformSection(
        "Jewelry",
        data[2]?.jewelryData,
        (item) => ({
          id: item.jewelry.id, // Ensure the id field is added correctly
          detail1: item.jewelry.jewelryName,
          detail2: item.jewelry.owner,
          detail3: formatAmount(item.jewelry.estimatedValue),
          detail4: item?.sharedDetails?.length
            ? item.sharedDetails.map((detail) => detail.beneficiaryId)
            : null,
        }),
        "/user/my-estate/jewelry/",
        "jewelry"
      ),
      transformSection(
        "Vehicle",
        data[3]?.vehicleData,
        (item) => ({
          id: item.vehicle.id, // Ensure the id field is added correctly
          detail1: item.vehicle.vehicleCaption,
          detail2: item.vehicle.owner,
          detail3: formatAmount(item.vehicle.estValue),
          detail4: item?.sharedDetails?.length
            ? item.sharedDetails.map((detail) => detail.beneficiaryId)
            : null,
        }),
        "/user/my-estate/vehicles/",
        "vehicles"
      ),
      transformSection(
        "Investment",
        data[4]?.investmentData,
        (item) => ({
          id: item.investment.id, // Ensure the id field is added correctly
          detail1: item.investment.investmentCaption,
          detail2: item.investment.owner,
          detail3: formatAmount(item.investment.totalAmount),
          detail4: item?.sharedDetails?.length
            ? item.sharedDetails.map((detail) => detail.beneficiaryId)
            : null,
        }),
        "/user/my-estate/investment/",
        "investments"
      ),
      transformSection(
        "Bitcoin BTC",
        data[5]?.cryptoAssetData,
        (item) => ({
          id: item.cryptoAssest.id, // Ensure the id field is added correctly
          detail1: item.cryptoAssest.cryptoCaption,
          detail2: item.cryptoAssest.owner,
          detail3: formatAmount(item.cryptoAssest.estValue),
          detail4: item?.sharedDetails?.length
            ? item.sharedDetails.map((detail) => detail.beneficiaryId)
            : null,
        }),
        "/user/my-estate/crypto/",
        "crypto"
      ),
      transformSection(
        "Business-Passive Income",
        data[6]?.incomeData,
        (item) => ({
          id: item.income.id, // Ensure the id field is added correctly
          detail1: item.income.incomeCaption,
          detail2: item.income.owner,
          detail3: formatAmount(item.income.incomeAmount),
          detail4: item?.sharedDetails?.length
            ? item.sharedDetails.map((detail) => detail.beneficiaryId)
            : null,
        }),
        "/user/my-estate/income/",
        "income"
      ),
      transformSection(
        "Miscellaneous Assets",
        data[7]?.otherAssetData,
        (item) => ({
          id: item.otherAsset.id, // Ensure the id field is added correctly
          detail1: item.otherAsset.assetName,
          detail2: item.otherAsset.owner,
          detail3: formatAmount(item.otherAsset.assetValue),
          detail4: item?.sharedDetails?.length
            ? item.sharedDetails.map((detail) => detail.beneficiaryId)
            : null,
        }),
        "/user/my-estate/otherAsset/",
        "other-assests"
      ),
      transformSection(
        "Insurance",
        data[8]?.insuranceData,
        (item) => ({
          id: item.insurance.id, // Ensure the id field is added correctly
          detail1: item.insurance.insuranceCaption,
          detail2: item.insurance.owner,
          detail3: formatAmount(item.insurance.insuranceAmount),
          detail4:
            item?.sharedDetails?.length &&
            item?.deathBenefitsModel?.sharedDetails.length
              ? item.sharedDetails.map((detail) => detail.beneficiaryId) &&
                item.deathBenefitsModel?.sharedDetails.map(
                  (detail) => detail.beneficiaryId
                )
              : null,
        }),
        "/user/my-estate/insurances/",
        "insurances"
      ),
    ];
    // Remove sections where details are empty
    const filteredSections = sections.filter(
      (section) => section.details.length > 0
    );

    // Automatically set the first section to expandedAssets
    if (filteredSections.length > 0) {
      setExpandedAssets({ [filteredSections[0].name]: true });
    }

    return filteredSections;
  };

  const handleClickUrl = (url) => {
    navigate("/user/my-estate/" + url);
  };

  let userId = getUser().commonId;

  const [beneficiary, setBeneficiary] = useState([]);
  const getBeneficiaryData = () => {
    const token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBeneficiary(res);
      })
      .catch((err) => {});
  };

  // Original getBeneficiaryName function
  const getBeneficiaryName = (id) => {
    const foundBeneficiary = beneficiary.find((b) => b.id === parseInt(id));
    return foundBeneficiary
      ? `${foundBeneficiary.firstName} ${foundBeneficiary.lastName}`
      : "Beneficiary not found";
  };

  // get combined data in single list
  const [combinedData, setCombinedData] = useState([]);
  const getCombinedDataFromServer = () => {
    getCombinedData(userId)
      .then((res) => {
        setCombinedData(transformData(res));
        console.log(beneficiary);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getBeneficiaryData();
    getCombinedDataFromServer();
    setShow(true);
    // const firstAssetName = combinedData[0]?.name;
    // if (firstAssetName) {
    //   setExpandedAssets((prevState) => ({
    //     ...prevState,
    //     [firstAssetName]: true,
    //   }));
    // }
  }, []);

  const toggleExpand = (assetName) => {
    setExpandedAssets((prevState) => {
      if (assetName === combinedData[0].name && prevState[assetName]) {
        return {
          ...prevState,
          [assetName]: !prevState[assetName],
        };
      }
      return {
        ...prevState,
        [assetName]: !prevState[assetName],
      };
    });
  };

  const handleViewDetails = (detail) => {
    setViewDetail(detail);
  };

  const handleCloseDetails = () => {
    setViewDetail(null);
  };

  //   const assetsData = [
  //     {
  //       name: "Real Estate",
  //       details: [
  //         {
  //           detail1: "Detail 1.1",
  //           detail2: "Detail 1.2",
  //           detail3: "Detail 1.3",
  //           detail4: "Detail 1.4",
  //           detail5: "Detail 1.5",
  //           detail6: "Detail 1.6",
  //           Status: "Completed",
  //         },
  //         {
  //           detail1: "Detail 2.1",
  //           detail2: "Detail 2.2",
  //           detail3: "Detail 2.3",
  //           detail4: "Detail 2.4",
  //           detail5: "Detail 2.5",
  //           detail6: "Detail 2.6",
  //           Status: "Incomplete",
  //         },
  //         {
  //           detail1: "Detail 1.1",
  //           detail2: "Detail 1.2",
  //           detail3: "Detail 1.3",
  //           detail4: "Detail 1.4",
  //           detail5: "Detail 1.5",
  //           detail6: "Detail 1.6",
  //           Status: "Completed",
  //         },
  //         {
  //           detail1: "Detail 2.1",
  //           detail2: "Detail 2.2",
  //           detail3: "Detail 2.3",
  //           detail4: "Detail 2.4",
  //           detail5: "Detail 2.5",
  //           detail6: "Detail 2.6",
  //           Status: "Completed",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Banks",
  //       details: [
  //         {
  //           detail1: "Detail 2.1",
  //           detail2: "Detail 2.2",
  //           detail3: "Detail 2.3",
  //           detail4: "Detail 2.4",
  //           detail5: "Detail 2.5",
  //           detail6: "Detail 2.6",
  //           Status: "Incomplete",
  //         },
  //         {
  //           detail1: "Detail 2.8",
  //           detail2: "Detail 2.9",
  //           detail3: "Detail 2.10",
  //           detail4: "Detail 2.11",
  //           detail5: "Detail 2.12",
  //           detail6: "Detail 2.13",
  //           Status: "Completed",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Jewelry",
  //       details: [
  //         {
  //           detail1: "Detail 3.1",
  //           detail2: "Detail 3.2",
  //           detail3: "Detail 3.3",
  //           detail4: "Detail 3.4",
  //           detail5: "Detail 3.5",
  //           detail6: "Detail 3.6",
  //           Status: "Completed",
  //         },
  //         {
  //           detail1: "Detail 3.8",
  //           detail2: "Detail 3.9",
  //           detail3: "Detail 3.10",
  //           detail4: "Detail 3.11",
  //           detail5: "Detail 3.12",
  //           detail6: "Detail 3.13",
  //           Status: "Completed",
  //         },
  //       ],
  //     },
  //     {
  //       name: null,
  //     },
  //   ];

  const getAssetStatus = (details) => {
    return details.some((detail) => detail.Status === "Incomplete")
      ? "Incomplete"
      : "Completed";
  };

  //   const filteredAssetsData = assetsData.filter((asset) => asset.name);

  return (
    <div className={`your-component ${show ? "fade-in-element" : ""}`}>
      <UserBase>
        <div className="mt-5"></div>
        <SideBar>
          <div className="lagacychart_main">
            {/* {filteredAssetsData.map((asset, index) => {
              const assetStatus = getAssetStatus(asset.details);

              return (
                <div
                  key={index}
                  className={`legacychart_base ${
                    expandedAssets[asset.name] ? "expanded" : ""
                  }`}
                >
                  <div
                    className="lagacychart_details_main"
                    onClick={() => toggleExpand(asset.name)}
                  >
                    <div className="lagacychart_card_details">
                      <p className="lagacy_card_name">{asset.name}</p>
                      <p
                        className={`lagacy_details ${assetStatus.toLowerCase()}`}
                      >
                        {assetStatus}
                      </p>
                    </div>
                    <div
                      className="legacy_button"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleExpand(asset.name);
                      }}
                    >
                      <div className="lagacy_dropdown_button">
                        <FontAwesomeIcon
                          className="lagacy_Icon"
                          icon={
                            expandedAssets[asset.name]
                              ? faChevronUp
                              : faChevronDown
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {expandedAssets[asset.name] && (
                    <div className="lagacy_details_cards">
                      {asset.details.map((detail, detailIndex) => (
                        <Paper
                          key={detailIndex}
                          className={`lagacy_detail_card ${detail.Status.toLowerCase()}`}
                          sx={{ padding: 1.5 }}
                        >
                          <p className="legacy_row">
                            <strong>Assets</strong> {detail.detail1}
                          </p>
                          <p className="legacy_row">
                            <strong>Owner</strong> {detail.detail2}
                          </p>
                          <p className="legacy_row">
                            <strong>Value</strong> {detail.detail3}
                          </p>
                          <p className="legacy_row">
                            <strong>Detail</strong> {detail.detail4}
                          </p>
                          <p className="legacy_row">
                            <strong>Beneficiary</strong> {detail.detail5}
                          </p>
                          <p className="legacy_row">
                            <strong>Status</strong>{" "}
                            <span
                              className={`legacy_page_notification ${detail.Status.toLowerCase()}`}
                            >
                              {detail.Status}
                            </span>
                          </p>
                          <div className="aegacy_row_action_btn">
                            <Link
                              to="/user/dashboard"
                              className="legacy_row_Action"
                            >
                              <p>
                                <FontAwesomeIcon icon={faPencilAlt} />
                              </p>
                              Edit
                            </Link>
                            <button
                              className="legacy_row_Action"
                              onClick={() => handleViewDetails(detail)}
                            >
                              <p>
                                <FontAwesomeIcon icon={faCircleInfo} />
                              </p>
                              View Details
                            </button>
                          </div>
                        </Paper>
                      ))}
                    </div>
                  )}
                </div>
              );
            })} */}

            {combinedData.map((asset, index) => {
              const assetStatus = getAssetStatus(asset.details);

              return (
                <div
                  key={index}
                  className={`legacychart_base ${
                    expandedAssets[asset.name] ? "expanded" : ""
                  }`}
                >
                  <div
                    className="lagacychart_details_main"
                    onClick={() => toggleExpand(asset.name)}
                  >
                    <div className="lagacychart_card_details">
                      <p className="lagacy_card_name">{asset.name}</p>
                      <p
                        className={`lagacy_details ${assetStatus.toLowerCase()}`}
                      >
                        {assetStatus}
                      </p>
                    </div>
                    <div
                      className="legacy_button"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleExpand(asset.name);
                      }}
                    >
                      <div className="lagacy_dropdown_button">
                        <FontAwesomeIcon
                          className="lagacy_Icon"
                          icon={
                            expandedAssets[asset.name]
                              ? faChevronUp
                              : faChevronDown
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {expandedAssets[asset.name] && (
                    <div className="lagacy_details_cards">
                      {asset.details.map((detail, detailIndex) => (
                        <Paper
                          key={detailIndex}
                          className={`lagacy_detail_card ${detail.Status.toLowerCase()}`}
                          sx={{ padding: 1.5 }}
                        >
                          <p className="legacy_row">
                            <strong>Assets</strong> {detail.detail1}
                          </p>
                          <p className="legacy_row">
                            <strong>Owner</strong> {detail.detail2}
                          </p>
                          <p className="legacy_row">
                            <strong>Value</strong> {detail.detail3}
                          </p>
                          <p className="legacy_row">
                            <strong>Beneficiary</strong>{" "}
                            {Array.isArray(detail.detail4)
                              ? detail.detail4
                                  .map((id) => getBeneficiaryName(id))
                                  .join(", ")
                              : "Not Available"}
                          </p>
                          <p className="legacy_row">
                            <strong>Status</strong>{" "}
                            <span
                              className={`legacy_page_notification ${detail.Status.toLowerCase()}`}
                            >
                              {detail.Status}
                            </span>
                          </p>
                          <div className="aegacy_row_action_btn">
                            <Link
                              to={`${detail.edit_url}${detail.id}`}
                              className="legacy_row_Action"
                            >
                              <p>
                                <FontAwesomeIcon icon={faPencilAlt} />
                              </p>
                              Edit
                            </Link>
                            <button
                              className="legacy_row_Action"
                              onClick={() => handleClickUrl(detail.url)}
                            >
                              <p>
                                <FontAwesomeIcon icon={faCircleInfo} />
                              </p>
                              View Details
                            </button>
                          </div>
                        </Paper>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
            {/* 
            <div
              className={`legacy_popup_wrapper ${
                viewDetail ? "visible" : "hidden"
              }`}
            >
              <div className="legacy_popup">
                {viewDetail && (
                  <LegacyDetails
                    detail={viewDetail}
                    onClose={handleCloseDetails}
                  />
                )}
              </div>
            </div> */}
          </div>
        </SideBar>
      </UserBase>
    </div>
  );
}

export default Legacychary;
