import {
  faMinus,
  faPlus,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import { FormHelperText, Input, Textarea } from "@mui/joy";
import { Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { Button, Form } from "reactstrap";
import { formatAmount } from "../../components/Common";
import SideBar from "../../components/sidebar/Sidebar";
import UserBase from "../../components/user/UserBase";
import {
  addPersonalLoan,
  deletePersonalLoan,
  getPersonalLoans,
} from "../../services/PersonalLoanService";
import {
  getSecondaryUser,
  getToken,
  getUser,
} from "../../services/user-service";
import { ConfirmationPopup } from "../my-estate/ConfirmationPopup";
import Deletebutton from "../my-estate/Deletebutton";
import UpdateButton from "../my-estate/UpdateButton";
import "./Liabilities.css";
import axios from "axios";

const initialPersonalLoanData = {
  personalLoan: {
    personalLoanListName: "",
    name: "",
    date: "",
    street: "",
    apartment: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
    estimatedLoan: "",
    notes: "",
  },
};

function PersonalLoan() {
  const [personalLoanList, setPersonalLoanList] = useState([
    initialPersonalLoanData,
  ]);
  const [show, setShow] = useState(false);
  let [form1, setForm1] = useState(false);

  const [modal, setModal] = useState(false);
  const [personalLoanListNameValue, setPersonalLoanListNameValue] =
    useState("");
  const handleChangeAddPersonalLoanListName = (event) => {
    const value = event.target.value;
    setPersonalLoanListNameValue(value);
  };
  const handleChangeConfirm = () => {
    if (personalLoanListNameValue !== "") {
      setPersonalLoanList((prevList) => {
        const updatedPersonalLoan = [...prevList];

        updatedPersonalLoan[0].personalLoan.personalLoanListName =
          personalLoanListNameValue;
        return updatedPersonalLoan;
      });
      setForm1(!form1);
    }
    togglePersonalLoanList();
  };
  const togglePersonalLoanList = () => {
    setModal(false);
  };

  const [existingPersonalLoanListName, setExistingPersonalLoanListName] =
    useState(null);
  const toggle = (event, value, closePopup) => {
    setExistingPersonalLoanListName(value);
    resetForm();
    if (closePopup) {
      setForm1(false);
      setPersonalLoanListNameValue("");
      return;
    }
    if (value) {
      setForm1(true);
    } else {
      if (personalLoanListNameValue !== "") {
        setForm1(false);
      } else {
        setModal(true);
      }
    }
  };

  useEffect(() => {
    if (existingPersonalLoanListName) {
      setPersonalLoanList((prevList) =>
        prevList.map((item) => ({
          ...item,
          personalLoan: {
            ...item.personalLoan,
            personalLoanListName: existingPersonalLoanListName, // Set the new jewelryListName
          },
        }))
      );
    }
  }, [existingPersonalLoanListName]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();

  const today = dayjs().format("YYYY-MM-DD");
  const handleChanges = (e, field, index) => {
    const { value } = e.target;
    setCurrentIndex(index);
    if (field === "zipCode") {
      if (/^\d{0,5}$/.test(value)) {
        setPersonalLoanList((prevList) =>
          prevList.map((item, i) =>
            i === index
              ? {
                  ...item,
                  personalLoan: {
                    ...item.personalLoan,
                    [field]: value,
                  },
                }
              : item
          )
        );
      }
    } else {
      setPersonalLoanList((prevList) =>
        prevList.map((item, i) =>
          i === index
            ? {
                ...item,
                personalLoan: {
                  ...item.personalLoan,
                  [field]: value,
                },
              }
            : item
        )
      );
    }
  };
  const resetForm = () => {
    setPersonalLoanList([initialPersonalLoanData]);
  };

  const personalLoanForm = (event) => {
    event.preventDefault();

    let token = "Bearer " + getToken();
    const formData = new FormData();

    formData.append("data", JSON.stringify(personalLoanList));
    console.log("data: ", personalLoanList);

    addPersonalLoan(formData, token)
      .then((resp) => {
        AddCard();
        toast.success("Data Added !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle(null, null, "close");
      })
      .catch((error) => {});
  };

  // show notes popup
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedNote, setSelectedNote] = useState("");

  const handleOpenPopup = (note) => {
    setSelectedNote(note);
    setPopupVisible(true);
  };

  let [card, setCard] = useState([]);
  let [showDetail, setShowDetail] = useState([]);
  let [show1, setShow1] = useState(false);

  // card creating
  const AddCard = () => {
    let userId = getUser().commonId;
    let token = "Bearer " + getToken(); // Added 'Bearer'
    getPersonalLoans(token, userId)
      .then((res) => {
        setCard(res);
      })
      .catch((error) => {
        setCard([]);
      });
  };

  const ShowCreditCardDetails = (obj) => {
    setShowDetail(obj);
    setShow1(true);
  };

  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [currentIdType, setCurrentIdType] = useState("");
  const removePersonalLoan = (id, idType) => {
    setShowConfirmationDelete(true);
    setCurrentId(id);
    setCurrentIdType(idType);
  };

  const handleConfirmationDeleteClose = () => {
    setShowConfirmationDelete(false);
  };

  // Code by Purnendu
  const handleRemove = (params) => {
    setShowConfirmationDelete(false);
    const { Id, idType } = params;
    if (idType === "personalLoanId") {
      deletePersonalLoan(Id)
        .then((res) => {
          toast.success("Deleted successfully...", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          AddCard();
          setShow1(false);
        })
        .catch((error) => {});
    }
  };

  const addPersonalLoanRow = () => {
    setPersonalLoanList((prevPersonalLoanList) => [
      ...prevPersonalLoanList,
      {
        ...initialPersonalLoanData,
        personalLoan: {
          ...initialPersonalLoanData.personalLoan,
          personalLoanListName:
            prevPersonalLoanList[0]?.personalLoan.personalLoanListName || "",
        },
      },
    ]);
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const removePersonalLoanRow = (index) => {
    setShowConfirmation(true);
    setCurrentIndex(index);
  };

  const handleConfirmation = () => {
    setShowConfirmation(false);
    const index = currentIndex;
    setPersonalLoanList(personalLoanList.filter((_, i) => i !== index));
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  useEffect(() => {
    const fetchDataFromAPI = () => {
      if (personalLoanList[currentIndex]?.personalLoan?.zipCode.length === 5) {
        const apiUrl = `https://api.zippopotam.us/us/${personalLoanList[currentIndex]?.personalLoan?.zipCode}`;
        axios
          .get(apiUrl)
          .then((res) => {
            if (res.data && res.data.places && res.data.places.length > 0) {
              const place = res.data.places[0];
              const city = place["place name"];
              const state = place["state abbreviation"];
              const country = res.data["country abbreviation"];

              setPersonalLoanList((prevList) =>
                prevList.map((item, i) =>
                  i === currentIndex
                    ? {
                        ...item,
                        personalLoan: {
                          ...item.personalLoan,
                          city: city,
                          state: state,
                          country: country,
                        },
                      }
                    : item
                )
              );
            }
          })
          .catch((error) => {});
      } else {
        setPersonalLoanList((prevList) =>
          prevList.map((item, i) =>
            i === currentIndex
              ? {
                  ...item,
                  personalLoan: {
                    ...item.personalLoan,
                    city: "",
                    state: "",
                    country: "",
                  },
                }
              : item
          )
        );
      }
    };

    fetchDataFromAPI();
  }, [personalLoanList[currentIndex]?.personalLoan?.zipCode]);

  useEffect(() => {
    AddCard();
    setShow(true);
  }, []);

  // Group and consolidate data
  const consolidated = card.reduce((acc, item) => {
    const listName = item.personalLoan.personalLoanListName;

    if (!acc[listName]) {
      acc[listName] = {
        personalLoanListName: listName,
        data: [],
      };
    }

    acc[listName].data.push({
      personalLoan: item.personalLoan,
    });
    return acc;
  }, {});

  // Convert consolidated object to an array
  const uniquePersonalLoanListResult = Object.values(consolidated);

  return (
    <div className={`your-component ${show ? "fade-in-element" : ""}`}>
      <UserBase>
        <div className="mt-5"></div>
        <SideBar>
          {primaryUserDetails.accountType === "secondary" &&
          secondaryUserDetails.accountType === "primary" &&
          secondaryUserDetails.isSecondaryUserEditable === "false" ? (
            ""
          ) : (
            <div className="addme">
              <div className="addme_inner">
                <button
                  style={{ padding: "7px 14px" }}
                  onClick={() => toggle()}
                >
                  Add Personal Loan
                </button>
              </div>
            </div>
          )}

          <div className="propCard">
            <div className="propCard-card">
              {uniquePersonalLoanListResult.map((item, index) => (
                <div
                  key={index}
                  className="propCard-card-body"
                  style={{ padding: "20px 20px 10px 20px" }}
                >
                  <div className="propCard_card_status"></div>
                  <h5 className="propCard-card-title">
                    {item?.personalLoanListName}
                  </h5>
                  <div className="propCard-btn">
                    <p className="viewDetails">
                      <button
                        className="jewelry_add_more"
                        onClick={(event) =>
                          toggle(event, item.personalLoanListName)
                        }
                      >
                        Add More
                      </button>
                      <button
                        style={{ margin: "10px 0" }}
                        onClick={() => ShowCreditCardDetails(item?.data)}
                      >
                        View Details
                      </button>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {form1 && (
            <div
              className="overlay1"
              style={{ transition: "500ms", height: "" }}
            >
              <div>
                <Form onSubmit={personalLoanForm} className="addform_body">
                  <container>
                    <div className="homepage jewelry_container">
                      <Card>
                        <CardHeader>
                          <div
                            style={{ width: "100%" }}
                            className="addform_heading"
                          >
                            <h3 className="addform_heading_h1">
                              {personalLoanListNameValue &&
                                personalLoanListNameValue}
                              {existingPersonalLoanListName &&
                                existingPersonalLoanListName}
                            </h3>
                            <div
                              className="addform_close"
                              onClick={(event) => toggle(event, "", "close")}
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody className="credit_card_body">
                          <div>
                            <table className="credit_card_table">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th style={{ width: "140px" }}>
                                    Lender Name*
                                  </th>
                                  <th style={{ width: "140px" }}>
                                    Estimated Loan*
                                  </th>
                                  <th style={{ width: "140px" }}>
                                    Date borrowed*
                                  </th>
                                  <th style={{ width: "120px" }}>Street</th>
                                  <th style={{ width: "120px" }}>Apartment</th>
                                  <th style={{ width: "100px" }}>Zip Code</th>
                                  <th style={{ width: "100px" }}>City</th>
                                  <th style={{ width: "100px" }}>State</th>
                                  <th style={{ width: "120px" }}>Country</th>
                                  <th>Notes</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {personalLoanList.map((row, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      <Input
                                        style={{ width: "140px" }}
                                        placeholder="Lender Name"
                                        onChange={(e) =>
                                          handleChanges(e, "name", index)
                                        }
                                        value={
                                          personalLoanList[index]?.personalLoan
                                            ?.name || ""
                                        }
                                        required
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        style={{ width: "140px" }}
                                        placeholder="Estimated Loan"
                                        type="number"
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "estimatedLoan",
                                            index
                                          )
                                        }
                                        value={
                                          personalLoanList[index]?.personalLoan
                                            ?.estimatedLoan || ""
                                        }
                                        startDecorator={<div>$</div>}
                                        required
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        style={{ width: "140px" }}
                                        type="date"
                                        onChange={(e) =>
                                          handleChanges(e, "date", index)
                                        }
                                        value={
                                          personalLoanList[index]?.personalLoan
                                            ?.date || ""
                                        }
                                        slotProps={{
                                          input: {
                                            max: today,
                                          },
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        style={{ width: "120px" }}
                                        placeholder="Street"
                                        onChange={(e) =>
                                          handleChanges(e, "street", index)
                                        }
                                        value={
                                          personalLoanList[index]?.personalLoan
                                            ?.street || ""
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        style={{ width: "120px" }}
                                        placeholder="Apartment"
                                        onChange={(e) =>
                                          handleChanges(e, "apartment", index)
                                        }
                                        value={
                                          personalLoanList[index]?.personalLoan
                                            ?.apartment || ""
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        style={{ width: "100px" }}
                                        placeholder="Zip Code"
                                        onChange={(e) =>
                                          handleChanges(e, "zipCode", index)
                                        }
                                        value={
                                          personalLoanList[index]?.personalLoan
                                            ?.zipCode || ""
                                        }
                                      />
                                      <FormHelperText error>
                                        <Typography
                                          variant="caption"
                                          color="error"
                                        >
                                          {personalLoanList[index]?.personalLoan
                                            ?.zipCode !== "" &&
                                            personalLoanList[index]
                                              ?.personalLoan?.city === "" &&
                                            personalLoanList[index]
                                              ?.personalLoan?.state === "" &&
                                            personalLoanList[index]
                                              ?.personalLoan?.country === "" &&
                                            "Invalid Zip Code"}
                                        </Typography>
                                      </FormHelperText>
                                    </td>
                                    <td>
                                      <Input
                                        style={{ width: "100px" }}
                                        placeholder="City"
                                        onChange={(e) =>
                                          handleChanges(e, "city", index)
                                        }
                                        value={
                                          personalLoanList[index]?.personalLoan
                                            ?.city || ""
                                        }
                                        required
                                        disabled
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        style={{ width: "100px" }}
                                        placeholder="State"
                                        onChange={(e) =>
                                          handleChanges(e, "state", index)
                                        }
                                        value={
                                          personalLoanList[index]?.personalLoan
                                            ?.state || ""
                                        }
                                        required
                                        disabled
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        style={{ width: "120px" }}
                                        placeholder="Country"
                                        onChange={(e) =>
                                          handleChanges(e, "country", index)
                                        }
                                        value={
                                          personalLoanList[index]?.personalLoan
                                            ?.country || ""
                                        }
                                        required
                                        disabled
                                      />
                                    </td>
                                    <td>
                                      <Textarea
                                        style={{
                                          fontSize: "14px",
                                        }}
                                        placeholder="Notes"
                                        id="notes"
                                        value={
                                          personalLoanList[index].personalLoan
                                            .notes
                                        }
                                        onChange={(e) =>
                                          handleChanges(e, "notes", index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <div className="jewelry_action">
                                        {personalLoanList.length > 1 && (
                                          <Button
                                            className="credit_card_remove_button"
                                            onClick={() =>
                                              removePersonalLoanRow(index)
                                            }
                                          >
                                            <FontAwesomeIcon icon={faMinus} />
                                          </Button>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div style={{ marginTop: "8px" }}>
                              <Button
                                className="credit_card_add_more_button"
                                type="button"
                                onClick={addPersonalLoanRow}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </Button>
                            </div>
                          </div>
                        </CardBody>
                        <CardFooter>
                          <div className="text-center">
                            <Button
                              className="my-estate-clear-btn"
                              type="reset"
                              onClick={resetForm}
                              outline
                            >
                              Clear
                            </Button>
                            <Button
                              outline
                              type=""
                              className="my-estate-add-btn"
                            >
                              Add
                            </Button>
                          </div>
                        </CardFooter>
                      </Card>
                    </div>
                  </container>
                </Form>
              </div>
            </div>
          )}

          {modal && (
            <div>
              <Modal
                show={modal}
                toggle={togglePersonalLoanList}
                style={{ paddingTop: "50px" }}
              >
                <ModalBody style={{ color: "black" }}>
                  Please create your personal loan catalog here:
                  <div>
                    <Input
                      type="text"
                      value={personalLoanListNameValue}
                      placeholder="Enter your personal loan catalog name"
                      onChange={handleChangeAddPersonalLoanListName}
                    />
                  </div>
                </ModalBody>
                <ModalFooter style={{ padding: "5px" }}>
                  <Button
                    className="confirm_delete_btn"
                    onClick={handleChangeConfirm}
                  >
                    Add
                  </Button>{" "}
                  <Button
                    className="confirm_cancel_btn"
                    onClick={togglePersonalLoanList}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          )}

          {show1 && (
            <>
              <div className="popup">
                <div className="popup-content popup-content-download">
                  <div className="note_popup">
                    <div className="note_popup_heading">
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <h2>{`${showDetail[0]?.personalLoan?.personalLoanListName} Details`}</h2>
                      </div>
                      <div>
                        <button
                          className="note_popup_heading_close_btn"
                          onClick={() => {
                            setShow1(false);
                          }}
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </button>
                      </div>
                    </div>
                    <div>
                      {showDetail.map((details, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "5px",
                            padding: "10px",
                            border: "solid 1px lightgray",
                            borderRadius: "5px",
                            minWidth: "230px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* Left side */}

                            <div>
                              <p
                                style={{
                                  fontSize: "17px",
                                  color: "black",
                                  fontWeight: "500",
                                }}
                              >
                                {`Item - ${index + 1}`}
                                {"  "}
                              </p>
                            </div>
                            {/* Right side */}
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {primaryUserDetails.accountType === "secondary" &&
                              secondaryUserDetails.accountType === "primary" &&
                              secondaryUserDetails.isSecondaryUserEditable ===
                                "false" ? (
                                ""
                              ) : (
                                <div className="me-2">
                                  <Tooltip title="Click Here To Edit">
                                    <div>
                                      <UpdateButton
                                        URL={"../liabilities/p-loan/"}
                                        id={details.personalLoan.id}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              )}

                              {primaryUserDetails.accountType === "secondary" &&
                              secondaryUserDetails.accountType === "primary" &&
                              secondaryUserDetails.isSecondaryUserEditable ===
                                "false" ? (
                                ""
                              ) : (
                                <FontAwesomeIcon
                                  onClick={() =>
                                    removePersonalLoan(
                                      details.personalLoan.id,
                                      "personalLoanId"
                                    )
                                  }
                                  className="delete-button-comp"
                                  style={{ cursor: "pointer" }}
                                  icon={faTrash}
                                />
                              )}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",

                              flexWrap: "wrap",
                            }}
                            className="mt-2"
                          >
                            {/* Left section */}
                            <div style={{ flex: "1", minWidth: "250px" }}>
                              {details?.personalLoan?.name && (
                                <p
                                  style={{ color: "black", fontWeight: "bold" }}
                                >
                                  Lender Name :{" "}
                                  {details?.personalLoan?.name && (
                                    <span style={{ fontWeight: "500" }}>
                                      {details?.personalLoan?.name}
                                    </span>
                                  )}
                                </p>
                              )}

                              {details?.personalLoan?.date && (
                                <p
                                  style={{ color: "black", fontWeight: "bold" }}
                                >
                                  Date borrowed :{" "}
                                  <span style={{ fontWeight: "500" }}>
                                    {details?.personalLoan?.date && (
                                      <span>{details?.personalLoan?.date}</span>
                                    )}
                                  </span>
                                </p>
                              )}

                              {(details?.personalLoan?.street ||
                                details?.personalLoan?.apartment ||
                                details?.personalLoan?.zipCode) && (
                                <p
                                  style={{ color: "black", fontWeight: "bold" }}
                                >
                                  Address :{" "}
                                  <span style={{ fontWeight: "500" }}>
                                    {(details?.personalLoan?.street ||
                                      details?.personalLoan?.apartment ||
                                      details?.personalLoan?.zipCode) && (
                                      <span>
                                        {`${
                                          details?.personalLoan?.street
                                            ? `${details?.personalLoan?.street}, `
                                            : ""
                                        }${
                                          details?.personalLoan?.apartment
                                            ? `${details?.personalLoan?.apartment}, `
                                            : ""
                                        }${
                                          details?.personalLoan?.city
                                            ? `${details?.personalLoan?.city}, `
                                            : ""
                                        }${
                                          details?.personalLoan?.state
                                            ? `${details?.personalLoan?.state}, `
                                            : ""
                                        }${
                                          details?.personalLoan?.country
                                            ? `${details?.personalLoan?.country} `
                                            : ""
                                        }${
                                          details?.personalLoan?.zipCode
                                            ? `${details?.personalLoan?.zipCode}`
                                            : ""
                                        }`}
                                      </span>
                                    )}
                                  </span>
                                </p>
                              )}
                            </div>

                            {/* Right section */}
                            <div style={{ flex: "0.5", minWidth: "250px" }}>
                              {details?.personalLoan?.estimatedLoan && (
                                <p
                                  style={{ color: "black", fontWeight: "bold" }}
                                >
                                  Estimated Loan :{" "}
                                  <span
                                    style={{
                                      color: "green",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {details?.personalLoan?.estimatedLoan && (
                                      <span>
                                        {formatAmount(
                                          details?.personalLoan?.estimatedLoan
                                        )}
                                      </span>
                                    )}
                                  </span>
                                </p>
                              )}

                              {details?.personalLoan?.notes && (
                                <Tooltip title="Click To see Note">
                                  <p
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      handleOpenPopup(
                                        details?.personalLoan?.notes
                                      );
                                      setShow1(!show1);
                                    }}
                                  >
                                    Note:{" "}
                                    <span style={{ fontWeight: "500" }}>
                                      {" "}
                                      {details && details?.personalLoan?.notes
                                        ? details?.personalLoan?.notes.slice(
                                            0,
                                            5
                                          )
                                        : ""}
                                      ...
                                      <span className="readmore">
                                        Read More
                                      </span>
                                    </span>
                                  </p>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {showConfirmation && (
            <ConfirmationPopup
              handleConfirmation={handleConfirmation}
              handleConfirmationClose={handleConfirmationClose}
              anyText="Do you want to remove item from this list?"
            />
          )}

          {showConfirmationDelete && (
            <Deletebutton
              handleRemove={handleRemove}
              Id={currentId}
              idType={currentIdType}
              handleConfirmationClose={handleConfirmationDeleteClose}
            />
          )}

          {popupVisible && (
            <div className="popup">
              <div className="popup-content">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div>
                      <h2>Notes</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisible(false);
                          setShow1(!show1);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div>
                    <p>{selectedNote}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </SideBar>
      </UserBase>
    </div>
  );
}
export default PersonalLoan;
