import React from "react";
import Typed from "typed.js";

export function Notification() {
  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      // strings: ['Currently, this page is under maintenance. Services for this page are not available now. Thank you!'],
      strings: [
        "Application is currently under development. Stay tuned for updates. Thank you!",
      ],
      typeSpeed: 50,
      loop: true,
      backSpeed: 40,
      startDelay: 100,
      backDelay: 2500,
      showCursor: false,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div style={{ height: "20px" }}>
      <span
        style={{
          color: "red",
          display: "flex",
          justifyContent: "center",
          padding: "0 10px",
          backgroundColor: "yellow",
        }}
        ref={el}
      ></span>
    </div>
  );
}






export function Homepagenotification() {
  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      // strings: ['Currently, this page is under maintenance. Services for this page are not available now. Thank you!'],
      strings: [
        "Application is in Beta stage. Please avoid registering for now. Stay tuned for updates. Thank you!",
      ],
      typeSpeed: 30,
      loop: true,
      backSpeed: 40,
      startDelay: 150,
      backDelay: 2500,
      showCursor: false,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div style={{ height: "20px" }}>
      <span
        style={{
          color: "red",
          display: "flex",
          justifyContent: "center",
          padding: "0 10px",
          backgroundColor: "yellow",
        }}
        ref={el}
      ></span>
    </div>
  );
}
