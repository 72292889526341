import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  FormLabel,
  Input,
  Option,
  Select,
  Textarea,
} from "@mui/joy";
import { FormControlLabel, Switch, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form } from "reactstrap";
import SideBar from "../../../components/sidebar/Sidebar";
import UserBase from "../../../components/user/UserBase";
import "../../../css/formPOPup.css";
import "../../../css/myestate_edit.css";
import {
  getSingleVehicle,
  updateVehicles,
} from "../../../services/VehicleService";
import {
  fetchNetWorth,
  getBeneficiary,
  getOwnerByUserId,
  getSecondaryUser,
  getToken,
  getTrustByUserId,
  getUser,
} from "../../../services/user-service";
import SharedBeneficiaryEdit from "../../../components/SharedBeneficiaryEdit";
function EditVehical() {
  const { id } = useParams();
  const navigate = useNavigate();
  //set data
  const [data, setData] = useState({
    vehicle: {
      owner: "",
      vehicleCaption: "",
      vehicleType: "",
      otherVehicleType: "",
      year: "",
      vinNumber: "",
      make: "",
      otherMake: "",
      estValue: "",
      model: "",
      otherModel: "",
      miles: "",
      trust: "",
      notes: "",
    },
    mortgage: {
      mortgage: "",
      mortgageInstitution: "",
      accountNumber: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "vehicleType") {
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          vehicleType: newValue,
        },
      }));
    }

    if (stringValue === "make") {
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          make: newValue,
        },
      }));
    }

    if (stringValue === "model") {
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          model: newValue,
        },
      }));
    }

    if (stringValue === "owner") {
      // Convert ownerName array to a single string
      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);
  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };
  const [sharedType, setSharedType] = useState(null);
  const currentYear = new Date().getFullYear();
  const handleChanges = (e, field, section) => {
    const newValue = e.target.value;
    if (field === "sharedType") {
      setSharedType(newValue);

      setData((prevData) => {
        const resetSharedDetails = [
          {
            distributedType: "",
            distributedValue: "",
            distributedAmount: "",
            beneficiaryId: "",
          },
        ];

        return {
          ...prevData,
          sharedDetails: resetSharedDetails,
        };
      });
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          trust: "",
        },
      }));
    } else {
      if (section === "vehicle" || field === "trust") {
        if (field === "year" && newValue > currentYear) {
          if (newValue > currentYear) {
            setData((prevData) => ({
              ...prevData,
              vehicle: {
                ...prevData.vehicle,
                [field]: "",
              },
            }));
            toast.error("You cannot select a future year.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          } else if (newValue.length === 4 && newValue < 1900) {
            setData((prevData) => ({
              ...prevData,
              vehicle: {
                ...prevData.vehicle,
                [field]: "",
              },
            }));
            toast.error("You cannot select year before 1900.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          } else {
            setData((prevData) => ({
              ...prevData,
              vehicle: {
                ...prevData.vehicle,
                [field]: newValue,
              },
            }));
          }
        } else {
          setData((prevData) => ({
            ...prevData,
            vehicle: {
              ...prevData.vehicle,
              [field]: newValue,
            },
          }));
          setEstimatedTotalAmount(data.vehicle.estValue);
        }
      } else {
        setData((prevData) => ({
          ...prevData,
          mortgage: {
            ...prevData.mortgage,
            [field]: newValue,
          },
        }));
      }
    }
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
  };

  // post the form
  const vehiclesForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();
    const formData = new FormData();
    if (null != selectedImage) {
      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
        console.log("this is file indexs", selectedImage[i]);
      }
    }
    formData.append("data", JSON.stringify(data));

    updateVehicles(formData, token)
      .then((resp) => {
        toast.success("Updated Successfully!!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        fetchNetWorth(primaryUserDetails.commonId);
        navigate("/user/my-estate/vehicles");
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred. Please try again.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  const [responseData, setResponseData] = useState(null);
  const [responseAssetData, setResponseAssetData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = "Bearer " + getToken();
        const res = await getSingleVehicle(token, id);
        console.log("this is vehicle responce ", res);
        setData({
          ...data,
          vehicle: res.vehicle,
          mortgage: res.mortgage,
          documents: res.documents,
          sharedDetails: res.sharedDetails,
        });
        setResponseData(res);
        setResponseAssetData(res?.vehicle);
        const ownerNameArray = res.vehicle.owner.split(", ");
        setOwnerName(ownerNameArray);

        if (res.vehicle.trust !== "" && res.vehicle.trust != null) {
          setSharedType("Trust");
        } else if (res.sharedDetails.length > 0) {
          setSharedType("Beneficiary");
        } else {
          setSharedType(null);
        }
        setIsChecked(res.mortgage ? true : false);

        setEstimatedTotalAmount(res.vehicle.estValue);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    getBenificiarydata();
    getTrustsData();
  }, []);

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => console.log(err));
  };

  //

  const [benevisible, setbeneVisible] = useState(false);
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.vehicle.estValue);
  };

  const handleEditCloseBeneficiary = (params) => {
    const { name } = params;
    if (name !== undefined) {
      setData((prevData) => ({
        ...prevData,
        sharedDetails: name,
      }));
    }
    setbeneVisible(false);
  };

  const [trusts, setTrusts] = useState([]);
  const getTrustsData = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getTrustByUserId(token, userId)
      .then((res) => {
        setTrusts(res);
      })
      .catch((err) => {});
  };
  const handleTrustSave = () => {
    setbeneVisible(false);
  };

  return (
    <UserBase>
      <div className="mt-5"></div>
      <SideBar>
        <div className="overlay1" style={{ transition: "500ms", height: "" }}>
          <div className="addform_ichest">
            <div className="addform_main">
              <div className="addform_heading">
                <h3 className="addform_heading_h1">Edit Vehicle Assets</h3>
                <div className="addform_close">
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={() => {
                      navigate("/user/my-estate/vehicles");
                    }}
                  />
                </div>
              </div>
              <div className="addform_body">
                <Form onSubmit={vehiclesForm} className="addform_body">
                  <div className="addform_select_body">
                    <div className="addform_body_left">
                      <div>
                        <Tooltip title="Select Owner" className="mt-2">
                          <div>
                            <FormLabel required>Select Owner</FormLabel>
                            <Autocomplete
                              multiple
                              id="tags-default"
                              placeholder="Select Owner"
                              options={ownerNames}
                              value={ownerName}
                              onChange={(event, newValue) =>
                                handleChange(event, newValue, "owner")
                              }
                              required={ownerName.length > 0 ? false : true}
                            />
                          </div>
                        </Tooltip>
                      </div>

                      <div>
                        <Tooltip title="Enter your vehicle heading">
                          <div className="mt-2">
                            <FormLabel>Vehicle Heading</FormLabel>
                            <Input
                              className="input_mui_joy"
                              placeholder="Enter vehicle heading"
                              onChange={(e) =>
                                handleChanges(e, "vehicleCaption", "vehicle")
                              }
                              value={data.vehicle.vehicleCaption}
                            />
                          </div>
                        </Tooltip>
                      </div>

                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter the type of your vehicle">
                              <div className="mt-2">
                                <FormLabel required>Vehicle Type</FormLabel>
                                <Select
                                  value={data.vehicle.vehicleType}
                                  onChange={(event, newValue) =>
                                    handleChange(event, newValue, "vehicleType")
                                  }
                                  required
                                >
                                  <Option value="">
                                    Select type of vehicle
                                  </Option>
                                  <Option value="Auto">Auto</Option>
                                  <Option value="Two Wheeler">
                                    Two Wheeler
                                  </Option>
                                  <Option value="Boat">Boat</Option>
                                  <Option value="Yacht">Yacht</Option>
                                  <Option value="Other">Other</Option>
                                </Select>
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter the year of Manufacture of your vehicle">
                              <div className="mt-2">
                                <FormLabel required>
                                  Manufactured Year
                                </FormLabel>
                                <Input
                                  type="number"
                                  onChange={(e) =>
                                    handleChanges(e, "year", "vehicle")
                                  }
                                  value={data.vehicle.year}
                                  inputProps={{
                                    max: currentYear,
                                  }}
                                  placeholder="Manufactured Year"
                                  required
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>

                      {data.vehicle.vehicleType === "Other" && (
                        <div>
                          <Tooltip title="Enter your other vehicle type">
                            <div className="mt-2">
                              <FormLabel>Other Vehicle Type</FormLabel>
                              <Input
                                className="input_mui_joy"
                                placeholder="Enter Other Vehicle"
                                onChange={(e) =>
                                  handleChanges(
                                    e,
                                    "otherVehicleType",
                                    "vehicle"
                                  )
                                }
                                value={data.vehicle.otherVehicleType}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      )}

                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter the vin number">
                              <div>
                                <FormLabel>Vin Number</FormLabel>
                                <Input
                                  placeholder="Enter vin number"
                                  value={data.vehicle.vinNumber}
                                  onChange={(e) =>
                                    handleChanges(e, "vinNumber", "vehicle")
                                  }
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter the company of your vehicle">
                              <div>
                                <FormLabel required>Make</FormLabel>

                                <Input
                                  placeholder="Enter make"
                                  value={data.vehicle.make}
                                  onChange={(e) =>
                                    handleChanges(e, "make", "vehicle")
                                  }
                                  required
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>

                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter the model of your vehicle">
                              <div>
                                <FormLabel>Model</FormLabel>
                                <Input
                                  placeholder="Enter model"
                                  value={data.vehicle.model}
                                  onChange={(e) =>
                                    handleChanges(e, "model", "vehicle")
                                  }
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter your vehicle mileage">
                              <div>
                                <FormLabel>Miles</FormLabel>
                                <Input
                                  placeholder="Enter miles"
                                  type="number"
                                  onChange={(e) =>
                                    handleChanges(e, "miles", "vehicle")
                                  }
                                  value={data.vehicle.miles}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>

                      <div>
                        <Tooltip title="Enter estimated value">
                          <div className="mt-2">
                            <FormLabel required>Estimated Value</FormLabel>
                            <Input
                              type="number"
                              className="input_mui_joy"
                              placeholder="Enter Estimated Value"
                              onChange={(e) =>
                                handleChanges(e, "estValue", "vehicle")
                              }
                              value={data.vehicle.estValue}
                              startDecorator={<div>$</div>}
                              required
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>

                    <div className="addform_body_right_crypto">
                      <div className="mt-2 switch_changes_main">
                        <div className="switch_changes_base">
                          <div>Do you have loan against vehicle?</div>
                          <div>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isChecked}
                                  onChange={handleSwitchChange}
                                  color="primary"
                                  size="normal"
                                />
                              }
                              label={isChecked ? "Yes" : "No"}
                              labelPlacement="end"
                            />
                          </div>
                        </div>
                      </div>
                      {isChecked && (
                        <div>
                          <div>
                            <div>
                              <div>
                                <FormLabel required>
                                  {`Institution/Bank`}
                                </FormLabel>

                                <Input
                                  className="input_mui_joy"
                                  placeholder={`Institution/Bank`}
                                  onChange={(e) =>
                                    handleChanges(
                                      e,
                                      "mortgageInstitution",
                                      "mortgage"
                                    )
                                  }
                                  value={data?.mortgage?.mortgageInstitution}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="address_input">
                            <div className="mt-2 addform_input_half">
                              <div style={{ width: "100%" }}>
                                <FormLabel required>
                                  {`Account Number`}
                                </FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  type="number"
                                  placeholder={`Account Number`}
                                  onChange={(e) =>
                                    handleChanges(
                                      e,
                                      "accountNumber",
                                      "mortgage"
                                    )
                                  }
                                  value={data?.mortgage?.accountNumber}
                                  required
                                />
                              </div>
                            </div>
                            <div className="mt-2 addform_input_half">
                              <div>
                                <FormLabel required>{`Amount`}</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  type="number"
                                  placeholder="Amount"
                                  onChange={(e) =>
                                    handleChanges(e, "mortgage", "mortgage")
                                  }
                                  value={data?.mortgage?.mortgage}
                                  startDecorator={<div>$</div>}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div>
                        <Tooltip title="Add your banks related file">
                          <div className="mt-2">
                            <FormLabel>Supporting Document</FormLabel>
                            <input
                              style={{
                                border: "solid 1px lightgray",
                                borderLeft: "none",
                                width: "100%",
                                borderRadius: "5px",
                              }}
                              type="file"
                              name="myfile"
                              id="exampleFile"
                              onChange={(e) => handleImageChange(e, 5)}
                              accept=".pdf"
                              multiple
                            />
                          </div>
                        </Tooltip>
                      </div>
                      <div
                        className="bene-select mt-3"
                        onClick={handleShowBeneficiary}
                        style={{ cursor: "pointer" }}
                      >
                        Select Your Beneficiary
                      </div>

                      <div className="address_input">
                        <div style={{ width: "100%" }} className="mt-2">
                          <Tooltip title="Enter notes for your vehicle">
                            <div>
                              <FormLabel>Notes</FormLabel>
                              <Textarea
                                sx={{ height: "182px", minHeight: "52px" }}
                                placeholder="Notes"
                                id="notes"
                                onChange={(e) =>
                                  handleChanges(e, "notes", "vehicle")
                                }
                                value={data.vehicle.notes}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="text-center"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button outline type="" className="my-estate-add-btn">
                      Update
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        {benevisible && (
          <SharedBeneficiaryEdit
            responseData={responseData}
            responseAssetData={responseAssetData}
            handleTrustSave={handleTrustSave}
            assetData={data?.vehicle}
            trusts={trusts}
            handleChanges={handleChanges}
            sharedType={sharedType}
            handleCloseBeneficiaryPopup={handleEditCloseBeneficiary}
            estimatedTotalAmount={estimatedTotalAmount}
            typeBeneficiary={"normal"}
            sharedDetails={data?.sharedDetails}
          />
        )}
      </SideBar>
    </UserBase>
  );
}

export default EditVehical;
