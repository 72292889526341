import "@fortawesome/fontawesome-svg-core/styles.css";

import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import "../../components/styles.css";
import "../../components/swiper.css";
import "../../components/user/whyichest.css";

import { Container, Nav, Navbar } from "react-bootstrap";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { Option, Select } from "@mui/joy";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import FooterPage from "./footer";

const ContactUs = () => {
  const line1 = "Life is about legacy,".split(" ");
  const line2 = "I-Chest.com helps preserve it !!!".split(" ");
  const first = "Life is about legacy, I-Chest.com helps preserve it !!!".split(
    " "
  );
  const [key, setKey] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setKey((prevKey) => prevKey + 1);
    }, 3000);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <div className="homepage_design_main">
        <div className="homepage_design_main">
          <Navbar
            expand="lg"
            fixed="top"
            bg="dark"
            variant="dark"
            aria-label="Main navigation"
            className="navbar_base"
          >
            <Container>
              <Navbar.Brand href="#" className="navbar-brand logo-image">
                <img src="./logo192.webp" alt="alternative" />
                <div className="App_this">
                  <div className="tab_largescreen_view">
                    {first.map((el, i) => (
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                          duration: 1,
                          delay: i / 10,
                        }}
                        key={i + key * first.length}
                      >
                        {el}{" "}
                      </motion.span>
                    ))}
                  </div>
                  <div className="tab_movile_view">
                    {line1.map((el, i) => (
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                          duration: 1,
                          delay: i / 10,
                        }}
                        key={i + key * line1.length}
                      >
                        {el}{" "}
                      </motion.span>
                    ))}
                  </div>
                  <div className="tab_movile_view">
                    {line2.map((el, i) => (
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                          duration: 0.25,
                          delay: i / 10,
                        }}
                        key={i + key * line2.length}
                      >
                        {el}{" "}
                      </motion.span>
                    ))}
                  </div>
                </div>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbar-nav" />
              <Navbar.Collapse id="navbar-nav">
                <Nav className="ms-auto">
                  <Nav.Link href="/">Home</Nav.Link>
                  {/* <Nav.Link href="/services">Services</Nav.Link> */}
                  <Nav.Link href="/features">Features</Nav.Link>
                  {/* <Nav.Link href="/">Partners</Nav.Link> */}
                  <Link className="partner" to="/home#aboutus">
                    Partners
                  </Link>
                  <Nav.Link href="/WhyI-chest">Why I-chest</Nav.Link>
                  <Nav.Link href="#">Contact Us</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>

        <div className={`your-component fade-in-element`}>
          <div className="whyichest_base">
            <div className="whyichest_main">
              <div className="whyichest_heading">
                <h2 style={{ marginTop: "1rem" }}>
                  Business Opportunities and Investments
                </h2>
              </div>
              <div className="whyichest_paragraph">
                <p>
                  I-Chest.com is actively seeking investors, both nationally and
                  internationally, to join us in this innovative venture. As a
                  platform breaking new ground in asset management and estate
                  planning, we recognize the value of strategic partnerships and
                  investment to further enhance our offerings and expand our
                  reach.
                </p>
              </div>
              <br />

              <div className="whyichest_second_para">
                <div className="whyichest_image">
                  <LazyLoadImage
                    className="whyichest_image_img"
                    src="/img/business_investment.webp"
                    alt="secure_data_image "
                    effect="blur"
                  />
                </div>
                <div className="whyichest_content">
                  <p>
                    We invite interested parties to submit their information for
                    consideration. Our team will carefully analyses each
                    submission to assess how your investment or business
                    proposal aligns with our platform's objectives and long-term
                    goals. This is a unique opportunity to be part of a
                    cutting-edge platform poised to revolutionize the way
                    individuals and entities manage their wealth, assets, and
                    personal legacies.
                  </p>
                  <br />
                  <p>
                    By investing in I-Chest.com, you will be contributing to the
                    development and expansion of a secure, reliable, and
                    innovative platform designed to meet the growing demand for
                    comprehensive asset management solutions. Whether you are an
                    individual investor, a venture capital firm, or a strategic
                    partner, we welcome your interest and look forward to
                    exploring potential collaboration opportunities.
                  </p>
                  <br />
                  <p>
                    In addition to financial returns, your investment will play
                    a crucial role in driving technological advancements,
                    enhancing user experience, and broadening our market reach.
                    You will also have the opportunity to influence the
                    direction of a platform that is set to redefine the
                    standards of asset management and estate planning globally.
                    Join us in shaping the future of wealth management, where
                    security, innovation, and customer-centric solutions are at
                    the forefront.
                  </p>
                </div>
              </div>
              <br />
              <div className="whyichest_last_para">
                <p>
                  Please send your information and investment inquiries to
                  contact@i-chest.com, and our team will be in touch to discuss
                  the next steps. Together, we can build a platform that not
                  only meets current market needs but also sets the standard for
                  the future of digital asset management.
                </p>
              </div>
            </div>
          </div>

          <div className="contact_us_form_main">
            <div className="contact_us_form_detail">
              <div className="contact_us_form_fields">
                <form className="contact_us_form">
                  <div className="contact_us_title">Contact us</div>
                  <Select defaultValue="" className="contact_us_select">
                    <Option value="" disabled>
                      Select an option
                    </Option>
                    <Option value="general">General Inquiry</Option>
                    <Option value="support">Support</Option>
                    <Option value="feedback">Feedback</Option>
                  </Select>
                  <input
                    type="number"
                    placeholder="Phone number"
                    className="input"
                  />
                  <input
                    type="text"
                    placeholder="Your email"
                    className="input"
                  />


                  <input type="text" placeholder="Subject" className="input" />
                  <textarea placeholder="Your message"></textarea>

                  <button className="contact_us_btn">Submit</button>
                </form>
              </div>
              <div className="contact_us_form_img">
                <LazyLoadImage
                  className="whyichest_image_img"
                  src="/img/contact-us.webp"
                  alt="secure_data_image "
                  effect="blur"
                />
              </div>
            </div>
          </div>
        </div>
        <FooterPage />
      </div>
    </>
  );
};
export default ContactUs;
