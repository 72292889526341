import React, { Suspense, lazy, useEffect, useState } from "react";
import SideBar from "../../components/sidebar/Sidebar";
import TrusteeBase from "../../components/trustee/TrusteeBase";
import { useParams } from "react-router-dom";
import {
  downloadDocument1,
  getBeneficiary,
  getCombinedData,
  getToken,
  getUser,
  getUserModelById,
} from "../../services/user-service";
import { Card, CardBody, Table } from "reactstrap";
import { TableBody, TableHead, TableRow, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faHouse,
  faDownload,
  faEye,
  faEyeSlash,
  faBuildingColumns,
  faGem,
  faCarSide,
  faHandHoldingDollar,
  faMoneyCheckDollar,
  faLocationDot,
  faShieldHalved,
} from "@fortawesome/free-solid-svg-icons";
import { faBitcoin } from "@fortawesome/free-brands-svg-icons";
import { formatAmount } from "../../components/Common";

export default function ViewUserAssets() {
  const { id } = useParams();

  // get attorney user
  const [user1, setUser1] = useState([]);
  const getUserDetail = () => {
    getUserModelById(id)
      .then((res) => {
        setUser1(res.data.user);
      })
      .catch((err) => console.log(err));
  };

  // get combined data in single list
  const [combinedData, setCombinedData] = useState([]);
  const getCombinedDataFromServer = () => {
    getCombinedData(id)
      .then((res) => {
        setCombinedData(res);
      })
      .catch((err) => {});
  };

  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let token = "Bearer " + getToken();
    getBeneficiary(token, id)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };

  const getBenificiaryName = (id) => {
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }

    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found"; // Or handle the case where beneficiary with the given ID isn't found
    }
  };

  const handleDownload = (id, fileName) => {
    let myarry = fileName.split(".");
    let token = "Bearer " + getToken();
    downloadDocument1(id)
      .then((response) => {
        const downloadUrl = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `${myarry[0]}.${myarry[1]}`;
        link.click();
        URL.revokeObjectURL(downloadUrl);
      })
      .catch((error) => {});
  };

  // popup component
  const [showPopup, setShowPopup] = useState(false);

  const GenerateWill = lazy(() => import("../my-estate/GenerateWill"));

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  // for assests show
  let [showAssets, SetshowAssets] = useState(false);

  let [showAdditionField1, setshowAdditionField1] = useState(false);
  let [assetTypeValue, setAssetTypeValue] = useState("");

  //   show download popup
  const [popupVisibleDownlaod, setPopupVisibleDownlaod] = useState(false);
  const [selectedDownlaod, setSelectDownload] = useState("");

  const handleShowDownlaod = (showDetail, value) => {
    setPopupVisibleDownlaod(true);
    setSelectDownload(showDetail);
    setAssetTypeValue(value);
  };

  // show beneficiary popup
  const [beneficiaryVisible, setBeneficiaryVisible] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");

  const handleOpenBeneficiary = (showDetail, value) => {
    setSelectedBeneficiary(showDetail);
    setAssetTypeValue(value);
    setBeneficiaryVisible(true);
  };

  // show notes popup
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedNote, setSelectedNote] = useState("");

  const handleOpenPopup = (note, value) => {
    setSelectedNote(note);
    setPopupVisible(true);
    setAssetTypeValue(value);
  };

  let [showAdditionField, SetshowAdditionField] = useState(false);

  const [visibleAccountIndices, setVisibleAccountIndices] = useState([]);

  const toggleVisibility = (index) => {
    const currentIndex = visibleAccountIndices.indexOf(index);
    const newVisibleIndices = [...visibleAccountIndices];

    if (currentIndex === -1) {
      newVisibleIndices.push(index);
    } else {
      newVisibleIndices.splice(currentIndex, 1);
    }

    setVisibleAccountIndices(newVisibleIndices);
  };

  const renderAccountNumber = (accountNumber, index) => {
    if (visibleAccountIndices.includes(index)) {
      return accountNumber;
    } else {
      // Mask all but the last four digits
      const maskedDigits = accountNumber.slice(0, -4).replace(/\d/g, "*");
      const lastFourDigits = accountNumber.slice(-4);
      return maskedDigits + lastFourDigits;
    }
  };

  let [showDetailOtherAsset, setShowDetailOtherAsset] = useState([]); // this is to display the card details
  let [showDetailRealEstate, setShowDetailRealEstate] = useState([]); // this is to display the card details
  let [showDetailBank, setShowDetailBank] = useState([]); // this is to display the card details
  let [showDetailInvestment, setShowDetailInvestment] = useState([]); // this is to display the card details
  let [showDetailIncome, setShowDetailIncome] = useState([]);
  let [showDetailVehicle, setShowDetailVehicle] = useState([]); // this is to display the card details
  let [showDetailJewelry, setShowDetailJewelry] = useState([]); // this is to display the card details
  let [showDetailCrypto, setShowDetailCrypto] = useState([]); // this is to display the card details
  let [showDetailInsurance, setShowDetailInsurance] = useState([]); // this is to display the card details

  let [showRealEstate, setShowRealEstate] = useState(false);
  let [showBank, setShowBank] = useState(false);
  let [showInvestment, setShowInvestment] = useState(false);
  let [showIncome, setShowIncome] = useState(false);
  let [showVehicle, setShowVehicle] = useState(false);
  let [showJewelry, setShowJewelry] = useState(false);
  let [showCrypto, setShowCrypto] = useState(false);
  let [showInsurance, setShowInsurance] = useState(false);
  let [showOtherAsset, setShowOtherAsset] = useState(false);

  // showing the details of cards like popup
  const toggle = (obj, assetName) => {
    switch (assetName) {
      case "otherAsset":
        setShowDetailOtherAsset(obj);
        setShowOtherAsset(true);
        break;
      case "realEstate":
        setShowDetailRealEstate(obj);
        setShowRealEstate(true);
        break;
      case "bank":
        setShowDetailBank(obj);
        setShowBank(true);
        break;
      case "investment":
        setShowDetailInvestment(obj);
        setShowInvestment(true);
        break;
      case "income":
        setShowDetailIncome(obj);
        setShowIncome(true);
        break;
      case "vehicle":
        setShowDetailVehicle(obj);
        setShowVehicle(true);
        break;
      case "jewelry":
        setShowDetailJewelry(obj);
        setShowJewelry(true);
        break;
      case "crypto":
        setShowDetailCrypto(obj);
        setShowCrypto(true);
        break;
      case "insurance":
        setShowDetailInsurance(obj);
        setShowInsurance(true);
        break;
      default:
    }
  };

  useEffect(() => {
    getBenificiarydata();
    getCombinedDataFromServer();
    getUserDetail();
  }, []);
  return (
    <>
      {showPopup ? (
        <Suspense fallback={<div>Loading...</div>}>
          <GenerateWill
            combinedData={combinedData}
            closePopup={closePopup}
            userType={"attorney"}
            id={id}
            attorneyUser={user1}
          />
        </Suspense>
      ) : (
        <TrusteeBase>
          <div className="mt-5"></div>
          {/* <SideBar> */}
          <div
            style={{ marginTop: "60px" }}
            className="btn-name dashboard_all_btn"
          >
            <button
              style={{ cursor: "pointer", marginLeft: "11px" }}
              className="dashboard_top_button"
              onClick={() => {
                openPopup();
              }}
            >
              Inventory Report
            </button>
          </div>
          <container>
            <div
              className="homepage"
              style={{ display: "flex", width: "80vw", height: "auto" }}
            >
              <Card
                style={{
                  overflow: "auto",
                  height: "fitContent",
                  minHeight: "50px",
                  maxHeight: "100vh",
                }}
              >
                {combinedData[0]?.realEstateData?.length > 0 && (
                  <CardBody>
                    <h2>Real Estate</h2>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <th className="tabe_rowsl">S.N</th>
                          <th className="tabe_row">Asset Name</th>
                          <th className="tabe_row">Owner Name</th>
                          <th className="tabe_row">Property Type</th>
                          <th className="tabe_row">Asset Value</th>
                          <th className="tabe_row">View Details</th>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {combinedData[0]?.realEstateData.map((data, index) => (
                          <TableRow key={index} style={{ textAlign: "center" }}>
                            <td>{index + 1}</td>
                            <td>{"Real Estate"}</td>
                            <td>
                              <span>{data.realEstate.owner}</span>
                            </td>
                            <td>{data.realEstate.propertyType}</td>
                            <td>
                              {formatAmount(data.realEstate.estPropertyVal)}
                            </td>
                            <td className=" viewDetails">
                              <button
                                onClick={() => toggle(data, "realEstate")}
                                style={{ margin: 0 }}
                              >
                                View Details
                              </button>
                            </td>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardBody>
                )}

                {combinedData[1]?.bankData?.length > 0 && (
                  <CardBody>
                    <h2>Bank</h2>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <th className="tabe_rowsl">S.N</th>
                          <th className="tabe_row">Asset Name</th>
                          <th className="tabe_row">Owner Name</th>
                          <th className="tabe_row">Bank Name</th>
                          <th className="tabe_row">Asset Value</th>
                          <th className="tabe_row">View Details</th>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {combinedData[1]?.bankData.map((data, index) => (
                          <TableRow key={index} style={{ textAlign: "center" }}>
                            <td>{index + 1}</td>
                            <td>{"Bank"}</td>
                            <td>
                              <span>{data.bank.owner}</span>
                            </td>
                            <td>{data.bank.bankName}</td>
                            <td>{formatAmount(data.bank.totalAmount)}</td>
                            <td className=" viewDetails">
                              <button
                                onClick={() => toggle(data, "bank")}
                                style={{ margin: 0 }}
                              >
                                View Details
                              </button>
                            </td>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardBody>
                )}

                {combinedData[2]?.jewelryData?.length > 0 && (
                  <CardBody>
                    <h2>Jewelry</h2>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <th className="tabe_rowsl">S.N</th>
                          <th className="tabe_row">Asset Name</th>
                          <th className="tabe_row">Owner Name</th>
                          <th className="tabe_row">Jewelry Type</th>
                          <th className="tabe_row">Asset Value</th>
                          <th className="tabe_row">View Details</th>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {combinedData[2]?.jewelryData.map((data, index) => (
                          <TableRow key={index} style={{ textAlign: "center" }}>
                            <td>{index + 1}</td>
                            <td>{"Jewelry"}</td>
                            <td>
                              <span>{data.jewelry.owner}</span>
                            </td>
                            <td>{data.jewelry.jewelryName}</td>
                            <td>{formatAmount(data.jewelry.estimatedValue)}</td>
                            <td className=" viewDetails">
                              <button
                                onClick={() => toggle(data, "jewelry")}
                                style={{ margin: 0 }}
                              >
                                View Details
                              </button>
                            </td>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardBody>
                )}

                {combinedData[3]?.vehicleData?.length > 0 && (
                  <CardBody>
                    <h2>Vehicle</h2>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <th className="tabe_rowsl">S.N</th>
                          <th className="tabe_row">Asset Name</th>
                          <th className="tabe_row">Owner Name</th>
                          <th className="tabe_row">Vehicle Type</th>
                          <th className="tabe_row">Asset Value</th>
                          <th className="tabe_row">View Details</th>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {combinedData[3]?.vehicleData.map((data, index) => (
                          <TableRow key={index} style={{ textAlign: "center" }}>
                            <td>{index + 1}</td>
                            <td>{"Vehicle"}</td>
                            <td>
                              <span>{data.vehicle.owner}</span>
                            </td>
                            <td>{data.vehicle.vehicleType}</td>
                            <td>{formatAmount(data.vehicle.estValue)}</td>
                            <td className=" viewDetails">
                              <button
                                onClick={() => toggle(data, "vehicle")}
                                style={{ margin: 0 }}
                              >
                                View Details
                              </button>
                            </td>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardBody>
                )}

                {combinedData[4]?.investmentData?.length > 0 && (
                  <CardBody>
                    <h2>Investment</h2>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <th className="tabe_rowsl">S.N</th>
                          <th className="tabe_row">Asset Name</th>
                          <th className="tabe_row">Owner Name</th>
                          <th className="tabe_row">Investment</th>
                          <th className="tabe_row">Asset Value</th>
                          <th className="tabe_row">View Details</th>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {combinedData[4]?.investmentData.map((data, index) => (
                          <TableRow key={index} style={{ textAlign: "center" }}>
                            <td>{index + 1}</td>
                            <td>{"Investment"}</td>
                            <td>
                              <span>{data.investment.owner}</span>
                            </td>
                            <td>{data.investment.investment}</td>
                            <td>{formatAmount(data.investment.totalAmount)}</td>
                            <td className=" viewDetails">
                              <button
                                onClick={() => toggle(data, "investment")}
                                style={{ margin: 0 }}
                              >
                                View Details
                              </button>
                            </td>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardBody>
                )}

                {combinedData[5]?.cryptoAssetData?.length > 0 && (
                  <CardBody>
                    <h2>Bit Coin</h2>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <th className="tabe_rowsl">S.N</th>
                          <th className="tabe_row">Asset Name</th>
                          <th className="tabe_row">Owner Name</th>
                          <th className="tabe_row">Coin</th>
                          <th className="tabe_row">Asset Value</th>
                          <th className="tabe_row">View Details</th>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {combinedData[5]?.cryptoAssetData.map((data, index) => (
                          <TableRow key={index} style={{ textAlign: "center" }}>
                            <td>{index + 1}</td>
                            <td>{"Bit Coin"}</td>
                            <td>
                              <span>{data.cryptoAssest.owner}</span>
                            </td>
                            <td>{data.cryptoAssest.coin}</td>
                            <td>{formatAmount(data.cryptoAssest.estValue)}</td>
                            <td className=" viewDetails">
                              <button
                                onClick={() => toggle(data, "crypto")}
                                style={{ margin: 0 }}
                              >
                                View Details
                              </button>
                            </td>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardBody>
                )}

                {combinedData[6]?.incomeData?.length > 0 && (
                  <CardBody>
                    <h2>Income</h2>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <th className="tabe_rowsl">S.N</th>
                          <th className="tabe_row">Asset Name</th>
                          <th className="tabe_row">Owner Name</th>
                          <th className="tabe_row">Business Source</th>
                          <th className="tabe_row">Asset Value</th>
                          <th className="tabe_row">View Details</th>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {combinedData[6]?.incomeData.map((data, index) => (
                          <TableRow key={index} style={{ textAlign: "center" }}>
                            <td>{index + 1}</td>
                            <td>{"Income"}</td>
                            <td>
                              <span>{data.income.owner}</span>
                            </td>
                            <td>{data.income.businessSource}</td>
                            <td>{formatAmount(data.income.incomeAmount)}</td>
                            <td className=" viewDetails">
                              <button
                                onClick={() => toggle(data, "income")}
                                style={{ margin: 0 }}
                              >
                                View Details
                              </button>
                            </td>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardBody>
                )}

                {combinedData[7]?.otherAssetData?.length > 0 && (
                  <CardBody>
                    <h2>Other Asset</h2>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <th className="tabe_rowsl">S.N</th>
                          <th className="tabe_row">Asset Name</th>
                          <th className="tabe_row">Owner Name</th>
                          <th className="tabe_row">Asset Caption</th>
                          <th className="tabe_row">Asset Value</th>
                          <th className="tabe_row">View Details</th>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {combinedData[7]?.otherAssetData.map((data, index) => (
                          <TableRow key={index} style={{ textAlign: "center" }}>
                            <td>{index + 1}</td>
                            <td>{"Other Asset"}</td>
                            <td>
                              <span>{data.otherAsset.owner}</span>
                            </td>
                            <td>{data.otherAsset.assetCaption}</td>
                            <td>{formatAmount(data.otherAsset.assetValue)}</td>
                            <td className=" viewDetails">
                              <button
                                onClick={() => toggle(data, "otherAsset")}
                                style={{ margin: 0 }}
                              >
                                View Details
                              </button>
                            </td>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardBody>
                )}

                {combinedData[8]?.insuranceData?.length > 0 && (
                  <CardBody>
                    <h2>Insurance</h2>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <th className="tabe_rowsl">S.N</th>
                          <th className="tabe_row">Asset Name</th>
                          <th className="tabe_row">Owner Name</th>
                          <th className="tabe_row">Detail</th>
                          <th className="tabe_row">Asset Value</th>
                          <th className="tabe_row">View Details</th>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {combinedData[8]?.insuranceData.map((data, index) => (
                          <TableRow key={index} style={{ textAlign: "center" }}>
                            <td>{index + 1}</td>
                            <td>{"Insurance"}</td>
                            <td>
                              <span>{data.insurance.owner}</span>
                            </td>
                            <td>{data.insurance.details}</td>
                            <td>
                              {formatAmount(
                                data.deathBenefitsModel.deathBenefits
                                  .insuranceAmount
                              )}
                            </td>
                            <td className=" viewDetails">
                              <button
                                onClick={() => toggle(data, "insurance")}
                                style={{ margin: 0 }}
                              >
                                View Details
                              </button>
                            </td>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardBody>
                )}
              </Card>
            </div>
          </container>

          {/* for realEstate popup  */}
          {showRealEstate && Object.keys(showDetailRealEstate).length > 0 && (
            <>
              <div
                className="card__data"
                style={{ transition: "all 1s ease-out" }}
              >
                <div className="card__data-container">
                  <section className="section1">
                    <div>
                      <div className="row1-text">
                        <FontAwesomeIcon
                          icon={faHouse}
                          style={{ color: "#025596", fontSize: "18px" }}
                        />
                        <span style={{}}>
                          {showDetailRealEstate.realEstate.propertyCaption}
                        </span>
                      </div>
                      <div className="row1-button">
                        <div>
                          {showDetailRealEstate.documents &&
                            showDetailRealEstate.documents.length > 0 && (
                              <Tooltip title="click to see multiple downlaod files">
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleShowDownlaod(
                                      showDetailRealEstate,
                                      "realEstate"
                                    );
                                    setShowRealEstate(!showRealEstate);
                                  }}
                                >
                                  <div className="myestate_download_button dwnbtn">
                                    <FontAwesomeIcon
                                      className="myestate_download_icon"
                                      icon={faDownload}
                                    />
                                    <span></span>
                                  </div>
                                </div>
                              </Tooltip>
                            )}
                        </div>

                        <div>
                          <span
                            className="card__data-close"
                            onClick={() => {
                              setShowRealEstate(!showRealEstate);
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="section2">
                    <div>
                      <div className="col1">
                        {showDetailRealEstate.realEstate.owner && (
                          <p>
                            Owner :{" "}
                            <code>{showDetailRealEstate.realEstate.owner}</code>
                          </p>
                        )}

                        {showDetailRealEstate.realEstate.propertyType ===
                        "Other" ? (
                          ""
                        ) : (
                          <p>
                            Type of Property:{" "}
                            <code>
                              {" "}
                              {showDetailRealEstate.realEstate.propertyType}
                            </code>
                          </p>
                        )}

                        {showDetailRealEstate.realEstate.propertyType ===
                          "Other" && true ? (
                          <p>
                            Other Type of Property:{" "}
                            <code>
                              {" "}
                              {
                                showDetailRealEstate.realEstate
                                  .otherPropertyType
                              }
                            </code>
                          </p>
                        ) : (
                          ""
                        )}
                        <p>
                          Street Address:{" "}
                          <code>
                            {showDetailRealEstate.realEstate.streetAddress}
                          </code>
                        </p>

                        {showDetailRealEstate.realEstate.aptNumber && (
                          <p>
                            Apartment:{" "}
                            <code>
                              {showDetailRealEstate.realEstate.aptNumber}
                            </code>
                          </p>
                        )}
                        <p>
                          Zip Code:{" "}
                          <code>{showDetailRealEstate.realEstate.zipCode}</code>
                        </p>
                        <p>
                          City / Town:{" "}
                          <code>{showDetailRealEstate.realEstate.city}</code>
                        </p>
                        <p>
                          State:{" "}
                          <code>{showDetailRealEstate.realEstate.state}</code>
                        </p>
                      </div>
                      <div className="col2">
                        <p>
                          Country:{" "}
                          <code>{showDetailRealEstate.realEstate.country}</code>
                        </p>

                        <p>
                          Estimated Equity:{" "}
                          <code>
                            {" "}
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              {" "}
                              {formatAmount(
                                showDetailRealEstate.realEstate.equity
                              )}
                            </span>
                          </code>
                        </p>

                        <p>
                          Estimated Annual Property Tax:
                          <code style={{ color: "red", fontWeight: "bold" }}>
                            {" "}
                            {formatAmount(
                              showDetailRealEstate.realEstate.propertyTax
                            )}
                          </code>
                        </p>

                        {
                          <Tooltip title={`Click To See Details`}>
                            <p
                              onClick={() => {
                                setshowAdditionField1(showDetailRealEstate);
                                setShowRealEstate(!showRealEstate);
                              }}
                            >
                              Mortgages Details:&nbsp;
                              <code>
                                <span className="readmore">Click to see</span>
                              </code>
                            </p>
                          </Tooltip>
                        }

                        <p>
                          Estimate Property Value:{" "}
                          <code style={{ color: "green", fontWeight: "bold" }}>
                            {formatAmount(
                              showDetailRealEstate.realEstate.estPropertyVal
                            )}
                          </code>
                        </p>

                        {showDetailRealEstate?.sharedDetails[0] && (
                          <p
                            onClick={() => {
                              handleOpenBeneficiary(
                                showDetailRealEstate,
                                "realEstate"
                              );
                              setShowRealEstate(!showRealEstate);
                            }}
                          >
                            Beneficiary Details{" "}
                            <code>
                              <span className="readmore">Click Here</span>
                            </code>
                          </p>
                        )}

                        {showDetailRealEstate.realEstate.notes && (
                          <Tooltip title="Click To see Note">
                            <p
                              onClick={() => {
                                handleOpenPopup(
                                  showDetailRealEstate.realEstate.notes,
                                  "realEstate"
                                );
                                setShowRealEstate(!showRealEstate);
                              }}
                            >
                              Note:{" "}
                              <code>
                                {showDetailRealEstate &&
                                showDetailRealEstate.realEstate.notes
                                  ? showDetailRealEstate.realEstate.notes.slice(
                                      0,
                                      10
                                    )
                                  : ""}
                                ...<span className="readmore">Read More</span>
                              </code>
                            </p>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </>
          )}

          {/* for bank popup  */}
          {showBank && Object.keys(showDetailBank).length > 0 && (
            <>
              <div className="card__data">
                <div className="card__data-container">
                  <section className="section1">
                    <div>
                      <p className="row1-text">
                        <FontAwesomeIcon
                          icon={faBuildingColumns}
                          style={{ color: "#025596", fontSize: "18px" }}
                        />
                        <span>{showDetailBank.bank.bankName}</span>
                      </p>
                      <div className="row1-button">
                        <div>
                          {showDetailBank.documents &&
                            showDetailBank.documents.length > 0 && (
                              <Tooltip title="click to see multiple downlaod files">
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleShowDownlaod(showDetailBank, "bank");
                                    setShowBank(!showBank);
                                  }}
                                >
                                  <div className="myestate_download_button dwnbtn">
                                    <FontAwesomeIcon
                                      className="myestate_download_icon"
                                      icon={faDownload}
                                    />
                                    <span></span>
                                  </div>
                                </div>
                              </Tooltip>
                            )}
                        </div>

                        <div>
                          <span
                            className="card__data-close"
                            onClick={() => {
                              setShowBank(!showBank);
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="section2">
                    <div>
                      <div className="col1">
                        {showDetailBank.bank.owner && (
                          <p>
                            Owner : <code>{showDetailBank.bank.owner}</code>
                          </p>
                        )}

                        {showDetailBank.bank.bankType !== "Other" &&
                        showDetailBank.bank.bankName ? (
                          <p>
                            Bank Name:
                            <code>
                              &nbsp;&nbsp;
                              {showDetailBank.bank.bankName}
                            </code>
                          </p>
                        ) : (
                          <p>
                            Other Banks:{" "}
                            <code>{showDetailBank.bank.bankName}</code>
                          </p>
                        )}
                        {
                          <Tooltip title={`Click To See Details`}>
                            <p
                              onClick={() => {
                                SetshowAdditionField(showDetailBank);
                              }}
                            >
                              Additional Accounts Details:&nbsp;
                              <code>
                                <span className="readmore">Click to see</span>
                              </code>
                            </p>
                          </Tooltip>
                        }
                        <p>
                          Estimated Total Bank Assets:{" "}
                          <code style={{ color: "green", fontWeight: "bold" }}>
                            {formatAmount(showDetailBank.bank.totalAmount)}
                          </code>
                        </p>
                      </div>
                      <div className="col2">
                        {showDetailBank.bank.safetyBox ? (
                          <p>
                            Safty Box:{" "}
                            <code>
                              {showDetailBank.bank.safetyBox === "true" ? (
                                "yes"
                              ) : (
                                <span style={{ color: "red" }}>No</span>
                              )}
                            </code>
                          </p>
                        ) : (
                          ""
                        )}
                        {showDetailBank.bank.safetyBoxNumber ? (
                          <p>
                            SaftyBox ID:{" "}
                            <code>
                              {showDetailBank.bank.safetyBoxNumber === "" ? (
                                ""
                              ) : (
                                <span>
                                  {showDetailBank.bank.safetyBoxNumber}
                                </span>
                              )}
                            </code>
                          </p>
                        ) : (
                          ""
                        )}
                        {showDetailBank.sharedDetails[0] && (
                          <p
                            onClick={() => {
                              handleOpenBeneficiary(showDetailBank, "bank");
                              setShowBank(!showBank);
                            }}
                          >
                            Beneficiary Details{" "}
                            <code>
                              <span className="readmore">Click Here</span>
                            </code>
                          </p>
                        )}
                        {showDetailBank.bank.notes && (
                          <Tooltip title="Click To see Note">
                            <p
                              onClick={() => {
                                handleOpenPopup(
                                  showDetailBank.bank.notes,
                                  "bank"
                                );
                                setShowBank(!showBank);
                              }}
                            >
                              Note:{" "}
                              <code>
                                {showDetailBank && showDetailBank.bank.notes
                                  ? showDetailBank.bank.notes.slice(0, 5)
                                  : ""}
                                ...<span className="readmore">Read More</span>
                              </code>
                            </p>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </>
          )}

          {/* for jewelry popup  */}
          {showJewelry && Object.keys(showDetailJewelry).length > 0 && (
            <>
              <div className="card__data">
                <div className="card__data-container">
                  <section className="section1">
                    <div>
                      <p className="row1-text">
                        <FontAwesomeIcon
                          icon={faGem}
                          style={{ color: "#025596", fontSize: "18px" }}
                        />
                        <span>{showDetailJewelry.jewelry.jewelryName}</span>
                      </p>
                      <div className="row1-button">
                        <div>
                          {showDetailJewelry.documents &&
                            showDetailJewelry.documents.length > 0 && (
                              <Tooltip title="click to see multiple downlaod files">
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleShowDownlaod(
                                      showDetailJewelry,
                                      "jewelry"
                                    );
                                    setShowJewelry(!showJewelry);
                                  }}
                                >
                                  <div className="myestate_download_button dwnbtn">
                                    <FontAwesomeIcon
                                      className="myestate_download_icon"
                                      icon={faDownload}
                                    />
                                    <span></span>
                                  </div>
                                </div>
                              </Tooltip>
                            )}
                        </div>

                        <div>
                          <span
                            className="card__data-close"
                            onClick={() => {
                              setShowJewelry(!showJewelry);
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="section2">
                    <div>
                      <div className="col1">
                        {showDetailJewelry.jewelry.owner && (
                          <p>
                            Owner :{" "}
                            <code>{showDetailJewelry.jewelry.owner}</code>
                          </p>
                        )}
                        {showDetailJewelry.jewelry.jewelryName && (
                          <p>
                            Jewelry Type :{" "}
                            {showDetailJewelry.jewelry.jewelryName ===
                            "Other" ? (
                              <code>
                                {showDetailJewelry.jewelry.otherJewelryName}
                              </code>
                            ) : (
                              <code>
                                {showDetailJewelry.jewelry.jewelryName}
                              </code>
                            )}
                          </p>
                        )}
                        <p>
                          Jewelry Name:{" "}
                          <code>
                            {showDetailJewelry.jewelry.jewelryCaption}
                          </code>
                        </p>

                        {showDetailJewelry.jewelry.weight && (
                          <p>
                            Weight(gm):{" "}
                            <code>{showDetailJewelry.jewelry.weight}</code>
                          </p>
                        )}
                      </div>

                      <div className="col2">
                        <p>
                          Quantity:{" "}
                          <span>{showDetailJewelry.jewelry.quantity}</span>
                        </p>
                        <p>
                          Value/Quantity:{" "}
                          <code style={{ color: "green", fontWeight: "bold" }}>
                            {formatAmount(
                              showDetailJewelry.jewelry.perQuantityValue
                            )}
                          </code>
                        </p>
                        <p>
                          Appraised Value:{" "}
                          <code style={{ color: "green", fontWeight: "bold" }}>
                            {formatAmount(
                              showDetailJewelry.jewelry.estimatedValue
                            )}
                          </code>
                        </p>
                        {showDetailJewelry.jewelry.caratValue && (
                          <p>
                            Carat Value:{" "}
                            <code>{showDetailJewelry.jewelry.caratValue}</code>
                          </p>
                        )}

                        {showDetailJewelry.sharedDetails[0] && (
                          <p
                            onClick={() => {
                              handleOpenBeneficiary(
                                showDetailJewelry,
                                "jewelry"
                              );
                              setShowJewelry(!showJewelry);
                            }}
                          >
                            Beneficiary Details{" "}
                            <code>
                              <span className="readmore">Click Here</span>
                            </code>
                          </p>
                        )}

                        {showDetailJewelry.jewelry.notes && (
                          <Tooltip title="Click To see Note">
                            <p
                              onClick={() => {
                                handleOpenPopup(
                                  showDetailJewelry.jewelry.notes,
                                  "jewelry"
                                );
                                setShowJewelry(!showJewelry);
                              }}
                            >
                              Note:{" "}
                              <code>
                                {" "}
                                {showDetailJewelry &&
                                showDetailJewelry.jewelry.notes
                                  ? showDetailJewelry.jewelry.notes.slice(0, 5)
                                  : ""}
                                ...<span className="readmore">Read More</span>
                              </code>
                            </p>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </>
          )}

          {/* for vehicle popup  */}
          {showVehicle && Object.keys(showDetailVehicle).length > 0 && (
            <>
              <div className="card__data">
                <div className="card__data-container">
                  <section className="section1">
                    <div>
                      <p className="row1-text">
                        <FontAwesomeIcon
                          icon={faCarSide}
                          style={{ color: "#025596", fontSize: "18px" }}
                        />
                        <span>{showDetailVehicle.vehicle.vehicleType}</span>
                      </p>
                      <div className="row1-button">
                        <div>
                          {showDetailVehicle.documents &&
                            showDetailVehicle.documents.length > 0 && (
                              <Tooltip title="click to see multiple downlaod files">
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleShowDownlaod(
                                      showDetailVehicle,
                                      "vehicle"
                                    );
                                    setShowVehicle(!showVehicle);
                                  }}
                                >
                                  <div className="myestate_download_button dwnbtn">
                                    <FontAwesomeIcon
                                      className="myestate_download_icon"
                                      icon={faDownload}
                                    />
                                    <span></span>
                                  </div>
                                </div>
                              </Tooltip>
                            )}
                        </div>

                        <div>
                          <span
                            className="card__data-close"
                            onClick={() => {
                              setShowVehicle(!showVehicle);
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="section2">
                    <div>
                      <div className="col1">
                        {showDetailVehicle.vehicle.owner && (
                          <p>
                            Owner :{" "}
                            <code>{showDetailVehicle.vehicle.owner}</code>
                          </p>
                        )}
                        <p>
                          Vehicle Type:{" "}
                          <code>{showDetailVehicle.vehicle.vehicleType}</code>
                        </p>
                        <p>
                          Year Manufactured:{" "}
                          <code>{showDetailVehicle.vehicle.year}</code>
                        </p>
                        <p>
                          Loan:{" "}
                          <code style={{ color: "red", fontWeight: "bold" }}>
                            {formatAmount(showDetailVehicle.vehicle.loan)}
                          </code>
                        </p>
                        <p>
                          Make: <code>{showDetailVehicle.vehicle.make}</code>
                        </p>
                        <p>
                          Miles:{" "}
                          {showDetailVehicle.vehicle.make === "Other" ? (
                            <code>{showDetailVehicle.vehicle.otherMake}</code>
                          ) : (
                            <code>{showDetailVehicle.vehicle.make}</code>
                          )}
                        </p>
                      </div>
                      <div className="col2">
                        <p>
                          Model:{" "}
                          {showDetailVehicle.vehicle.model === "Other" ? (
                            <code>{showDetailVehicle.vehicle.otherModel}</code>
                          ) : (
                            <code>{showDetailVehicle.vehicle.model}</code>
                          )}
                        </p>
                        <p>
                          Estimated Value :{" "}
                          <code style={{ color: "green", fontWeight: "bold" }}>
                            {formatAmount(showDetailVehicle.vehicle.estValue)}
                          </code>
                        </p>
                        {showDetailVehicle.vehicle.equity && (
                          <p>
                            Estimated Equity :{" "}
                            <code
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              {formatAmount(showDetailVehicle.vehicle.equity)}
                            </code>
                          </p>
                        )}
                        <p>
                          Vehicle Caption:{" "}
                          <code>
                            {showDetailVehicle.vehicle.vehicleCaption}
                          </code>
                        </p>

                        {showDetailVehicle.sharedDetails[0] && (
                          <p
                            onClick={() => {
                              handleOpenBeneficiary(
                                showDetailVehicle,
                                "vehicle"
                              );
                              setShowVehicle(!showVehicle);
                            }}
                          >
                            Beneficiary Details{" "}
                            <code>
                              <span className="readmore">Click Here</span>
                            </code>
                          </p>
                        )}

                        {showDetailVehicle.vehicle.notes && (
                          <Tooltip title="Click To see Note">
                            <p
                              onClick={() => {
                                handleOpenPopup(
                                  showDetailVehicle.vehicle.notes,
                                  "vehicle"
                                );
                                setShowVehicle(!showVehicle);
                              }}
                            >
                              Note:{" "}
                              <code>
                                {" "}
                                {showDetailVehicle &&
                                showDetailVehicle.vehicle.notes
                                  ? showDetailVehicle.vehicle.notes.slice(0, 5)
                                  : ""}
                                ...<span className="readmore">Read More</span>
                              </code>
                            </p>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </>
          )}

          {/* for investment popup  */}
          {showInvestment && Object.keys(showDetailInvestment).length > 0 && (
            <>
              <div className="card__data">
                <div className="card__data-container">
                  <section className="section1">
                    <div>
                      <p className="row1-text">
                        <FontAwesomeIcon
                          icon={faHandHoldingDollar}
                          style={{ color: "#025596", fontSize: "18px" }}
                        />
                        <span>
                          {showDetailInvestment.investment.investment}
                        </span>
                      </p>

                      <div className="row1-button">
                        <div>
                          {showDetailInvestment.documents &&
                            showDetailInvestment.documents.length > 0 && (
                              <Tooltip title="click to see multiple downlaod files">
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleShowDownlaod(
                                      showDetailInvestment,
                                      "investment"
                                    );
                                    setShowInvestment(!showInvestment);
                                  }}
                                >
                                  <div className="myestate_download_button dwnbtn">
                                    <FontAwesomeIcon
                                      className="myestate_download_icon"
                                      icon={faDownload}
                                    />
                                    <span></span>
                                  </div>
                                </div>
                              </Tooltip>
                            )}
                        </div>

                        <div>
                          <span
                            className="card__data-close"
                            onClick={() => {
                              setShowInvestment(!showInvestment);
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="section2">
                    <div>
                      <div className="col1">
                        {showDetailInvestment.investment.owner && (
                          <p>
                            Owner :{" "}
                            <code>{showDetailInvestment.investment.owner}</code>
                          </p>
                        )}
                        <p>
                          Investment:{" "}
                          <code>
                            {showDetailInvestment.investment.investment}
                          </code>
                        </p>
                        <p>
                          Investment Caption:{" "}
                          <code>
                            {showDetailInvestment.investment.investmentCaption}
                          </code>
                        </p>
                        <p>
                          Name of Exchange:{" "}
                          <code>
                            {
                              showDetailInvestment.investment
                                .nameOfTheInvestment
                            }
                          </code>
                        </p>
                        <p>
                          Total Amount:{" "}
                          <code style={{ color: "green", fontWeight: "bold" }}>
                            {formatAmount(
                              showDetailInvestment.investment.totalAmount
                            )}
                          </code>
                        </p>

                        {showDetailInvestment.sharedDetails[0] && (
                          <p
                            onClick={() => {
                              handleOpenBeneficiary(
                                showDetailInvestment,
                                "investment"
                              );
                              setShowInvestment(!showInvestment);
                            }}
                          >
                            Beneficiary Details{" "}
                            <code>
                              <span className="readmore">Click Here</span>
                            </code>
                          </p>
                        )}

                        {showDetailInvestment.investment.notes && (
                          <Tooltip title="Click To see Note">
                            <p
                              onClick={() => {
                                handleOpenPopup(
                                  showDetailInvestment.investment.notes,
                                  "investment"
                                );
                                setShowInvestment(!showInvestment);
                              }}
                            >
                              Note:{" "}
                              <code>
                                {" "}
                                {showDetailInvestment &&
                                showDetailInvestment.investment.notes
                                  ? showDetailInvestment.investment.notes.slice(
                                      0,
                                      5
                                    )
                                  : ""}
                                ...<span className="readmore">Read More</span>
                              </code>
                            </p>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </>
          )}

          {/* for crypto popup  */}
          {showCrypto && Object.keys(showDetailCrypto).length > 0 && (
            <>
              <div className="card__data">
                <div className="card__data-container">
                  <section className="section1">
                    <div>
                      <p className="row1-text">
                        <FontAwesomeIcon
                          icon={faBitcoin}
                          style={{ color: "#025596", fontSize: "18px" }}
                        />
                        <span>{showDetailCrypto.cryptoAssest.coin}</span>
                      </p>
                      <div className="row1-button">
                        <div>
                          {showDetailCrypto.documents &&
                            showDetailCrypto.documents.length > 0 && (
                              <Tooltip title="click to see multiple downlaod files">
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleShowDownlaod(
                                      showDetailCrypto,
                                      "crypto"
                                    );
                                    setShowCrypto(!showCrypto);
                                  }}
                                >
                                  <div className="myestate_download_button dwnbtn">
                                    <FontAwesomeIcon
                                      className="myestate_download_icon"
                                      icon={faDownload}
                                    />
                                    <span></span>
                                  </div>
                                </div>
                              </Tooltip>
                            )}
                        </div>

                        <div>
                          <span
                            className="card__data-close"
                            onClick={() => {
                              setShowCrypto(!showCrypto);
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="section2">
                    <div>
                      <div className="col1">
                        {showDetailCrypto.cryptoAssest.owner && (
                          <p>
                            Owner :{" "}
                            <code>{showDetailCrypto.cryptoAssest.owner}</code>
                          </p>
                        )}
                        {showDetailCrypto.cryptoAssest.heading && (
                          <p style={{ color: "black", fontWeight: "500" }}>
                            Heading :{" "}
                            <span>
                              {showDetailCrypto.cryptoAssest.heading && (
                                <span>
                                  {showDetailCrypto.cryptoAssest.heading}
                                </span>
                              )}
                            </span>
                          </p>
                        )}
                        {showDetailCrypto.cryptoAssest.coin && (
                          <p style={{ color: "black", fontWeight: "500" }}>
                            Coin :{" "}
                            <span>
                              {showDetailCrypto.cryptoAssest.coin && (
                                <span>
                                  {showDetailCrypto.cryptoAssest.coin}
                                </span>
                              )}
                            </span>
                          </p>
                        )}
                        {showDetailCrypto.cryptoAssest.storageType ===
                        "Exchange" ? (
                          <p
                            style={{
                              color: "black",
                              fontWeight: "500",
                            }}
                          >
                            Exchange :{" "}
                            <span>
                              {showDetailCrypto.cryptoAssest
                                .exchangeOrWallet === "Other" ? (
                                <span>
                                  {
                                    showDetailCrypto.cryptoAssest
                                      .otherExchangeOrWallet
                                  }
                                </span>
                              ) : (
                                <span>
                                  {
                                    showDetailCrypto.cryptoAssest
                                      .exchangeOrWallet
                                  }
                                </span>
                              )}
                            </span>
                          </p>
                        ) : (
                          <>
                            <p
                              style={{
                                color: "black",
                                fontWeight: "500",
                              }}
                            >
                              Wallet :{" "}
                              <span>
                                {showDetailCrypto.cryptoAssest
                                  .exchangeOrWallet === "Other" ? (
                                  <span>
                                    {
                                      showDetailCrypto.cryptoAssest
                                        .otherExchangeOrWallet
                                    }
                                  </span>
                                ) : (
                                  <span>
                                    {
                                      showDetailCrypto.cryptoAssest
                                        .exchangeOrWallet
                                    }
                                  </span>
                                )}
                              </span>
                            </p>
                          </>
                        )}
                      </div>
                      <div className="col2">
                        <p>
                          Quantity:{" "}
                          <code>
                            {showDetailCrypto.cryptoAssest.quantity && (
                              <span>
                                {showDetailCrypto.cryptoAssest.quantity}
                              </span>
                            )}
                          </code>
                        </p>
                        <p>
                          Estimated Value:{" "}
                          <code style={{ color: "green", fontWeight: "bold" }}>
                            {showDetailCrypto.cryptoAssest.estValue ? (
                              <span>
                                {formatAmount(
                                  showDetailCrypto.cryptoAssest.estValue
                                )}
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                Server Down Try Again Later
                              </span>
                            )}
                          </code>
                        </p>

                        {showDetailCrypto.sharedDetails[0] && (
                          <p
                            onClick={() => {
                              handleOpenBeneficiary(showDetailCrypto, "crypto");
                              setShowCrypto(!showCrypto);
                            }}
                          >
                            Beneficiary Details{" "}
                            <code>
                              <span className="readmore">Click Here</span>
                            </code>
                          </p>
                        )}

                        {showDetailCrypto.cryptoAssest.notes && (
                          <Tooltip title="Click To see Note">
                            <p
                              onClick={() => {
                                handleOpenPopup(
                                  showDetailCrypto.cryptoAssest.notes,
                                  "crypto"
                                );
                                setShowCrypto(!showCrypto);
                              }}
                            >
                              Note:{" "}
                              <code>
                                {showDetailCrypto &&
                                showDetailCrypto.cryptoAssest.notes
                                  ? showDetailCrypto.cryptoAssest.notes.slice(
                                      0,
                                      5
                                    )
                                  : ""}
                                ...<span className="readmore">Read More</span>
                              </code>
                            </p>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </>
          )}

          {/* for income popup  */}
          {showIncome && Object.keys(showDetailIncome).length > 0 && (
            <>
              <div className="card__data">
                <div className="card__data-container">
                  <section className="section1">
                    <div>
                      <p className="row1-text">
                        <FontAwesomeIcon
                          icon={faMoneyCheckDollar}
                          style={{ color: "#025596", fontSize: "18px" }}
                        />
                        <span>{showDetailIncome.income.incomeAmount}</span>
                      </p>
                      <div className="row1-button">
                        <div>
                          {showDetailIncome.documents &&
                            showDetailIncome.documents.length > 0 && (
                              <Tooltip title="click to see multiple downlaod files">
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleShowDownlaod(
                                      showDetailIncome,
                                      "income"
                                    );
                                    setShowIncome(!showIncome);
                                  }}
                                >
                                  <div className="myestate_download_button dwnbtn">
                                    <FontAwesomeIcon
                                      className="myestate_download_icon"
                                      icon={faDownload}
                                    />
                                    <span></span>
                                  </div>
                                </div>
                              </Tooltip>
                            )}
                        </div>

                        <div>
                          <span
                            className="card__data-close"
                            onClick={() => {
                              setShowIncome(!showIncome);
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="section2">
                    <div>
                      <div className="col1">
                        {showDetailIncome.income.owner && (
                          <p>
                            Owner : <code>{showDetailIncome.income.owner}</code>
                          </p>
                        )}
                        <p>
                          Income:{" "}
                          <code style={{ color: "green", fontWeight: "bold" }}>
                            {formatAmount(showDetailIncome.income.incomeAmount)}
                          </code>
                        </p>
                        <p>
                          Source:{" "}
                          <code>{showDetailIncome.income.businessSource}</code>
                        </p>
                      </div>
                      <div className="col2">
                        <p>
                          Income Caption{" "}
                          <code>{showDetailIncome.income.incomeCaption}</code>
                        </p>
                        {showDetailIncome.sharedDetails[0] && (
                          <p
                            onClick={() => {
                              handleOpenBeneficiary(showDetailIncome, "income");
                              setShowIncome(!showIncome);
                            }}
                          >
                            Beneficiary Details{" "}
                            <code>
                              <span className="readmore">Click Here</span>
                            </code>
                          </p>
                        )}

                        {showDetailIncome.income.notes && (
                          <Tooltip title="Click To see Note">
                            <p
                              onClick={() => {
                                handleOpenPopup(
                                  showDetailIncome.income.notes,
                                  "income"
                                );
                                setShowIncome(!showIncome);
                              }}
                            >
                              Note:{" "}
                              <code>
                                {showDetailIncome &&
                                showDetailIncome.income.notes
                                  ? showDetailIncome.income.notes.slice(0, 5)
                                  : ""}
                                ...<span className="readmore">Read More</span>
                              </code>
                            </p>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </>
          )}

          {/* for otherAsset popup  */}
          {showOtherAsset && Object.keys(showDetailOtherAsset).length > 0 && (
            <>
              <div className="card__data">
                <div className="card__data-container">
                  <section className="section1">
                    <div>
                      <p className="row1-text">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          style={{ color: "#4aafff", fontSize: "18px" }}
                        />
                        <span>
                          {showDetailOtherAsset.otherAsset.otherAssets1}
                        </span>
                      </p>
                      <div className="row1-button">
                        <div>
                          {showDetailOtherAsset.documents &&
                            showDetailOtherAsset.documents.length > 0 && (
                              <Tooltip title="click to see multiple downlaod files">
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleShowDownlaod(
                                      showDetailOtherAsset,
                                      "otherAsset"
                                    );
                                    setShowOtherAsset(!showOtherAsset);
                                  }}
                                >
                                  <div className="myestate_download_button dwnbtn">
                                    <FontAwesomeIcon
                                      className="myestate_download_icon"
                                      icon={faDownload}
                                    />
                                    <span></span>
                                  </div>
                                </div>
                              </Tooltip>
                            )}
                        </div>

                        <div>
                          <span
                            className="card__data-close"
                            onClick={() => {
                              setShowOtherAsset(!showOtherAsset);
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="section2">
                    <div>
                      <div className="col1">
                        {showDetailOtherAsset.otherAsset.owner && (
                          <p>
                            Owner :{" "}
                            <code>{showDetailOtherAsset.otherAsset.owner}</code>
                          </p>
                        )}
                        {showDetailOtherAsset.otherAsset.assetName && (
                          <p style={{ color: "black", fontWeight: "500" }}>
                            Asset Name :{" "}
                            <span>
                              {showDetailOtherAsset.otherAsset.assetName && (
                                <span>
                                  {showDetailOtherAsset.otherAsset.assetName}
                                </span>
                              )}
                            </span>
                          </p>
                        )}
                        <p>
                          Asset Value:{" "}
                          <code style={{ color: "green", fontWeight: "bold" }}>
                            {formatAmount(
                              showDetailOtherAsset.otherAsset.assetValue
                            )}
                          </code>
                        </p>
                      </div>

                      <div className="col2">
                        {showDetailOtherAsset.otherAsset.assetCaption && (
                          <p style={{ color: "black", fontWeight: "500" }}>
                            Asset Caption :{" "}
                            <span>
                              {showDetailOtherAsset.otherAsset.assetCaption && (
                                <span>
                                  {showDetailOtherAsset.otherAsset.assetCaption}
                                </span>
                              )}
                            </span>
                          </p>
                        )}

                        {showDetailOtherAsset.sharedDetails[0] && (
                          <p
                            onClick={() => {
                              handleOpenBeneficiary(
                                showDetailOtherAsset,
                                "otherAsset"
                              );
                              setShowOtherAsset(!showOtherAsset);
                            }}
                          >
                            Beneficiary Details{" "}
                            <code>
                              <span className="readmore">Click Here</span>
                            </code>
                          </p>
                        )}

                        {showDetailOtherAsset.otherAsset.notes && (
                          <Tooltip title="Click To see Note">
                            <p
                              onClick={() => {
                                handleOpenPopup(
                                  showDetailOtherAsset.otherAsset.notes,
                                  "otherAsset"
                                );
                                setShowOtherAsset(!showOtherAsset);
                              }}
                            >
                              Note:{" "}
                              <code>
                                {" "}
                                {showDetailOtherAsset &&
                                showDetailOtherAsset.otherAsset.notes
                                  ? showDetailOtherAsset.otherAsset.notes.slice(
                                      0,
                                      5
                                    )
                                  : ""}
                                ...<span className="readmore">Read More</span>
                              </code>
                            </p>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </>
          )}

          {/* for insurance popup  */}
          {showInsurance && Object.keys(showDetailInsurance).length > 0 && (
            <>
              <div className="card__data">
                <div className="card__data-container">
                  <section className="section1">
                    <div>
                      <p className="row1-text">
                        <FontAwesomeIcon
                          icon={faShieldHalved}
                          style={{ color: "#025596", fontSize: "18px" }}
                        />
                        <span>{showDetailInsurance.insurance.details}</span>
                      </p>
                      <div className="row1-button">
                        <div>
                          {showDetailInsurance.documents &&
                            showDetailInsurance.documents.length > 0 && (
                              <Tooltip title="click to see multiple downlaod files">
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleShowDownlaod(
                                      showDetailInsurance,
                                      "insurance"
                                    );
                                    setShowInsurance(!showInsurance);
                                  }}
                                >
                                  <div className="myestate_download_button dwnbtn">
                                    <FontAwesomeIcon
                                      className="myestate_download_icon"
                                      icon={faDownload}
                                    />
                                    <span></span>
                                  </div>
                                </div>
                              </Tooltip>
                            )}
                        </div>

                        <div>
                          <span
                            className="card__data-close"
                            onClick={() => {
                              setShowInsurance(!showInsurance);
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="section2">
                    <div>
                      <div className="col1">
                        {showDetailInsurance.insurance.owner && (
                          <p>
                            Owner :{" "}
                            <code>{showDetailInsurance.insurance.owner}</code>
                          </p>
                        )}

                        {showDetailInsurance.insurance.insuranceCaption && (
                          <p>
                            Insurance Caption:{" "}
                            <code>
                              {showDetailInsurance.insurance.insuranceCaption}
                            </code>
                          </p>
                        )}

                        <p>
                          Insurance Provider:{" "}
                          <code>
                            {showDetailInsurance.insurance.insuranceProvider}
                          </code>
                        </p>
                        {showDetailInsurance.insurance.details && (
                          <p>
                            Details:{" "}
                            <code>{showDetailInsurance.insurance.details}</code>
                          </p>
                        )}

                        {showDetailInsurance.insurance.notes && (
                          <Tooltip title="Click To see Note">
                            <p
                              onClick={() => {
                                handleOpenPopup(
                                  showDetailInsurance.insurance.notes,
                                  "insurance"
                                );
                                setShowInsurance(!showInsurance);
                              }}
                            >
                              Note/Point of contact:{" "}
                              <code>
                                {" "}
                                {showDetailInsurance &&
                                showDetailInsurance.insurance.notes
                                  ? showDetailInsurance.insurance.notes.slice(
                                      0,
                                      5
                                    )
                                  : ""}
                                ...<span className="readmore">Read More</span>
                              </code>
                            </p>
                          </Tooltip>
                        )}
                      </div>
                      <div className="col2">
                        <p>
                          Insurance Amount:{" "}
                          <code style={{ color: "green", fontWeight: "bold" }}>
                            {formatAmount(
                              showDetailInsurance.deathBenefitsModel
                                .deathBenefits.insuranceAmount
                            )}
                          </code>
                        </p>

                        <p
                          onClick={() => {
                            handleOpenBeneficiary(
                              showDetailInsurance.deathBenefitsModel,
                              "insurance"
                            );
                            setShowInsurance(!showInsurance);
                          }}
                        >
                          Death Benefits Beneficiary:{" "}
                          <code>
                            {showDetailInsurance.deathBenefitsModel
                              .sharedDetails[0] ? (
                              <span className="readmore">Click Here</span>
                            ) : (
                              <span
                                style={{ color: "#e74c3c", fontWeight: "bold" }}
                              >
                                No Beneficiary
                              </span>
                            )}
                          </code>
                        </p>

                        {showDetailInsurance.insurance.insuranceAmount && (
                          <p>
                            Insurance Cash Amount:{" "}
                            <code
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              {formatAmount(
                                showDetailInsurance.insurance.insuranceAmount
                              )}
                            </code>
                          </p>
                        )}
                        {showDetailInsurance.sharedDetails[0] && (
                          <p
                            onClick={() => {
                              handleOpenBeneficiary(
                                showDetailInsurance,
                                "insurance"
                              );
                              setShowInsurance(!showInsurance);
                            }}
                          >
                            Beneficiary Details{" "}
                            <code>
                              <span className="readmore">Click Here</span>
                            </code>
                          </p>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </>
          )}

          {/*  download popup */}
          {popupVisibleDownlaod && (
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h2>Download Files</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          if (assetTypeValue == "realEstate") {
                            setPopupVisibleDownlaod(false);
                            setShowRealEstate(!showRealEstate);
                          } else if (assetTypeValue == "otherAsset") {
                            setPopupVisibleDownlaod(false);
                            setShowOtherAsset(!showOtherAsset);
                          } else if (assetTypeValue == "bank") {
                            setPopupVisibleDownlaod(false);
                            setShowBank(!showBank);
                          } else if (assetTypeValue == "investment") {
                            setPopupVisibleDownlaod(false);
                            setShowInvestment(!showInvestment);
                          } else if (assetTypeValue == "income") {
                            setPopupVisibleDownlaod(false);
                            setShowIncome(!showIncome);
                          } else if (assetTypeValue == "vehicle") {
                            setPopupVisibleDownlaod(false);
                            setShowVehicle(!showVehicle);
                          } else if (assetTypeValue == "jewelry") {
                            setPopupVisibleDownlaod(false);
                            setShowJewelry(!showJewelry);
                          } else if (assetTypeValue == "crypto") {
                            setPopupVisibleDownlaod(false);
                            setShowCrypto(!showCrypto);
                          } else if (assetTypeValue == "insurance") {
                            setPopupVisibleDownlaod(false);
                            setShowInsurance(!showInsurance);
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>

                  <div>
                    {selectedDownlaod.documents &&
                      selectedDownlaod.documents.length > 0 &&
                      selectedDownlaod.documents.map((file, index) => (
                        <div key={index} style={{ marginBottom: "20px" }}>
                          <Tooltip title={file.fileName}>
                            <div
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                gap: "20px",
                              }}
                              onClick={() => {
                                handleDownload(file.id, file.fileName);
                              }}
                            >
                              Download - {index + 1}
                              <div className="myestate_download_button dwnbtn">
                                <FontAwesomeIcon
                                  className="myestate_download_icon"
                                  icon={faDownload}
                                />
                                <span>{file.fileName}</span>
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* beneficiary popup  */}
          {beneficiaryVisible && (
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h2>share property</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          if (assetTypeValue == "realEstate") {
                            setBeneficiaryVisible(false);
                            setShowRealEstate(!showRealEstate);
                          } else if (assetTypeValue == "otherAsset") {
                            setBeneficiaryVisible(false);
                            setShowOtherAsset(!showOtherAsset);
                          } else if (assetTypeValue == "bank") {
                            setBeneficiaryVisible(false);
                            setShowBank(!showBank);
                          } else if (assetTypeValue == "investment") {
                            setBeneficiaryVisible(false);
                            setShowInvestment(!showInvestment);
                          } else if (assetTypeValue == "income") {
                            setBeneficiaryVisible(false);
                            setShowIncome(!showIncome);
                          } else if (assetTypeValue == "vehicle") {
                            setBeneficiaryVisible(false);
                            setShowVehicle(!showVehicle);
                          } else if (assetTypeValue == "jewelry") {
                            setBeneficiaryVisible(false);
                            setShowJewelry(!showJewelry);
                          } else if (assetTypeValue == "crypto") {
                            setBeneficiaryVisible(false);
                            setShowCrypto(!showCrypto);
                          } else if (assetTypeValue == "insurance") {
                            setBeneficiaryVisible(false);
                            setShowInsurance(!showInsurance);
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div>
                    {selectedBeneficiary.sharedDetails &&
                      selectedBeneficiary.sharedDetails.map(
                        (details, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              margin: "5px",
                              padding: "10px",
                              border: "solid 1px lightgray",
                              borderRadius: "5px",
                              minWidth: "230px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "17px",
                                  color: "black",
                                  fontWeight: "500",
                                }}
                              >
                                Share - {index + 1}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "20px",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "black",
                                  fontWeight: "400",
                                  marginLeft: "20px",
                                }}
                              >
                                Beneficiary Name:
                                <span style={{ marginLeft: "10px" }}>
                                  {getBenificiaryName(details.beneficiaryId)}
                                </span>
                              </p>
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "black",
                                  fontWeight: "400",
                                  marginLeft: "20px",
                                }}
                              >
                                Distributed Type:
                                <span style={{ marginLeft: "10px" }}>
                                  {details.distributedType}
                                </span>
                              </p>
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "black",
                                  fontWeight: "400",
                                  marginLeft: "20px",
                                }}
                              >
                                Distributed Value:{" "}
                                <span style={{ marginLeft: "10px" }}>
                                  {details &&
                                    details.distributedType === "dollar" &&
                                    "$"}
                                  {details.distributedValue}
                                  {details.distributedType === "percentage" &&
                                    "%"}
                                </span>
                              </p>
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "black",
                                  fontWeight: "400",
                                  marginLeft: "20px",
                                }}
                              >
                                Distributed Amount:
                                <span style={{ marginLeft: "10px" }}>
                                  ${details.distributedAmount}
                                </span>
                              </p>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* notes popup  */}
          {popupVisible && (
            // Popup div
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div>
                      <h2>Notes</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          if (assetTypeValue == "realEstate") {
                            setPopupVisible(false);
                            setShowRealEstate(!showRealEstate);
                          } else if (assetTypeValue == "otherAsset") {
                            setPopupVisible(false);
                            setShowOtherAsset(!showOtherAsset);
                          } else if (assetTypeValue == "bank") {
                            setPopupVisible(false);
                            setShowBank(!showBank);
                          } else if (assetTypeValue == "investment") {
                            setPopupVisible(false);
                            setShowInvestment(!showInvestment);
                          } else if (assetTypeValue == "income") {
                            setPopupVisible(false);
                            setShowIncome(!showIncome);
                          } else if (assetTypeValue == "vehicle") {
                            setPopupVisible(false);
                            setShowVehicle(!showVehicle);
                          } else if (assetTypeValue == "jewelry") {
                            setPopupVisible(false);
                            setShowJewelry(!showJewelry);
                          } else if (assetTypeValue == "crypto") {
                            setPopupVisible(false);
                            setShowCrypto(!showCrypto);
                          } else if (assetTypeValue == "insurance") {
                            setPopupVisible(false);
                            setShowInsurance(!showInsurance);
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div>
                    <p>{selectedNote}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* mortgages popup  */}
          {showAdditionField1 && (
            // Display the popup when a row is selected
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup_heading">
                  <div style={{ width: "100%" }}>
                    <h2 style={{ textAlign: "center" }}>Mortgages Details</h2>
                  </div>
                  <div>
                    <button
                      className="note_popup_heading_close_btn"
                      onClick={() => {
                        setshowAdditionField1(false);
                        setShowRealEstate(!showRealEstate);
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  </div>
                </div>
                {/* <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", }} > */}
                {showDetailRealEstate &&
                  showDetailRealEstate.mortgages.map((mortgageList, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "5px",
                        padding: "10px",
                        border: "solid 1px lightgray",
                        borderRadius: "5px",
                        minWidth: "230px",
                      }}
                    >
                      <div>
                        {index === 0 ? (
                          <p
                            style={{
                              fontSize: "17px",
                              color: "black",
                              fontWeight: "500",
                            }}
                          >
                            Primary Mortgage
                          </p>
                        ) : (
                          <p
                            style={{
                              fontSize: "17px",
                              color: "black",
                              fontWeight: "500",
                            }}
                          >
                            Secondary Mortgage
                          </p>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "20px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "15px",
                            color: "black",
                            fontWeight: "400",
                            marginLeft: "20px",
                          }}
                        >
                          Loan:
                          <span
                            style={{
                              marginLeft: "10px",
                              fontWeight: "500",
                            }}
                          >
                            {formatAmount(mortgageList.mortgage)}
                          </span>
                        </p>
                        {index === 0 ? (
                          <p
                            style={{
                              fontSize: "15px",
                              color: "black",
                              fontWeight: "400",
                              marginLeft: "20px",
                            }}
                          >
                            Primary Mortgage:
                            <span
                              style={{
                                marginLeft: "10px",
                                fontWeight: "500",
                              }}
                            >
                              {mortgageList.mortgageNumber}
                            </span>
                          </p>
                        ) : (
                          <p
                            style={{
                              fontSize: "15px",
                              color: "black",
                              fontWeight: "400",
                              marginLeft: "20px",
                            }}
                          >
                            Secondary Mortgage:
                            <span
                              style={{
                                marginLeft: "10px",
                                fontWeight: "500",
                              }}
                            >
                              {mortgageList.mortgageNumber}
                            </span>
                          </p>
                        )}

                        {/* <p
                              style={{
                                fontSize: "15px",
                                color: "black",
                                fontWeight: "400",
                                marginLeft: "20px",
                              }}
                            >
                              Mortgage Lenders:
                              <span
                                style={{
                                  marginLeft: "10px",
                                  fontWeight: "500",
                                }}
                              >
                                {mortgageList.mortgageInstitution}
                              </span>
                            </p> */}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}

          {/* accounts popup  */}
          {showAdditionField && (
            // Display the popup when a row is selected
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup_heading">
                  <div style={{ width: "100%" }}>
                    <h2 style={{ textAlign: "center" }}>Accounts Details</h2>
                  </div>
                  <div>
                    <button
                      className="note_popup_heading_close_btn"
                      onClick={() => {
                        SetshowAdditionField(false);
                        setShowBank(!showBank);
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  </div>
                </div>
                {/* <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", }} > */}
                {showDetailBank.accounts &&
                  showDetailBank.accounts.map((account, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "5px",
                        padding: "10px",
                        border: "solid 1px lightgray",
                        borderRadius: "5px",
                        minWidth: "230px",
                      }}
                    >
                      <div>
                        <p
                          style={{
                            fontSize: "17px",
                            color: "black",
                            fontWeight: "500",
                          }}
                        >
                          Account - {index + 1}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "20px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "15px",
                            color: "black",
                            fontWeight: "400",
                            marginLeft: "20px",
                          }}
                        >
                          Account Number:
                          <span
                            style={{ marginLeft: "10px", fontWeight: "500" }}
                          >
                            {renderAccountNumber(account.accountNumber, index)}
                          </span>
                          <button
                            onClick={() => toggleVisibility(index)}
                            style={{
                              marginLeft: "10px",
                              border: "none",
                              background: "none",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={
                                visibleAccountIndices.includes(index)
                                  ? faEye
                                  : faEyeSlash
                              }
                            />
                          </button>
                        </p>
                        <p
                          style={{
                            fontSize: "15px",
                            color: "black",
                            fontWeight: "400",
                            marginLeft: "20px",
                          }}
                        >
                          Account Type:{" "}
                          <span
                            style={{ marginLeft: "10px", fontWeight: "500" }}
                          >
                            {account.accountType}
                          </span>
                        </p>
                        <p
                          style={{
                            fontSize: "15px",
                            color: "black",
                            fontWeight: "400",
                            marginLeft: "20px",
                          }}
                        >
                          Account Balance:
                          <span
                            style={{ marginLeft: "10px", fontWeight: "500" }}
                          >
                            {formatAmount(account.balance)}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}
                {/* </div> */}
              </div>
            </div>
          )}

          {/* other asset fields  */}
          {showAssets && (
            // Display the popup when a row is selected
            <div className="popup">
              <div className="popup-content">
                <div className="note_popup_heading">
                  <div>
                    <h2>All Additional Assets</h2>
                  </div>
                  <div>
                    <button
                      className="note_popup_heading_close_btn"
                      onClick={() => {
                        SetshowAssets(false);
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  </div>
                </div>
                <div className="popup-body">
                  {showAssets.otherAsset.otherAssets1 !== 0 &&
                  showAssets.otherAsset.otherAssets1 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      Other Field 1 : {showAssets.otherAsset.otherAssets1}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {showAssets.otherAsset.otherAssets2 !== 0 &&
                  showAssets.otherAsset.otherAssets2 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      Other Field 2 : {showAssets.otherAsset.otherAssets2}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {showAssets.otherAsset.otherAssets3 !== 0 &&
                  showAssets.otherAsset.otherAssets3 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      Other Field 3 : {showAssets.otherAsset.otherAssets3}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {showAssets.otherAsset.otherAssets4 !== 0 &&
                  showAssets.otherAsset.otherAssets4 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      Other Field 4 : {showAssets.otherAsset.otherAssets4}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {showAssets.otherAsset.otherAssets5 !== 0 &&
                  showAssets.otherAsset.otherAssets5 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      Other Field 5 : {showAssets.otherAsset.otherAssets5}
                    </p>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* </SideBar> */}
        </TrusteeBase>
      )}
    </>
  );
}
