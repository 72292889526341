import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Input } from "reactstrap";
import "../../css/why-I-chest.css";
function FooterPage() {
  return (
    <>
      <div className="footer_section" id="Contact Us">
        <div className="footer_section_base">
          <div className="footer_section_main">
            <div className="footer_section_column footer_section_contact">
              <h4 className="about_text">Contact Us</h4>
              <div className="phone_email phone_no_text">
                <FontAwesomeIcon icon={faPhone} />
                <span className="padding_left_15">+1(224)300-6873</span>
              </div>
              <div className="phone_email location_text">
                <FontAwesomeIcon icon={faEnvelope} />
                <span className="padding_left_15">contact@i-chest.com</span>
                {/* </a> */}
              </div>
              {/* <div className="footer_icon">
                                <div className="footer_icon_main">
                                    <a
                                        className="footer_all_icon linkedIn_icon"
                                        href="https://www.linkedin.com/"
                                        target="blank"
                                    >
                                        <FontAwesomeIcon icon={faLinkedin} />
                                    </a>
                                </div>
                                <div className="footer_icon_main">
                                    <a
                                        className="footer_all_icon youtube_icon"
                                        href="https://www.linkedin.com/"
                                        target="blank"
                                    >
                                        <FontAwesomeIcon icon={faYoutube} />
                                    </a>
                                </div>
                                <div className="footer_icon_main">
                                    <a
                                        className="footer_all_icon facebook_icon"
                                        href="https://www.linkedin.com/"
                                        target="blank"
                                    >
                                        <FontAwesomeIcon icon={faFacebook} />
                                    </a>
                                </div>
                                <div className="footer_icon_main">
                                    <a
                                        className="footer_all_icon twitter_icon"
                                        href="https://www.linkedin.com/"
                                        target="blank"
                                    >
                                       <FontAwesomeIcon icon={faXTwitter} />
                                    </a>
                                </div>
                            </div> */}
            </div>
            <div className="footer_section_column footer_section_address">
              <h4 className="about_text">Address</h4>
              <p className="dolor_text">
                1468 Circle Ct, Elk Grove Village, IL. Zip 60007, United States
              </p>
            </div>
            <div className="footer_section_column footer_section_links">
              <h4 className="about_text">Quick Links</h4>
              <div className="footer_links">
                <div>
                  <Link className="footer_all_links" to="/">
                    Home
                  </Link>
                </div>
                <div>
                  <Link className="footer_all_links" to="/features">
                    Features
                  </Link>
                </div>
                <div>
                  <Link className="footer_all_links" to="/whyI-chest">
                    Why I-chest
                  </Link>
                </div>
                <div>
                  <Link className="footer_all_links" to="/contact-us">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
            <div className="footer_section_column footer_section_newsletter">
              <h4 className="about_text">Newsletter</h4>
              <div className="newsletter">
                <div>
                  <Input
                    type="text"
                    className="mail_text"
                    placeholder="Enter your email"
                    name="Enter your email"
                  />
                </div>

                <div className="subscribe">
                  <a className="subscribe_bt" href="#">
                    Subscribe
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright">
          <div class="container">
            <div class="row">
              <div className="copyright_section">
                <div style={{ textAlign: "center", color: "white" }}>
                  <Typography
                    variant="body1"
                    component="span"
                    className="copyright_text"
                  >
                    Copyright &copy; 2023 All Right Reserved By&nbsp;
                    <a href="http://i-chest.com">
                      I<span style={{ color: "#1dff00" }}>&#8209;</span>
                      chest.com
                    </a>
                  </Typography>
                </div>
                <div className="copyright_text"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default FooterPage;
