import { myAxios } from "./helper";
import { getToken } from "./user-service";

export const addMortgageLoan = (mortgageLoan, token) => {
    return myAxios.post("/mortgage-loan/", mortgageLoan, { headers: { authorization: token } }).then((response) => response.data);
};
export const getMortgageLoans = (token, userId) => {
    return myAxios.get('/mortgage-loan/' + userId, { headers: { authorization: token } }).then((response) => response.data);
};
export const updateMortgageLoan = (mortgageLoan, token,) => {
    return myAxios.put("/mortgage-loan/", mortgageLoan, { headers: { authorization: token } }).then((response) => response.data);
};
export const getSingleMortgageLoan = (token, id) => {
    return myAxios.get('/mortgage-loan/mortgage-loan/' + id, { headers: { authorization: token } }).then((response) => response.data);
};

export const deleteMortgageLoan = (mortgageLoanId) => {
    let token = "Bearer " + getToken();
    return myAxios.delete(`/mortgage-loan/mortgage-loan/${mortgageLoanId}`, { headers: { authorization: token } }).then((response) => response.data);
}

export const getExistingMortgageLoans = (token, userId) => {
    return myAxios.get('/common/existing-mortgage-loan/' + userId, { headers: { authorization: token } }).then((response) => response.data);
};