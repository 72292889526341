import axios from "axios";

// export const BASE_CLIENT_URL = 'http://18.224.186.88';
// export const BASE_URL = 'http://18.224.186.88:8080';

export const BASE_CLIENT_URL = 'https://i-chest.com';
export const BASE_URL = 'https://api.i-chest.com';

export const myAxios = axios.create({
    baseURL: BASE_URL,
}); 