import "@fortawesome/fontawesome-svg-core/styles.css";

import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import "../../components/styles.css";
import "../../components/swiper.css";
import "../../components/user/whyichest.css";

import { Container, Nav, Navbar } from "react-bootstrap";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import {
  faChartLine,
  faClipboardCheck,
  faCloudUploadAlt,
  faNetworkWired,
  faPencilAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import FooterPage from "./footer";

const Services = () => {
  const line1 = "Life is about legacy,".split(" ");
  const line2 = "I-Chest.com helps preserve it !!!".split(" ");
  const first = "Life is about legacy, I-Chest.com helps preserve it !!!".split(
    " "
  );
  const [key, setKey] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setKey((prevKey) => prevKey + 1);
    }, 3000);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <div className="homepage_design_main">
        <div className="homepage_design_main">
          <Navbar
            expand="lg"
            fixed="top"
            bg="dark"
            variant="dark"
            aria-label="Main navigation"
            className="navbar_base"
          >
            <Container>
              <Navbar.Brand href="#" className="navbar-brand logo-image">
                <img src="./logo192.webp" alt="alternative" />
                <div className="App_this">
                  <div className="tab_largescreen_view">
                    {first.map((el, i) => (
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                          duration: 1,
                          delay: i / 10,
                        }}
                        key={i + key * first.length}
                      >
                        {el}{" "}
                      </motion.span>
                    ))}
                  </div>
                  <div className="tab_movile_view">
                    {line1.map((el, i) => (
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                          duration: 1,
                          delay: i / 10,
                        }}
                        key={i + key * line1.length}
                      >
                        {el}{" "}
                      </motion.span>
                    ))}
                  </div>
                  <div className="tab_movile_view">
                    {line2.map((el, i) => (
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                          duration: 0.25,
                          delay: i / 10,
                        }}
                        key={i + key * line2.length}
                      >
                        {el}{" "}
                      </motion.span>
                    ))}
                  </div>
                </div>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbar-nav" />
              <Navbar.Collapse id="navbar-nav">
                <Nav className="ms-auto">
                  <Nav.Link href="/">Home</Nav.Link>
                  {/* <Nav.Link href="#services">Services</Nav.Link> */}
                  <Nav.Link href="/features">Features</Nav.Link>
                  {/* <Nav.Link href="/">Partners</Nav.Link> */}
                  <Link className="partner" to="/home#aboutus">
                    Partners
                  </Link>
                  <Nav.Link href="/WhyI-chest">Why I-chest</Nav.Link>
                  <Nav.Link href="/contact-us">Contact Us</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>

        <div className={`your-component fade-in-element`}>
          <div className="whyichest_base">
            <div className="whyichest_main">
              <div className="whyichest_heading">
                <h1 style={{ marginTop: "1rem" }}>Explore our Services</h1>
              </div>
              <div className="whyichest_paragraph">
                <p>
                  I-Chest.com is a repository with a unique objective: to
                  resolve the challenges of asset management. The platform will
                  store comprehensive information on earned, unearned, or
                  inherited assets, accompanied by all relevant supporting
                  documents. It is designed to ensure that, in the case of an
                  emergency or unforeseen event, there is a clear plan for asset
                  distribution and management. Additionally, I-Chest.com aims to
                  extend its scope to include information about assets held
                  internationally, providing a complete picture of an
                  individual's or entity's wealth and property.
                </p>
              </div>
              <br />

              <div className="whyichest_second_para">
                <div className="whyichest_image">
                  <LazyLoadImage
                    className="whyichest_image_img"
                    src="/img/services.webp"
                    alt="secure_data_image "
                    effect="blur"
                  />
                </div>
                <div className="whyichest_content services_list">
                  <p>
                    <li>
                      Dynamic Assets, Liabilities, and Personal Information
                      Storage Platform.
                    </li>
                  </p>
                  <br />
                  <p>
                    <li>
                      Decentralize the information by sharing it with your
                      confidants and professionals.
                    </li>
                  </p>
                  <br />
                  <p>
                    <li>Plan of action in case of a mishap.</li>
                  </p>
                  <br />
                  <p>
                    <li>
                      Writing center for diaries, autobiographies, books etc.
                    </li>
                  </p>
                  <br />
                  <p>
                    <li>Explore your networth.</li>
                  </p>
                  <br />
                </div>
              </div>
              <br />
              <div className="whyichest_last_para">
                <p>
                  I-Chest.com is a unique repository platform designed to
                  address specific needs in asset inventory management. It
                  securely stores comprehensive information on earned, unearned,
                  and inherited assets, along with all relevant supporting
                  documents. The platform is built to ensure that, in the event
                  of an emergency or unforeseen circumstances, there is a clear
                  and actionable plan for asset distribution and management.
                  Additionally, I-Chest.com seeks to broaden its scope by
                  including information on internationally held assets,
                  offering a complete overview of an individual's or entity's
                  wealth and property.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-1 accordion_services">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <h1 class="h2-heading">Our Key Services</h1>
                <p class="p-heading"></p>
              </div>
            </div>

            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <div class="accordion-icon">
                  <span class="fas fa-tv">
                    {" "}
                    <FontAwesomeIcon icon={faCloudUploadAlt} />
                  </span>
                </div>
                <div class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Dynamic Storage
                  </button>
                </div>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Securely store and manage assets, liabilities and personal
                    information.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <div class="accordion-icon blue">
                  <span class="fas fa-microphone">
                    {" "}
                    <FontAwesomeIcon icon={faNetworkWired} />
                  </span>
                </div>
                <div class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Decentralized Sharing
                  </button>
                </div>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Share information with trusted confidantes and
                    professionals.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-icon purple">
                  <span class="fas fa-video">
                    <FontAwesomeIcon icon={faClipboardCheck} />
                  </span>
                </div>
                <div class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Emergency Planning
                  </button>
                </div>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Ensure a plan is in place for asset management in case of a
                    mishap.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-icon orange">
                  <span class="fas fa-tools">
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </span>
                </div>
                <div class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Personal Writing Space
                  </button>
                </div>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    A dedicated area for diaries, autobiographies, books and
                    more.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div
                  style={{ backgroundColor: "#868d20" }}
                  class="accordion-icon"
                >
                  <span class="fas fa-tools">
                    <FontAwesomeIcon icon={faChartLine} />
                  </span>
                </div>
                <div class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefive"
                    aria-expanded="false"
                    aria-controls="collapsefive"
                  >
                    Net Worth Exploration
                  </button>
                </div>
                <div
                  id="collapsefive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Gain insights into your total net worth.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterPage />
      </div>
    </>
  );
};
export default Services;
