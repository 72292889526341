import { faArrowRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHelperText, FormLabel, Input } from "@mui/joy";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { checkEmail, checkUsername, sendOtp } from "../services/user-service";
import { Emailtemplate, EmailTemplatePostfix } from "./Emailtemplate";
import Otppage from "./Otppage";
import "./SignupPage.css";
import { changeUsFormatNumber } from "../components/Common";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
const steps = [
  {
    label: "Personal Information",
    description: "Add your personal information",
  },
  {
    label: "Address Information",
    description: "Add your address information",
  },
  {
    label: "Account Information",
    description: "Add your account information",
  },
  // {
  //   label: 'Do you want to invite a secondary user?',
  //   description: 'Add your secondary user information',
  // },
  // {
  //   label: 'Add Owners',
  //   description: 'Add your owners information',
  // },
  // {
  //   label: 'Add Beneficiaries',
  //   description: 'Add your beneficiaries',
  // },
  // {
  //   label: 'Do you have an existing Trust',
  //   description: 'Add your trust',
  // },
  // {
  //   label: 'Do you have an attorney/ executors/ trustees/ Grantors/ Advisor?',
  //   description: 'Add your trustee information',
  // },
];

const Signup = ({ onBack }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [formValues, setFormValues] = React.useState({
    personalInformation: {
      firstName: "",
      lastName: "",
      email: "",
      dob: "",
      gender: "",
      phoneNo: "",
    },
    addressInformation: {
      street: "",
      apartment: "",
      city: "",
      zip: "",
      country: "",
      state: "",
    },
    accountInformation: {
      username: "",
      password: "",
      confirmPassword: "",
      role: "USER",
      jointAccount: false,
      accountType: "normal",
    },
    // secondaryUser: { invite: '', firstName: '', lastName: '', email: '', phoneNo: '', access: '' },
    // owners: { selected: '', firstName: '', lastName: '', gender: '', dob: '', countryOfBirth: '', relationship: '', otherRelationship: '' },
    // beneficiaries: { firstName: '', lastName: '', gender: '', dob: '', countryOfBirth: '', relationship: '', otherRelationship: '' },
    // trust: { trustedselected: '', nameoftrust: '', trusttype: '', createdate: '', idnumber: '' },
    // trustee: { trustSelected: '', firstName: '', lastName: '', email: '', phoneNo: '' },
  });
  const [errors, setErrors] = React.useState({
    personalInformation: {
      firstName: false,
      email: false,
      emailValid: true,
      firstNameValidate: true,
      lastNameValidate: true,
      dob: false,
      gender: false,
      phoneNo: false,
      phoneNumberValid: true,
    },
    addressInformation: {
      zip: false,
      zipValidate: true,
      validZip: true,
    },
    accountInformation: {
      username: false,
      password: false,
      confirmPassword: false,
      passwordMatch: true,
      passwordValid: true,
      usernameValid: true,
    },
    // secondaryUser: { invite: false, firstName: false, email: false, phoneNo: false, access: false },
    // owners: { selected: false, firstName: false, gender: false, relationship: false },
    // beneficiaries: { firstName: false, gender: false, relationship: false },
    // trust: { trustedselected: false, nameoftrust: false },
    // trustee: { trustSelected: false, firstName: false, email: false, phoneNo: false }
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const [showTermCondtitions, setShowTermCondtitions] = React.useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const validateAlphanumeric = (value) => {
    return !/^\d+$/.test(value);
  };

  const validateZipCode = (zipCode) => {
    return /^\d{5}$/.test(zipCode);
  };

  const validateEmail = (email) => {
    const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    return re.test(email);
  };

  const validatePhoneNumber = (phoneNo) => {
    const re = /^(\+1\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/;
    return re.test(phoneNo);
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;
    return re.test(password);
  };

  const validateUsername = (username) => {
    const re = /^[a-z][a-z0-9_]*$/;
    return re.test(username);
  };

  const checkEmailExists = (email) => {
    return new Promise((resolve, reject) => {
      if (email !== "" && validateEmail(email)) {
        checkEmail(email)
          .then((res) => {
            if (res === true) {
              resolve(res); // Resolve the promise with true
            } else {
              resolve(res); // Resolve the promise with false
            }
          })
          .catch((err) => {
            console.error(err);
            reject(err); // Reject the promise with the error
          });
      } else {
        resolve(false); // Resolve the promise with false if email is invalid
      }
    });
  };

  const checkUsernameExists = (username) => {
    return new Promise((resolve, reject) => {
      if (username !== "") {
        checkUsername(username)
          .then((res) => {
            if (res === true) {
              resolve(res); // Resolve the promise with true
            } else {
              resolve(res); // Resolve the promise with false
            }
          })
          .catch((err) => {
            console.error(err);
            reject(err); // Reject the promise with the error
          });
      } else {
        resolve(false); // Resolve the promise with false if email is invalid
      }
    });
  };
  const [validateExistsEmail, setValidateExistsEmail] = useState(false);
  const [validateExistsUsername, setValidateExistsUsername] = useState(false);
  const handleNext = async () => {
    if (activeStep === 0) {
      const { firstName, lastName, email, dob, gender, phoneNo } =
        formValues.personalInformation;
      const emailValid = validateEmail(email);
      const phoneNumberValid = validatePhoneNumber(phoneNo);
      const firstNameValidate = validateAlphanumeric(firstName);

      const lastNameValidate =
        lastName === "" ? true : validateAlphanumeric(lastName);

      try {
        const isEmailExist = await checkEmailExists(email);
        if (isEmailExist) {
          setValidateExistsEmail(isEmailExist);
          return;
        }
      } catch (err) {
        console.error("Error: ", err);
      }

      setErrors({
        personalInformation: {
          firstName: !firstName,
          email: !email,
          emailValid,
          firstNameValidate,
          lastNameValidate,
          dob: !dob,
          gender: !gender,
          phoneNo: !phoneNo,
          phoneNumberValid,
        },
        addressInformation: errors.addressInformation,
        accountInformation: errors.accountInformation,
        // secondaryUser: errors.secondaryUser,
        // owners: errors.owners,
        // beneficiaries: errors.beneficiaries,
        // trust: errors.trust,
      });
      if (
        !firstName ||
        !email ||
        !emailValid ||
        !firstNameValidate ||
        !lastNameValidate ||
        !dob ||
        !gender ||
        !phoneNo ||
        !phoneNumberValid
      ) {
        return;
      }
    }

    if (activeStep === 1) {
      const { street, city, zip, country, state } =
        formValues.addressInformation;

      // const cityValidate = city === "" ? true : validateAlphanumeric(city);
      // const stateValidate = validateAlphanumeric(state);
      // const countryValidate = validateAlphanumeric(country);
      const zipValidate = validateZipCode(zip);
      const validZip = errors.addressInformation.validZip;
      setErrors({
        personalInformation: errors.personalInformation,
        accountInformation: errors.accountInformation,
        addressInformation: {
          // street: !street,
          // city: !city,
          zip: !zip,
          zipValidate,
          validZip,
          // cityValidate,
          // stateValidate,
          // countryValidate,
          // country: !country,
          // state: !state,
        },
        // secondaryUser: errors.secondaryUser,
        // owners: errors.owners,
        // beneficiaries: errors.beneficiaries,
        // trust: errors.trust,
        // trustee: errors.trustee,
      });
      // if (!street || !city || !zip || !country || !state) {
      //   return;
      // }
      if (
        // !country ||
        // !state ||
        !zip ||
        !zipValidate ||
        !validZip
        // !cityValidate ||
        // !stateValidate ||
        // !countryValidate
      ) {
        return;
      }
    }

    if (activeStep === 2) {
      const { username, password, confirmPassword } =
        formValues.accountInformation;
      const passwordMatch = password === confirmPassword;
      const passwordValid = validatePassword(password);
      const usernameValid = validateUsername(username);
      setErrors({
        personalInformation: errors.personalInformation,
        accountInformation: {
          username: !username,
          password: !password,
          confirmPassword: !confirmPassword,
          passwordMatch,
          passwordValid,
          usernameValid,
        },
        addressInformation: errors.addressInformation,
      });
      if (
        !username ||
        !password ||
        !confirmPassword ||
        !passwordMatch ||
        !passwordValid ||
        !usernameValid
      ) {
        return;
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const updateCombinedInformation = (formValues) => ({
    ...formValues.personalInformation,
    ...formValues.addressInformation,
    ...formValues.accountInformation,
  });

  const [validmail, setValidmail] = useState(false);
  const [userAllInformation, setUserAllInformation] = useState({});

  // submit the form
  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    const userInformation = updateCombinedInformation(formValues);
    setUserAllInformation(userInformation);
    event.preventDefault();
    if (
      validateUsername(userInformation.username) &&
      errors.accountInformation.usernameValid &&
      errors.accountInformation.passwordMatch &&
      showTermCondtitions
    ) {
      try {
        const isUsernameExist = await checkUsernameExists(
          userInformation.username
        );
        if (isUsernameExist) {
          setValidateExistsUsername(isUsernameExist);
          return;
        }
      } catch (err) {
        console.error("Error: ", err);
      }

      // send otp to email
      sendOtp({
        to: userInformation.email,
        subject: "Test Api",
        message: Emailtemplate,
        postfixMessage: EmailTemplatePostfix,
      })
        .then((res) => {
          setValidmail(true);
          toast.success("Otp has been sent in your email");
        })
        .catch((err) => {
          toast.error("Mail is not correct");

          return;
        });
    } else {
      return;
    }

    // const updatedFormValues = {
    //   userDetails: combinedInformation,
    //   secondaryUser: formValues.secondaryUser.invite === "Yes" ? formValues.secondaryUser : {},
    //   owners: formValues.owners.selected === "NewOwner" ? formValues.owners.selected : {},
    //   beneficiaries: formValues.beneficiaries,
    //   trust: formValues.trust.trustedselected === "Yes" ? formValues.trust.trustedselected : {},
    //   trustee: formValues.trustee.trustSelected === "Yes" ? formValues.trustee.trustSelected : {},
    // };
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setValidateExistsEmail(false);
  };

  const handleReset = () => {
    setActiveStep(0);
    setFormValues({
      personalInformation: {
        firstName: "",
        lastName: "",
        email: "",
        dob: "",
        gender: "",
        phoneNo: "",
      },
      addressInformation: {
        street: "",
        city: "",
        zip: "",
        country: "",
        state: "",
      },
      accountInformation: { username: "", password: "", confirmPassword: "" },
      // secondaryUser: { invite: '', firstName: '', lastName: '', email: '', phoneNo: '', access: '' },
      // owners: { selected: '', firstName: '', lastName: '', gender: '', dob: '', countryOfBirth: '', relationship: '', otherRelationship: '' },
      // beneficiaries: { firstName: '', lastName: '', gender: '', dob: '', countryOfBirth: '', relationship: '', otherRelationship: '' },
      // trust: { trustedselected: '', nameoftrust: '', trusttype: '', createdate: '', idnumber: '' },
      // trustee: { trustSelected: '', firstName: '', lastName: '', email: '', phoneNo: '' },
    });
    setErrors({
      personalInformation: {
        firstName: false,
        email: false,
        emailValid: true,
        firstNameValidate: true,
        lastNameValidate: true,
        dob: false,
        gender: false,
        phoneNo: false,
        phoneNumberValid: true,
      },
      addressInformation: {
        street: false,
        city: false,
        zip: false,
        country: false,
        state: false,
      },
      accountInformation: {
        username: false,
        password: false,
        confirmPassword: false,
        passwordMatch: true,
        passwordValid: true,
        usernameValid: true,
      },
      // secondaryUser: { invite: false, firstName: false, email: false, phoneNo: false, access: false },
      // owners: { selected: false, firstName: false, gender: false, relationship: false, },
      // beneficiaries: { firstName: false, gender: false, relationship: false },
      // trust: { trustedselected: false, nameoftrust: false },
      // trustee: { trustSelected: false, firstName: false, email: false, phoneNo: false }
    });
  };

  const today = dayjs().format("YYYY-MM-DD");
  const handleInputChange = (e, section, field) => {
    const value = e.target.value;

    if (field === "phoneNo") {
      const formattedValue = changeUsFormatNumber(value);

      setFormValues({
        ...formValues,
        [section]: {
          ...formValues[section],
          [field]: formattedValue,
        },
      });
    } else if (field === "zip") {
      if (/^\d{0,5}$/.test(value)) {
        // Validation to allow only up to 5 digits (or empty string)
        setFormValues({
          ...formValues,
          [section]: {
            ...formValues[section],
            ["zip"]: value,
          },
        });
      }
    } else if (field === "dob" && value > today) {
      setFormValues({
        ...formValues,
        [section]: {
          ...formValues[section],
          ["dob"]: "",
        },
      });
      toast.error("You cannot select a future date.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      setFormValues({
        ...formValues,
        [section]: {
          ...formValues[section],
          [field]: value,
        },
      });
    }

    if (errors[section]) {
      setErrors({
        ...errors,
        [section]: {
          ...errors[section],
          [field]: !value,
          ...(field === "email" && { emailValid: validateEmail(value) }),
          ...(field === "firstName" && {
            firstNameValidate: validateAlphanumeric(value),
          }),
          ...(field === "lastName" && {
            lastNameValidate: value === "" ? true : validateAlphanumeric(value),
          }),
          ...(field === "phoneNo" && {
            phoneNumberValid: validatePhoneNumber(changeUsFormatNumber(value)),
          }),
          ...(section === "addressInformation" && {
            ...(field === "zip" && {
              zipValidate: validateZipCode(value.slice(0, 5)),
            }),
          }),
          ...(section === "accountInformation" &&
            field === "confirmPassword" && {
              passwordMatch: value === formValues.accountInformation.password,
            }),
          ...(section === "accountInformation" &&
            field === "password" && {
              passwordMatch:
                value === formValues.accountInformation.confirmPassword,
              passwordValid: validatePassword(value),
            }),
          ...(section === "accountInformation" &&
            field === "username" && {
              usernameValid: validateUsername(value),
            }),
        },
      });
    }
  };

  useEffect(() => {
    const fetchDataFromAPI = () => {
      if (formValues.addressInformation.zip.length === 5) {
        const apiUrl = `https://api.zippopotam.us/us/${formValues.addressInformation.zip}`;
        axios
          .get(apiUrl)
          .then((res) => {
            if (res.data && res.data.places && res.data.places.length > 0) {
              const place = res.data.places[0];
              const city = place["place name"];
              const state = place["state abbreviation"];
              const country = res.data["country abbreviation"];

              setFormValues((prevState) => ({
                ...prevState,
                addressInformation: {
                  ...prevState.addressInformation,
                  city: city,
                  state: state,
                  country: country,
                },
              }));
              setErrors((prevState) => ({
                ...prevState,
                addressInformation: {
                  ...prevState.addressInformation,
                  validZip: true,
                },
              }));
            }
          })
          .catch((error) => {
            setErrors((prevState) => ({
              ...prevState,
              addressInformation: {
                ...prevState.addressInformation,
                validZip: false,
              },
            }));
          });
      } else {
        setFormValues((prevState) => ({
          ...prevState,
          addressInformation: {
            ...prevState.addressInformation,
            city: "",
            state: "",
            country: "",
          },
        }));
      }
    };

    fetchDataFromAPI();
  }, [formValues.addressInformation.zip]);

  const handleSelectChange = (e, section, field) => {
    const value = e.target.value;

    // if (section === 'secondaryUser' && field === 'invite') {
    //   if (value === 'No') {
    //     setFormValues(prevState => ({
    //       ...prevState,
    //       secondaryUser: { invite: value, firstName: '', lastName: '', email: '', phoneNo: '', accessLevel: '' }
    //     }));
    //   } else {
    //     setFormValues(prevState => ({
    //       ...prevState,
    //       secondaryUser: { ...prevState.secondaryUser, invite: value }
    //     }));
    //   }
    // } else {
    //   setFormValues(prevState => ({
    //     ...prevState,
    //     [section]: { ...prevState[section], [field]: value }
    //   }));
    // }
  };

  const handleCheckboxChange = (fieldName) => (event) => {
    if (fieldName === "showPassword") {
      setShowPassword(event.target.checked);
    }
    if (fieldName === "termConditions") {
      setShowTermCondtitions(event.target.checked);
    }
  };

  const handleFlagValueReceived = (flagValue) => {
    setValidmail(flagValue);
    onBack();
  };

  let signIn = "register";

  return (
    <>
      {validmail ? (
        <Otppage
          userDetails={userAllInformation}
          onFlagReceived={handleFlagValueReceived}
          otpType={signIn}
        />
      ) : (
        <div className="login-popup page">
          <div className="signup">
            <Card className="signup_card">
              <div className="signup_heading">
                <p>Create Your Account</p>
                <div className="signup_cancel_icon">
                  <FontAwesomeIcon
                    className="cancel_icon"
                    icon={faTimes}
                    onClick={onBack}
                  />
                </div>
              </div>
              <div className="signup_form_card_main">
                <Stepper
                  activeStep={activeStep}
                  orientation=""
                  className="banrakas"
                >
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel>{step.label}</StepLabel>
                      {/* <StepContent>
                    <Typography>{step.description}</Typography>
                  </StepContent> */}
                    </Step>
                  ))}
                </Stepper>
                <Box className="stepper_form" sx={{ width: "100%" }}>
                  <form onSubmit={handleSubmit} className="signup_submit_form">
                    <div className="signup_submit_form_design">
                      {activeStep === 0 && (
                        <>
                          <div>
                            <FormControl
                              fullWidth
                              error={Boolean(
                                errors.personalInformation.firstName
                              )}
                            >
                              <FormLabel required>First Name</FormLabel>
                              <Input
                                className="input_mui_joy"
                                placeholder="Enter First Name"
                                required
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "personalInformation",
                                    "firstName"
                                  )
                                }
                                value={formValues.personalInformation.firstName}
                              />
                              {(errors.personalInformation.firstName ||
                                !errors.personalInformation
                                  .firstNameValidate) && (
                                <FormHelperText error>
                                  <Typography variant="caption" color="error">
                                    {errors.personalInformation.firstName
                                      ? "First Name is required"
                                      : !errors.personalInformation.firstName
                                      ? "Enter valid first name"
                                      : ""}
                                  </Typography>
                                </FormHelperText>
                              )}
                            </FormControl>
                          </div>

                          <div>
                            <FormLabel>Last Name</FormLabel>
                            <Input
                              className="input_mui_joy"
                              placeholder="Enter Last Name"
                              value={formValues.personalInformation.lastName}
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "personalInformation",
                                  "lastName"
                                )
                              }
                            />
                            {!errors.personalInformation.lastNameValidate && (
                              <FormHelperText error>
                                <Typography variant="caption" color="error">
                                  Enter valid last name
                                </Typography>
                              </FormHelperText>
                            )}
                          </div>

                          <div>
                            <FormControl
                              fullWidth
                              error={Boolean(
                                errors.personalInformation.email ||
                                  !errors.personalInformation.emailValid
                              )}
                            >
                              <FormLabel>Email*</FormLabel>
                              <Input
                                className="input_mui_joy"
                                required
                                placeholder="Enter Email"
                                value={formValues.personalInformation.email}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "personalInformation",
                                    "email"
                                  )
                                }
                              />
                              {(errors.personalInformation.email ||
                                !errors.personalInformation.emailValid ||
                                validateExistsEmail) && (
                                <FormHelperText>
                                  <Typography variant="caption" color="error">
                                    {errors.personalInformation.email
                                      ? "Email is required"
                                      : !errors.personalInformation.emailValid
                                      ? "Enter a valid email"
                                      : validateExistsEmail
                                      ? "Email already exist"
                                      : ""}
                                  </Typography>
                                </FormHelperText>
                              )}
                            </FormControl>
                          </div>
                          <div>
                            <FormControl
                              fullWidth
                              error={Boolean(errors.personalInformation.dob)}
                            >
                              <FormLabel shrink>Date of Birth*</FormLabel>
                              <Input
                                type="date"
                                required
                                value={formValues.personalInformation.dob}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "personalInformation",
                                    "dob"
                                  )
                                }
                                slotProps={{
                                  input: {
                                    max: today,
                                  },
                                }}
                              />
                              {errors.personalInformation.dob && (
                                <FormHelperText error>
                                  <Typography variant="caption" color="error">
                                    Date of Birth is required
                                  </Typography>
                                </FormHelperText>
                              )}
                            </FormControl>
                          </div>
                          <div>
                            <FormControl
                              fullWidth
                              error={Boolean(errors.personalInformation.gender)}
                            >
                              <FormLabel>Gender*</FormLabel>
                              <Select
                                value={formValues.personalInformation.gender}
                                required
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "personalInformation",
                                    "gender"
                                  )
                                }
                                displayEmpty
                                sx={{ height: "40px" }}
                              >
                                <MenuItem value="" disabled>
                                  Select Your Gender
                                </MenuItem>
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                              </Select>
                              {errors.personalInformation.gender && (
                                <FormHelperText>
                                  <Typography variant="caption" color="error">
                                    Gender is required
                                  </Typography>
                                </FormHelperText>
                              )}
                            </FormControl>
                          </div>
                          <div>
                            <FormControl
                              fullWidth
                              error={Boolean(
                                errors.personalInformation.phoneNo ||
                                  !errors.personalInformation.phoneNumberValid
                              )}
                            >
                              <FormLabel>Phone Number*</FormLabel>
                              <Input
                                className="input_mui_joy"
                                required
                                placeholder="Enter Phone Number"
                                value={formValues.personalInformation.phoneNo}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "personalInformation",
                                    "phoneNo"
                                  )
                                }
                              />
                              {(errors.personalInformation.phoneNo ||
                                !errors.personalInformation
                                  .phoneNumberValid) && (
                                <FormHelperText>
                                  <Typography variant="caption" color="error">
                                    {errors.personalInformation.phoneNo
                                      ? "Phone Number is required"
                                      : !errors.personalInformation
                                          .phoneNumberValid
                                      ? "Enter a valid phone number"
                                      : ""}
                                  </Typography>
                                </FormHelperText>
                              )}
                            </FormControl>
                          </div>
                        </>
                      )}
                      {activeStep === 1 && (
                        <>
                          <div>
                            <FormLabel>Street</FormLabel>
                            <Input
                              className="input_mui_joy"
                              placeholder="Enter Street"
                              value={formValues.addressInformation.street}
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "addressInformation",
                                  "street"
                                )
                              }
                            />
                          </div>

                          <div>
                            <FormLabel>Apartment</FormLabel>
                            <Input
                              className="input_mui_joy"
                              placeholder="Enter Apartment"
                              value={formValues.addressInformation.apartment}
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "addressInformation",
                                  "apartment"
                                )
                              }
                            />
                          </div>

                          <div className="address_input">
                            <div className="addform_input_half">
                              <FormLabel>Zip Code</FormLabel>
                              <Input
                                type="number"
                                className="input_mui_joy"
                                placeholder="Enter Zip Code"
                                value={formValues.addressInformation.zip}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "addressInformation",
                                    "zip"
                                  )
                                }
                              />
                              {(errors.addressInformation.zip ||
                                !errors.addressInformation.zipValidate ||
                                !errors.addressInformation.validZip) && (
                                <FormHelperText error>
                                  <Typography variant="caption" color="error">
                                    {errors.addressInformation.zip
                                      ? "Zip code is required"
                                      : !errors.addressInformation.zipValidate
                                      ? "Please enter a valid 5-digit Zip Code"
                                      : !errors.accountInformation.validZip
                                      ? "This is not a valid Zip Code"
                                      : ""}
                                  </Typography>
                                </FormHelperText>
                              )}
                            </div>
                            <div className="addform_input_half">
                              <FormLabel>City</FormLabel>
                              <Input
                                className="input_mui_joy"
                                placeholder="Enter City"
                                value={formValues.addressInformation.city}
                                readOnly
                                disabled
                              />
                            </div>
                          </div>
                          <div className="address_input">
                            <div className="addform_input_half">
                              <FormControl
                                fullWidth
                                error={Boolean(errors.addressInformation.state)}
                              >
                                <FormLabel>State/Province*</FormLabel>
                                <Input
                                  required
                                  className="input_mui_joy"
                                  placeholder="Enter State/Province"
                                  value={formValues.addressInformation.state}
                                  readOnly
                                  disabled
                                />
                              </FormControl>
                            </div>
                            <div className="addform_input_half">
                              <FormControl
                                fullWidth
                                error={Boolean(
                                  errors.addressInformation.country
                                )}
                              >
                                <FormLabel>Country*</FormLabel>
                                <Input
                                  required
                                  className="input_mui_joy"
                                  placeholder="Enter Country"
                                  value={formValues.addressInformation.country}
                                  readOnly
                                  disabled
                                />
                              </FormControl>
                            </div>
                          </div>
                        </>
                      )}
                      {activeStep === 2 && (
                        <>
                          <div>
                            <FormControl
                              fullWidth
                              error={Boolean(
                                errors.accountInformation.username
                              )}
                            >
                              <FormLabel>Username*</FormLabel>
                              <Input
                                required
                                className="input_mui_joy"
                                placeholder="Enter Username"
                                value={formValues.accountInformation.username}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "accountInformation",
                                    "username"
                                  )
                                }
                              />

                              {(errors.accountInformation.username ||
                                !errors.accountInformation.usernameValid ||
                                validateExistsUsername) && (
                                <FormHelperText>
                                  <Typography variant="caption" color="error">
                                    {errors.accountInformation.username
                                      ? "Username is required"
                                      : !errors.accountInformation.usernameValid
                                      ? "Username must start with a lowercase letter and can only contain lowercase letters, numbers, and underscores"
                                      : validateExistsUsername
                                      ? "Username already exist"
                                      : ""}
                                  </Typography>
                                </FormHelperText>
                              )}
                            </FormControl>
                          </div>

                          <div>
                            <FormControl
                              fullWidth
                              error={Boolean(
                                errors.accountInformation.password ||
                                  !errors.accountInformation.passwordValid
                              )}
                            >
                              <FormLabel>Password*</FormLabel>
                              <Input
                                className="input_mui_joy"
                                required
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter Password"
                                value={formValues.accountInformation.password}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "accountInformation",
                                    "password"
                                  )
                                }
                                autoComplete="new-password"
                              />

                              {(errors.accountInformation.password ||
                                !errors.accountInformation.passwordValid) && (
                                <FormHelperText>
                                  <Typography variant="caption" color="error">
                                    {errors.accountInformation.password
                                      ? "Password is required"
                                      : !errors.accountInformation.passwordValid
                                      ? "Password must be at least 10 characters long and include one number, one special character, and one uppercase letter"
                                      : ""}
                                  </Typography>
                                </FormHelperText>
                              )}
                            </FormControl>
                          </div>

                          <div>
                            <FormControl
                              fullWidth
                              error={Boolean(
                                errors.accountInformation.confirmPassword ||
                                  !errors.accountInformation.passwordMatch
                              )}
                            >
                              <FormLabel>Confirm Password*</FormLabel>
                              <Input
                                className="input_mui_joy"
                                required
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter Confirm Password"
                                value={
                                  formValues.accountInformation.confirmPassword
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "accountInformation",
                                    "confirmPassword"
                                  )
                                }
                              />

                              {(errors.accountInformation.confirmPassword ||
                                !errors.accountInformation.passwordMatch) && (
                                <FormHelperText>
                                  <Typography variant="caption" color="error">
                                    {errors.accountInformation.confirmPassword
                                      ? "Confirm Password is required"
                                      : !errors.accountInformation.passwordMatch
                                      ? "Passwords must match"
                                      : ""}
                                  </Typography>
                                </FormHelperText>
                              )}
                            </FormControl>
                            <FormControl fullWidth style={{ marginTop: "8px" }}>
                              <FormControlLabel
                                label="Show Password"
                                control={
                                  <Checkbox
                                    checked={showPassword}
                                    onChange={handleCheckboxChange(
                                      "showPassword"
                                    )}
                                    color="primary"
                                  />
                                }
                              />
                            </FormControl>
                          </div>

                          <div>
                            <FormControl fullWidth>
                              <FormControlLabel
                                label={
                                  <Box display="flex" alignItems="center">
                                    <Typography>
                                      I do accept&nbsp;
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#4b4beb",
                                        }}
                                        // to="/terms-and-conditions"
                                        onClick={() => {
                                          alert(
                                            "term and conditions under development"
                                          );
                                        }}
                                      >
                                        terms and conditions
                                      </Link>
                                      &nbsp;of your site
                                    </Typography>
                                  </Box>
                                }
                                control={
                                  <Checkbox
                                    checked={showTermCondtitions}
                                    onChange={handleCheckboxChange(
                                      "termConditions"
                                    )}
                                    color="primary"
                                  />
                                }
                              />
                            </FormControl>

                            {formSubmitted && !showTermCondtitions && (
                              <FormHelperText>
                                <Typography variant="caption" color="error">
                                  {!showTermCondtitions
                                    ? "Check term and conditions"
                                    : ""}
                                </Typography>
                              </FormHelperText>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <Box className="signup_card_button" sx={{ mb: 1 }}>
                      <Button
                        className="back_button"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        type="button"
                      >
                        Back
                      </Button>
                      {activeStep === steps.length - 1 && (
                        <Button
                          className="next_button"
                          variant="contained"
                          type="submit"
                        >
                          Register
                          <FontAwesomeIcon
                            className="arrow_icon"
                            icon={faArrowRight}
                          />
                        </Button>
                      )}
                      {activeStep !== steps.length - 1 && (
                        <Button
                          className="next_button"
                          variant="contained"
                          onClick={handleNext}
                          type="button"
                        >
                          Next
                          <FontAwesomeIcon
                            className="arrow_icon"
                            icon={faArrowRight}
                          />
                        </Button>
                      )}
                    </Box>
                  </form>
                </Box>
              </div>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default Signup;
