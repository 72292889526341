// Code By purnendu
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import TrusteeApproval from "../pages/TrusteeApproval";
import { getEmailByUsername, sendOtp } from "../services/user-service";
import { EmailTemplatePostfix, Emailtemplate } from "./Emailtemplate";
import Otppage from "./Otppage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function ForgotPassword() {
  const [username, setUsername] = useState("");
  const [validmail, setValidmail] = useState(false);
  const [usermail, setUsermail] = useState("");
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);

  const handleChanges = (e) => {
    setUsername(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    getEmailByUsername(username)
      .then((res) => {
        setUserDetails(res);
        if (!res) {
          toast.error("Username is not correct please correct your username");
          return;
        }
        setUsermail(res.email);
        sendOtp({
          to: res.email,
          subject: "Test Api",
          message: Emailtemplate,
          postfixMessage: EmailTemplatePostfix,
        })
          .then((res) => {
            setValidmail(true);
          })
          .catch((err) => {
            return;
          });
      })
      .catch((err) => {
        toast.error("Server Error" + err);

        return;
      });
  };

  const [visibleFPassword, setVisibleFPassword] = useState(false);

  const handleFlagValueReceived = (flagValue) => {
    setValidmail(flagValue);
    setVisibleFPassword(true);
  };

  let forgetPassword = "forgetPassword";

  useEffect(() => { }, [visibleFPassword]);

  return (
    <>
      {visibleFPassword ? (
        <TrusteeApproval />
      ) : (
        <>
          {validmail ? (
            <Otppage
              userDetails={userDetails}
              usermail={usermail}
              onFlagReceived={handleFlagValueReceived}
              otpType={forgetPassword}
            />
          ) : (
            <>
              <div>
                <div>
                  <Container className="restepassword_container">
                    <div className="restepassword_maincard">

                      <Form
                        onSubmit={(e) => {
                          handleSubmit(e);
                        }}
                      ><div className="login_heading">
                          <p>Forgot Password</p>
                          <div className="login_cancel_icon">
                            <FontAwesomeIcon
                              className="cancel_icon_login"
                              icon={faTimes}
                              onClick={() => window.history.back()}
                            />
                          </div>
                        </div>


                        <div class="flex-column">
                          <label>Username </label></div>
                        <div class="inputForm">

                          <input type="text" class="input"
                            placeholder="Enter your username"
                            id="username"
                            value={username}
                            onChange={(e) => handleChanges(e)}
                          />
                        </div>
                        <button class="button_submit_login">Reset Password</button>

                      </Form>
                    </div>
                  </Container>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
