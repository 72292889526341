import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Textarea } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardHeader } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import SideBar from "../../../components/sidebar/Sidebar";
import UserBase from "../../../components/user/UserBase";
import {
  getSingleMortgageLoan,
  updateMortgageLoan,
} from "../../../services/MortgageLoan";
import { getToken } from "../../../services/user-service";

function EditMortgage() {
  const { id } = useParams();
  const [data, setData] = useState({
    mortgageLoan: {
      estimatedLoan: "",
      financialInstitution: "",
      assetAgainstLoan: "",
      notes: "",
    },
  });

  const navigate = useNavigate();
  const [files, setFiles] = useState([]);

  const handleChanges = (e, field) => {
    const { value } = e.target;
    setData((prevData) => ({
      ...prevData,
      mortgageLoan: {
        ...prevData.mortgageLoan,
        [field]: value,
      },
    }));
  };

  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        // Update state with selected files
        setFiles(selectedFilesArray);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = "";
      }
    }
  };

  const creditCardForm = (event) => {
    event.preventDefault();

    let token = "Bearer " + getToken();
    const formData = new FormData();

    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
    }

    formData.append("data", JSON.stringify(data));
    console.log("data: ", data);

    updateMortgageLoan(formData, token)
      .then((resp) => {
        toast.success("Updated Successfully!!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        navigate("/user/liabilities/mortgage");
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = "Bearer " + getToken();
        const res = await getSingleMortgageLoan(token, id);
        console.log("data: ", res);
        setData({
          ...data,
          mortgageLoan: res.mortgageLoan,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <UserBase>
      <div className="mt-5"></div>
      <SideBar>
        <div className="overlay1" style={{ transition: "500ms", height: "" }}>
          <div>
            <form onSubmit={creditCardForm} className="addform_body">
              <container className="credit_card_base">
                <div className="homepage credit_card_main">
                  <Card>
                    <CardHeader>
                      <div
                        style={{ width: "100%" }}
                        className="addform_heading"
                      >
                        <h3 className="addform_heading_h1">
                          {"Edit Mortgage/Loan"}
                        </h3>
                        <div className="addform_close">
                          <FontAwesomeIcon
                            icon={faXmark}
                            onClick={() => {
                              navigate("/user/liabilities/mortgage");
                            }}
                          />
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="credit_card_body">
                      <div>
                        <table className="credit_card_table">
                          <thead>
                            <tr>
                              <th style={{ width: "192px" }}>
                                Estimated Loan*
                              </th>
                              <th style={{ width: "240px" }}>
                                Financial Institution*
                              </th>
                              <th style={{ width: "240px" }}>
                                Asset Against Loan
                              </th>
                              <th style={{ width: "192px" }}>
                                Supporting Document*
                              </th>
                              <th style={{ width: "220px" }}>Notes</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Input
                                  placeholder="Estimated Loan"
                                  type="number"
                                  onChange={(e) =>
                                    handleChanges(e, "estimatedLoan")
                                  }
                                  value={
                                    data?.mortgageLoan?.estimatedLoan || ""
                                  }
                                  startDecorator={<div>$</div>}
                                  required
                                />
                              </td>
                              <td>
                                <Input
                                  placeholder="Financial Institution"
                                  onChange={(e) =>
                                    handleChanges(e, "financialInstitution")
                                  }
                                  value={
                                    data?.mortgageLoan?.financialInstitution ||
                                    ""
                                  }
                                  required
                                />
                              </td>
                              <td>
                                <Input
                                  placeholder="Asset Against Loan"
                                  onChange={(e) =>
                                    handleChanges(e, "assetAgainstLoan")
                                  }
                                  value={
                                    data?.mortgageLoan?.assetAgainstLoan || ""
                                  }
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  style={{
                                    border: "solid 1px lightgray",
                                    borderLeft: "none",
                                    width: "240px",
                                    borderRadius: "5px",
                                  }}
                                  type="file"
                                  name="myfile"
                                  id="exampleFile"
                                  onChange={handleImageChange}
                                  accept=".pdf"
                                  multiple
                                />
                              </td>
                              <td>
                                <Textarea
                                  style={{
                                    fontSize: "14px",
                                    width: "220px",
                                  }}
                                  placeholder="Notes"
                                  id="notes"
                                  value={data?.mortgageLoan.notes}
                                  onChange={(e) => handleChanges(e, "notes")}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="text-center">
                        <Button
                          type="submit"
                          outline
                          className="my-estate-add-btn"
                        >
                          Update
                        </Button>
                      </div>
                    </CardFooter>
                  </Card>
                </div>
              </container>
            </form>
          </div>
        </div>
      </SideBar>
    </UserBase>
  );
}
export default EditMortgage;
