import { faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import "../css/myestare.css";

function ShowSharedBeneficiary({
  primaryUserDetails,
  secondaryUserDetails,
  removeInsurance,
  showDetail,
  getBenificiaryName,
  handleCloseBeneficiary,
}) {
  return (
    <>
      <div className="popup">
        <div className="popup-content popup-content-download">
          <div className="note_popup">
            <div className="note_popup_heading">
              <div style={{ textAlign: "center", width: "100%" }}>
                <h2>Share Property</h2>
              </div>
              <div>
                <button
                  className="note_popup_heading_close_btn"
                  onClick={() => {
                    handleCloseBeneficiary();
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </div>
            </div>
            <div>
              {showDetail &&
                showDetail.map((details, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "5px",
                      padding: "10px",
                      border: "solid 1px lightgray",
                      borderRadius: "5px",
                      minWidth: "230px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "17px",
                          color: "black",
                          fontWeight: "500",
                        }}
                      >
                        Share - {index + 1}
                      </p>
                      {primaryUserDetails.accountType === "secondary" &&
                      secondaryUserDetails.accountType === "primary" &&
                      secondaryUserDetails.isSecondaryUserEditable ===
                        "false" ? (
                        ""
                      ) : (
                        <FontAwesomeIcon
                          onClick={() =>
                            removeInsurance(details.id, "sharedDetailId")
                          }
                          className="delete-button-comp"
                          style={{ cursor: "pointer" }}
                          icon={faTrash}
                        />
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "20px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "15px",
                          color: "black",
                          fontWeight: "400",
                          marginLeft: "20px",
                        }}
                      >
                        Beneficiary Name:
                        <span style={{ marginLeft: "10px" }}>
                          {getBenificiaryName(details.beneficiaryId)}
                        </span>
                      </p>
                      <p
                        style={{
                          fontSize: "15px",
                          color: "black",
                          fontWeight: "400",
                          marginLeft: "20px",
                        }}
                      >
                        Distributed Type:
                        <span style={{ marginLeft: "10px" }}>
                          {details.distributedType}
                        </span>
                      </p>
                      <p
                        style={{
                          fontSize: "15px",
                          color: "black",
                          fontWeight: "400",
                          marginLeft: "20px",
                        }}
                      >
                        Distributed Value:{" "}
                        <span style={{ marginLeft: "10px" }}>
                          {details &&
                            details.distributedType === "dollar" &&
                            "$"}
                          {details.distributedValue}
                          {details.distributedType === "percentage" && "%"}
                        </span>
                      </p>
                      <p
                        style={{
                          fontSize: "15px",
                          color: "black",
                          fontWeight: "400",
                          marginLeft: "20px",
                        }}
                      >
                        Distributed Amount:
                        <span style={{ marginLeft: "10px" }}>
                          ${details.distributedAmount}
                        </span>
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShowSharedBeneficiary;
