import { faUser, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SideBar from "../../components/sidebar/Sidebar";
import TrusteeBase from "../../components/trustee/TrusteeBase";
import {
  addAttorneyUsers,
  getAllSharedPropety,
  getAttorneyInvitationByAttorneyId,
  getAttorneyUsersByAttorneyId,
  getBeneficiary,
  getExistUserInAttorneyByUserId,
  getToken,
  getUser,
  getUserModelById,
  initiateProperty,
  pdfGenerate,
  removeAttorneyInvitation,
  updateAttorneyUsers,
} from "../../services/user-service";
import "./TrusteeDashboard.css";
import { Notification } from "../../components/alerting-visitors/notification";
import Deletebutton from "../my-estate/Deletebutton";
import { ConfirmationPopup } from "../my-estate/ConfirmationPopup";

export default function Userdashboard() {
  const [properties, setProperties] = useState([]);

  let user = getUser();
  // const [modal, setModal] = useState(false);
  // const toggle = () => setModal(!modal);
  // const [data,setData]=useState({});

  //   const getDataBenificiary=(property)=>{
  //     toggle();
  //     getFormdata(property).then((res)=>{
  //      const result=res.data.filter((obj)=>{
  //          return property.username===obj.user;
  //      })
  //      console.log(result[result.length-1]);
  //      setData(result[result.length-1]);
  //    }).catch((err)=>{
  //      console.log(err);
  //      console.log("error.........");
  //    })
  // }

  const handleInitiate = (property) => {
    initiateProperty(property)
      .then((res) => {
        toast.success("Property Initiate SuccessFully !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        // getdata();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const handleDownloadPDF = async () => {
  //   const pdfContent = PDFFile();
  //   const pdfBlob = await ReactPDF.renderToBlob(pdfContent);

  //   saveAs(pdfBlob, 'my-document.pdf');
  // };

  // const handleGenerate=()=>{
  //   const data=pdfGenerate();
  //   const blob = new Blob([data.data], { type: 'application/octet-stream' });
  //     const downloadUrl = URL.createObjectURL(blob);
  //     const link = document.createElement('a');
  //     link.href = downloadUrl;
  //     link.download = 'BenificiaryProperty.pdf'; // Set the desired file name and extension
  //     link.click();
  //     URL.revokeObjectURL(downloadUrl);
  // }
  const handleGenerate = (property) => {
    console.log("property details : " + property);
    pdfGenerate(property)
      .then((response) => {
        console.log("response : " + response);
        const downloadUrl = URL.createObjectURL(response.data);
        console.log(downloadUrl);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `${getBenificiaryName(property.beneficiaryId)}_${
          property.assetName
        }_${property.id}.pdf`; // Set the desired file name and extension
        link.click();
        URL.revokeObjectURL(downloadUrl);
      })
      .catch((error) => {
        // Handle the error
        console.log("error : " + error);
      });
  };

  // const handleViewdetails=(property)=>{
  //   toggle();
  //   viewDetailsfalse(property).then((res)=>{
  //       console.log("Trustee Can't see...... view details");
  //       toast.success("RollBack Succesfully..... !!", {
  //           position: toast.POSITION.BOTTOM_CENTER,
  //       });
  //   }).catch((err)=>{
  //       console.log("Error..............");
  //       console.log(err);
  //   })
  // }

  // const handleAprove=(property)=>{
  //   toggle();
  //   aproveByTrustee(property).then((res)=>{
  //       console.log(res);
  //       toast.success("Aprove Successfully User can see Benificiarydetails..... !!", {
  //           position: toast.POSITION.BOTTOM_CENTER,
  //       });
  //       getdata();
  //   }).catch((err)=>{
  //       console.log("Error.......");
  //       console.log(err);
  //   })
  // }

  // useEffect(() => {
  //   getproperty(user)
  //     .then((res) => {
  //       console.log(res);
  //       const result = res.data;
  //       setProperties(result);
  //     })
  //     .catch((err) => {
  //       console.log("Error... " + err);
  //     });
  // }, []);

  // for user image
  // const [imageSrc, setImageSrc] = useState("");

  const base64ToImage = (imageData) => {
    const base64String = imageData;
    const trimmedBase64 = base64String ? base64String.trim() : "";
    if (trimmedBase64) {
      // setImageSrc(`data:image/jpeg;base64,${trimmedBase64}`);

      return `data:image/jpeg;base64,${trimmedBase64}`;
    }
    return null;
  };

  const [attorneyUsers, setAttorneyUsers] = useState([]);
  const getAttorneyUserDetail = () => {
    let userId = user.id;
    let token = "Bearer " + getToken();
    getAttorneyUsersByAttorneyId(token, userId)
      .then((res) => {
        setAttorneyUsers(res);
        // base64ToImage(res.data.user.image);
      })
      .catch((err) => console.log(err));
  };

  const [attorneyInvitation, setAttorneyInvitation] = useState([]);
  const getAttorneyInvitationDetail = () => {
    let attorneyEmail = user.email;
    let token = "Bearer " + getToken();
    getAttorneyInvitationByAttorneyId(token, attorneyEmail)
      .then((res) => {
        setAttorneyInvitation(res);
        // base64ToImage(res.data.user.image);
      })
      .catch((err) => console.log(err));
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [currentUsername, setCurrentUsername] = useState(null);
  const [handleFunctionName, setHandleFunctionName] = useState(null);

  const handleAcceptAndRemoveInvite = (id, username, value) => {
    setShowConfirmation(true);
    setCurrentId(id);
    setCurrentUsername(username);
    setHandleFunctionName(value);
  };

  const handleRemove = (params) => {
    setShowConfirmation(false);
    const { Id, roleText, userName } = params;
    if (roleText === "removeInvitation") {
      let token = "Bearer " + getToken();
      removeAttorneyInvitation(token, userName)
        .then((res) => {
          toast.success(`Invitation Removed!`, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          getAttorneyInvitationDetail();
        })
        .catch((error) => {});
    } else {
      let token = "Bearer " + getToken();
      const formData = new FormData();
      getExistUserInAttorneyByUserId(token, Id)
        .then((res) => {
          if (res !== false) {
            const data = {
              id: res.id,
              userId: res.userId,
              attorneyId: res.attorneyId,
              prevUserId: res.prevUserId,
            };
            formData.append("data", JSON.stringify(data));

            updateAttorneyUsers(token, formData)
              .then((res) => {
                toast.success(`Invitation Accepted!`, {
                  position: toast.POSITION.BOTTOM_CENTER,
                });
                getAttorneyUserDetail();
                getAttorneyInvitationDetail();
              })
              .catch((error) => {});
          } else {
            let attorneyId = user.id;

            const data = {
              userId: Id,
              attorneyId: attorneyId,
            };
            formData.append("data", JSON.stringify(data));

            addAttorneyUsers(token, formData)
              .then((res) => {
                toast.success(`Invitation Accepted!`, {
                  position: toast.POSITION.BOTTOM_CENTER,
                });
                getAttorneyUserDetail();
                getAttorneyInvitationDetail();
              })
              .catch((error) => {});
          }
        })
        .catch((error) => {});
    }
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = (userId) => {
    console.log("userId: ", userId);
    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => console.log(err));
  };

  const getBenificiaryName = (id) => {
    console.log("getBenificiaryName id : ", id);
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      console.log("IF condition");
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }
    console.log("foundBenificiary details : ", foundBenificiary);
    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found"; // Or handle the case where beneficiary with the given ID isn't found
    }
  };

  const getPropertyData = () => {
    let userId = user.userid;
    let token = "Bearer " + getToken();
    getAllSharedPropety(token, userId)
      .then((res) => {
        console.log("data:", res);
        setProperties(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [showUserDetails, setShowUserDetails] = useState(null);
  const [show, setShow] = useState(false);
  const toggleShow = (user) => {
    setShowUserDetails(user);
    setShow(!show);
  };

  useEffect(() => {
    getAttorneyUserDetail();
    getAttorneyInvitationDetail();
  }, []);

  return (
    <TrusteeBase>
      <div style={{ marginTop: "4rem" }}></div>
      {/* <SideBar> */}
      {/* <container>
          <div
            className="homepage"
            style={{ display: "flex", width: "80vw", height: "auto" }}
          >
            <Card className="profile" style={{ flex: "2" }}>
              <img
                className="profilepic"
                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                alt="React"
              ></img>
              <name className="name" style={{color:"black"}}>
                {user.firstName + " "}
                {user.lastName}
              </name>
              <username className="profilealign">
                User Name : {user.username}
              </username>
              <email className="profilealign">Email ID : {user.email}</email>
              <userid className="profilealign">User ID:{user.id}</userid>
              <role className="profilealign">Role : {user.role} </role>
            </Card>
            <Card
              style={{
                overflow: "auto",
                height: "fitContent",
                minHeight: "50px",
                maxHeight: "100vh",
              }}
            >
              <CardBody>
                <Table>
                  <TableHead>
                    <TableRow>
                      <th className="tabe_rowsl">S.N</th>
                      <th className="tabe_row">Beneficiary Name</th>
                      <th>View Details</th>
                      <th className="tabe_row">Property Name</th>
                      <th className="tabe_row">Property Details</th>
                      <th className="tabe_row">Property From</th>
                      <th className="tabe_row">View Details</th>
                      <th className="tabe_row">Actions</th>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {properties.map((property, index) => (
                      <TableRow key={index} style={{ textAlign: "center" }}>
                        <td style={{ textAlign: "start" }} >
                          {index + 1}
                        </td>
                        <td>{getBenificiaryName(property.beneficiaryId)}</td>

                        <td>
                          {property.assetName === "internationalAsset"
                            ? "Pending"
                            : property.assetName.charAt(0).toUpperCase() +
                              property.assetName.slice(1)}
                        </td>
                        <td>
                          {property.propertyid}
                          <Propertydetails property={property.propertyname} Id={property.propertyid} />
                        </td>
                        <td>
                          {user1?.data?.user?.firstName +
                            " " +
                            user1?.data?.user?.lastName}
                        </td>
                        <td>
                          {property.viewTrustee ? (
                            <Benificiarydetailsbytrustee
                              property={property}
                              role={"trustee"}
                            />
                          ) : (
                            <Button color="info" disabled>
                              View
                            </Button>
                          )}
                        </td>
                        <td style={{ display: "flex" }}>
                          {property.userApprove ? (
                            <Button
                              color="success"
                              onClick={() => {
                                handleGenerate(property);
                              }}
                            >
                              Generate
                            </Button>
                          ) : (
                            <Button color="success" disabled>
                              Generate
                            </Button>
                          )}

                          {property.initiateBoolean ? (
                            <Button
                              color="warning"
                              style={{
                                marginLeft: "10px",
                                width: "94px",
                                marginbuttom: "auto",
                              }}
                              disabled
                            >
                              Initiated
                            </Button>
                          ) : (
                            <Button
                              color="warning"
                              onClick={() => {
                                handleInitiate(property);
                              }}
                              style={{ marginLeft: "10px", width: "94px" }}
                            >
                              Initiate
                            </Button>
                          )}
                        </td>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </container> */}

      <div
        style={{
          backgroundColor: "yellow",
          padding: "3px 0 6px 0",
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <Notification />
        </Suspense>
      </div>

      {attorneyInvitation.length > 0 &&
        attorneyInvitation.map((userData, index) => {
          const imageSrc = base64ToImage(userData?.image);
          return (
            <div
              key={index}
              className="add_trustee_list"
              style={{ marginTop: "1.5rem" }}
            >
              <div className="add_trustee_details">
                {imageSrc ? (
                  <img src={imageSrc} alt="Trustee_profile_pic" />
                ) : (
                  <img src="./../img/avtar.jpg" alt="Not Trustee_profile_pic" />
                )}

                <div className="add_trustee_name_email">
                  <div className="add_trustee_name">
                    <li className="add_trustee_name_line">Name: </li>
                    <li>
                      {userData.firstName} {userData.lastName}
                    </li>
                  </div>
                  <div className="add_trustee_email">
                    <li className="add_trustee_email_line">Address: </li>
                    <li>
                      {userData.street && `${userData.street}, `}
                      {userData.city && `${userData.city}, `}
                      {userData.state && `${userData.state}, `}
                      {userData.zip && `${userData.zip} `}
                    </li>
                  </div>
                </div>
              </div>
              <div className="add_trustee_btn">
                <button
                  className="add_trustee_details_btn"
                  onClick={() => {
                    handleAcceptAndRemoveInvite(userData.id, userData.username, 'removeInvitation');
                  }}
                >
                  Cancel
                </button>
                {/* <Deletebutton
                    handleRemove={handleRemove}
                    Id={userData.id}
                    userName={userData.username}
                    roleText="removeInvitation"
                  /> */}

                {/* <Deletebutton
                    handleRemove={handleRemove}
                    Id={userData.id}
                    userEmail={userData.email}
                    roleText="acceptInvitation"
                  /> */}
                <button
                  style={{ border: "none" }}
                  type="button"
                  onClick={() => {
                    handleAcceptAndRemoveInvite(userData.id, userData.email, 'acceptInvite');
                  }}
                  className="add_trustee_Add"
                >
                  Accept Invitation
                </button>
              </div>
            </div>
          );
        })}

      {attorneyUsers.length > 0 &&
        attorneyUsers.map((userData, index) => {
          const imageSrc = base64ToImage(userData?.image);
          return (
            <div
              key={index}
              className="add_trustee_list"
              style={{ marginTop: "1.5rem" }}
            >
              <div className="add_trustee_details">
                {imageSrc ? (
                  <img src={imageSrc} alt="Trustee_profile_pic" />
                ) : (
                  <img src="./../img/avtar.jpg" alt="Not Trustee_profile_pic" />
                )}

                <div className="add_trustee_name_email">
                  <div className="add_trustee_name">
                    <li className="add_trustee_name_line">Name: </li>
                    <li>
                      {userData.firstName} {userData.lastName}
                    </li>
                  </div>
                  <div className="add_trustee_email">
                    <li className="add_trustee_email_line">Address: </li>
                    <li>
                      {userData.street && `${userData.street}, `}
                      {userData.city && `${userData.city}, `}
                      {userData.state && `${userData.state}, `}
                      {userData.zip && `${userData.zip} `}
                    </li>
                  </div>
                </div>
              </div>
              <div className="add_trustee_btn">
                <button
                  className="add_trustee_details_btn"
                  onClick={() => {
                    toggleShow(userData);
                    getBenificiarydata(userData.id);
                  }}
                >
                  Details
                </button>
                <Link
                  className="add_trustee_Add"
                  to={`view-assets/${userData.id}`}
                >
                  View Assets
                </Link>
              </div>
            </div>
          );
        })}

      {show && Object.keys(showUserDetails).length > 0 && (
        <>
          <div className="card__data" style={{ transition: "all 1s ease-out" }}>
            <div className="card__data-container">
              <section className="section1">
                <div>
                  <p className="row1-text">
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ color: "#025596", fontSize: "18px" }}
                    />
                    <span style={{}}>
                      {showUserDetails.firstName} {showUserDetails.lastName}
                    </span>
                  </p>
                  <div className="row1-button">
                    <div>
                      <span
                        className="card__data-close"
                        onClick={() => {
                          toggleShow();
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </span>
                    </div>
                  </div>
                </div>
              </section>

              <section className="section2">
                <div>
                  <div className="col1">
                    {showUserDetails.firstName && (
                      <p>
                        Name:{" "}
                        <code>
                          {showUserDetails.firstName} {showUserDetails.lastName}
                        </code>
                      </p>
                    )}
                    {showUserDetails.email && (
                      <p>
                        Email:{" "}
                        <code style={{ textTransform: "lowercase" }}>
                          {showUserDetails.email}
                        </code>
                      </p>
                    )}
                    {showUserDetails.dob && (
                      <p>
                        Date of birth: <code>{showUserDetails.dob}</code>
                      </p>
                    )}
                    {showUserDetails.phoneNo && (
                      <p>
                        Phone Number: <code>{showUserDetails.phoneNo}</code>
                      </p>
                    )}
                    {showUserDetails.currentAddress && (
                      <p>
                        Current Address:{" "}
                        <code>{showUserDetails.currentAddress}</code>
                      </p>
                    )}
                  </div>
                  <div className="col2">
                    {showUserDetails.gender && (
                      <p>
                        Gender: <code>{showUserDetails.gender}</code>
                      </p>
                    )}
                    {showUserDetails.meritalStatus && (
                      <p>
                        Marital Status:{" "}
                        <code>{showUserDetails.meritalStatus}</code>
                      </p>
                    )}
                    {showUserDetails.jobStatus && (
                      <p>
                        Job Status: <code>{showUserDetails.jobStatus}</code>
                      </p>
                    )}
                    {showUserDetails.permanentAddress && (
                      <p>
                        Permanent Address:{" "}
                        <code>{showUserDetails.permanentAddress}</code>
                      </p>
                    )}
                    {beneficiary.length > 0 && (
                      <p>
                        Beneficiary:{" "}
                        {beneficiary.map((beneDetails, index) => (
                          <code key={index}>
                            {index > 0 ? ", " : ""}
                            {`${beneDetails.firstName} ${beneDetails.lastName}`}
                          </code>
                        ))}
                      </p>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </>
      )}

      {showConfirmation && (
        <ConfirmationPopup
          handleConfirmation={handleRemove}
          handleConfirmationClose={handleConfirmationClose}
          Id={currentId}
          userName={currentUsername}
          roleText={handleFunctionName}
          anyText={
            handleFunctionName === "removeInvitation"
              ? "Are you sure, do you want to remove invite?"
              : "Are you sure, do you want to accept invite?"
          }
        />
      )}
      {/* </SideBar> */}
      {/* <div>
        <Footer />
      </div> */}
    </TrusteeBase>
  );
}
